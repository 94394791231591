import { useContext } from 'react';
import { useSelector } from 'react-redux';

import useCurrency from '../modules/currency/hooks/use-currency';
import { selectCart, selectCheckout } from '../store/slice';
import DataContext from '../store/dataContext';
import UiContext from '../store/uiContext';
import { platformValue } from '../helpers/prelaunchData';
import { platformList } from '../commons/constants';

const useCartTotalsUtils = () => {
  const { getShoppercurrency } = useCurrency();
  const cartData = useSelector(selectCart);
  const checkoutData = useSelector(selectCheckout);
  const { giftCertificates } = useContext(DataContext);
  const { done } = useContext(UiContext);

  const shouldRenderBilledInUSD = () =>
    getShoppercurrency() &&
    Object.keys(getShoppercurrency()).length > 0 &&
    getShoppercurrency().baseCode !== 'USD' &&
    !getShoppercurrency().isTransactional;

  const isGiftAvailable = () => (giftCertificates ? giftCertificates?.length > 0 : false);

  const getOrderTotal = () => {
    const address = checkoutData?.address?.line1;
    const gift = isGiftAvailable();
    const isBigcom = platformValue === platformList.bigcommerce;
    if (checkoutData?.shouldApplyStoreCredit) {
      return cartData?.totals.billed;
    }
    if (!address) {
      return cartData?.totals.subtotal;
    }
    if (gift && !address && isBigcom && !done) {
      return (cartData?.totals?.total || 0) - (cartData?.totals?.shipping || 0);
    }
    return cartData?.totals.billed;
  };

  return { shouldRenderBilledInUSD, getOrderTotal };
};

export default useCartTotalsUtils;
