import React, { useContext, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import PhoneInput from 'react-phone-number-input';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-phone-number-input/style.css';
import classNames from 'classnames';

import { datadogRum } from '@datadog/browser-rum';
import { selectApiStatus, selectCart, selectCheckout, cartSlice } from '../../store/slice';
import DataContext from '../../store/dataContext';
import { parsePhoneInternational } from '../../commons/utils/phone-parser';
import useApiPostOrderError from '../../hooks/useApiPostOrderError';
import useBillingPhone from '../../commons/hooks/use-billing-phone';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import useErrors from '../../store/errors/useErrors';
import UiContext from '../../store/uiContext';
import { setStatus } from '../../modules/billing/slice';
import { analyticsStage2 } from '../../helpers/constants';
import { useEndpoint } from '../../hooks/useEndpoint';

import './style.scss';

const BillingPhoneWithDDI = ({
  phoneLabel,
  classNameInput,
  disabled,
  error,
  themeColor,
  outlineColor,
  isPhoneRequired,
}) => {
  const {
    billingPhone,
    updateBillingPhone,
    contextBillingAddress,
    contextShippingAddress,
    setValidNumber,
    resetTimestamp,
  } = useContext(DataContext);

  const { postAnalyticsStage2 } = useEndpoint();
  const [isNotEmpty, setIsNotEmpty] = useState('');
  const [country, setCountry] = useState('');
  const { validatePhone } = useBillingPhone();
  const [defaultCountry, setDefaultCountry] = useState('US');

  const { addErrorToField, resetErrorOnField } = useErrors();
  const { hasPhoneError } = useApiPostOrderError();

  const dispatch = useDispatch();
  const checkoutData = useSelector(selectCheckout);

  const input = useRef(null);
  const cartData = useSelector(selectCart);
  const statusApi = useSelector(selectApiStatus);
  const uiContext = useContext(UiContext);

  const setPhoneNumberSuccess = () => {
    resetErrorOnField('billingPhone');
    dispatch(setStatus({ isReady: true }));
  };

  const setPhoneNumberError = message => {
    addErrorToField({
      billingPhone: {
        hasHighlight: false,
        message,
      },
    });
  };

  const validatePhoneNumber = phone => {
    if (isPhoneRequired && !phone) {
      setPhoneNumberError(`Please enter a valid ${phoneLabel}`);
      return false;
      // eslint-disable-next-line no-else-return
    } else if (!isPhoneRequired && !phone && !hasPhoneError()) {
      setPhoneNumberSuccess();
      return true;
    }
    const validate = validatePhone(phone);

    if (validate === 'TOO_SHORT') {
      setPhoneNumberError(`${phoneLabel} is too short`);
      setValidNumber(false);
      return false;
    }

    if (validate === 'TOO_LONG') {
      setPhoneNumberError(`${phoneLabel} is too long`);
      setValidNumber(false);
      return false;
    }

    if (
      validate === 'INVALID_NUMBER' ||
      validate === 'INVALID_COUNTRY' ||
      validate === 'NOT_A_NUMBER' ||
      validate === 'INVALID_LENGTH'
    ) {
      setPhoneNumberError(`Please enter a valid ${phoneLabel}`);
      setValidNumber(false);
      return false;
    }

    setPhoneNumberSuccess();
    setValidNumber(true);
    return true;
  };

  const handleSuggestNumber = (number, countryIsoCode = 'US') => {
    if (number) {
      const parsedNumber = parsePhoneInternational(number, countryIsoCode);
      if (parsedNumber && validatePhone(parsedNumber) === 'VALID_NUMBER' && !billingPhone) {
        updateBillingPhone(parsedNumber);
        dispatch(cartSlice.actions.setPhone(parsedNumber));
        setPhoneNumberSuccess();
        setValidNumber(true);
      }
      dispatch(cartSlice.actions.setPhone(billingPhone));
    }
  };

  useEffect(() => {
    const loginNumber = cartData?.customer?.phone;
    if (loginNumber && statusApi?.event === 'createCart' && !billingPhone) {
      handleSuggestNumber(loginNumber);
    }

    let suggestion = cartData?.suggestions?.find(
      item => item.address?.singleLine === checkoutData?.address?.singleLine,
    );

    if (!suggestion && cartData?.suggestions?.length > 0) {
      suggestion = cartData?.suggestions[0];
    }

    const suggestionNumber = suggestion?.phone;
    const suggestionCountry = suggestion?.address?.country;

    if (
      suggestionNumber &&
      (statusApi?.event === 'createCart' || statusApi?.event === 'emailChange' || statusApi?.event === 'suggestion')
    ) {
      handleSuggestNumber(suggestionNumber, suggestionCountry);
    }
  }, [cartData, statusApi]);

  const handleFocus = () => {
    const phoneInputs = document.getElementsByClassName('phone-with-ddi');
    for (let x = 0; x < phoneInputs?.length; x++) {
      phoneInputs[x].style.border = `2px solid ${outlineColor}`;
    }

    setIsNotEmpty('is-value');

    resetErrorOnField('billingPhone');
    uiContext.updateLocalError(
      uiContext.localError.nameError,
      uiContext.localError.addressError,
      uiContext.localError.creditCardError,
    );
  };

  useEffect(() => {
    if (billingPhone) {
      setIsNotEmpty('is-value');
    }
  }, []);

  const handleBlur = () => {
    const phoneInputs = document.getElementsByClassName('phone-with-ddi');
    for (let x = 0; x < phoneInputs?.length; x++) {
      phoneInputs[x].style.border = null;
    }

    if (checkoutData.customer.phone !== billingPhone) {
      if (validatePhoneNumber(billingPhone)) {
        datadogRum.addAction('valid-phone-entered');
        dispatch(cartSlice.actions.setPhone(billingPhone));
        postAnalyticsStage2({ step: analyticsStage2.PHONE });
      } else {
        datadogRum.addAction('invalid-phone-entered');
      }
    }
  };

  const handleOnChange = value => {
    updateBillingPhone(value);
  };

  const handleOnChangeCountry = c => {
    if (c) {
      setCountry(c);
    } else {
      setCountry(defaultCountry);
    }
  };

  const resetState = () => {
    updateBillingPhone('');
    resetErrorOnField('billingPhone');
    setDefaultCountry('US');
  };

  useEffect(() => {
    if (contextBillingAddress.country) {
      setDefaultCountry(contextBillingAddress.country);
    } else if (contextShippingAddress.country) {
      setDefaultCountry(contextShippingAddress.country);
    } else {
      setDefaultCountry('US');
    }
  }, [contextBillingAddress?.country, contextShippingAddress?.country]);

  useDidUpdateEffect(resetState, resetTimestamp);

  return (
    <div tabIndex="-1" className="phone-input-wrapper" data-testid="phone-with-ddi">
      {disabled && <div className="nf-disabled-but-clickable" />}
      <PhoneInput
        className={`${classNameInput} ${isNotEmpty} phone-with-ddi`}
        ref={input}
        id="phone-with-ddi"
        onChange={handleOnChange}
        value={disabled ? '' : billingPhone}
        onCountryChange={handleOnChangeCountry}
        onFocus={handleFocus}
        onBlur={handleBlur}
        country={country}
        defaultCountry={defaultCountry}
        disabled={disabled}
        autoComplete="tel-national"
        type="tel-national"
        international={false}
        initialValueFormat="national"
        addInternationalOption={false}
        countrySelectProps={{ autoComplete: 'country', name: 'country' }}
        countryOptionsOrder={['US', 'CA', 'MX', '|', '...']}
      />
      <label className={disabled ? 'phone-disabled' : ''} tabIndex="-1" htmlFor="phone-with-ddi">
        <span
          tabIndex="-1"
          className={classNames({
            placeholder: true,
          })}
          style={{ display: billingPhone ? 'none' : '' }}
        >
          {isPhoneRequired ? '* ' : ''}
          {phoneLabel}
        </span>
        <span tabIndex="-1" className="placeholder-top" style={{ color: themeColor }}>
          {phoneLabel}
        </span>
      </label>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

BillingPhoneWithDDI.propTypes = {
  phoneLabel: PropTypes.string,
  classNameInput: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  themeColor: PropTypes.string,
  outlineColor: PropTypes.string,
  isPhoneRequired: PropTypes.bool,
};

export default BillingPhoneWithDDI;
