import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { tldList } from '../helpers/topLevelDomains';

const useValidate = () => {
  const { t } = useTranslation();

  const initialErrorValue = {
    error: false,
    message: '',
  };

  const validateEmail = value => {
    const error = { ...initialErrorValue };
    if (
      !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+$/i.test(value) && tldList.includes(value.substring(value.lastIndexOf('.') + 1))) &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ) {
      error.message = t('validate.invalid_email_address');
      error.error = true;
    }
    return error;
  };

  const validateNewAddress = address => {
    const testingAddress = { ...address };
    if (!testingAddress.apt) {
      testingAddress.apt = 'astub';
    }
    return !_.some(testingAddress, _.isEmpty);
  };

  const validateName = name => {
    const error = { ...initialErrorValue };
    // don't present any errors if the field is empty
    if (!name) {
      return error;
    }
    if (name.replace(/\s/g, '').length < 3) {
      error.message = t('validate.name_too_short');
      error.error = true;
    } else if (name.trim().split(/[ ,]+/).length <= 1) {
      error.message = t('validate.please_fill_in_first_and_last_name');
      error.error = true;
    }
    return error;
  };

  const validatePhone = phone => {
    const error = { ...initialErrorValue };
    if (phone.replace(/\s/, '').length < 8) {
      error.message = t('validate.please_enter_a_valid_phone_number');
      error.error = true;
    }
    return error;
  };

  const isBillingValid = billing => {
    const { billingFullName, billingAddress1Term, phone } = billing;
    const isNameValid = !validateName(billingFullName).error;
    const isPhoneValid = !validatePhone(phone).error;
    const isAddress1Valid = billingAddress1Term.length > 0;
    if (isNameValid && isPhoneValid && isAddress1Valid) {
      return true;
    }
    return false;
  };

  return { initialErrorValue, validateEmail, validateNewAddress, validateName, validatePhone, isBillingValid };
};

export default useValidate;
