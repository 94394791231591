import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { currentBaseUrl } from '../../../../helpers/urlConst';

import ShippingMethodImage from '../../../../commons/components/shipping-method-image';
import { amountFormatter } from '../../../../helpers/format';

import { returnMethod, findSelectedMethod, parseTypeAsText } from '../../utils';

import style from './index.module.scss';

const CollapsedData = ({
  address,
  line2,
  company,
  fullName,
  methods,
  shopperCurrency,
  title,
  toggleCollapse,
  shippingMethodKey,
}) => {
  const { t } = useTranslation();

  const hasDataAvailable = [address, line2, company, fullName].some(Boolean);

  const getSelectedMethodLabel = () => {
    if (methods && methods.length > 0) {
      const selectedMethod = findSelectedMethod({
        list: methods,
        returnType: returnMethod.object,
        selectedKey: shippingMethodKey,
      });
      return (
        <>
          <p className={style['type-label']}>
            <ShippingMethodImage type={selectedMethod?.type} label={selectedMethod?.label} />
            {parseTypeAsText(t(`shipping_method_select.${selectedMethod?.type}`), selectedMethod?.label)}
            {` / `}
            {selectedMethod?.price !== 0
              ? `${amountFormatter({
                  shopperCurrency,
                  value: selectedMethod?.price.toFixed(2),
                })}`
              : t('shipping_method_select.free')}
          </p>
        </>
      );
    }
    return null;
  };

  const handleClick = () => {
    toggleCollapse();
  };

  const renderData = () => (
    <>
      <h3>{title}</h3>
      <input
        className={style['edit-data']}
        type="image"
        alt={`${t('collapsed_data.edit')} ${title}`}
        src={`${currentBaseUrl()}assets/edit-pencil.svg`}
        onClick={handleClick}
      />
      <p>
        <span className={style.strong}>{fullName}</span> {company ? ` - ${company}` : ``}
      </p>
      <p>{address}</p>
      <p>{line2}</p>
      {getSelectedMethodLabel()}
    </>
  );

  const renderEmpty = () => (
    <>
      <h3>{title}</h3>
      <input
        className={style['edit-data']}
        type="image"
        alt={`${t('collapsed_data.edit')} ${title}`}
        src={`${currentBaseUrl()}assets/edit-pencil.svg`}
        onClick={handleClick}
      />
      <p className={style['no-data']}>{t('collapsed_data.information_not_provided_yet')}</p>
    </>
  );

  if (hasDataAvailable) {
    return <div className={style.wrapper}>{renderData()}</div>;
  }

  return <div className={style.wrapper}>{renderEmpty()}</div>;
};

CollapsedData.propTypes = {
  address: PropTypes.string,
  line2: PropTypes.string,
  company: PropTypes.string,
  fullName: PropTypes.string,
  methods: PropTypes.oneOfType([PropTypes.object]),
  shopperCurrency: PropTypes.oneOfType([PropTypes.object]),
  title: PropTypes.string.isRequired,
  toggleCollapse: PropTypes.func,
  shippingMethodKey: PropTypes.string,
};

export default CollapsedData;
