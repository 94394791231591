import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCart,
  selectCheckout,
  patchEnrich as enrichData,
  postSubmit as submitData,
  postAnalyticsStage1 as analyticsStage1,
  postAnalyticsStage2 as analyticsStage2,
  selectBigcomGiftCard,
} from '../store/slice';
import { paymentIdList } from '../helpers/constants';
import DataContext from '../store/dataContext';
import { currentPaymentMethod } from '../modules/payment/slice';
import { usePlugin } from './usePlugin';
import { useStoreApi } from './useStoreApi';
import { convertGiftsToBackend } from '../components/Coupon/CouponUtils';

export const useEndpoint = () => {
  const dispatch = useDispatch();
  const cartData = useSelector(selectCart);
  const checkoutData = useSelector(selectCheckout);
  const { merchantId, platformCartId, device, storeUrl, applyStoreCredit, orderNotes, giftCertificates } =
    useContext(DataContext);
  const selectedMethod = useSelector(currentPaymentMethod);
  const { selectPluginConsent } = usePlugin();
  const { storeVar } = useStoreApi();
  const certificates = convertGiftsToBackend(giftCertificates);
  const bigcomGiftCard = useSelector(selectBigcomGiftCard);

  const addInput =
    selectedMethod === paymentIdList.PAYPAL ||
    selectedMethod === paymentIdList.PAYLATER ||
    selectedMethod === paymentIdList.AMAZONPAY;

  const postSubmit = async ({
    id,
    cartId,
    address,
    billing,
    payment,
    shippingMethodKey,
    pickUpMethodKey,
    mergedInfo,
  }) => {
    const payload = {
      merchantId,
      cartId: cartId || checkoutData?.id,
      cart: cartData,
    };
    const checkoutPayload = {
      customer: {
        ...checkoutData.customer,
        deviceID: device?.id,
      },
    };

    if (address && Object.values(address)?.length > 0) {
      checkoutPayload.address = address;
    }

    if (billing && Object.values(billing)?.length > 0) {
      checkoutPayload.billing = billing;
    }

    if (shippingMethodKey) {
      checkoutPayload.shippingMethodKey = shippingMethodKey;
    } else if (pickUpMethodKey) {
      checkoutPayload.pickUpMethodKey = pickUpMethodKey;
    }

    if (payment) {
      checkoutPayload.payment = payment;
    }

    if (id) {
      checkoutPayload.id = id;
    }

    checkoutPayload.input = {
      ...checkoutData?.input,
      plugin: {
        ...checkoutData?.input?.plugin,
        ...selectPluginConsent('attentive'),
        ...selectPluginConsent('bigcommerceNewsletter'),
        ...selectPluginConsent('klaviyo'),
      },
    };

    checkoutPayload.plugin = {
      socialImpact: {
        nonprofitId: storeVar?.nonprofitId,
        selectionId: storeVar?.selectionId,
      },
    };

    checkoutPayload.notes = [orderNotes];

    if (applyStoreCredit !== null) {
      checkoutPayload.shouldApplyStoreCredit = applyStoreCredit;
    }

    if (bigcomGiftCard) {
      checkoutPayload.giftCards = [bigcomGiftCard];
    }
    if (checkoutData.giftCards) {
      checkoutPayload.giftCards = checkoutData.giftCards;
    }

    checkoutPayload.giftCertificates = certificates;

    let checkout = {};
    if (mergedInfo) {
      checkout = {
        ...mergedInfo,
        ...checkoutPayload,
      };
    } else {
      checkout = {
        ...checkoutData,
        ...checkoutPayload,
      };
    }

    payload.checkout = checkout;
    const data = await dispatch(
      submitData({
        merchantId,
        lookupKey: platformCartId,
        cartId: checkoutData?.id,
        cart: cartData,
        storeUrl,
        checkout,
      }),
    ).unwrap();
    return data;
  };

  const patchEnrich = async ({
    address,
    billing,
    email,
    shippingMethodKey,
    payment,
    additionalCharges,
    coupons,
    giftCards,
    input,
    inputPayment,
    shouldApplyStoreCredit,
    plugin,
    removeShippingMethod,
    pickUpMethodKey,
    removePickUpMethod,
  }) => {
    const paymentData = {
      paypal: {
        storeURL: window.location.href,
      },
      amazonpay: {
        storeURL: window.location.href,
      },
    };
    const payload = {
      merchantId,
      cartId: checkoutData?.id,
      cart: cartData,
    };
    const checkoutPayload = {
      customer: {
        ...checkoutData.customer,
        deviceID: device?.id,
      },
    };
    if (email) {
      checkoutPayload.customer.email = email;
    }
    if (shippingMethodKey) {
      checkoutPayload.shippingMethodKey = shippingMethodKey;
    }
    if (pickUpMethodKey) {
      checkoutPayload.pickUpMethodKey = pickUpMethodKey;
    }
    if (additionalCharges) {
      checkoutPayload.additionalCharges = additionalCharges;
    }
    if (address && Object.values(address)?.length > 0) {
      checkoutPayload.address = address;
    }
    if (billing && Object.values(billing)?.length > 0) {
      checkoutPayload.billing = billing;
    }
    if (coupons) {
      checkoutPayload.coupons = coupons;
    }
    if (giftCards) {
      checkoutPayload.giftCards = giftCards;
    }
    if (payment) {
      checkoutPayload.payment = payment;
    }
    if (input) {
      checkoutPayload.input = {};
      if (plugin) {
        checkoutPayload.input.plugin = {
          ...(checkoutData.input?.plugin || {}),
          ...plugin,
        };
      }
      if (inputPayment) {
        checkoutPayload.input.payment = paymentData;
      }
    }
    if (addInput) {
      if (!checkoutPayload.input) {
        checkoutPayload.input = {};
      }
      checkoutPayload.input.payment = paymentData;
    }
    if (shouldApplyStoreCredit !== null) {
      checkoutPayload.shouldApplyStoreCredit = shouldApplyStoreCredit;
    }
    const checkout = {
      ...checkoutData,
      ...checkoutPayload,
    };
    if (removeShippingMethod) {
      delete checkout.shippingMethodKey;
    }
    if (removePickUpMethod) {
      delete checkout.pickUpMethodKey;
    }
    payload.checkout = checkout;
    const data = await dispatch(
      enrichData({
        merchantId,
        cartId: checkoutData?.id,
        cart: cartData,
        checkout,
      }),
    ).unwrap();
    return data;
  };

  const postAnalyticsStage1 = async ({ cartId, step, hasLoaded }) => {
    const data = await dispatch(
      analyticsStage1({
        cartId: cartId || checkoutData?.id,
        hasLoaded,
        merchantId,
        step,
      }),
    ).unwrap();

    return data;
  };

  const postAnalyticsStage2 = async ({ cartId, step, hasLoaded }) => {
    const data = await dispatch(
      analyticsStage2({
        cartId: cartId || checkoutData?.id,
        hasLoaded,
        merchantId,
        step,
      }),
    ).unwrap();

    return data;
  };

  return { patchEnrich, postSubmit, postAnalyticsStage1, postAnalyticsStage2 };
};
