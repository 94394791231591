import { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectCheckout } from '../store/slice';
import { setPaymentMethod, currentPaymentMethod } from '../modules/payment/slice';
import { status, paymentIdList } from '../helpers/constants';
import autofillParser from '../helpers/autofillParser';
import { CHECKOUT_VAULT_URL } from '../helpers/urlConst';
import DataContext from '../store/dataContext';
import UiContext from '../store/uiContext';
import useApiStatus from '../store/apiStatus/useApiStatus';
import { EVENT, trigger } from '../commons/utils/custom-event';

import { useProduct } from './useProduct';

const useAutofill = () => {
  const {
    updateUserCardId,
    updateIsAutofill,
    updateShippingName,
    updateShippingAddress1,
    updateShippingAddress2,
    updateCompanyName,
    updateContextShippingAddress,
  } = useContext(DataContext);
  const { TO_CARD_ADD, setShowUserAddControl, updateCheckoutState, TO_SHIPPING_ADDRESS_ADD } = useContext(UiContext);
  const { allDigital } = useProduct();
  const checkoutData = useSelector(selectCheckout);
  const selectedMethod = useSelector(currentPaymentMethod);
  const dispatch = useDispatch();
  const { set: setApiStatus } = useApiStatus();

  const handleNoAutofill = () => {
    updateCheckoutState(TO_SHIPPING_ADDRESS_ADD);
    updateIsAutofill(false);
  };

  const handleUi = () => {
    updateCheckoutState(TO_CARD_ADD);
  };

  const handleData = parsedSuggestions => {
    updateIsAutofill(true);
    if (!allDigital()) {
      updateShippingName(parsedSuggestions?.name);
      updateShippingAddress1(parsedSuggestions?.address1);
      updateShippingAddress2(parsedSuggestions?.address2);
      updateCompanyName(parsedSuggestions?.address?.company);
    }
    updateUserCardId(parsedSuggestions?.cardId);
  };

  const handleAutofill = () => {
    const parsedSuggestions = autofillParser(checkoutData);
    if (parsedSuggestions.isAvailable) {
      handleUi();
      handleData(parsedSuggestions);
    }
  };

  const focusShippingName = () => {
    setTimeout(() => {
      const shippingField = document.getElementById('shipping-name');
      const billingField = document.getElementById('billing-name');
      if (shippingField) {
        shippingField.focus();
      } else if (billingField) {
        billingField.focus();
      }
    }, 0);
  };

  const executeAutofill = async cart => {
    const autofillInfo = autofillParser({
      availableStoreCredit: 0,
      cardTokenization: {
        onBehalfOf: cart?.payment?.direct?.onBehalfOf,
        tokenizedFor: cart?.payment?.direct?.tokenisedFor,
        url: CHECKOUT_VAULT_URL,
      },
      newCardOnBehalfOf: cart?.payment?.direct?.onBehalfOf,
      suggestions: cart?.suggestions,
      cartTotals: cart?.totals,
      success: true,
    });

    updateCompanyName(autofillInfo.address?.company);
    updateContextShippingAddress(autofillInfo.address);

    updateCheckoutState(TO_SHIPPING_ADDRESS_ADD);
    dispatch(setPaymentMethod(selectedMethod || paymentIdList.CREDIT_CARD));
  };

  const autofillForm = (checkout, cart) => {
    setShowUserAddControl(false);
    if (!cart?.suggestions || cart?.suggestions?.length === 0) {
      if (checkout?.customer?.email) {
        setApiStatus({ isEmailReady: status.READY });
      }
      updateCheckoutState(TO_SHIPPING_ADDRESS_ADD);
      return;
    }
    updateCheckoutState(TO_CARD_ADD);
    updateIsAutofill(true);
    executeAutofill(cart);
    focusShippingName();
    const apiResponse = {
      availableStoreCredit: 0,
      cardTokenization: {
        onBehalfOf: cart?.payment?.direct?.onBehalfOf,
        tokenizedFor: cart?.payment?.direct?.tokenisedFor,
        url: CHECKOUT_VAULT_URL,
      },
      newCardOnBehalfOf: cart?.payment?.direct?.onBehalfOf,
      suggestions: cart?.suggestions,
      cartTotals: cart?.totals,
      success: true,
    };
    trigger(EVENT.EMAIL_RESPONSE_READY, apiResponse);
    setApiStatus({ isEmailReady: status.READY });
  };

  useEffect(() => {
    if (checkoutData?.status === 'fulfilled') {
      handleAutofill();
    }
  }, [checkoutData]);

  return { handleNoAutofill, handleAutofill, autofillForm };
};

export default useAutofill;
