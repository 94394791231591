import * as actionTypes from './actionTypes';

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case actionTypes.Device_Set:
      return {
        ...state,
        ...payload,
        isReady: true,
      };
    default:
      throw new Error(`Unhandled type: ${type}`);
  }
};
