import React, { useContext, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { currentBaseUrl } from '../../../helpers/urlConst';
import { selectCheckout } from '../../../store/slice';
import DataContext from '../../../store/dataContext';

import { handleAddOfferedItem } from '../SpecialOfferServices';

import { ReactComponent as Arrow } from './icon/arrow.svg';
import styles from './index.module.scss';

const SpecialOfferBanner = ({
  handleApplieOffer,
  isAppliedOffer,
  minutes,
  seconds,
  offeredItem,
  apiLoading,
  setApiLoading,
  updateOfferedItem,
}) => {
  const { t } = useTranslation();
  const dataContext = useContext(DataContext);
  const checkoutData = useSelector(selectCheckout);
  const dispatch = useDispatch();
  const expiresTimeRef = useRef(null);

  const [currentStep, setCurrentStep] = useState(1);
  // The condition should looks like this becoase if not
  // the animation will be refreshing every time when second will be zero
  const isTimeExist = seconds !== undefined;

  const updatePriceUI = price => {
    const mainPrice = String(price).split('.')[0];
    const restPrice = String(price).split('.')[1];

    return (
      <>
        $<span className={styles['item-price-after']}>{mainPrice}</span>
        {restPrice ? `.${restPrice}` : '.00'}
      </>
    );
  };

  const orderButtonContent = () => {
    //
    const showLoadingAnimation = stepValue => {
      //
      const getTranslation = () => {
        switch (stepValue) {
          case 1:
            return t('ppo.adding_to_order');
          case 2:
            return t('ppo.confirming_payment');
          case 3:
            return t('ppo.almost_done');
          case 4:
            return t('ppo.all_set');
          default:
            break;
        }
        return t('ppo.all_set');
      };

      return (
        <>
          <img alt="Loading..." src={`${currentBaseUrl()}loaders/spiner-plugin.png`} width="19.4" height="19.4" />
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={stepValue}
              addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
              classNames="animation"
            >
              <div className={styles['add-to-order-btn-container']}>{getTranslation()}</div>
            </CSSTransition>
          </SwitchTransition>
        </>
      );
    };

    return (
      <>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={apiLoading}
            addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
            classNames="animation"
          >
            <div className={styles['add-to-order-btn-container']}>
              {apiLoading ? showLoadingAnimation(currentStep) : t('ppo.add_btn')}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </>
    );
  };

  useEffect(() => {
    let interval;
    const animationDuration = 10;
    const intervalDuration = (animationDuration / 4) * 1000;
    if (apiLoading && currentStep <= 3) {
      interval = setInterval(() => {
        setCurrentStep(prevState => prevState + 1);
      }, intervalDuration);
    } else if (currentStep === 4 && interval) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [apiLoading, currentStep]);

  return (
    <div
      className={`
    ${styles['offer-wrapper']}
    ${isAppliedOffer && styles['scale-up']}
    `}
    >
      <div className={styles['offer-header']}>
        <div className={styles['offer-header-content']}>
          <p className={styles['offer-header-title']}>{t('ppo.header')}</p>
          <CSSTransition nodeRef={expiresTimeRef} in={isTimeExist} timeout={300}>
            {state => (
              <span ref={expiresTimeRef} className={`${styles['offer-header-expires']} ${styles[`node-${state}`]}`}>
                {t('ppo.expires')}{' '}
                <span className={styles['offer-header-time']}>
                  {' '}
                  {minutes}:{seconds < 10 ? `0${seconds}` : seconds} min
                </span>
              </span>
            )}
          </CSSTransition>
        </div>
        <Arrow className={styles['offer-header-collapse']} onClick={handleApplieOffer} />
      </div>
      <div className={styles['offer-content']}>
        <img alt="offer_image" src={offeredItem?.imageURL} width={300} height={278} />
        <div className={styles['offer-content-info']}>
          <p className={styles['item-title']}>{offeredItem?.name}</p>
          <p className={styles['item-price']}>
            {updatePriceUI(offeredItem?.price)}
            {offeredItem?.originPrice && (
              <span className={styles['item-price-before']}>${updatePriceUI(offeredItem?.originPrice)}</span>
            )}
          </p>
          {offeredItem?.discount && <span className={styles['item-label']}>save {offeredItem?.discount}%</span>}
          <button
            type="button"
            className={styles['add-to-order-btn']}
            onClick={() =>
              handleAddOfferedItem({
                setApiLoading,
                offeredItem,
                updateOfferedItem,
                checkoutData,
                dispatch,
                dataContext,
              })
            }
            disabled={apiLoading}
          >
            {orderButtonContent()}
          </button>
          {/* eslint-disable-next-line react/no-danger */}
          <div className={styles['item-description']} dangerouslySetInnerHTML={{ __html: offeredItem?.description }} />
        </div>
      </div>
    </div>
  );
};

export default SpecialOfferBanner;

SpecialOfferBanner.propTypes = {
  handleApplieOffer: PropTypes.func.isRequired,
  isAppliedOffer: PropTypes.bool.isRequired,
  minutes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  seconds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  apiLoading: PropTypes.bool.isRequired,
  setApiLoading: PropTypes.bool.isRequired,
  offeredItem: PropTypes.oneOfType([PropTypes.object]),
  updateOfferedItem: PropTypes.func.isRequired,
};
