const clickEvents = ['mousedown', 'click', 'mouseup'];

export const simulateMouseClick = element => {
  clickEvents.forEach(type =>
    element.dispatchEvent(
      new MouseEvent(type, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      }),
    ),
  );
};
