import { currentBaseUrl } from '../../../helpers/urlConst';

export const getPaymentProviders = buttonsType => ({
  ach: {
    alt: 'ACH',
    src: `${currentBaseUrl()}payment-options/${buttonsType}/ach.svg`,
  },
  acima: {
    alt: 'Acima',
    src: `${currentBaseUrl()}payment-options/${buttonsType}/acima.svg`,
  },
  affirm: {
    alt: 'Affirm',
    src: `${currentBaseUrl()}payment-options/${buttonsType}/affirm.svg`,
  },
  afterpay: {
    alt: 'Afterpay',
    src: `${currentBaseUrl()}payment-options/${buttonsType}/afterpay.svg`,
  },
  amazonpay: {
    alt: 'Amazon',
    src: `${currentBaseUrl()}payment-options/${buttonsType}/amazonpay.svg`,
  },
  applepay: {
    alt: 'Apple',
    src: `${currentBaseUrl()}payment-options/${buttonsType}/applepay.svg`,
  },
  creditkey: {
    alt: 'Credit Key',
    src: `${currentBaseUrl()}payment-options/${buttonsType}/creditkey.svg`,
  },
  credova: {
    alt: 'Credova',
    src: `${currentBaseUrl()}payment-options/${buttonsType}/credova.svg`,
  },
  paytomorrow: {
    alt: 'PayTomorrow',
    src: `${currentBaseUrl()}payment-options/${buttonsType}/paytomorrow.svg`,
  },
  googlepay: {
    alt: 'Google',
    src: `${currentBaseUrl()}payment-options/${buttonsType}/googlepay.svg`,
  },
  klarna: {
    alt: 'Klarna',
    src: `${currentBaseUrl()}payment-options/${buttonsType}/klarna.svg`,
  },
  paylater: {
    alt: 'Pay Later',
    src: `${currentBaseUrl()}payment-options/${buttonsType}/paylater.svg`,
  },
  sezzle: {
    alt: 'Sezzle',
    src: `${currentBaseUrl()}payment-options/${buttonsType}/sezzle.svg`,
  },
  snapfinance: {
    alt: 'Snap Finance',
    src: `${currentBaseUrl()}payment-options/${buttonsType}/snapfinance.svg`,
  },
  venmo: {
    alt: 'Venmo',
    src: `${currentBaseUrl()}payment-options/${buttonsType}/venmo.svg`,
  },
});

export const sections = Object.freeze({
  cartPage: 'cartPage',
  previewDropdown: 'previewDropdown',
  addToCartModal: 'addToCartModal',
});
