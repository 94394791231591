import { useTranslation } from 'react-i18next';

import { platformValue, storeLanguage, storeUrlValue } from '../../helpers/prelaunchData';
import * as bigcommerce from '../../modules/authentication/utils/bigcommerce';
import { messageType } from '../../modules/credit-card-app/constants';
import { AUTH_MESSAGE } from '../../modules/authentication/constants';
import * as common from '../../modules/authentication/utils/common';
import { defaultLanguage } from '../../helpers/constants';
import { CHECKOUT_CC_URL } from '../../helpers/urlConst';
import { formatedLang } from '../../helpers/i18n';

import { CROSS_MESSAGE, crossRequest } from '../utils/cross-origin-communication';
import { platformList } from '../constants';

const isAuthenticationSupported = (value = platformValue) =>
  [
    platformList.bigcommerce,
    platformList.magento,
    platformList.woocommerce,
    platformList.commercecloud,
    platformList.sdk,
  ].includes(value);

const isMultiLanguageSupported = (value = platformValue) => [platformList.commercecloud].includes(value);

export const defaultUrl =
  {
    [platformList.bigcommerce]: 'checkout',
    [platformList.magento]: 'checkout',
    [platformList.woocommerce]: 'checkout/',
    [platformList.commercecloud]: `checkout`,
  }[platformValue] || 'checkout';

export const defaultValues = {
  isBigcommerce: platformValue === platformList.bigcommerce,
  isMagento: platformValue === platformList.magento,
  isWoocommerce: platformValue === platformList.woocommerce,
  isCommercecloud: platformValue === platformList.commercecloud,
  isSdk: platformValue === platformList.sdk,
  logIn: () => {},
  logOut: () => {},
  isUserAuthenticated: () => {},
  isAuthenticationSupported,
  isMultiLanguageSupported,
  setStoreLanguage: () => {},
};

const usePlatform = () => {
  const { i18n } = useTranslation();

  switch (platformValue) {
    case platformList.bigcommerce:
      return {
        ...defaultValues,
        logIn: () => common.postMessage(AUTH_MESSAGE.LOG_IN),
        logOut: () => bigcommerce.logOut(crossRequest, CROSS_MESSAGE.FETCH_FROM_WINDOW),
        isUserAuthenticated: apiResponse => bigcommerce.isUserAuthenticated(apiResponse),
        setStoreLanguage: apiResponse => i18n.changeLanguage(apiResponse),
      };
    case platformList.magento:
      return {
        ...defaultValues,
        logIn: () => common.postMessage(AUTH_MESSAGE.LOG_IN),
        logOut: () => common.postMessage(AUTH_MESSAGE.LOG_OUT),
        isUserAuthenticated: () => common.isUserAuthenticated(),
        setStoreLanguage: () => {
          const creditCardIframe = document.querySelector(`[src="${CHECKOUT_CC_URL}"]`);
          window.parent.postMessage(CROSS_MESSAGE.GET_LANGUAGE, storeUrlValue);
          window.onmessage = e => {
            const location = new URL(document.location);
            const params = location.searchParams;
            const messageData = e.data;
            if (messageData?.type === 'LANGUAGE_RESPONSE') {
              i18n.changeLanguage(messageData.content || params.get('lang') || defaultLanguage);
              creditCardIframe?.contentWindow.postMessage(
                {
                  type: messageType.CC_UPDATE_LANGUAGE,
                  payload: {
                    storeLanguage: messageData.content || params.get('lang') || defaultLanguage,
                  },
                },
                CHECKOUT_CC_URL,
              );
            }
          };
        },
      };
    case platformList.woocommerce:
      return {
        ...defaultValues,
        logIn: () => common.postMessage(AUTH_MESSAGE.LOG_IN),
        logOut: () => common.postMessage(AUTH_MESSAGE.LOG_OUT),
        isUserAuthenticated: () => common.isUserAuthenticated(),
        setStoreLanguage: apiResponse => i18n.changeLanguage(apiResponse),
      };
    case platformList.commercecloud:
      return {
        ...defaultValues,
        logIn: () => common.postMessage(AUTH_MESSAGE.LOG_IN),
        logOut: () => common.postMessage(AUTH_MESSAGE.LOG_OUT),
        isUserAuthenticated: () => common.isUserAuthenticated(),
        setStoreLanguage: () => i18n.changeLanguage(formatedLang(storeLanguage)),
      };
    case platformList.sdk:
      return {
        ...defaultValues,
        logIn: () => common.postMessage(AUTH_MESSAGE.LOG_IN),
        logOut: () => common.postMessage(AUTH_MESSAGE.LOG_OUT),
        isUserAuthenticated: () => common.isUserAuthenticated(),
        setStoreLanguage: () => apiResponse => i18n.changeLanguage(apiResponse),
      };
    default:
      return defaultValues;
  }
};

export default usePlatform;
