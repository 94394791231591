import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import usePlatform from '../../../../commons/hooks/use-platform';
import DataContext from '../../../../store/dataContext';
import useReset from '../../../../hooks/useReset';
import { useSkeleton } from '../../../../hooks/useSkeleton';
import { deleteData } from '../../../../store/slice';

import styles from './index.module.scss';

const LogInLogOutBtn = () => {
  const { t } = useTranslation();
  const { isAuthenticationSupported, logIn, logOut } = usePlatform();
  const { handleReset } = useReset();
  const dispatch = useDispatch();
  const { setShowLoading } = useSkeleton();

  const { isLoggedIn, updateWasLoggedIn, updateEmail, setIsLoggedIn, setUserWasAuthorized, merchantId, cartId } =
    useContext(DataContext);

  const doLogOut = async () => {
    setShowLoading(true);
    try {
      await dispatch(
        deleteData({
          merchantId,
          cartId,
        }),
      ).unwrap();
    } catch (err) {
      // err
    }
    try {
      await handleReset();
    } catch (err) {
      // err
    }
    try {
      await logOut();
    } catch (err) {
      // err
    }
    updateWasLoggedIn(false);
    setIsLoggedIn(false);
    setUserWasAuthorized(true);
    updateEmail('');
    setShowLoading(false);
  };

  if (isAuthenticationSupported() && isLoggedIn) {
    return (
      <span onClick={doLogOut} onKeyDown={null} tabIndex={0} role="button" className={styles.base}>
        {t('user_add.log_out')}
      </span>
    );
  }

  if (isAuthenticationSupported() && !isLoggedIn) {
    return (
      <span onClick={logIn} onKeyDown={null} tabIndex={0} role="button" className={styles.loginBtn}>
        {t('checkout.log_in')}
      </span>
    );
  }

  return null;
};

export default LogInLogOutBtn;
