import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { datadogRum } from '@datadog/browser-rum';
import { selectCart, selectCheckout, selectAdditionalData, selectError } from '../../../store/slice';
import UiContext from '../../../store/uiContext';
import DataContext from '../../../store/dataContext';
import useCartTotalsUtils from '../../../hooks/useCartTotalsUtils';
import useTagManager from '../../../hooks/useTagManager';
import useAddressApi from '../../../commons/hooks/use-address-api';
import { paymentIdList } from '../../../helpers/constants';
import { useStoreApi } from '../../../hooks/useStoreApi';
import { useEndpoint } from '../../../hooks/useEndpoint';
import { usePickup } from '../../../modules/shipping/containers/pickup-in-store/use-pickup';

import BillingPhone from '../../BillingPhone';
import SubmitBtn from '../../Button/SubmitBtn';
import { convertGiftsToBackend } from '../../Coupon/CouponUtils';

import { getDefaultGoogle } from './default';
import { handleGateway as handleBraintreeGateway } from './braintree';

import './style.scss';

const GoogleCheckout = ({ setOrderSubmitting }) => {
  const {
    cartId,
    contextShippingAddress,
    contextBillingAddress,
    shipCompany,
    shippingMethodId,
    billingPhone,
    orderNotes,
    email,
    broadcastMessage,
    paymentMethodError,
    isUserSaveInfo,
    cvvChanged,
    reset,
    device,
    validNumber,
    shopperCurrency,
    applyStoreCredit,
    orderSubmitting,
    cartDetails,
    cartTotals,
    registeredPaymentMethods,
    paymentMethodData,
    consent,
    giftCertificates,
    storeUrl,
  } = useContext(DataContext);
  const dispatch = useDispatch();
  const cartData = useSelector(selectCart);
  const submitError = useSelector(selectError);
  const portalSettings = cartData?.merchant?.settings;
  const { updateCheckoutState, TO_DONE, allowBillingAddressAdd, setOpenPreOrder } = useContext(UiContext);
  const [error, setError] = useState('');
  const [gatewayData, setGatewayData] = useState(null);
  const { getOrderTotal } = useCartTotalsUtils();
  const { dataLayer } = useTagManager();
  const checkoutData = useSelector(selectCheckout);
  const certificates = convertGiftsToBackend(giftCertificates);
  const [apiGoogleExecuteLoading, setApiGoogleExecuteLoading] = useState(false);
  const [apiGoogleExecuteResponse, setApiGoogleExecuteResponse] = useState({});
  const [apiGoogleExecuteError, setApiGoogleExecuteError] = useState(undefined);
  const { gpayKeys, settings } = paymentMethodData?.gpay || paymentMethodData?.googlepay || {};
  const { gateway } = gpayKeys || {};
  const { getWithShippingMethodPayload, executeWithShippingMethod } = useAddressApi();
  const gatewayFunctions = {
    braintree: handleBraintreeGateway,
  };
  const { storeVar } = useStoreApi();
  const { t } = useTranslation();
  const { isPickupActive } = usePickup();
  const additionalData = useSelector(selectAdditionalData);
  const { postSubmit } = useEndpoint();

  const address = {
    name: checkoutData?.address?.name,
    line1: checkoutData?.address?.line1,
    line2: checkoutData?.address?.line2,
    city: checkoutData?.address?.city,
    region: checkoutData?.address?.region,
    regionCode: checkoutData?.address?.regionCode,
    postal: checkoutData?.address?.postal,
    country: checkoutData?.address?.country,
    singleLine: checkoutData?.address?.singleLine,
    company: checkoutData?.address?.company,
  };

  const isPhoneVisible = () =>
    (submitError.data === 'PAYMENT DECLINED' || portalSettings.contactPhone.isEnabled) && !allowBillingAddressAdd;

  useEffect(() => {
    const handleSpecificGateway = async () => {
      if (gateway && !gatewayData) {
        gatewayFunctions[gateway](gpayKeys, setGatewayData);
      }
    };
    handleSpecificGateway();
  }, [paymentMethodData]);

  useEffect(() => {
    if (shippingMethodId && gatewayData && gpayKeys) {
      const data = {
        credentials: gpayKeys || {},
        settings: settings || {},
        cartDetails,
        cartTotals,
        shopperCurrency,
        getOrderTotal,
        postSubmit,
        cartId,
        contextShippingAddress,
        shipCompany,
        shippingMethodId,
        billingPhone,
        orderNotes,
        email,
        broadcastMessage,
        paymentMethodError,
        isUserSaveInfo,
        portalSettings,
        cvvChanged,
        reset,
        device,
        setOpenPreOrder,
        validNumber,
        applyStoreCredit,
        orderSubmitting,
        registeredPaymentMethods,
        paymentMethodData,
        shipping: checkoutData?.address,
        gatewayData,
        storeVar,
        storeUrl,
        checkoutData,
        cartData,
        consent,
        certificates,
        id: 'google-input-wrapper',
        additionalData,
        dispatch,
        setApiGoogleExecuteLoading,
        setApiGoogleExecuteResponse,
        setApiGoogleExecuteError,
        t,
      };
      getDefaultGoogle(data);
    }
  }, [
    paymentMethodData,
    cartTotals,
    shippingMethodId,
    gatewayData,
    isPickupActive,
    contextBillingAddress,
    contextShippingAddress,
  ]);

  useEffect(() => {
    if (apiGoogleExecuteResponse?.success) {
      updateCheckoutState(TO_DONE);
      dataLayer(apiGoogleExecuteResponse);
      reset();
      orderSubmitting(false);
      datadogRum.addAction('successful-order', { 'payment-method': 'googlepay' });
    }
  }, [apiGoogleExecuteResponse]);

  useEffect(() => {
    if (apiGoogleExecuteError) {
      setError(apiGoogleExecuteError);
    }
  }, [apiGoogleExecuteError]);

  useEffect(() => {
    if (apiGoogleExecuteLoading) {
      setOrderSubmitting(apiGoogleExecuteLoading);
    }
  }, [apiGoogleExecuteLoading]);

  useEffect(async () => {
    if (validNumber) {
      const payload = getWithShippingMethodPayload(
        address,
        address?.name,
        checkoutData?.id,
        checkoutData?.selectedKey,
        address?.company,
      );

      await executeWithShippingMethod(payload);
    }
  }, [validNumber]);

  return (
    <div className="input-wrapper">
      <div id="google-input-wrapper" />
      {isPhoneVisible() && <BillingPhone />}
      {paymentMethodError?.googlepay && <div className="google-error">{paymentMethodError?.googlepay || error}</div>}
      <SubmitBtn
        type={paymentIdList.GOOGLEPAY}
        style={{
          layout: 'horizontal',
          label: 'checkout',
          tagline: 'false',
          marginTop: 0,
          marginBottom: '20px',
        }}
      />
    </div>
  );
};

export default GoogleCheckout;

GoogleCheckout.propTypes = {
  setOrderSubmitting: PropTypes.func.isRequired,
};
