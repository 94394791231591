import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import UiContext from '../../store/uiContext';
import DataContext from '../../store/dataContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import CloseWrapperBtn from '../Button/CloseWrapperBtn/index';

import './style.scss';

const SkeletonLoading = ({ hideRight = false }) => {
  const { isMobileDevice } = useWindowDimensions();
  const uiContext = useContext(UiContext);
  const { isLoggedIn } = useContext(DataContext);
  const skeletonRef = useRef(null);
  const { t } = useTranslation();

  const animationDuration = hideRight ? 0 : 4;
  const oneSideAnimation = animationDuration / 2;

  const skeletonWrapper = skeletonRef?.current;
  const skeletonTimeOut = isMobileDevice ? oneSideAnimation * 1000 : animationDuration * 1000;

  // left side animation
  const durationStep = oneSideAnimation / 6;
  const firstDelayLeft = oneSideAnimation / 6;
  const secondDelayLeft = (oneSideAnimation / 6) * 2;
  const thirdDelayLeft = (oneSideAnimation / 6) * 3;
  const fourthDelayLeft = (oneSideAnimation / 6) * 4;
  const fifthDelayLeft = (oneSideAnimation / 6) * 5;

  // right side animation
  const firstDelayRight = oneSideAnimation + oneSideAnimation / 5;
  const secondDelayRight = oneSideAnimation + (oneSideAnimation / 5) * 2;
  const thirdDelayRight = oneSideAnimation + (oneSideAnimation / 5) * 3;
  const fourthDelayRight = oneSideAnimation + (oneSideAnimation / 5) * 4;
  const fifthDelayRight = oneSideAnimation + (oneSideAnimation / 5) * 5;

  setTimeout(() => {
    skeletonWrapper?.classList.add('transition');
  }, skeletonTimeOut);

  return (
    <div
      className={`skeleton-wrapper${hideRight ? ' hide-right' : ''}
      ${isLoggedIn ? ' logged-in' : ''}`}
      ref={skeletonRef}
    >
      <div className={`main ${hideRight ? 'hide-right' : ''}`}>
        {!hideRight && (
          <>
            <h1 className="title">{t('checkout.checkout')}</h1>
            <div className="block">
              <div
                className="skeleton-input"
                style={{ animation: `width-animation ${durationStep}s 1 normal forwards` }}
              />
              <div className="skeleton-next-btn">
                <div
                  className="next"
                  style={{ animation: `width-animation ${durationStep}s ${firstDelayLeft}s 1 normal forwards` }}
                />
              </div>
            </div>
          </>
        )}
        <div
          className="skeleton-input"
          style={{ animation: `width-animation ${durationStep}s ${secondDelayLeft}s 1 normal forwards` }}
        />
        <div
          className="skeleton-input"
          style={{ animation: `width-animation ${durationStep}s ${thirdDelayLeft}s 1 normal forwards` }}
        />
        <div
          className="skeleton-input"
          style={{ animation: `width-animation ${durationStep}s ${fourthDelayLeft}s 1 normal forwards` }}
        />
        <div
          className="skeleton-input"
          style={{ animation: `width-animation ${durationStep}s ${fourthDelayLeft}s 1 normal forwards` }}
        />
        <div
          className="skeleton-btn"
          style={{ animation: `width-animation ${durationStep}s ${fifthDelayLeft}s 1 normal forwards` }}
        />
      </div>
      {!hideRight && (
        <div className="sidebar">
          <div
            className="line"
            style={{ animation: `width-animation ${durationStep}s ${firstDelayRight}s 1 normal forwards` }}
          />
          <div
            className="block"
            style={{ animation: `width-animation ${durationStep}s ${secondDelayRight}s 1 normal forwards` }}
          >
            <div className="square" />
            <div className="line-wrapper">
              <div className="line" />
              <div className="line" />
              <div className="line" />
            </div>
          </div>
          <div
            className="block"
            style={{ animation: `width-animation ${durationStep}s ${thirdDelayRight}s 1 normal forwards` }}
          >
            <div className="square" />
            <div className="line-wrapper">
              <div className="line" />
              <div className="line" />
              <div className="line" />
            </div>
          </div>
          <div className="total-info">
            <div className="left-side">
              <div
                className="line"
                style={{ animation: `width-animation ${durationStep}s ${fourthDelayRight}s 1 normal forwards` }}
              />
              <div
                className="line"
                style={{ animation: `width-animation ${durationStep}s ${fourthDelayRight}s 1 normal forwards` }}
              />
              <div
                className="line"
                style={{ animation: `width-animation ${durationStep}s ${fifthDelayRight}s 1 normal forwards` }}
              />
              <div
                className="line"
                style={{ animation: `width-animation ${durationStep}s ${fifthDelayRight}s 1 normal forwards` }}
              />
            </div>
            <div className="right-side">
              <div
                className="line"
                style={{ animation: `width-animation ${durationStep}s ${fourthDelayRight}s 1 normal forwards` }}
              />
              <div
                className="line"
                style={{ animation: `width-animation ${durationStep}s ${fourthDelayRight}s 1 normal forwards` }}
              />
              <div
                className="line"
                style={{ animation: `width-animation ${durationStep}s ${fifthDelayRight}s 1 normal forwards` }}
              />
              <div
                className="line"
                style={{ animation: `width-animation ${durationStep}s ${fifthDelayRight}s 1 normal forwards` }}
              />
            </div>
          </div>
        </div>
      )}
      <div className="linear-gradient" />
      {!hideRight && (
        <CloseWrapperBtn isDone={uiContext.done} isEnterAddressManuallyOpened={uiContext.allowManualAddress} />
      )}
    </div>
  );
};

SkeletonLoading.propTypes = {
  hideRight: PropTypes.bool,
};

export default SkeletonLoading;
