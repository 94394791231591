// eslint-disable-next-line consistent-return
export const isLocalStorageAvailable = () => {
  try {
    if (typeof localStorage !== 'undefined') {
      return true;
    }
  } catch (e) {
    return false;
  }
};

const nfCartId = cartId => `nf-${cartId}`;

export const saveData = (key, data, cartId) => {
  if (isLocalStorageAvailable()) {
    const itemKey = nfCartId(cartId);
    const parsedCart = JSON.parse(window.localStorage.getItem(itemKey)) || {};
    parsedCart[key] = data;
    window.localStorage.setItem(itemKey, JSON.stringify(parsedCart));
  }
  return false;
};

export const retrieveData = (key, cartId, defaultValue) => {
  if (isLocalStorageAvailable()) {
    const itemKey = nfCartId(cartId);
    const parsedCart = JSON.parse(window.localStorage.getItem(itemKey)) || {};
    return parsedCart[key] || defaultValue;
  }
  return false;
};

export const clearCartData = cartId => {
  if (isLocalStorageAvailable()) {
    const itemKey = nfCartId(cartId);
    window.localStorage.removeItem(itemKey);
  }
  return false;
};
