import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';

import style from './index.module.scss';

const Menu = ({
  highlightedIndex,
  isOpen,
  inputItems,
  getItemProps,
  search,
  theme,
  setAllowEnterManually,
  getMenuProps,
}) => {
  const { t } = useTranslation();

  const getLiStyles = index => (highlightedIndex === index ? { backgroundColor: '#f2f2f2' } : {});

  const handleTouchEnd = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const renderItems = () => {
    const html = [];

    if (isOpen) {
      const actions = [];
      const lis = inputItems.map((item, index) => {
        const key = `${item.description}-${index}`;
        return (
          <li style={getLiStyles(index)} key={key} {...getItemProps({ item })}>
            <Highlighter searchWords={[search]} textToHighlight={item.description} autoEscape />
          </li>
        );
      });

      if (search) {
        actions.push(
          <button
            type="button"
            className={style['btn-cant-find']}
            style={{ color: theme.colour?.main }}
            onMouseDown={() => setAllowEnterManually(true)}
            onTouchStart={() => setAllowEnterManually(true)}
            onTouchEnd={handleTouchEnd}
            key="btn-cant-find"
          >
            {t('google_address_autocomplete.cant_find_your_address')}
          </button>,
        );
      }

      if (search && inputItems.length === 0) {
        actions.push(
          <button
            type="button"
            className={style['btn-enter-manually']}
            style={{ borderColor: theme.colour?.main }}
            onMouseDown={() => setAllowEnterManually(true)}
            onTouchStart={() => setAllowEnterManually(true)}
            onTouchEnd={handleTouchEnd}
            key="btn-enter-manually"
          >
            {t('google_address_autocomplete.enter_manually')}
          </button>,
        );
      }

      if (actions.length > 0) {
        lis.push(
          <li className={style.actions} key="actions">
            {actions}
          </li>,
        );
      }

      html.push(lis);
      return html;
    }

    return null;
  };

  return (
    <ul className={style.ul} {...getMenuProps()}>
      {renderItems()}
    </ul>
  );
};

Menu.propTypes = {
  highlightedIndex: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  inputItems: PropTypes.oneOfType([PropTypes.array]).isRequired,
  getItemProps: PropTypes.func.isRequired,
  search: PropTypes.string,
  theme: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setAllowEnterManually: PropTypes.func.isRequired,
  getMenuProps: PropTypes.func.isRequired,
};

export default Menu;
