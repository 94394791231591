import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import DataContext from '../../../../store/dataContext';

import { convertAddressToBackend } from '../../utils';

const useAddressApi = () => {
  const { device, email, billingPhone, contextBillingAddress, billingName, orderNotes } = useContext(DataContext);

  const { t } = useTranslation();

  const isAddressPayloadValid = address => {
    if (address) {
      const street = address?.street || address?.line1;
      const state = address?.state || address?.region;
      const stateLong = address?.stateLong || address?.region;
      const zip = address?.zip || address?.postal;
      return [street, address.city, state, stateLong, zip, address.countryIso2, address.userInputSingleLine].every(
        Boolean,
      );
    }
    return false;
  };

  const getWithShippingAddressPayload = (address, fullName, cartId, company, line2, action) => {
    const shipAddress = convertAddressToBackend(address, fullName);
    return {
      cartId,
      action,
      email,
      address: {
        ...shipAddress,
        company,
        line2,
      },
      storeURL: window.location.href,
      deviceId: device?.id,
      i18n: {
        message1: t('shipping_methods.there_are_no_shipping_methods_available'),
      },
    };
  };

  const getWithShippingMethodPayload = (address, fullName, cartId, selectedKey, shipCompany, additionalCharges) => {
    const shipAddress = convertAddressToBackend(address, fullName);
    const data = {
      deviceId: device?.id,
      email,
      cartId,
      shipName: fullName,
      shipCompany,
      shipAddress,
      shipMethodKey: selectedKey,
      shippingMethodId: selectedKey,
      storeURL: window.location.href,
    };

    if (billingPhone) {
      data.billPhone = billingPhone;
    }

    if (contextBillingAddress && Object.keys(contextBillingAddress).length > 0) {
      data.billName = billingName;
      data.billAddress = contextBillingAddress;
    }

    if (orderNotes) {
      data.orderNotes = orderNotes;
    }

    if (additionalCharges) {
      data.additionalCharges = additionalCharges;
    }

    return {
      cartId,
      data,
    };
  };

  return { isAddressPayloadValid, getWithShippingAddressPayload, getWithShippingMethodPayload };
};

export default useAddressApi;
