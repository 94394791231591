import { useSelector } from 'react-redux';
import { selectCart } from '../store/slice';

import { productType } from '../helpers/constants';

export const useProduct = () => {
  const cartData = useSelector(selectCart);

  const allDigital = () => {
    if (cartData?.items?.length > 0) {
      return cartData?.items.every(item => item.type === productType.DIGITAL);
    }
    return false;
  };

  return { allDigital };
};
