import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { useEndpoint } from '../../hooks/useEndpoint';
import useCartTotals from '../../store/cartTotals/useCartTotals';
import useApiStatus from '../../store/apiStatus/useApiStatus';
import { values, validateForm, isValidAddress } from './CouponUtils';
import CouponCloseButton from './CouponCloseButton';
import CouponOpenButton from './CouponOpenButton';
import { selectCart } from '../../store/slice';
import { selectShipping } from '../../modules/shipping/slice';
import DataContext from '../../store/dataContext';
import UiContext from '../../store/uiContext';
import { status } from '../../helpers/constants';
import { isPopup } from '../../helpers/urlConst';
import { refreshCertificates } from './CouponServices';
import CouponForm from './CouponForm';

import './CouponContainer.scss';

const CouponContainer = () => {
  const {
    contextShippingAddress,
    updateCoupon,
    updateShippingMethods,
    updateShippingMethodId,
    updateGiftInfo,
    giftCertificates,
    additionalCharges,
  } = useContext(DataContext);
  const { t } = useTranslation();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [formValues, setFormValues] = useState(values);
  const { formStatus, setFormStatus } = useContext(UiContext);
  const [apiError, setApiError] = useState(null);
  const { setData: setCartTotalsData, cartTotals } = useCartTotals();
  const { set: setApiStatus, isLoading: isApiStatusLoading } = useApiStatus();
  const cartData = useSelector(selectCart);
  const shipping = useSelector(selectShipping);
  const { patchEnrich } = useEndpoint();

  const formErrors = validateForm(formValues, formStatus, apiError);
  const isAddressFilledOut = isValidAddress(contextShippingAddress);

  const handleChange = e => {
    setFormStatus(status.IDLE);
    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value,
    });
  };

  const resetForm = () => {
    setFormValues(values);
    setFormStatus(status.IDLE);
  };

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
    resetForm();
  };

  const handleAddCoupon = async () => {
    try {
      const result = await patchEnrich({
        coupons: cartData?.merchant?.settings?.coupon?.isEnabled ? [formValues.code] : [],
        giftCards: cartData?.merchant?.settings?.giftCard?.isEnabled ? [formValues.code] : [],
        additionalCharges,
      });
      return {
        cart: result.cart,
        shippingMethods: result.cart.shippingMethods,
        shippingMethodKey: result.checkout.shippingMethodKey,
        totals: result.cart.totals,
        checkout: result.checkout,
        success:
          result.cart.coupons?.[0]?.code?.toUpperCase() === formValues.code?.toUpperCase() ||
          result.cart.giftCards?.[0]?.code === formValues.code,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setApiError(null);
    setFormStatus(status.LOADING);
    const applyCouponResult = await handleAddCoupon();
    const freshGiftInfo = await refreshCertificates(giftCertificates);
    setFormStatus(status.SUBMITTED);
    if (applyCouponResult.success) {
      if (Object.keys(applyCouponResult.totals).length > 0) {
        let newCartTotals;
        if (shipping.bopis.isEnabled) {
          newCartTotals = {
            ...cartTotals.data,
            ...applyCouponResult.totals,
            charged: applyCouponResult.totals.total,
            grandTotal: applyCouponResult.totals.total,
            total: applyCouponResult.totals.total,
          };
        } else {
          newCartTotals = { ...cartTotals.data, ...applyCouponResult.totals };
        }
        setCartTotalsData(newCartTotals);
      }
      if (isAddressFilledOut) {
        updateShippingMethods(applyCouponResult.shippingMethods);
        updateShippingMethodId(applyCouponResult.shippingMethodKey);
        setApiStatus({ isShippingMethodReady: status.READY });
      }
      if (applyCouponResult.cart.coupons[0]) {
        updateCoupon(applyCouponResult.cart.coupons[0]);
      }
      if (freshGiftInfo.length > 0) {
        updateGiftInfo(freshGiftInfo);
      } else if (applyCouponResult.giftCertificates?.length > 0) {
        updateGiftInfo(applyCouponResult.giftCertificates);
      }
      setIsFormVisible(false);
    } else {
      setApiError({
        errorMsg: t('invalid_code_or_gift_card'),
      });
    }
  };

  const isCodesAvailable = () =>
    cartData?.merchant?.settings?.coupon?.isEnabled || cartData?.merchant?.settings?.giftCard?.isEnabled;

  return isCodesAvailable() ? (
    <section style={{ display: isPopup ? 'none' : 'initial' }} className="coupon-section">
      <SwitchTransition mode="out-in">
        <CSSTransition
          addEndListener={node => {
            node.addEventListener('transitionend', () => null, false);
          }}
          timeout={250}
          classNames="animation"
          key={isFormVisible}
        >
          {isFormVisible ? (
            <div className="coupon-section--with-close-button">
              <CouponForm
                formValues={formValues}
                formStatus={formStatus}
                formErrors={formErrors}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isApiStatusLoading={isApiStatusLoading}
              />
              <CouponCloseButton toggleFormVisibility={toggleFormVisibility} />
            </div>
          ) : (
            <CouponOpenButton toggleFormVisibility={toggleFormVisibility} />
          )}
        </CSSTransition>
      </SwitchTransition>
    </section>
  ) : (
    <div />
  );
};

export default CouponContainer;
