/* global paypal */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { datadogRum } from '@datadog/browser-rum';
import { useBroadcastChannel } from '../../../hooks/useBroadcastChannel';
import {
  selectCart,
  selectCheckout,
  selectHasPluginSMS,
  selectAdditionalData,
  selectError,
} from '../../../store/slice';
import DataContext from '../../../store/dataContext';
import UiContext from '../../../store/uiContext';
import useCartTotalsUtils from '../../../hooks/useCartTotalsUtils';
import useTagManager from '../../../hooks/useTagManager';
import useCurrency from '../../../modules/currency/hooks/use-currency';
import { defaultUrl } from '../../../commons/hooks/use-platform';
import { usePickup } from '../../../modules/shipping/containers/pickup-in-store/use-pickup';
import { selectShipping } from '../../../modules/shipping/slice';
import { useEndpoint } from '../../../hooks/useEndpoint';

import BillingPhone from '../../BillingPhone';
import CartTotalsMobile from '../../../modules/cart/components/cartMobile';
import SubmitBtn from '../../Button/SubmitBtn';

import { PaymentDescription } from '../DefaultView';

import './style.scss';

const VenmoCheckout = ({ setOrderSubmitting, flags }) => {
  const {
    billingPhone,
    broadcastMessage,
    cartDetails,
    cartTotals,
    paymentMethodData,
    paymentMethodError,
    registeredPaymentMethods,
    reset,
    setApiPostOrderError,
    setBroadcastMessage,
    setPaymentMethodError,
    setRegisteredPaymentMethods,
    shipCompany,
    orderSubmitting,
    moveToPlatform,
    storeUrl,
  } = useContext(DataContext);
  const cartData = useSelector(selectCart);
  const checkoutData = useSelector(selectCheckout);
  const hasPluginSMS = useSelector(selectHasPluginSMS);
  const submitError = useSelector(selectError);
  const portalSettings = cartData?.merchant?.settings;
  const additionalData = useSelector(selectAdditionalData);
  const { isPickupActive } = usePickup();
  const shippingState = useSelector(selectShipping);
  const { postSubmit } = useEndpoint();

  const { updateCheckoutState, TO_DONE, setShowOverlay, allowBillingAddressAdd, setOpenPreOrder } =
    useContext(UiContext);

  const { getOrderTotal } = useCartTotalsUtils();
  const [apiPaypalExecuteLoading, setApiPaypalExecuteLoading] = useState(false);
  const [apiPaypalExecuteError, setApiPaypalExecuteError] = useState(undefined);

  const { receiveMessage } = useBroadcastChannel();
  const { dataLayer } = useTagManager();
  const [method, setMethod] = useState('venmo');

  const { t } = useTranslation();

  const { getShoppercurrency } = useCurrency();

  const isPhoneVisible = () =>
    ((submitError.data === 'PAYMENT DECLINED' || portalSettings.contactPhone.isEnabled) && !allowBillingAddressAdd) ||
    hasPluginSMS;

  useEffect(() => {
    if (paymentMethodData?.paypal && paymentMethodData.paypal.rel !== 'approve') {
      setPaymentMethodError({
        venmo: t('payment_method.venmo.error'),
      });
    }
  }, [paymentMethodData]);

  const postVenmo = async () => {
    const payment = {};
    payment.context = {
      cvv: undefined,
      cardType: 'alt',
      onBehalfOf: cartData?.payment?.direct?.onBehalfOf,
      paymentId: broadcastMessage.paymentId,
      payerId: broadcastMessage.payerId,
      method: method || 'paypal',
    };
    payment.id = broadcastMessage.paymentId;
    payment.type = 'paypal';

    try {
      setApiPaypalExecuteLoading(true);
      let pickUpMethodKey;
      let shippingMethodKey;
      const address = {
        ...checkoutData.address,
        name: checkoutData.address.name || additionalData.shipping.name,
        company: checkoutData.address.company || additionalData.shipping.company,
        line2: checkoutData.address.line2 || additionalData.shipping.line2,
      };

      if (isPickupActive()) {
        pickUpMethodKey = shippingState.bopis.selectedPickupMethod.lookupKey;
        shippingMethodKey = undefined;
      } else {
        pickUpMethodKey = undefined;
        shippingMethodKey = checkoutData?.shippingMethodKey;
      }

      const result = await postSubmit({
        address,
        billing: {
          phone: billingPhone,
        },
        shippingMethodKey,
        pickUpMethodKey,
        payment,
      });
      if (result?.checkout?.payment?.type === 'paypal') {
        updateCheckoutState(TO_DONE);
        dataLayer(result);
        reset();
        if (!result?.redirectUrl) {
          orderSubmitting(false);
        }
        datadogRum.addAction('successful-order', { 'payment-method': 'venmo' });
      }
    } catch (err) {
      setApiPaypalExecuteError(err);
    }
    setApiPaypalExecuteLoading(false);
  };

  useEffect(() => {
    if (broadcastMessage) {
      if (cartData?.items?.find(item => item?.preOrder?.expectedAvailability)) {
        setOpenPreOrder({
          payload: {},
          callback: postVenmo,
        });
      } else {
        postVenmo();
      }
    }
  }, [broadcastMessage]);

  useEffect(() => {
    if (apiPaypalExecuteLoading) {
      setOrderSubmitting(apiPaypalExecuteLoading);
    }
  }, [apiPaypalExecuteLoading]);

  useEffect(() => {
    if (apiPaypalExecuteError) {
      setApiPostOrderError(t('payment_method.venmo.error'));
      setBroadcastMessage(undefined);
      setPaymentMethodError({
        venmo: t('payment_method.venmo.error'),
      });
      orderSubmitting(false);
    }
  }, [apiPaypalExecuteError]);

  useEffect(() => {
    receiveMessage();
  }, []);

  const formatAddressLine2 = (line2, company) => {
    if (line2 && company) {
      return `${line2}, ${company}`;
    }
    if (company) {
      return company;
    }
    return line2;
  };

  useEffect(() => {
    if (registeredPaymentMethods?.venmo) {
      if (paypal) {
        const { name, line1, line2, city, region, postal, country } = checkoutData?.address;
        paypal.getFundingSources().forEach(fundingSource => {
          if (fundingSource === 'venmo') {
            const button = paypal.Buttons({
              fundingSource,
              createOrder: (_data, actions) =>
                actions.order.create({
                  application_context: {
                    shipping_preference: 'SET_PROVIDED_ADDRESS',
                  },
                  purchase_units: [
                    {
                      amount: {
                        currency_code: getShoppercurrency()?.baseCode || 'USD',
                        value: getOrderTotal(),
                        breakdown: {
                          handling: {
                            value: cartData?.totals?.handling || 0,
                            currency_code: getShoppercurrency()?.baseCode || 'USD',
                          },
                          shipping: {
                            value: cartData?.totals?.shipping || 0,
                            currency_code: getShoppercurrency()?.baseCode || 'USD',
                          },
                          tax_total: {
                            value: cartData?.totals?.tax !== undefined ? cartData?.totals?.tax : 0,
                            currency_code: getShoppercurrency()?.baseCode || 'USD',
                          },
                          insurance: {
                            value: cartData?.totals?.insurance || 0,
                            currency_code: getShoppercurrency()?.baseCode || 'USD',
                          },
                          discount: {
                            value:
                              (cartData?.totals?.discount || 0) +
                              (cartData?.totals?.coupons?.reduce((partial, a) => partial + a.amount, 0) || 0),
                            currency_code: getShoppercurrency()?.baseCode || 'USD',
                          },
                          item_total: {
                            value: parseFloat(
                              String(
                                cartData?.items.reduce((partial, a) => partial + a.price * a.quantity - a.discount, 0),
                              ),
                            ).toFixed(2),
                            currency_code: getShoppercurrency()?.baseCode || 'USD',
                          },
                        },
                      },
                      items: cartData?.items.map(item => ({
                        name: item.name,
                        quantity: item.quantity,
                        unit_amount: {
                          currency_code: getShoppercurrency()?.baseCode || 'USD',
                          value: item.price - item.discount,
                        },
                      })),
                      shipping: {
                        name: {
                          full_name: name,
                        },
                        address: {
                          recipient_name: name,
                          address_line_1: line1,
                          address_line_2: formatAddressLine2(line2, shipCompany),
                          admin_area_2: city,
                          admin_area_1: region,
                          postal_code: postal,
                          country_code: country,
                        },
                      },
                    },
                  ],
                }),
              onApprove: data => {
                setMethod(fundingSource);
                setBroadcastMessage({ paymentId: data.orderID, payerId: data.payerID });
              },
              onError: () => setPaymentMethodError({ venmo: t('payment_method.paypal.error') }),
              onCancel: () => setPaymentMethodError({ venmo: '' }),
            });
            if (button.isEligible() && document.getElementById('venmo-button-container')) {
              button.render('#venmo-button-container');
            } else {
              setRegisteredPaymentMethods({
                ...registeredPaymentMethods,
                venmo: false,
              });
            }
          }
        });
      }
    }
  }, [registeredPaymentMethods, cartDetails, cartTotals]);

  useEffect(() => {
    if (broadcastMessage) {
      setShowOverlay(null);
    }
  }, [broadcastMessage]);

  const handleClick = () => {
    if (!registeredPaymentMethods.venmo && moveToPlatform?.venmo) {
      window.top.location.href = `${storeUrl}/${defaultUrl}?preventIframeLoad=true`;
    }
  };

  return (
    <div
      tabIndex={0}
      role="button"
      onClick={handleClick}
      onKeyDown={null}
      className={classNames({
        'venmo-space-default': !isPhoneVisible(),
        'venmo-space-phone': isPhoneVisible(),
        'mt-unset': paymentMethodError?.venmo,
        'platform-button': !registeredPaymentMethods.venmo && moveToPlatform?.venmo,
      })}
    >
      {isPhoneVisible() && <BillingPhone flags={flags} />}
      <CartTotalsMobile />
      {paymentMethodError?.venmo && <div className="paypal-error">{paymentMethodError?.venmo}</div>}
      <div className="venmo-container">
        <SubmitBtn type="venmo" style={{ layout: 'horizontal', label: 'checkout', tagline: 'false' }} />
      </div>
      <div id="venmo-button-container" className="paypal-venmo" />
      <PaymentDescription />
    </div>
  );
};

export default VenmoCheckout;

VenmoCheckout.propTypes = {
  setOrderSubmitting: PropTypes.func.isRequired,
  flags: PropTypes.shape({
    'disable-ddi-phone': PropTypes.bool,
  }),
};
