import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { currentBaseUrl } from '../../../helpers/urlConst';

import useApiStatus from '../../../store/apiStatus/useApiStatus';
import { selectCart, selectApiStatus } from '../../../store/slice';
import DataContext from '../../../store/dataContext';
import UiContext from '../../../store/uiContext';
import useSubmit from '../../../hooks/useSubmit';
import { useProduct } from '../../../hooks/useProduct';
import { selectBilling } from '../../../modules/billing/slice';
import { paymentIdList } from '../../../helpers/constants';
import usePlatform, { defaultUrl } from '../../../commons/hooks/use-platform';

import { ReactComponent as PayPalIcon } from './icon/paypal.svg';
import { ReactComponent as VenmoIcon } from './icon/venmo.svg';
import { ReactComponent as AmazonPayIcon } from './icon/amazonpay.svg';
import { ReactComponent as GooglePayIcon } from './icon/googlepay.svg';
import { ReactComponent as Lock } from './icon/lock.svg';

import './style.scss';

const SubmitBtn = props => {
  const { type, handleFunc, style, image } = props;
  const { isAnyCheckoutReady, registeredPaymentMethods, storeUrl, moveToPlatform, customer } = useContext(DataContext);
  const cartData = useSelector(selectCart);
  const portalSettings = cartData?.merchant?.settings;
  const { apiStatus, isLoading: isLoadingApiStatus } = useApiStatus();
  const uiContext = useContext(UiContext);
  const { handleClick } = useSubmit();
  const { t } = useTranslation();
  const { logIn } = usePlatform();

  const status = useSelector(selectApiStatus);
  const billingSelector = useSelector(selectBilling);
  const { allDigital } = useProduct();

  const isSubmitDisabled =
    uiContext.isLoadingInsurance ||
    uiContext.isLoadingRoute ||
    uiContext.isLoadingSignature ||
    isLoadingApiStatus ||
    status.isLoading ||
    uiContext.checkoutStage === uiContext.TO_USER_ADD ||
    (!isAnyCheckoutReady({
      type,
      isShippingReady: status.isReady || allDigital(),
      isBillingReady: billingSelector.status.isReady,
      isAdditionalServiceLoading:
        uiContext.isLoadingInsurance || uiContext.isLoadingRoute || uiContext.isLoadingSignature,
    }) &&
      uiContext.checkoutStage === uiContext.TO_USER_ADD) ||
    apiStatus.isCardInProgress;

  const SubmitBtnType = type === 'submit';
  const PayPalType = type === 'paypal';
  const VenmoType = type === 'venmo';
  const AmazonPayType = type === paymentIdList.AMAZONPAY;
  const GooglePayType = type === paymentIdList.GOOGLEPAY;

  const [rippleState, setRippleState] = useState('');
  const [rippleStyle, setRippleStyle] = useState({});
  const [localLoading, setLocalLoading] = useState(false);

  const customStyles = () => {
    if (SubmitBtnType) {
      return {
        ...style,
        backgroundColor: portalSettings?.theme?.colour?.main,
        color: isSubmitDisabled ? '' : portalSettings?.theme?.colour?.text,
      };
    }
    return style;
  };

  const button = useRef(null);
  const ripple = useRef(null);

  const isLoading =
    isLoadingApiStatus ||
    status.isLoadingWithShippingAddress ||
    status.isLoadingWithShippingMethod ||
    uiContext.isLoadingInsurance ||
    uiContext.isLoadingRoute ||
    uiContext.isLoadingSignature;

  const onKeyPress = e => {
    let timerId;
    setRippleState('');
    clearTimeout(timerId);
    const size = button.current.offsetWidth;
    const pos = button.current.getBoundingClientRect();
    const x = e.pageX - pos.left - size;
    const y = e.pageY - pos.top - size;

    const newRippleStyle = {
      top: `${y}px`,
      left: `${x}px`,
      width: `${size * 2}px`,
      height: `${size * 2}px`,
    };

    setRippleStyle(newRippleStyle);

    setRippleState('ripple-start ripple-active');
    timerId = setTimeout(() => {
      setRippleState('');
    }, 500);
  };

  const onKeyPressFunc = e => {
    if (handleFunc) {
      handleFunc(e);
    }
    onKeyPress(e);
  };

  const typeOfClass = () => {
    if (SubmitBtnType) {
      if (
        isSubmitDisabled &&
        !isLoadingApiStatus &&
        !status.isLoadingWithShippingAddress &&
        !status.isLoadingWithShippingMethod
      ) {
        return 'submit-button disable';
      }
      return 'submit-button';
    }
    if (PayPalType) {
      if (registeredPaymentMethods?.paylater?.isEnabled) {
        return 'paypal-button paypal-button-half';
      }
      return 'paypal-button';
    }
    if (VenmoType) {
      return 'paypal-button';
    }
    if (AmazonPayType) {
      return 'amazon-pay-button';
    }
    if (GooglePayType) {
      return 'google-pay-button';
    }
    return null;
  };

  const typeOfContent = () => {
    if (SubmitBtnType) {
      return (
        <>
          <Lock style={{ marginRight: '10px', fill: isSubmitDisabled ? '' : portalSettings?.theme?.colour?.text }} />
          {!uiContext.allowBillingAddressAdd ? t('submit_btn.submit_order') : t('submit_btn.complete_order')}
        </>
      );
    }
    if (PayPalType) {
      return registeredPaymentMethods?.paypal?.isEnabled ? (
        <PayPalIcon className="logo active" />
      ) : (
        <PayPalIcon className="logo active" />
      );
    }
    if (VenmoType) {
      return <VenmoIcon className="logo-bigger active" />;
    }
    if (AmazonPayType) {
      return (
        <>
          {t('submit_btn.checkout_with')}
          <AmazonPayIcon className="logo active" />
        </>
      );
    }
    if (GooglePayType) {
      return (
        <>
          {t('submit_btn.checkout_with')}
          <GooglePayIcon className="logo active" />
        </>
      );
    }
    return null;
  };

  const handleSubmitClick = () => {
    if (!registeredPaymentMethods[type] && moveToPlatform[type]) {
      setLocalLoading(true);
      window.top.location.href = `${storeUrl}/${defaultUrl}?preventIframeLoad=true`;
    }
    handleClick(type);
  };

  if (image) {
    return (
      <div className="btn-image">
        <input
          type="image"
          alt="Amazon Pay"
          width="319"
          height="47"
          src={image}
          onClick={() => handleSubmitClick(type)}
        />
      </div>
    );
  }

  return (
    <div className="nf-buttons-container">
      {customer?.isInGroup && (
        <button
          type="button"
          className="nf-login-submit-button"
          style={{ borderColor: portalSettings?.theme?.colour?.main, color: portalSettings?.theme?.colour?.main }}
          onClick={logIn}
        >
          <span>{t('submit_btn.login')}</span> {t('submit_btn.to_apply_custom_pricing')}
        </button>
      )}
      <button
        className={typeOfClass()}
        onClick={() => handleSubmitClick(type)}
        disabled={isSubmitDisabled}
        type="button"
        ref={button}
        onDrag={onKeyPressFunc}
        onMouseDown={onKeyPressFunc}
        style={customStyles()}
      >
        <span className={`ripple ${rippleState}`} ref={ripple} style={rippleStyle}></span>
        {isLoading || localLoading ? (
          <img alt="Loading..." src={`${currentBaseUrl()}loaders/spinner-white.png`} width="25" height="25" />
        ) : (
          typeOfContent()
        )}
      </button>
    </div>
  );
};

SubmitBtn.propTypes = {
  type: PropTypes.string.isRequired,
  handleFunc: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object]),
  image: PropTypes.string,
};

export default SubmitBtn;
