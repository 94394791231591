import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

import initialState from '../store/device/initialState';
import { getCookie } from './cookie';

const getFingerprint = () =>
  FingerprintJS?.load({
    token: process.env.REACT_APP_FINGERPRINTJS_API_KEY,
    endpoint: process.env.REACT_APP_FINGERPRINTJS_SUBDOMAIN,
  })
    .then(fp => fp?.get({ extendedResult: true }))
    .then(result => result)
    .catch(() => undefined);

export const getDevice = async () => {
  let response = initialState;
  try {
    const fp = await getFingerprint();

    if (fp) {
      response = {
        ...initialState,
        id: fp.visitorId,
        isIncognito: fp.incognito,
      };
    }
  } catch (err) {
    // err
  }
  if (response?.id) {
    return response;
  }
  try {
    const nfDevice = getCookie('nf_device');
    if (nfDevice) {
      response = {
        ...initialState,
        id: nfDevice,
      };
    }
  } catch (err) {
    // err;
  }
  if (response?.id) {
    return response;
  }

  try {
    const maxMindCookieId = getCookie('__mmapiwsid');
    if (maxMindCookieId) {
      response = {
        ...initialState,
        id: maxMindCookieId,
      };
    }
  } catch (err) {
    // err
  }
  if (response) {
    return response;
  }

  try {
    const deviceStorage = localStorage.getItem('deviceInformation');

    if (deviceStorage) {
      response = {
        ...initialState,
        id: deviceStorage.visitorId,
        isIncognito: deviceStorage.incognito,
      };
    }
  } catch (e) {
    // err
  }
  return response;
};
