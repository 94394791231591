import { CROSS_MESSAGE, crossRequest } from '../commons/utils/cross-origin-communication';

const appendScript = (x, url, type, content) => {
  const script = document.createElement('script');
  script.id = `script-${x}`;
  script.type = 'text/javascript';
  if (type === 'url') {
    script.src = url;
  } else {
    script.innerHTML = content;
  }
  document.body.appendChild(script);
  return script;
};

const getCode = (content, value) => {
  const regex = /\.then\((.*?)$/;
  const changeVarRegex = /%%(.*?)%%|%([^%]+)%|{{(.*?)}}|\${(.*?)}/g;
  const script = content
    ?.replace(changeVarRegex, value)
    ?.replace('document.body.appendChild', "document.getElementById('custom-plugin').appendChild");
  const match = script.match(regex);

  if (match) {
    const afterThen = match[1]?.match(regex);
    const codeAfterThen = afterThen[1].replace(/\)(?=[^)]*$)/, '');
    if (codeAfterThen) {
      return codeAfterThen;
    }
    return afterThen?.replace(/\)(?=[^)]*$)/, '');
  }
  return script;
};

const executeRequest = async (content, value, x, param = 'ORDER_ID') => {
  let regex;
  let regex2;
  if (param?.toLowerCase() === 'order_id' || param?.toLowerCase() === 'orderId') {
    regex = /\/api\/storefront\/order\/[%{${}]?ORDER_ID[%}]?/;
    regex2 = /\/api\/storefront\/order\/[%{$]{0,2}ORDER_ID[%}$]{0,2}/;
  }

  const match = content.match(regex) || content.match(regex2);
  if (match) {
    const api = match[0]?.replace('%');
    const regexToAddParam = /\/([^/]+)$/;
    const requestApi = api.replace(regexToAddParam, `/${value}`);
    let apiResponse;
    let parsedResponse;
    try {
      apiResponse = await crossRequest(
        CROSS_MESSAGE.FETCH_FROM_WINDOW,
        {
          url: requestApi,
          options: {
            method: 'GET',
          },
        },
        5000,
      );
    } catch (err) {
      apiResponse = {
        success: false,
        text: err,
      };
    }
    if (apiResponse?.success) {
      parsedResponse = JSON.parse(apiResponse?.text);
    }
    if (parsedResponse) {
      const code = getCode(content, value);
      const functionHasAName = code.match(/function\s+(\w+)\s*\(/)?.[1];
      let newCode;
      if (functionHasAName) {
        newCode = `const d = ${JSON.stringify(parsedResponse)};\nfunction ${functionHasAName}() {}`;
      } else {
        newCode = `${code.replace(
          /function \((.*?)\)/,
          `const $1 = ${JSON.stringify(parsedResponse)};\nfunction customNfFunction()`,
        )}\ncustomNfFunction();`;
      }
      appendScript(x, null, 'content', newCode);
    }
  }
};

const replaceVars = async (content, value, x) => {
  const paramKeys = {
    order_id: 'platformOrderId',
    orderid: 'platformOrderId',
    email: 'email',
    carttotal: 'total',
    cart_total: 'total',
  };
  const regex = /{{([^{}]+)}}/g;
  const matches = content.match(regex);
  const varList = matches.map(item => ({
    original: item,
    key: paramKeys[item.toLowerCase().replace('{{', '').replace('}}', '')],
    value: value[paramKeys[item.toLowerCase().replace('{{', '').replace('}}', '')]],
  }));

  let script = content;
  for (const key in varList) {
    if (varList[key].key === 'email' || varList[key].key === 'platformOrderId') {
      script = script.replace(varList[key].original, `'${varList[key].value}'`);
    } else {
      script = script.replace(varList[key].original, varList[key].value);
    }
  }
  return appendScript(x, null, 'content', script);
};

export const fetchScript = async (scriptData, x, value) => {
  try {
    const { url, content, type, param } = scriptData;
    if (content?.indexOf('api/storefront') > -1) {
      return executeRequest(content, value, x, param);
    }
    if (content?.indexOf('{{') > -1) {
      return replaceVars(content, value, x);
    }
    return appendScript(x, url, type, content);
  } catch (err) {
    return err;
  }
};

const addScript = (id, src) => {
  const checkScript = document.getElementById(id);
  if (!checkScript) {
    const script = document.createElement('script');
    script.id = id;
    script.type = 'text/javascript';
    script.src = src;
    document.body.appendChild(script);
  }
};

export const handlePaymentScripts = async paymentMethods => {
  if (paymentMethods?.paypal?.clientId) {
    addScript(
      'script-paypal',
      `https://www.paypal.com/sdk/js?client-id=${paymentMethods?.paypal?.clientId}&components=messages,buttons,funding-eligibility&enable-funding=paylater,venmo`,
    );
  }
  if (paymentMethods?.amazonPay?.isEnabled || paymentMethods?.amazonpay?.isEnabled) {
    addScript('script-amazon', 'https://static-na.payments-amazon.com/checkout.js');
  }
  if (paymentMethods?.googlepay?.isEnabled) {
    // Maybe we will need to remove this for Apple pay
    addScript('script-gpay', 'https://pay.google.com/gp/p/js/pay.js');
    addScript('script-braintree-client', 'https://js.braintreegateway.com/web/3.92.1/js/client.min.js');
    addScript('script-braintree-data', 'https://js.braintreegateway.com/web/3.92.1/js/data-collector.min.js');
  }
};
