import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { currentBaseUrl } from '../../../../helpers/urlConst';

import style from './index.module.scss';

const CollapsedData = ({ address, line2, cardType, fullName, last4Numbers, phoneNumber, title, toggleCollapse }) => {
  const { t } = useTranslation();

  const hasDataAvailable = [address, line2, cardType, fullName, last4Numbers, phoneNumber].some(Boolean);

  const handleClick = () => {
    toggleCollapse();
  };

  const renderCreditCard = () => {
    let cardImageMarkup = null;
    if (cardType) {
      cardImageMarkup = (
        <img alt={cardType} src={`${currentBaseUrl()}card-logos/${cardType}.svg`} width="40" height="12" />
      );
    } else {
      cardImageMarkup = <i className="icon-card" alt={cardType} />;
    }
    return (
      <div className={style['credit-card-data']}>
        {cardImageMarkup}
        <span>{` ••••-${last4Numbers}`}</span>
      </div>
    );
  };

  const renderData = () => (
    <>
      <h3>{title}</h3>
      <input
        className={style['edit-data']}
        type="image"
        alt={`${t('collapsed_data.edit')} ${title}`}
        src={`${currentBaseUrl()}assets/edit-pencil.svg`}
        onClick={handleClick}
      />
      <p className={style.strong}>{fullName}</p>
      {last4Numbers && renderCreditCard()}
      <p>{address}</p>
      <p>{line2}</p>
      <p>{phoneNumber}</p>
    </>
  );

  const renderEmpty = () => (
    <>
      <h3>{title}</h3>
      <input
        className={style['edit-data']}
        type="image"
        alt={`${t('collapsed_data.edit')} ${title}`}
        src={`${currentBaseUrl()}assets/edit-pencil.svg`}
        onClick={handleClick}
      />
      <p className={style['no-data']}>{t('collapsed_data.information_not_provided_yet')}</p>
    </>
  );

  if (hasDataAvailable) {
    return <div className={style.wrapper}>{renderData()}</div>;
  }

  return <div className={style.wrapper}>{renderEmpty()}</div>;
};

CollapsedData.propTypes = {
  address: PropTypes.string,
  line2: PropTypes.string,
  cardType: PropTypes.string,
  fullName: PropTypes.string,
  last4Numbers: PropTypes.string,
  phoneNumber: PropTypes.string,
  title: PropTypes.string.isRequired,
  toggleCollapse: PropTypes.func,
};

export default CollapsedData;
