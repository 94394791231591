import { useRef, useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';

const useDebounce = (callback, timestamp) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = (...args) => {
      ref.current?.(...args);
    };

    return debounce(func, timestamp);
  }, []);

  return debouncedCallback;
};

export default useDebounce;
