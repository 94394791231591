import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { storeUrlValue } from '../../../helpers/prelaunchData';

import { sections } from './utils';
import PaymentButtons from './payment-buttons';

const PaymentButtonsApp = ({ section }) => {
  const [buttonsConfiguration, setButtonsConfiguration] = useState(undefined);

  const listenWindowMessages = e => {
    const originUrl = e.origin.split('/').splice(0, 3).join('/');
    if (originUrl === storeUrlValue) {
      const messageData = e.data;
      if (messageData.type === 'NF_PAYMENT_BUTTONS_CONFIGURATION') {
        setButtonsConfiguration({
          useDefaultCheckout: messageData.payload.useDefaultCheckout,
          useNoFraudCheckout: messageData.payload.useNoFraudCheckout,
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', listenWindowMessages);
    return () => {
      window.removeEventListener('message', listenWindowMessages);
    };
  }, [listenWindowMessages]);

  if (section === sections.cartPage) {
    return <PaymentButtons buttonsConfiguration={buttonsConfiguration} section={sections.cartPage} />;
  }
  if (section === sections.previewDropdown) {
    return <PaymentButtons buttonsConfiguration={buttonsConfiguration} section={sections.previewDropdown} />;
  }
  if (section === sections.addToCartModal) {
    return <PaymentButtons buttonsConfiguration={buttonsConfiguration} section={sections.addToCartModal} />;
  }
  return null;
};

PaymentButtonsApp.propTypes = {
  section: PropTypes.string,
};

export default PaymentButtonsApp;
