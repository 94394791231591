import React, { useRef, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import { CHECKOUT_CC_URL } from '../../../helpers/urlConst';
import { selectShipping } from '../../shipping/slice';
import {
  selectCart,
  selectCheckout,
  selectHasPluginSMS,
  selectApiStatus,
  selectError,
  setIsPaymentReady,
} from '../../../store/slice';
import UiContext from '../../../store/uiContext';
import DataContext from '../../../store/dataContext';
import useApiStatus from '../../../store/apiStatus/useApiStatus';
import BillingCheckbox from '../../../components/BillingCheckbox';
import BillingPhone from '../../../components/BillingPhone';
import useCartTotalsUtils from '../../../hooks/useCartTotalsUtils';
import { usePickup } from '../../shipping/containers/pickup-in-store/use-pickup';
import { analyticsStage1, analyticsStage2 } from '../../../helpers/constants';
import { useEndpoint } from '../../../hooks/useEndpoint';

import { messageType } from '../constants';
import useEventsEffect from '../hooks/useEvents';

import style from './index.module.scss';

const CreditCardApp = ({ reload }) => {
  const { postAnalyticsStage1, postAnalyticsStage2 } = useEndpoint();
  const dispatch = useDispatch();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlParam = urlParams.get('applepay');
  const iframeRef = useRef(null);

  const { checkoutStage, TO_USER_ADD, allowBillingAddressAdd, setIsShippingSectionCollapsed } = useContext(UiContext);
  const {
    cartId,
    submitFailedTimestamp,
    email,
    setUserCardId,
    setUserCardCvv,
    setCardType,
    setCvvChanged,
    setLast4Numbers,
    setFirst6Numbers,
    contextBillingAddress,
    areSuggestionsVisible,
    setAreSuggestionsVisible,
    merchantId,
    platformCartId,
    storeUrl,
    isIframeLoaded,
    setIsIframeLoaded,
  } = useContext(DataContext);
  const { getOrderTotal } = useCartTotalsUtils();
  const cartData = useSelector(selectCart);
  const checkoutData = useSelector(selectCheckout);
  const hasPluginSMS = useSelector(selectHasPluginSMS);
  const status = useSelector(selectApiStatus);
  const submitError = useSelector(selectError);
  const { shippingMethodKey } = useSelector(selectShipping);
  const total = getOrderTotal();
  const portalSettings = cartData?.merchant?.settings;

  const { t } = useTranslation();

  const { set: setApiStatus } = useApiStatus();

  const { isPickupActive } = usePickup();
  const checkPickup = isPickupActive();

  const [isIframeInvisible, setIsIframeInvisible] = useState(false);

  const isFieldsetDisabled = checkoutStage === TO_USER_ADD || !cartData?.payment?.direct?.onBehalfOf;

  const showBillingCheckbox = () =>
    shippingMethodKey &&
    portalSettings?.checkoutMode?.billingBehaviour &&
    portalSettings?.checkoutMode?.billingBehaviour?.isEnabled &&
    checkoutData?.address?.name &&
    checkoutData?.address?.line1 &&
    email;

  const showBillingPhone = () =>
    ((submitError.data === 'PAYMENT DECLINED' || portalSettings.contactPhone.isEnabled) && !allowBillingAddressAdd) ||
    (hasPluginSMS && !allowBillingAddressAdd);

  const handleCvvOmit = omitCvv => omitCvv.shouldOmit && omitCvv.addressHash === checkoutData?.address?.hash;

  const sendMessageToCcIframe = (type, iframeInitialLoad = false, errorPayload) => {
    const dataToAutofill = cartData?.suggestions
      ?.filter(item => item?.address?.hash === checkoutData?.address?.hash)
      ?.map(item => item.payment);
    if (isIframeLoaded || iframeInitialLoad) {
      switch (type) {
        case messageType.CC_INITIAL_STATE: {
          iframeRef.current.contentWindow.postMessage(
            {
              type: messageType.CC_INITIAL_STATE,
              payload: {
                isFieldsetDisabled,
                theme: portalSettings.theme,
                storeLanguage: portalSettings.storeLanguage,
                requiredCvv: portalSettings.requiredCvv,
                nfCartId: cartId || '',
                userId: email,
                merchantId,
                platformCartId,
                storeUrl,
              },
            },
            CHECKOUT_CC_URL,
          );
          break;
        }
        case messageType.CC_UPDATE_STATE: {
          iframeRef.current.contentWindow.postMessage(
            {
              type: messageType.CC_UPDATE_STATE,
              payload: {
                isFieldsetDisabled,
                theme: portalSettings.theme,
                nfCartId: cartId || '',
                isLoggedIn: !!cartData?.customer?.email,
                userId: email,
              },
            },
            CHECKOUT_CC_URL,
          );
          break;
        }
        case messageType.CC_AUTOFILL:
          iframeRef.current.contentWindow.postMessage(
            {
              type: messageType.CC_AUTOFILL,
              payload: {
                apiShippingAddressResponse: {
                  ...dataToAutofill,
                  cards: checkPickup
                    ? []
                    : dataToAutofill?.map(item => ({
                        id: item.id,
                        expMonth: item.context.expMonth,
                        expYear: item.context.expYear,
                        code: {
                          name: item.context.cvv?.label || 'cvv',
                          size: item.context.cvv?.length || 3,
                        },
                        issuer: {
                          name: item.context.cardType,
                        },
                        type: item.context.cardType,
                        last4: item.context.last4,
                        first6: item.context.first6,
                        omitCvv:
                          item.context.cvv &&
                          handleCvvOmit(item.context.cvv) &&
                          !cartData?.merchant?.settings?.requiredReturningCVV?.isEnabled,
                      })),
                },
                isFieldsetDisabled,
              },
            },
            CHECKOUT_CC_URL,
          );
          break;
        case messageType.CC_EMPTY_ADDRESS:
          iframeRef.current.contentWindow.postMessage(
            {
              type: messageType.CC_EMPTY_ADDRESS,
            },
            CHECKOUT_CC_URL,
          );
          break;
        case messageType.CC_SUBMIT_FAILED:
          iframeRef.current.contentWindow.postMessage(
            {
              type: messageType.CC_SUBMIT_FAILED,
              payload: {
                submitFailedTimestamp,
                apiPostOrderError: errorPayload,
              },
            },
            CHECKOUT_CC_URL,
          );
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Couldn't post message to the Credit Card App`);
          break;
      }
    }
  };

  useEffect(() => {
    if (status.event === 'reset') {
      sendMessageToCcIframe(messageType.CC_AUTOFILL);
    }
  }, [status]);

  const handleOnLoad = () => {
    setIsIframeLoaded(true);
  };

  const registerMessages = e => {
    const matchUrl = e.origin.split('/').splice(0, 3).join('/');
    if (e.origin === matchUrl) {
      if (e.data.type === messageType.CC_IS_READY && e?.data?.payload) {
        if (!status.isPaymentReady && e.data.payload.isReady) {
          dispatch(setIsPaymentReady(true));
        }

        setApiStatus({ isCardReady: e?.data?.payload?.isReady });
        setUserCardId(e?.data?.payload?.userCardId);
        setUserCardCvv(e?.data?.payload?.userCardCvv);
        setCardType(e?.data?.payload?.cardType);
        setCvvChanged(e?.data?.payload?.cvvChanged);
        setLast4Numbers(e?.data?.payload?.last4Numbers);
        setFirst6Numbers(e?.data?.payload?.first6Numbers);

        if (e?.data?.payload?.isReady && e?.data?.payload?.userCardId) {
          if (cartData?.suggestions?.some(item => item.payment.id === e?.data?.payload?.userCardId)) {
            postAnalyticsStage1({ step: analyticsStage1.FAST_TRACK });
          } else {
            postAnalyticsStage1({ step: analyticsStage1.CREDIT_CARD });
          }
        }
      } else if (e.data.type === messageType.CC_SUGGESTIONS_ARE_VISIBLE) {
        setAreSuggestionsVisible(e?.data?.payload?.shouldRenderSuggestions);
      } else if (e.data.type === messageType.CC_TOKENIZE_STATUS) {
        setApiStatus({ isCardInProgress: e?.data?.payload?.isLoading });
      }
    }
  };

  const isError0008 = () => submitError.data === 'PAYMENT DECLINED';

  const isError0009 = () => submitError.data === 'INVALID BILLING ADDRESS';

  const isError0110 = () => submitError.data === 'INVALID BILLING ADDRESS / PAYMENT DECLINED';

  const hasError = isError0008() || isError0009() || isError0110() || isError0110();

  const renderHeaderAlerts = () => {
    if (isError0008()) {
      return <div className="declined-error-message">{t('card_add.card_declined_please_verify')}</div>;
    }
    if (isError0009()) {
      return <p className="billing-information-warning">{t('card_add.looks_like_we_need')}</p>;
    }
    if (isError0110() && contextBillingAddress && Object.keys(contextBillingAddress).length > 0) {
      return <div className="declined-error-message">{t('card_add.card_declined_please_verify')}</div>;
    }
    if (isError0110()) {
      return <div className="declined-error-message">{t('card_add.card_declined_please_provide')}</div>;
    }
    return null;
  };

  useEffect(() => {
    if (isIframeLoaded) {
      if (portalSettings.storeLanguage || reload) {
        sendMessageToCcIframe(messageType.CC_INITIAL_STATE, true);
      }
    }
  }, [isIframeLoaded, portalSettings]);

  useEffect(() => {
    if (isIframeLoaded && checkoutData?.id) {
      postAnalyticsStage2({ step: analyticsStage2.NOT_STARTED, hasLoaded: true });
    }
  }, [isIframeLoaded, checkoutData]);

  useEffect(() => {
    if (reload) {
      setIsIframeLoaded(false);
    }
  }, [reload]);

  useEffect(() => {
    window.addEventListener('message', registerMessages);
    return () => {
      window.removeEventListener('message', registerMessages);
    };
  }, [registerMessages]);

  useEffect(() => {
    sendMessageToCcIframe(messageType.CC_UPDATE_STATE);
  }, [isFieldsetDisabled]);

  if (iframeRef) {
    useEventsEffect(iframeRef);
  }

  useEffect(() => {
    if (
      status.isLoadingWithShippingAddress ||
      status?.event === 'login' ||
      status?.event === 'suggestion' ||
      (status?.event === 'createCart' && cartData.status === 'fulfilled') ||
      status?.event === 'pickup'
    ) {
      sendMessageToCcIframe(messageType.CC_AUTOFILL);
    }
  }, [status]);

  useEffect(() => {
    if (!checkoutData?.address?.hash) {
      sendMessageToCcIframe(messageType.CC_EMPTY_ADDRESS);
    }
  }, [checkoutData?.address]);

  useEffect(() => {
    if (submitFailedTimestamp) {
      if (isError0009() || isError0110()) {
        setIsShippingSectionCollapsed(true);
      }
      if (isError0008()) {
        sendMessageToCcIframe(messageType.CC_SUBMIT_FAILED, true, { errorCode: { id: 'NF_CHECKOUT_0008' } });
      } else if (isError0009()) {
        sendMessageToCcIframe(messageType.CC_SUBMIT_FAILED, true, { errorCode: { id: 'NF_CHECKOUT_0009' } });
      } else if (isError0110()) {
        sendMessageToCcIframe(messageType.CC_SUBMIT_FAILED, true, { errorCode: { id: 'NF_CHECKOUT_0110' } });
      }
    }
  }, [submitFailedTimestamp]);

  useEffect(() => {
    sendMessageToCcIframe(messageType.CC_AUTOFILL, false);
  }, [checkPickup]);

  useEffect(() => {
    if (total === 0) {
      setApiStatus({ isCardReady: true });
      setIsIframeInvisible(true);
    } else {
      setIsIframeInvisible(false);
    }
  }, [total]);

  return (
    <>
      {renderHeaderAlerts()}
      {areSuggestionsVisible ? <div className={style.placeholder} /> : null}
      {isFieldsetDisabled && (
        <div
          id="credit-card-disable"
          className={classNames(style.wrapperdisable, {
            [style.invisible]: isIframeInvisible,
            [style.suggestions]: areSuggestionsVisible,
            [style.nosuggestions]: !areSuggestionsVisible,
            [style.suggestionserror]: areSuggestionsVisible && hasError,
          })}
        />
      )}
      {urlParam !== 'openPopup' && (
        <iframe
          src={CHECKOUT_CC_URL}
          title={t('payment_method.credit_card.label')}
          ref={iframeRef}
          onLoad={handleOnLoad}
          className={classNames(style.wrapper, {
            [style.invisible]: isIframeInvisible,
            [style.suggestions]: areSuggestionsVisible,
            [style.nosuggestions]: !areSuggestionsVisible,
            [style.suggestionserror]: areSuggestionsVisible && hasError,
          })}
          scrolling="no"
        ></iframe>
      )}
      {showBillingCheckbox() && <BillingCheckbox />}
      {showBillingPhone() && <BillingPhone />}
    </>
  );
};

CreditCardApp.propTypes = {
  reload: PropTypes.bool,
};

export default CreditCardApp;
