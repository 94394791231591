import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withLDConsumer as featureFlagClient } from 'launchdarkly-react-client-sdk';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { pluginList } from '../../commons/constants';
import { selectCart, selectCheckout, selectApiStatus, setStatusEvent, selectError } from '../../store/slice';
import ShippingCollapsedData from '../../modules/shipping/components/collapsed-data';
import { currentPaymentMethod } from '../../modules/payment/slice';
import BillingCollapsedData from '../../modules/billing/components/collapsed-data';
import AdditionalServiceWrapper from '../../modules/additional-service/containers/additional-service-wrapper';
import useApiPostOrderError from '../../hooks/useApiPostOrderError';
import Shipping from '../../modules/shipping/containers/shipping';
import { useCheckoutSession } from '../../helpers/sessionHelper';
import { status, paymentIdList, analyticsStage1 } from '../../helpers/constants';
import useCurrency from '../../modules/currency/hooks/use-currency';
import { usePickup } from '../../modules/shipping/containers/pickup-in-store/use-pickup';
import Billing from '../../modules/billing/containers/billing';
import windowDimensions from '../../hooks/useWindowDimensions';
import usePortalSettings from '../../hooks/usePortalSettings';
import useApiStatus from '../../store/apiStatus/useApiStatus';
import { selectBilling } from '../../modules/billing/slice';
import { useSkeleton } from '../../hooks/useSkeleton';
import { useProduct } from '../../hooks/useProduct';
import { useEndpoint } from '../../hooks/useEndpoint';
import useAutofill from '../../hooks/useAutofill';
import DataContext from '../../store/dataContext';
import UiContext from '../../store/uiContext';
import { selectShipping } from '../../modules/shipping/slice';
import Header from '../../commons/components/header';
import PaymentMethodSelected from '../PaymentMethodSelected';
import SkeletonLoading from '../SkeletonLoading';
import PaymentMethods from '../PaymentMethods';
import BillingPhone from '../BillingPhone';
import CartTotalsMobile from '../../modules/cart/components/cartMobile';
import WelcomeBack from '../WelcomeBack';
import StoreCredit from '../StoreCredit';
import PaymentAdd from '../PaymentAdd';
import WarnTerms from './WarnTerms';
import UserAdd from '../UserAdd';
import { OrderNotesInput } from '../OrderNotes';
import './style.scss';

const Checkout = ({ setOrderSubmitting, flags }) => {
  const { resumeSession, isSessionActive } = useCheckoutSession();
  const { portalSettings } = usePortalSettings();
  const { isMobileDevice } = windowDimensions();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { apiStatus, set: setApiStatus } = useApiStatus();
  const { allDigital } = useProduct();
  const selectedMethod = useSelector(currentPaymentMethod);
  const billingInfo = useSelector(selectBilling);
  const submitError = useSelector(selectError);
  const shippingStatus = useSelector(selectApiStatus);
  const { autofillForm, handleNoAutofill } = useAutofill();
  const { shippingMethodKey } = useSelector(selectShipping);

  const dataContext = useContext(DataContext);
  const uiContext = useContext(UiContext);
  const cartData = useSelector(selectCart);
  const checkoutData = useSelector(selectCheckout);

  const { hasBillingError } = useApiPostOrderError();
  const { showLoading, setShowLoading } = useSkeleton();
  const { getShoppercurrency } = useCurrency();
  const { patchEnrich, postAnalyticsStage1 } = useEndpoint();

  const { isPickupActive } = usePickup();

  const creditCardType = selectedMethod === paymentIdList.CREDIT_CARD;

  useEffect(() => {
    if (
      (dataContext.isLoggedIn === false && cartData?.status === 'IDLE') ||
      (isSessionActive && dataContext.isLoggedIn === false && cartData?.status === 'IDLE')
    ) {
      resumeSession();
    }
  }, [dataContext.isLoggedIn, isSessionActive]);

  const emailChangeCallback = value => {
    uiContext.setShowUserAddControl(!dataContext.email || dataContext.email !== value);
  };

  const changeEmailStatus = (state = status.READY) => {
    setApiStatus({ isEmailReady: state });
  };

  const handleUserAdd = async emailTerm => {
    try {
      setShowLoading(true);
      dataContext.updateEmail(emailTerm);
      const result = await patchEnrich({
        email: emailTerm,
      });
      postAnalyticsStage1({ step: analyticsStage1.EMAIL });
      dataContext.setCustomer(result?.cart?.customer);
      changeEmailStatus();
      if (result) {
        if (!result.cart?.suggestions || result.cart?.suggestions?.length === 0) {
          setShowLoading(false);
          handleNoAutofill();
        }
        const autofillResult = await patchEnrich({
          email: emailTerm,
          address: result.cart?.suggestions[0].address,
          payment: result.cart?.suggestions[0].payment,
        });
        postAnalyticsStage1({ step: analyticsStage1.FAST_TRACK });
        dispatch(setStatusEvent({ event: 'suggestion' }));
        autofillForm(autofillResult.checkout, autofillResult.cart);
      }
    } catch (error) {
      if (error.message === 'nf_error_race_condition') {
        throw error;
      }
    }
  };

  const renderMobileExposedView = () => {
    const isNotThankYouPage = !uiContext.done;
    const markup = [];

    if (isNotThankYouPage && isMobileDevice) {
      const isMobileViewAndExposed =
        portalSettings.orderNotes.isEnabled && portalSettings.orderNotes.mobileView === 'Exposed';

      if (isMobileViewAndExposed) {
        markup.push(<OrderNotesInput key="order-notes" />);
      }

      const hasRoute = cartData?.plugin?.route;

      if (hasRoute) {
        markup.push(
          <AdditionalServiceWrapper
            type={pluginList.route}
            shopperCurrency={getShoppercurrency()}
            isAppMainLoading={dataContext.showLoading}
            isLoadingInsurance={uiContext?.isLoadingInsurance}
            isLoadingRoute={uiContext?.isLoadingRoute}
            isLoadingSignature={uiContext?.isLoadingSignature}
            shippingStatus={shippingStatus}
            formStatus={uiContext?.formStatus}
            isLoadingCoupons={uiContext?.isLoadingCoupons}
          />,
        );
      }

      const hasPackageInsurance = cartData?.plugin?.packageInsurance;

      if (hasPackageInsurance) {
        markup.push(
          <AdditionalServiceWrapper
            type={pluginList.packageInsurance}
            shopperCurrency={getShoppercurrency()}
            isAppMainLoading={dataContext.showLoading}
            isLoadingInsurance={uiContext?.isLoadingInsurance}
            isLoadingRoute={uiContext?.isLoadingRoute}
            isLoadingSignature={uiContext?.isLoadingSignature}
            shippingStatus={shippingStatus}
            formStatus={uiContext?.formStatus}
            isLoadingCoupons={uiContext?.isLoadingCoupons}
          />,
        );
      }

      const hasCustomerSignature = cartData?.plugin?.customerSignature;

      if (hasCustomerSignature) {
        markup.push(
          <AdditionalServiceWrapper
            type={pluginList.customerSignature}
            shopperCurrency={getShoppercurrency()}
            isAppMainLoading={dataContext.showLoading}
            isLoadingInsurance={uiContext?.isLoadingInsurance}
            isLoadingRoute={uiContext?.isLoadingRoute}
            isLoadingSignature={uiContext?.isLoadingSignature}
            shippingStatus={shippingStatus}
            formStatus={uiContext?.formStatus}
            isLoadingCoupons={uiContext?.isLoadingCoupons}
          />,
        );
      }
    }

    return markup;
  };
  useEffect(() => {
    if (shippingStatus?.event === 'createCart' && checkoutData?.customer?.email) {
      dataContext.updateEmail(checkoutData?.customer?.email);
      autofillForm(checkoutData, cartData);
    }
  }, [shippingStatus, checkoutData]);

  const isDisabledWelcomeBack = flags['disable-welcome-back'];

  const toggleCollapse = () => {
    dataContext.updateSameBilling(!dataContext.sameBilling);
    uiContext.setIsShippingSectionCollapsed(!uiContext.isShippingSectionCollapsed);
  };

  const isBillingSectionUnCollapsed =
    hasBillingError() && uiContext.isShippingSectionCollapsed && uiContext.allowBillingAddressAdd;

  const showBillingStandalone =
    !isBillingSectionUnCollapsed &&
    !dataContext.sameBilling &&
    checkoutData?.shippingMethodKey &&
    portalSettings?.checkoutMode?.billingBehaviour?.isEnabled;

  const showShipping = () =>
    cartData?.items?.length > 0 && !allDigital() && (cartData?.status === 'fulfilled' || cartData?.status === 'reset');

  const renderBilling = shouldBilling => {
    if (shouldBilling) {
      return (
        <Billing
          isFieldsetDisabled={
            uiContext.checkoutStage === uiContext.TO_USER_ADD || apiStatus.isEmailReady === status.LOADING
          }
          theme={cartData?.merchant?.settings?.theme}
          setContextBillingAddress={dataContext.setContextBillingAddress}
        >
          {shippingMethodKey && <BillingPhone flags={flags} isBillingAddress />}
        </Billing>
      );
    }
    return <div />;
  };

  return (
    <div
      className={classNames({
        section: true,
        declined: uiContext.isCardDeclined.error,
      })}
    >
      <Header />
      <UserAdd
        handleNext={handleUserAdd}
        changeEmailStatus={changeEmailStatus}
        emailChangeCallback={emailChangeCallback}
      />
      {showLoading && <SkeletonLoading hideRight />}
      <div className={showLoading ? 'hide-wrapper' : ''}>
        <div className={`${!uiContext.allowBillingAddressAdd ? 'content-wrapper' : 'billing-wrapper'}`}>
          <div className={uiContext.isShippingSectionCollapsed ? 'is-hidden' : ''}>
            {!isDisabledWelcomeBack && <WelcomeBack />}
            {showShipping() ? (
              <Shipping
                cartId={checkoutData?.id}
                isFieldsetDisabled={
                  uiContext.checkoutStage === uiContext.TO_USER_ADD || apiStatus.isEmailReady === status.LOADING
                }
                device={dataContext.device}
                updateCheckoutState={uiContext.updateCheckoutState}
                TO_SHIPPING_ADDRESS_ADD={uiContext.TO_SHIPPING_ADDRESS_ADD}
                isLoggedIn={dataContext.isLoggedIn}
                merchantId={dataContext?.merchantId}
                setApiShippingAddressResponse={dataContext.setApiShippingAddressResponse}
                setPaymentMethodData={dataContext.setPaymentMethodData}
                shopperCurrency={getShoppercurrency()}
                setContextBillingAddress={dataContext.setContextBillingAddress}
                theme={cartData?.merchant?.settings?.theme}
                updateContextShippingAddress={dataContext.updateContextShippingAddress}
                updateGiftInfo={dataContext.updateGiftInfo}
                updateShippingMethodId={dataContext.updateShippingMethodId}
                isAdditionalServiceLoading={uiContext.isLoadingInsurance || uiContext.isLoadingSignature}
                giftCertificates={dataContext.giftCertificates}
                isLoadingCoupons={uiContext.isLoadingCoupons || uiContext.formStatus === status.LOADING}
                isLoadingSignature={uiContext.isLoadingSignature}
                isLoadingInsurance={uiContext.isLoadingInsurance}
                isLoadingRoute={uiContext.isLoadingRoute}
                setShowLoadingPage={uiContext.setShowLoadingPage}
              />
            ) : (
              cartData?.items?.length > 0 &&
              !cartData?.isLoading && (
                <Billing
                  isFieldsetDisabled={uiContext.checkoutStage === uiContext.TO_USER_ADD || shippingStatus?.isLoading}
                  theme={cartData?.merchant?.settings?.theme}
                  setContextBillingAddress={dataContext.setContextBillingAddress}
                />
              )
            )}
          </div>
          {uiContext.isShippingSectionCollapsed && (
            <ShippingCollapsedData
              address={checkoutData?.address?.line1}
              line2={checkoutData?.address?.line2}
              company={checkoutData?.address?.company}
              fullName={checkoutData?.address?.name}
              methods={cartData?.shippingMethods}
              shippingMethodKey={checkoutData?.shippingMethodKey}
              shopperCurrency={getShoppercurrency()}
              title={t('shipping')}
              toggleCollapse={toggleCollapse}
            />
          )}
        </div>
        {((uiContext.allowBillingAddressAdd && portalSettings?.checkoutMode?.mode === 'ACTIVE') ||
          (uiContext.isCardDeclined.error && submitError.data !== 'PAYMENT DECLINED')) && (
          <h3 className="form-block-title active" id="checkout.billing_information">
            {t('checkout.billing_information')}
          </h3>
        )}
        {!flags['disable-store-credit'] && cartData?.customer?.storeCredit?.applied > 0 && <StoreCredit />}
        {renderBilling(
          (isBillingSectionUnCollapsed || showBillingStandalone || isPickupActive()) && !uiContext.isBillingError,
        )}
        <div
          style={{
            display:
              showBillingStandalone ||
              isBillingSectionUnCollapsed ||
              uiContext.isShippingSectionCollapsed ||
              !uiContext.allowBillingAddressAdd
                ? 'block'
                : 'none',
            position: dataContext.areSuggestionsVisible ? 'relative' : 'static',
          }}
        >
          <PaymentMethods flags={flags} />
          <PaymentMethodSelected flags={flags} setOrderSubmitting={setOrderSubmitting} />
        </div>
        {renderBilling(
          (isBillingSectionUnCollapsed || showBillingStandalone || isPickupActive()) && uiContext.isBillingError,
        )}
        {!uiContext.isShippingSectionCollapsed && uiContext.allowBillingAddressAdd && !showBillingStandalone && (
          <BillingCollapsedData
            address={billingInfo.address.singleLine}
            line2=""
            cardType={dataContext.cardType}
            fullName={billingInfo.fullName.value}
            last4Numbers={dataContext.last4Numbers}
            first6Numbers={dataContext.first6Numbers}
            phoneNumber={dataContext.billingPhone}
            title={t('billing')}
            toggleCollapse={toggleCollapse}
          />
        )}
        {creditCardType && <CartTotalsMobile />}
        {renderMobileExposedView()}
        {creditCardType &&
          (selectedMethod === paymentIdList.CREDIT_CARD ||
            selectedMethod === paymentIdList.UNDEFINED ||
            selectedMethod === null) && <PaymentAdd />}
        <WarnTerms />
      </div>
    </div>
  );
};

Checkout.propTypes = {
  setOrderSubmitting: PropTypes.func.isRequired,
  flags: PropTypes.shape({
    'disable-google-analytics': PropTypes.bool,
    'disable-paypal': PropTypes.bool,
    'disable-welcome-back': PropTypes.bool,
    'disable-store-credit': PropTypes.bool,
    'disable-ddi-phone': PropTypes.bool,
  }),
};

export default featureFlagClient()(Checkout);
