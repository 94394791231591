import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import { storeLanguage } from './prelaunchData';

export const ccNumberMask = (start, end) => `${start} **** **** ${end}`;

export const priceWithZeros = num => num.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 });

export const ccFullNumberSpaces = number => {
  const spacedNumber = number.toString().match(/.{1,4}/g);
  return spacedNumber.join(' ');
};

export const formatDate = (date, format) => {
  moment.locale(storeLanguage || 'en');
  return moment.utc(date)?.format(format);
};

export const reformatDate = (date, inputType) => {
  let formattedDate = date
    .replace(
      /^([1-9]\/|[2-9])$/g,
      '0$1/', // 3 => 03/
    )
    .replace(
      /^1([3-9])$/g,
      '01/$1', // 13 => 01/3
    )
    .replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
      '$1/$2', // 141 > 01/41
    )
    .replace(
      /^0\/|0+$/g,
      '0', // 0/ => 0 and 00 > 0
    )
    .replace(
      /[^\d|^/]*/g,
      '', // To allow only digits and `/`
    )
    .replace(
      /\/\//g,
      '/', // Prevent entering more than 1 `/`
    );

  if (formattedDate.length === 2 && inputType !== 'deleteContentBackward') {
    formattedDate = formattedDate.replace(
      /^(0[1-9]|1[0-2])$/g,
      '$1/', // 11 => 11/
    );
  }

  return formattedDate;
};

export const normalize = value => value.replace(/\D/g, '');

export const phoneFormat = rawPhone => {
  const phoneNumber = normalize(rawPhone);

  const partA = phoneNumber.substring(0, 3);
  const partB = phoneNumber.substring(3, 6);
  const partC = phoneNumber.substring(6, 11);

  if (partC) {
    return `(${partA}) ${partB}-${partC}`;
  }

  if (partB) {
    return `${partA}-${partB}`;
  }

  return phoneNumber;
};

const separateThousands = (value, thousandsSeparator) => {
  const numParts = value.toString().split('.');
  const decimalSeparator = thousandsSeparator === ',' ? ',' : '.';
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return numParts.join(decimalSeparator);
};

export const amountFormatter = ({ shopperCurrency, value, isNegative }) => {
  const { exchangeRate, precision, separator, symbol } = shopperCurrency;
  const position = symbol?.position ?? 'left';
  const token = symbol?.token ?? '$';
  const thousand = separator?.thousand ?? ',';
  const asFloat = parseFloat(value);
  if (Number.isNaN(asFloat)) {
    return 'x';
  }

  let amount = '';
  const withRate = Number(asFloat * exchangeRate).toFixed(precision || 2);
  if (thousand === ',') {
    amount = separateThousands(withRate);
  } else {
    amount = separateThousands(withRate, thousand);
  }
  amount = position === 'left' ? `${token}${amount}` : `${amount}${token}`;
  amount = isNegative ? `-${amount}` : `${amount}`;
  return amount;
};

export const formatAsTotal = ({ apiAmount, shopperCurrency, isShipping, isNegative, free, freeShippingFormat }) => {
  let value = '---';
  if (apiAmount !== undefined) {
    const shouldUseFreeAsText = apiAmount === 0 && isShipping && freeShippingFormat === 'text';
    value = shouldUseFreeAsText ? free : amountFormatter({ shopperCurrency, value: apiAmount, isNegative });
  }
  const isPending = value === '---';
  const amount = isPending ? '---' : value;
  return { isPending, amount };
};

export const reformatedAmount = (shopperCurrency, getOrderTotal) => {
  const totalAmount = amountFormatter({
    shopperCurrency,
    value: getOrderTotal(),
  });

  const sign = totalAmount.slice(0, 1);
  const amount = totalAmount.slice(1, -2);
  const rest = totalAmount.slice(-2);

  return { sign, amount, rest };
};
