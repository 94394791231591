/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';

import style from './index.module.scss';

export const NoOptionsMessage = () => null;

export const Menu = ({ children, options, isLoading, ...props }) => {
  const { nfTheme, nfSetAllowEnterManually, nfTranslation, inputValue } = props.selectProps;
  const hasSuggestions = options.length > 0;
  const hasInputValue = !!inputValue;

  const handleEnterManually = () => nfSetAllowEnterManually(true);

  const handleTouchEnd = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <components.Menu {...props}>
      {children}
      <>
        <div className={style.row}>
          {!isLoading && hasInputValue && (
            <button
              type="button"
              className={style['btn-as-text']}
              style={{ color: nfTheme.theme?.colour?.main }}
              onClick={handleEnterManually}
              onTouchStart={handleEnterManually}
              onTouchEnd={handleTouchEnd}
            >
              {nfTranslation.cant_find_your_address}
            </button>
          )}
          {!isLoading && !hasSuggestions && hasInputValue && (
            <button
              type="button"
              className={style['btn-manual']}
              style={{ borderColor: nfTheme.theme?.colour?.main }}
              onClick={handleEnterManually}
              onTouchStart={handleEnterManually}
              onTouchEnd={handleTouchEnd}
            >
              {nfTranslation.enter_manually}
            </button>
          )}
        </div>
        <div className={`${style.row} ${style['powered-by-google']}`}>{nfTranslation.powered_by_google}</div>
      </>
    </components.Menu>
  );
};

export const Input = ({ autoComplete, ...props }) => {
  const { nfAutocompleteId } = props.selectProps;
  return <components.Input {...props} autoComplete={nfAutocompleteId} />;
};
