import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCart } from '../../../store/slice';
import { EVENT, on, off, trigger } from '../../../commons/utils/custom-event';
import { CHECKOUT_CC_URL, CHECKOUT_VAULT_URL } from '../../../helpers/urlConst';

import { messageType } from '../constants';

const useEventsEffect = iframeRef => {
  const [previousOnBehalfOf, setPreviousOnBehalfOf] = useState(undefined);
  const cartData = useSelector(selectCart);
  const subscribeToResetCheckout = () => {
    on(EVENT.RESET_CHECKOUT, () => {
      if (iframeRef?.current?.contentWindow) {
        iframeRef?.current?.contentWindow?.postMessage(
          {
            type: messageType.CC_RESET,
          },
          '*',
        );
      }
    });
    return () => {
      off(EVENT.RESET_CHECKOUT);
    };
  };

  const subscribeToSubmitCheckout = () => {
    on(EVENT.SUBMIT_CHECKOUT, () => {
      if (iframeRef?.current?.contentWindow) {
        iframeRef.current.contentWindow.postMessage(
          {
            type: messageType.CC_SUBMIT,
          },
          CHECKOUT_CC_URL,
        );
      }
    });
    return () => {
      off(EVENT.SUBMIT_CHECKOUT);
    };
  };

  const subscribeToEmailResponse = () => {
    on(EVENT.EMAIL_RESPONSE_READY, ({ detail }) => {
      const hasDetails = detail && Object.values(detail).length > 0;
      if (hasDetails) {
        const onBehalfOf = detail?.cardTokenization?.onBehalfOf;
        const tokenizedFor = detail?.cardTokenization?.tokenizedFor;
        const cardTokenization = {
          url: CHECKOUT_VAULT_URL,
          onBehalfOf,
          tokenizedFor,
        };
        setPreviousOnBehalfOf(onBehalfOf);
        if (iframeRef?.current?.contentWindow && onBehalfOf) {
          iframeRef.current.contentWindow.postMessage(
            {
              type: messageType.CC_CARD_TOKENIZATION,
              payload: {
                cardTokenization,
              },
            },
            CHECKOUT_CC_URL,
          );
        }
      }
    });
    return () => {
      off(EVENT.EMAIL_RESPONSE_READY);
    };
  };

  const subscribeToEditEmail = () => {
    on(EVENT.EDIT_EMAIL, () => {
      if (iframeRef?.current?.contentWindow) {
        iframeRef.current.contentWindow.postMessage(
          {
            type: messageType.CC_EDIT_EMAIL,
          },
          '*',
        );
      }
    });
    return () => {
      off(EVENT.EDIT_EMAIL);
    };
  };

  useEffect(() => {
    subscribeToResetCheckout();
    subscribeToSubmitCheckout();
    subscribeToEmailResponse();
    subscribeToEditEmail();
  }, []);

  useEffect(() => {
    const onBehalfOf = cartData?.payment?.direct?.onBehalfOf;
    const tokenizedFor = cartData?.payment?.direct?.tokenisedFor;
    if (onBehalfOf && previousOnBehalfOf !== onBehalfOf) {
      trigger(EVENT.EMAIL_RESPONSE_READY, {
        cardTokenization: {
          onBehalfOf,
          tokenizedFor,
          url: CHECKOUT_VAULT_URL,
        },
      });
    }
  }, [cartData]);
};

export default useEventsEffect;
