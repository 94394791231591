import React from 'react';
import PropTypes from 'prop-types';

import './Terms.scss';

const Terms = ({ setActive }) => (
  <div className="rules-wrapper">
    <div className="rules-wrapper-header">
      <h1>Checkout terms of use - By NoFraud</h1>
    </div>
    <div className="rules-wrapper-body">
      <p>
        These End User Terms of Use (the “Terms”) govern Your use of NoFraud’s services made available to You by NoFraud
        (collectively, the “Services”) in connection with Your purchases of goods and services (each, a “Transaction”)
        from one or more Merchants (as such term is defined below). By accepting these Terms or by using any of the
        Services in connection with a Transaction, You are entering into a binding legal agreement with No Fraud LLC
        (“NoFraud”). If You do not agree to these Terms, You are not authorized to use or access the Services.
      </p>
      <p>
        ARBITRATION AND CLASS ACTION WAIVER NOTICE: THESE TERMS INCLUDE A MANDATORY BINDING ARBITRATION PROVISION AND
        CLASS ACTION WAIVER, WHICH LIMIT YOUR RIGHTS IN THE EVENT OF A DISPUTE RELATED TO THE SERVICES. PLEASE REFER TO
        THE{' '}
        <a
          href="#fake"
          onClick={() =>
            document.getElementById('arbitration').scrollIntoView({
              behavior: 'smooth',
            })
          }
        >
          “ARBITRATION AND CLASS ACTION WAIVER”
        </a>{' '}
        SECTION BELOW FOR MORE INFORMATION.
      </p>
      <p>
        You may be referred to herein as “You”, “Your”, and “End User”. NoFraud may also be referred to herein as “We,”
        “Us”, and “Our”.
      </p>
      <p className="rules-section-header">1. Privacy Policy.</p>
      {/* eslint max-len: ["error", { "code": 124 }] */}
      <p>
        Your use of the Services is subject to Our{' '}
        <a href="#fake" role="button" onClick={() => setActive('Privacy Policy')}>
          Privacy Policy
        </a>
        . You acknowledge that, in providing the Services, We may collect, record, use, share, and otherwise process
        Your personal information, including through the use of automated technologies, as described in the Privacy
        Policy.{' '}
        <b>
          Please be advised that we are not responsible for the privacy or data security practices of Merchants, which
          may have privacy policies or practices that differ from those explained in Our Privacy Policy. We strongly
          suggest You review the privacy policy of the relevant Merchants who have enabled You to use NoFraud’s checkout
          functionality.
        </b>
      </p>
      <p className="rules-section-header">2. Modifications.</p>{' '}
      <p>
        These Terms may be modified by NoFraud at any time upon notice to You. You agree that e-mail notice, or an
        announcement upon signing on to the Services shall be considered sufficient notice to You of such modifications.
        You are encouraged to check these Terms regularly. In addition, We may change the manner in which We provide any
        aspect of the Services, including any process, software, or other technology used to perform the Services. You
        may access a description of the current features and functionality included in the Services by clicking on this{' '}
        <a href="https://www.nofraud.com/platform/managed-services/" target="_blank" rel="noreferrer">
          link
        </a>{' '}
        or by visiting Our website at{' '}
        <a href="https://www.nofraud.com" target="_blank" rel="noreferrer">
          www.nofraud.com
        </a>
        . Your continued use of the Services after We have posted such modification to the Services or notified You of
        such changes to the Terms shall constitute Your acceptance of such modifications.
      </p>
      <p className="rules-section-header">3. Eligibility.</p>
      <p>
        You must be at least 18 years of age or the age of majority in Your jurisdiction to use the Services.You
        represent and warrant that You are the age of majority in Your jurisdiction, but in any event, at least 18 years
        of age. If You are under the age of 18 or the age of majority in Your jurisdiction, You may not use the Service.
        If You are using and/or registering for the Services on behalf of a company, entity, or organization (each, an
        “Organization”), You represent and warrant that (i) You are an authorized representative of such Organization
        with the authority to bind such Organization to these Terms, and (ii) agree to be bound by these Terms on behalf
        of such Organization You agree to provide Us with accurate and complete information in connection with Your use
        of the Service and to keep such information current.
      </p>
      <p className="rules-section-header">4. Acceptable Use Policy.</p>{' '}
      <p>
        You may use the Services solely for Your own internal, non-commercial use, and only in a manner that complies
        with all applicable laws. You shall not allow any third-party to use the Services on Your behalf. You agree not
        to (i) attempt to disassemble, decompile, or otherwise reverse engineer the Services or any portion thereof,
        (ii) use the Services in a way that could interfere with, disrupt, negatively affect, or inhibit other users
        from fully enjoying the Services, or that could damage, disable, overburden, or impair the functioning of the
        Services, (iii) delete or in any manner alter the copyright, trademark, and other proprietary rights notices or
        markings appearing on the Services or any output of the Services, or (iv) upload viruses or other malicious code
        to the Services or otherwise compromise, bypass, or circumvent the security of the Services.
      </p>
      <p className="rules-section-header">5. Communications From NoFraud.</p>{' '}
      <ol className="terms-list">
        <li>
          NoFraud may communicate with You in order to enable one of Your Transactions with a Merchant, for secondary
          authentication, to verify a Transaction, or to send You receipts, reminders and other notifications associated
          with Your Transaction(s). You hereby consent to accept and receive such communications from Us, including
          e-mail, text messages, calls, and push notifications to the cellular telephone number You provide to Us. You
          acknowledge that You are not required to consent to receive promotional texts or calls as a condition of using
          the Services. These communications may be generated by automated systems, for the purposes mentioned above.
        </li>
        <li>
          Depending on Your current carrier plan, You may incur charges for text messages or e-mail and agree You will
          not hold, nor participate in any action which seeks to hold, NoFraud liable for any charges incurred. We shall
          have no liability for transmission delays or message failures.
        </li>
        <li>
          You may opt-out of receiving communications by following the unsubscribe options We provide to You in each
          form of communication or by sending an email to <a href="mailto:support@nofraud.com">support@nofraud.com</a>.
          If You unsubscribe, We may restrict or terminate Your access to the Service.
        </li>
      </ol>
      <p className="rules-section-header">6. Ownership of the Services.</p>{' '}
      <p>
        As between You and NoFraud, NoFraud (and its licensors) owns all rights to the Services, including all
        proprietary content, information, materials, software, images, text, graphics, illustrations, logos, patents,
        trademarks service marks, copyrights, and “look and feel” of the Services, the underlying platforms, software,
        and other technologies, all modifications, enhancements, and updates thereof, and all intellectual property
        rights worldwide in any of the foregoing. You will not delete or in any manner alter the copyright, trademark,
        and other proprietary rights notices or markings appearing on any output of, or otherwise generated by, the
        Services. NoFraud grants You a worldwide, royalty-free, non-assignable, non-exclusive, revocable, and
        non-sublicensable license to use the Services. This license is for the sole purpose of using and enjoying the
        Services in a way that these Terms, including the Privacy Policy, and Our policies allow. You may not use the
        Services in ways that are not authorized by these Terms, nor may You help anyone else in doing so.
      </p>
      <p className="rules-section-header">7. Additional Terms of Third Parties.</p>{' '}
      <p>
        The Services are integrated with the e-commerce sites (each, a “Site”) of merchants who have enabled NoFraud’s
        checkout functionality (referred to herein as “Merchants”) in order to help facilitate the acceptance of
        payments by Merchant and its payment processors in connection with Transactions. Any Transaction made by You may
        be subject to additional terms, including but not limited to, terms of the relevant Merchant and Your payment
        card or financial institution. You agree to abide by such additional terms.
      </p>
      <ol className="terms-list">
        <li>
          <b>Relationship with the Merchant.</b> You acknowledge that the goods and/or services that You are purchasing
          from the Site are sold by the Merchant only and not from NoFraud. NoFraud does not charge You to use the
          Services. However, You may be charged processing or other fees by the Merchant in connection with a
          Transaction. You should check the terms and conditions of the relevant Merchant to understand what charges may
          apply to the Transaction, if any.
        </li>
        <li>
          <b>Issues with Goods or Services.</b> The Merchant shall be solely responsible for any issues or questions
          related to or arising from the goods/services, including but not limited to issues relating to goods/services
          quality, product advertising accuracy, shipping and delivery, product warranty, product return, refund, or
          exchange, and subscription terms (if any) entered into with the Merchant.
        </li>
        <li>
          <b>Payment Card Charges.</b> You understand and acknowledge that Your payment card will be charged by the
          merchant and its payment processors, and not by NoFraud. The merchant and Your payment card issuer shall be
          solely responsible for any issues or disputes arising from, related to, any and all charges to Your payment
          card in connection with the Transaction.
        </li>
      </ol>
      <p className="rules-section-header">8. Term.</p>{' '}
      <p>
        These Terms shall remain in effect upon Your acceptance of the Terms or Your first use or access of the Service,
        whichever occurs first, until terminated in accordance with these Terms. Any terms and conditions of these Terms
        that must survive termination in order to give them their proper effect and meaning shall survive termination
        and remain in effect, including but not limited to Sections 1, 5, 6, 11-15. NoFraud may terminate Your access to
        the Services and these Terms at any time at its sole discretion, with or without providing notice thereof to
        You. You may terminate these Terms by ceasing use of the Services and contacting{' '}
        <a href="mailto:support@nofraud.com">support@nofraud.com</a>.
      </p>
      <p className="rules-section-header">9. Adherence with Laws.</p>{' '}
      <p>
        You agree to comply fully with all applicable laws and regulations with respect to Your use of the Services. You
        represent and warrant that Your use of the Services shall not infringe upon or otherwise misappropriate the
        intellectual property rights of any third party.
      </p>
      <p className="rules-section-header">10. No Warranty.</p>{' '}
      <p>
        THE SERVICES ARE PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
        LAW, NOFRAUD EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS, EXPRESS OR IMPLIED, INCLUDING ANY IMPLIED
        WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT, AND ANY
        WARRANTIES AND CONDITIONS ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. NO ADVICE OR INFORMATION, WHETHER
        ORAL OR WRITTEN, OBTAINED FROM NOFRAUD OR ELSEWHERE WILL CREATE ANY WARRANTY OR CONDITION NOT EXPRESSLY STATED
        IN THESE TERMS.
      </p>
      <p className="rules-section-header">11. Limitation of Liability.</p>{' '}
      <p>
        IN NO EVENT WILL NOFRAUD BE LIABLE TO YOU FOR DAMAGES OF ANY KIND IN CONNECTION WITH OR RELATED TO ANY PRODUCTS
        OR SERVICES PURCHASED BY YOU FROM THE MERCHANT, INCLUDING ANY AMOUNTS CHARGES TO YOUR PAYMENT CARD FOR SUCH
        PRODUCTS AND SERVICES, OR FOR ANY INACCURACIES, MISTAKES OR TYPOGRAPHICAL ERRORS ON OR INABILITY TO USE THE
        MERCHANT’S SITE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NOFRAUD’S TOTAL LIABILITY TO YOU IN
        CONNECTION WITH THE NOFRAUD SERVICES FROM ALL CAUSES OF ACTION AND UNDER ALL THEORIES OF LIABILITY WILL BE
        LIMITED TO AND WILL NOT EXCEED $100. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL NOFRAUD
        BE LIABLE TO YOU IN CONNECTION WITH THE NOFRAUD SERVICES FOR ANY SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE OR
        CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF USE, DATA, BUSINESS OR PROFITS) OR FOR THE COST OF PROCURING SUBSTITUTE
        PRODUCTS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR THE USE OR PERFORMANCE OF THE SOFTWARE, WHETHER
        SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT
        LIABILITY OR OTHERWISE, AND WHETHER OR NOT NOFRAUD HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
        THE FOREGOING LIMITATIONS WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THESE TERMS IS FOUND TO
        HAVE FAILED OF ITS ESSENTIAL PURPOSE. THE LAWS OF CERTAIN JURISDICTIONS MAY NOT ALLOW THE EXCLUSIONS AND
        LIMITATIONS OF DAMAGES IN THIS SECTION, IN WHICH CASE NOFRAUD’S LIABILITY FOR DAMAGES IN CONNECTION WITH THESE
        TERMS IN SUCH JURISDICTIONS SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY THE LAWS OF SUCH JURISDICTION.
      </p>
      <p className="rules-section-header">12. Indemnification.</p>{' '}
      <p>
        You shall indemnify and hold harmless NoFraud from and against any claim, damages, loss, and expenses, including
        attorney’s fees (“Claim”) arising from or related to Your use of the Services, including but not limited to any
        Claim arising from or related to: a.) Your violation of these Terms; b.) Your violation of any applicable law or
        regulation; c.) Your violation of any terms between Yourself and any Merchant; and d.) Your violation of any
        terms between Yourself and any payment card issuer.
      </p>
      <p className="rules-section-header">13. Other Terms.</p>{' '}
      <p>
        These Terms constitute the complete understanding and agreement between the parties with respect to the subject
        matter contained herein, and supersedes all previous agreements, representations, warranties, statements,
        negotiations, understandings and undertakings, whether written or oral, pertaining to such subject matter. These
        Terms are governed by and construed in accordance with the laws of the State of Delaware excluding any conflicts
        of law provisions that would require the application of the laws of any state other than the State of Delaware.
        The parties agree that the U.N. Convention on Contracts for the International Sale of Goods shall not apply to
        these Terms or any transaction contemplated thereby. If for any reason a court of competent jurisdiction finds
        any provision of these Terms invalid or unenforceable, that provision of the Terms will be enforced to the
        maximum extent permissible and the other provisions of these Terms will remain in full force and effect. You may
        not assign or transfer these Terms, in whole or in part, by operation of law or otherwise, without Our prior
        written consent. Any attempted assignment in violation of the foregoing sentence shall be null and void. NoFraud
        may assign these Terms at its sole discretion.
      </p>
      <p className="rules-section-header">14. Dispute Resolution.</p>{' '}
      <p>
        You agree to notify NoFraud of any potential disputes. If You and We are not able to resolve Your dispute
        informally within 60 days, You may seek relief through binding arbitration as set forth below. Any action
        hereunder by You must be brought, if at all, within one (1) year from the accrual of the cause of action.
      </p>
      <p className="rules-section-header" id="arbitration">
        15. Arbitration Agreement and Class Action Waiver.
      </p>{' '}
      <ol className="terms-list">
        <li>
          <b>Mandatory Arbitration</b>. YOU AND NoFraud AGREE TO SUBMIT ANY AND ALL DISPUTES ARISING OUT OF OR RELATED
          TO YOUR USE OF THE SITE (each, a “Dispute”) to binding arbitration pursuant to the Federal Arbitration Act
          (Title 9 of the United States Code), which shall govern the interpretation and enforcement of this arbitration
          agreement (“Arbitration Agreement”). You and NoFraud waive the right to a trial by jury and any right to have
          a Dispute heard in court. In arbitration, Disputes are resolved by a neutral arbitrator instead of a judge or
          jury, discovery is more limited than in court, and the arbitrator &apos;s decision is subject to limited
          review by courts. The arbitrator can award on an individual basis the same damages and relief as a court,
          including monetary damages, injunctive relief, and declaratory relief. Judgment on the arbitrator&apos;s award
          may be entered in any court having jurisdiction thereof. A single arbitrator with the American Arbitration
          Association (&quot;AAA&quot;) will conduct the arbitration, and the award may not exceed the relief allowed by
          applicable law. The arbitration proceedings will be conducted by teleconference or videoconference, rather
          than in person, unless the arbitrator determines upon request by either You or No Fraud that an in-person
          hearing is appropriate. Any in-person appearances will be held at a location which is reasonably convenient to
          both parties with due consideration of their ability to travel and other pertinent circumstances. If the
          parties are unable to agree on a location, such determination should be made by the arbitrator. The AAA&apos;s
          Consumer Arbitration Rules will apply. If AAA will not apply those rules, then AAA&apos;s Commercial
          Arbitration Rules will apply. The AAA&apos;s rules and a form that can be used to initiate arbitration
          proceedings are available at http://www.adr.org. You and NoFraud agree that if for any reason AAA will not
          conduct or becomes unavailable to conduct the
        </li>
        <li>
          <b>Arbitration Class Action Waiver</b>. You and NoFraud agree that the arbitration will be conducted solely on
          an individual basis and not on a class, representative, consolidated, or private attorney general basis. A
          Dispute may not be consolidated with a claim brought or discovery by any person or entity that is not a party
          to the arbitration proceeding. The arbitrator may not award relief to any person or entity other than a party
          to the arbitration proceeding and may only award such relief as is necessary to provide relief to a party to
          the arbitration proceeding. If a court deems any portion of this Section 15.b invalid or unenforceable, then
          Sections 15.a and 15.c of this Arbitration Agreement will be null and void.
        </li>
        <li>
          <b>Fees and Costs in Arbitration</b>. Payment of all filing and arbitrator fees will be governed by the
          AAA&apos;s rules. NoFraud will reimburse You for those fees up to $7,500, unless the arbitrator determines the
          claims are frivolous. Likewise, NoFraud will not seek filing fees or arbitrator fees unless the arbitrator
          determines the claims are frivolous.
        </li>
        <li>
          <b>Non-Arbitration Class Action and Jury Waiver</b>. You and NoFraud agree that if for any reason a Dispute
          proceeds in court rather than arbitration: (1) You and NoFraud waive any right to a jury trial; (2) the
          Dispute will proceed solely on an individual, non-class, non-representative basis; and (3) neither You nor
          NoFraud may be a class representative or class member or otherwise participate in any class, representative,
          consolidated, or private attorney general proceeding.
        </li>
        <li>
          NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS SECTION, YOU MAY CHOOSE TO PURSUE YOUR CLAIM IN COURT AND NOT
          BY ARBITRATION IF YOU OPT OUT OF THIS ARBITRATION AGREEMENT WITHIN 30 DAYS FROM THE EARLIER OF: (1) THE DATE
          YOU ACCEPT THESE TERMS, OR (2) THE DATE YOU FIRST AGREED OR ASSENTED TO AN AGREEMENT WITH NoFraud THAT
          CONTAINED AN ARBITRATION PROVISION. The opt-out notice must be emailed to: notices@nofraud.com. The opt-out
          notice must state that You do not agree to this Arbitration Agreement and must include Your name, address,
          phone number and email address. This procedure is the only way You can opt out of this Arbitration Agreement,
          and failure to comply strictly with this procedure and the applicable deadline automatically will render the
          opt-out notice null and void. If You opt out of the arbitration provision, all other parts of this Arbitration
          Agreement will continue to apply.
        </li>
      </ol>
      <p className="rules-section-header">16. Export Control.</p>
      <p>
        You may not use or otherwise export or re-export the Services except as authorized by U.S. law and the laws of
        the jurisdiction(s) in which the Services were exported/reexported. You represent and warrant that You are not
        (x) located in any country that is subject to an embargo by the U.S. government or the government of the
        jurisdiction(s) in which the Services are exported/reexported, or that has been designated as a &quot;terrorist
        supporting&quot; country by the U.S. government or the government of the jurisdiction(s) in which the Services
        are exported/reexported, or (y) listed on any U.S. Government list of prohibited or restricted parties including
        the Treasury Department&apos;s list of Specially Designated Nationals or the U.S. Department of Commerce Denied
        Person&apos;s List or Entity List or a similar list by the government of the jurisdiction(s) in which the
        Services are exported/reexported. Downloading or using Our Services is at Your sole risk. Recognizing the global
        nature of the Internet, You agree to comply with all local rules and laws regarding Your use of Our Services,
        including as it concerns online conduct and acceptable content.
      </p>
      <p className="rules-section-header">17. Notice to California Residents.</p>{' '}
      <p>
        Under California Civil Code Section 1789.3, California residents are entitled to the following specific consumer
        rights information: The provider of the Services is No Fraud LLC. To file a complaint regarding the Services or
        to receive further information regarding use of the Services, send a letter to the address below or contact
        NoFraud via email at <a href="mailto:support@nofraud.com">support@nofraud.com</a> (with “California Resident
        Request” as the Subject Line). You may also contact the Complaint Assistance Unit of the Division of Consumer
        Services of the Department of Consumer Affairs in writing at 1625 North Market Boulevard, Suite N 112,
        Sacramento CA 95834 or by telephone at (800) 952-5210.
      </p>
      <p className="rules-section-header">18. Contact Us.</p>{' '}
      <p>
        You may contact us with any comments, questions, or suggestions you might have regarding these Terms or the
        services at:
      </p>
      <div className="terms-body-contact">
        <p>NoFraud</p>
        <p>One World Trade Center</p>
        <p>76 Floor Suite G</p>
        <p>New York, NY 10007</p>
        <p>212.655.9864</p>
        <a href="mailto: support@nofraud.com" className="mail-link">
          support@nofraud.com
        </a>
      </div>
    </div>
  </div>
);

Terms.propTypes = {
  setActive: PropTypes.func.isRequired,
};

export default Terms;
