import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import DataContext from '../../store/dataContext';
import useCartTotals from '../../store/cartTotals/useCartTotals';

import { convertAddressToBackend } from '../../modules/shipping/utils';
import { useEndpoint } from '../../hooks/useEndpoint';

const useAddressApi = () => {
  const { device, email, billingPhone, contextBillingAddress, billingName, orderNotes, setPaymentMethodData } =
    useContext(DataContext);
  const { t } = useTranslation();
  const { setData: setCartTotalsData, setError: setCartTotalsError } = useCartTotals();
  const { patchEnrich } = useEndpoint();

  const isAddressPayloadValid = address =>
    address &&
    [
      address.line1,
      address.city,
      address.region,
      address.regionCode,
      address.postal,
      address.country,
      address.singleLine,
    ].every(Boolean);

  const getWithShippingAddressPayload = (address, fullName, cartId, action, shipCompany, additionalCharges) => {
    const shipAddress = convertAddressToBackend(address, fullName);

    return {
      cartId,
      action,
      data: {
        email,
        cartId,
        shipName: fullName,
        shipCompany,
        shipAddress,
        storeURL: window.location.href,
        deviceId: device?.id,
        additionalCharges: additionalCharges || [],
      },
      i18n: {
        message1: t('shipping_methods.there_are_no_shipping_methods_available'),
      },
    };
  };

  const getWithShippingMethodPayload = (address, fullName, cartId, selectedKey, shipCompany, additionalCharges) => {
    const shipAddress = convertAddressToBackend(address, fullName);
    const data = {
      deviceId: device?.id,
      email,
      cartId,
      shipName: fullName,
      shipCompany,
      shipAddress,
      shippingMethodKey: selectedKey,
      shippingMethodId: selectedKey,
      storeURL: window.location.href,
    };

    if (billingPhone) {
      data.billPhone = billingPhone;
    }

    if (contextBillingAddress && Object.keys(contextBillingAddress).length > 0) {
      data.billName = billingName;
      data.billAddress = contextBillingAddress;
    }

    if (orderNotes) {
      data.orderNotes = orderNotes;
    }

    if (additionalCharges) {
      data.additionalCharges = additionalCharges;
    }

    return {
      cartId,
      data,
    };
  };

  const executeWithShippingMethod = async payload => {
    try {
      const result = await patchEnrich({
        shippingMethodKey: payload,
      });
      setCartTotalsData(result.cartTotals);
      setPaymentMethodData(result.payment);
      return result;
    } catch (error) {
      setCartTotalsError(error.stack);
      return null;
    }
  };

  return {
    isAddressPayloadValid,
    getWithShippingAddressPayload,
    getWithShippingMethodPayload,
    executeWithShippingMethod,
  };
};

export default useAddressApi;
