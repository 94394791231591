import { useContext, useEffect } from 'react';
import DataContext from '../store/dataContext';

export const useSkeleton = () => {
  const { showLoading, setShowLoading, isLoggedIn } = useContext(DataContext);
  let timer;

  useEffect(() => {
    if (showLoading) {
      if (timer) {
        clearTimeout(timer);
      }
      if (isLoggedIn) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        setShowLoading(false);
      }, [15000]);
    } else {
      clearTimeout(timer);
      const windowHeight = window.innerHeight;
      const sidebarHeight = document.querySelector('.app-sidebar')?.offsetHeight;
      const mainHeight = document.querySelector('.app-main')?.offsetHeight;
      const isWindowSmallerThanContent = windowHeight < sidebarHeight || windowHeight < mainHeight;
      if (isWindowSmallerThanContent) {
        document.querySelector('div#checkout-app')?.setAttribute('style', 'align-items: flex-start');
      }
    }
    return () => clearTimeout(timer);
  }, [showLoading, isLoggedIn]);

  return { showLoading, setShowLoading };
};
