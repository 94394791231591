import { initialErrorState } from '../../utils/initial-error-state';
import { validationSource } from '../../constants';

export const validateFullName = (nameValue, nameErrors, source) => {
  if (nameValue === '' && source !== validationSource.FROM_SUBMIT) {
    return initialErrorState;
  }

  if (nameValue.replace(/\s/g, '').length < 3) {
    return {
      ...initialErrorState,
      hasError: true,
      message: nameErrors['full_name.name_too_short'],
    };
  }

  if (nameValue.trim().split(/[ ,]+/).length <= 1) {
    return {
      ...initialErrorState,
      hasError: true,
      message: nameErrors['full_name.please_fill_in_first_and_last_name'],
    };
  }
  return initialErrorState;
};
