import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

const InputWrapper = forwardRef(
  (
    {
      autoComplete = 'new-password',
      children,
      classNameInput = '',
      classNameWrapper = '',
      disabled,
      error,
      inputId,
      onChange,
      onFocus,
      onKeyDown,
      placeholder,
      placeholderTop,
      theme,
      isUppercase = false,
      type,
      value,
      ...props
    },
    ref,
  ) => (
    <div className={`nf-input-wrapper ${classNameWrapper}`}>
      {disabled && <div id="nf-disabled-but-clickable" className="nf-disabled-but-clickable" />}

      <input
        aria-label={placeholderTop}
        autoComplete={autoComplete}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        className={classNameInput}
        disabled={disabled}
        data-testid={inputId}
        id={inputId}
        name={inputId}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        ref={ref}
        style={{ borderColor: theme?.colour?.outline }}
        type={type}
        value={value}
        {...props}
      />
      <label htmlFor={inputId}>
        <span
          className={classNames({
            'is-uppercase': isUppercase,
            placeholder: true,
          })}
        >
          {placeholder}
        </span>
        <span
          className={classNames({
            'is-uppercase': isUppercase,
            'placeholder-top': true,
          })}
          style={{ color: theme?.colour?.main }}
        >
          {placeholderTop}
        </span>
      </label>

      {error && <div className="error">{error}</div>}

      {children}
    </div>
  ),
);

InputWrapper.propTypes = {
  autoComplete: PropTypes.string,
  children: PropTypes.node,
  classNameInput: PropTypes.string,
  classNameWrapper: PropTypes.string,
  disabled: PropTypes.bool,
  isUppercase: PropTypes.bool,
  error: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  placeholderTop: PropTypes.string.isRequired,
  theme: PropTypes.oneOfType([PropTypes.object]),
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default InputWrapper;
