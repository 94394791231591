import { useEffect } from 'react';
import axios from 'axios';

import { SMARTY_US_AUTOCOMPLETE_URL, SMARTY_US_STREET_URL } from '../../../helpers/urlConst';

import useDebounce from '../../hooks/use-debounce';
import { autofillSource } from '../../constants';
import { reportError } from '../../utils/report-error';

import { parseGeoData } from '../google-address-autocomplete/utils';

import {
  formatAsAutocompleteSuggestion,
  formatSmartySelectionAsQueryParams,
  formatSmartyForLegacy,
  getSmartySecondaryQueryParams,
} from './utils';

const useAutocompleteApi = (search, setInputItems) => {
  const fetchSmartyUsSuggestions = async () => {
    const encodedSearch = encodeURIComponent(search);
    const result = await axios.get(
      `${SMARTY_US_AUTOCOMPLETE_URL}?key=${process.env.REACT_APP_SMARTY_KEY}&search=${encodedSearch}&max_results=5&source=all`,
    );
    if (result?.data?.suggestions?.length > 0) {
      const formatted = result.data.suggestions.map(item =>
        formatAsAutocompleteSuggestion(item, autofillSource.SMARTY_US_AUTOCOMPLETE),
      );
      setInputItems(formatted);
      return true;
    }
    setInputItems([]);
    return false;
  };

  const fetchGoogleSuggestions = async () => {
    const { google } = window;
    if (!google?.maps?.places) return;

    const service = new google.maps.places.AutocompleteService();
    const language = navigator.language || 'en';
    service.getPlacePredictions({ input: search, language }, result => {
      if (result?.length > 0) {
        const formatted = result.map(item =>
          formatAsAutocompleteSuggestion(item, autofillSource.GOOGLE_AUTOCOMPLETION_SERVICE),
        );
        setInputItems(formatted);
      } else {
        setInputItems([]);
      }
    });
  };

  const debouncedRequest = useDebounce(async highlightedIndex => {
    try {
      // If the user has already selected an item, don't make any more requests
      const shouldSkipEarly = highlightedIndex > -1;
      if (shouldSkipEarly) {
        return;
      }

      const hasFetchedSmartySuggestions = await fetchSmartyUsSuggestions();
      if (!hasFetchedSmartySuggestions) {
        fetchGoogleSuggestions();
      }
    } catch (err) {
      reportError(err);
      fetchGoogleSuggestions();
    }
  }, 300);

  const expandSmartySecondary = async selection => {
    try {
      const queryParams = getSmartySecondaryQueryParams(search, selection);
      const result = await axios.get(
        `${SMARTY_US_AUTOCOMPLETE_URL}?key=${process.env.REACT_APP_SMARTY_KEY}&${queryParams}`,
      );
      if (result?.data?.suggestions?.length > 0) {
        const formatted = result.data.suggestions.map(item =>
          formatAsAutocompleteSuggestion(item, autofillSource.SMARTY_US_AUTOCOMPLETE),
        );
        setInputItems(formatted);
      }
    } catch (err) {
      reportError(err);
    }
  };

  const handleSmartySelection = async (
    selection,
    setShouldOpenAdditionalData,
    updateAdditionalData,
    additionalData,
  ) => {
    try {
      if (selection.secondary) {
        updateAdditionalData({
          ...additionalData,
          line2: selection.secondary,
        });
        setShouldOpenAdditionalData(new Date().getTime());
        return formatSmartyForLegacy(selection);
      }

      const queryParams = formatSmartySelectionAsQueryParams(selection);
      const smartyUsValidation = `${SMARTY_US_STREET_URL}&key=${process.env.REACT_APP_SMARTY_KEY}&${queryParams}&match=enhanced`;
      const response = await axios.get(smartyUsValidation);

      if (response.data.length > 0) {
        const shouldAskForAdditionalData = response.data[0].analysis.dpv_match_code === 'D';
        if (shouldAskForAdditionalData) {
          setShouldOpenAdditionalData(new Date().getTime());
        }
        return formatSmartyForLegacy(selection, additionalData);
      }

      return null;
    } catch (err) {
      reportError(err);

      return null;
    }
  };

  const handleGoogleSelection = async (selection, setShouldOpenAdditionalData) => {
    try {
      const { google } = window;
      if (!google?.maps?.Geocoder) return null;
      const service = new window.google.maps.Geocoder();
      const response = await service.geocode({ placeId: selection.place_id });

      if (response?.results?.length > 0) {
        const shouldAskForAdditionalData =
          response.results[0].types?.includes('premise') || response.results[0].types?.includes('subpremise');
        if (shouldAskForAdditionalData) {
          setShouldOpenAdditionalData(new Date().getTime());
        }
        return parseGeoData(response.results[0].address_components);
      }

      return null;
    } catch (err) {
      reportError(err);

      return null;
    }
  };

  useEffect(() => {
    const scriptLoaded = document.getElementById('NF-script-google-maps-api');
    if (!window.google?.maps?.places && !scriptLoaded) {
      const script = document.createElement('script');
      script.id = 'NF-script-google-maps-api';
      script.src = `https://maps.googleapis.com/maps/api/js?language=en&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return { debouncedRequest, handleSmartySelection, handleGoogleSelection, expandSmartySecondary };
};

export default useAutocompleteApi;
