export const ApiStatus_Set = 'ApiStatus_Set';
export const ApiStatus_Reset = 'ApiStatus_Reset';

export const setApiStatus = (key, value) => ({
  type: ApiStatus_Set,
  payload: {
    [key]: value,
  },
});

export const resetApiStatus = () => ({
  type: ApiStatus_Reset,
});
