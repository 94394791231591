import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { amountFormatter, priceWithZeros } from '../../../../helpers/format';
import { currentBaseUrl } from '../../../../helpers/urlConst';
import { fontSize, fontWeight } from '../../../../helpers/constants';
import { pluginList } from '../../../../commons/constants';
import { selectCart, selectApiStatus } from '../../../../store/slice';

import useProtection from '../../hooks/use-shipping-protection';
import useCustomerSignature from '../../hooks/use-customer-signature';

import './index.scss';

const AdditionalServiceWrapper = ({
  type,
  shopperCurrency,
  isLoadingInsurance,
  isLoadingRoute,
  isLoadingSignature,
  checkout,
}) => {
  const { handleChange: handleChangeProtection } = useProtection();
  const { handleChange: handleChangeSignature } = useCustomerSignature(checkout);
  const cartData = useSelector(selectCart);
  const apiStatus = useSelector(selectApiStatus);
  const { t } = useTranslation();
  const isApplied = cartData?.additionalCharges.find(item => item.name === cartData?.plugin?.[type]?.charge?.name);

  const showCorrectTitle = informationType => {
    if (informationType === 'title') {
      if (type === 'packageInsurance') {
        return t('protection_container.title_insurance');
      }
      if (type === 'route') {
        return t('protection_container.title_route');
      }
      if (type === 'customerSignature') {
        return t('signature_container.title');
      }
      return '';
    }
    if (informationType === 'description') {
      if (type === 'packageInsurance') {
        return t('protection_container.description');
      }
      if (type === 'route') {
        return t('protection_container.description');
      }
      if (type === 'customerSignature') {
        return t('signature_container.description');
      }
      return '';
    }
    return '';
  };

  const showCorrectPrice = () => priceWithZeros(Number(cartData?.plugin?.[type]?.charge?.price || 0).toFixed(2));

  const handleChange = event => {
    if (type === 'packageInsurance') {
      handleChangeProtection(type)(event);
    }
    if (type === 'route') {
      handleChangeProtection(type)(event);
    }
    if (type === 'customerSignature') {
      handleChangeSignature(event);
    }
  };

  const currentStatus = () => !isApplied;

  const isLoadingState = () => {
    if (type === 'packageInsurance') {
      return isLoadingInsurance;
    }
    if (type === 'route') {
      return isLoadingRoute;
    }
    if (type === 'customerSignature') {
      return isLoadingSignature;
    }
    return true;
  };

  const renderInfoBlock = () => {
    if (type === 'packageInsurance' || type === 'route') {
      return (
        <div className="info-wrapper">
          {t('protection_container.info_wrapper')}
          <span className="triangle">▲</span>
        </div>
      );
    }
    return null;
  };

  const renderText = () => {
    const text = currentStatus() ? `+${t('plugin_btn.add')}` : t('plugin_btn.remove');
    if (cartData?.merchant?.settings?.theme?.font?.plugins?.weight) {
      return (
        <div
          style={{
            fontWeight: fontWeight[cartData?.merchant?.settings?.theme?.font?.plugins?.weight],
            fontSize: fontSize[cartData?.merchant?.settings?.theme?.font?.plugins?.size],
            color: cartData?.merchant?.settings?.theme?.colour?.plugins,
          }}
        >
          {text}
        </div>
      );
    }
    return text;
  };

  const renderLoadingState = () => {
    if (isLoadingState()) {
      return (
        <div className="loading-wrapper">
          <img
            className="loading"
            alt="Loading..."
            src={`${currentBaseUrl()}loaders/spinner-blue.png`}
            width={15}
            height={15}
          />
        </div>
      );
    }
    return (
      <div
        className={`${currentStatus() ? 'add-btn' : 'remove-btn'} reset-styles`}
        style={currentStatus() ? { color: cartData?.merchant?.settings?.theme?.colour?.main } : null}
      >
        {renderText()}
      </div>
    );
  };

  return (
    <button className="plugin-button" type="button" onClick={handleChange} disabled={apiStatus.isLoading}>
      <section className="protection-wrapper">
        <div className="protect-route">
          <div className="title-wrapper">
            {type === 'route' && (
              <img className="route-logo" alt="route-logo" src={`${currentBaseUrl()}assets/route.svg`} />
            )}
            <p className="title">{showCorrectTitle('title')}</p>
            {type === 'route' && (
              <img className="question-icon" alt="question-icon" src={`${currentBaseUrl()}assets/question-dark.svg`} />
            )}
          </div>
          <p className="number">
            {amountFormatter({
              shopperCurrency,
              value: showCorrectPrice(),
            })}
          </p>
        </div>
        <div className="shipping-issues">
          <p className="description">{showCorrectTitle('description')}</p>
          {renderLoadingState()}
        </div>
        {renderInfoBlock()}
      </section>
    </button>
  );
};

export default AdditionalServiceWrapper;

AdditionalServiceWrapper.propTypes = {
  type: PropTypes.oneOf([pluginList.packageInsurance, pluginList.customerSignature, pluginList.route]).isRequired,
  shopperCurrency: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isLoadingInsurance: PropTypes.bool.isRequired,
  isLoadingRoute: PropTypes.bool.isRequired,
  isLoadingSignature: PropTypes.bool.isRequired,
  checkout: PropTypes.oneOfType([PropTypes.object]),
};
