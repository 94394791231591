import { useContext } from 'react';

import useApiStatus from '../../../store/apiStatus/useApiStatus';
import { status } from '../../../helpers/constants';
import UiContext from '../../../store/uiContext';

import { useEndpoint } from '../../../hooks/useEndpoint';

const useProtection = () => {
  const { setIsLoadingInsurance, setIsLoadingRoute } = useContext(UiContext);
  const { set: setApiStatus } = useApiStatus();
  const { patchEnrich } = useEndpoint();

  const handleChange = type => async () => {
    const plugin = {};

    if (type === 'route') {
      setIsLoadingRoute(true);
      plugin.route = {};
    } else if (type === 'packageInsurance') {
      setIsLoadingInsurance(true);
      plugin.packageInsurance = {};
    }

    const result = await patchEnrich({
      input: true,
      plugin,
    });

    if (result.cart) {
      if (type === 'route') {
        setIsLoadingRoute(false);
      } else if (type === 'packageInsurance') {
        setIsLoadingInsurance(false);
      }

      setApiStatus({ isShippingMethodReady: status.READY });
    }
  };

  return { handleChange };
};

export default useProtection;
