export const on = (eventType, listener) => {
  document.addEventListener(eventType, listener);
};

export const off = (eventType, listener) => {
  document.removeEventListener(eventType, listener);
};

export const trigger = (eventType, data) => {
  const event = new CustomEvent(eventType, { detail: data });
  document.dispatchEvent(event);
};

export const EVENT = Object.freeze({
  EMAIL_RESPONSE_READY: 'EMAIL_RESPONSE_READY',
  RESET_CHECKOUT: 'RESET_CHECKOUT',
  SUBMIT_CHECKOUT: 'SUBMIT_CHECKOUT',
  EDIT_EMAIL: 'EDIT_EMAIL',
});
