import React, { useState, useContext, useEffect } from 'react';
import { useSelect } from 'downshift';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { initialErrorState } from '../../../../commons/utils/initial-error-state';
import DataContext from '../../../../store/dataContext';
import { EVENT, on, off } from '../../../../commons/utils/custom-event';
import { deliveryMethod as deliveryMethodConstant } from '../../../../commons/constants';
import { selectCart, selectCheckout } from '../../../../store/slice';
import useCartTotals from '../../../../store/cartTotals/useCartTotals';
import { useEndpoint } from '../../../../hooks/useEndpoint';

import { useModal } from '../../../modal/hooks/use-modal';
import { backendErrorCodes } from '../../../modal/constants';

import { selectShipping, setSelectedPickupMethod, resetKeys } from '../../slice';

import style from './index.module.scss';

const itemToString = item => (item ? `${item.name} ${item.address.line1}` : '');

const PickUpInStore = ({ saveToLocalStorage, theme, triggerValidation, setDisableTab }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { bopis, deliveryMethod } = useSelector(selectShipping);
  const cartData = useSelector(selectCart);
  const checkoutData = useSelector(selectCheckout);
  const { patchEnrich } = useEndpoint();

  const { setApiShippingAddressResponse } = useContext(DataContext);

  const [error, setError] = useState(initialErrorState);

  const isPickup = deliveryMethod === deliveryMethodConstant.pickup;

  const {
    setError: setCartTotalsError,
    setLoading: setCartTotalsLoading,
    setData: setCartTotalsData,
  } = useCartTotals();

  const { showProductsOutOfStock } = useModal();

  const updateSelectedPickupMethod = newSelectedItem => {
    dispatch(setSelectedPickupMethod(newSelectedItem));
    saveToLocalStorage('selectedPickupMethod', newSelectedItem);
  };

  const executeWithPickupMethod = async newSelectedItem => {
    try {
      setCartTotalsLoading();
      dispatch(resetKeys());
      const result = await patchEnrich({
        address: checkoutData?.address,
        pickUpMethodKey: newSelectedItem.lookupKey,
        removeShippingMethod: true,
      });
      setApiShippingAddressResponse(result);
      setCartTotalsData(result.cart.totals);
      updateSelectedPickupMethod(newSelectedItem);
      setError(initialErrorState);
      setDisableTab(false);
      return result;
    } catch (e) {
      if (e.error === backendErrorCodes.outOfStock) {
        showProductsOutOfStock({ products: e.message.products, cartItems: cartData.items });
      }
      setCartTotalsError(null);
      setDisableTab(false);
      return null;
    }
  };

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    selectItem,
  } = useSelect({
    items: bopis.pickupMethods,
    itemToString,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      if (isPickup) {
        executeWithPickupMethod(newSelectedItem);
      }
    },
  });

  const shouldMapAndRenderMethods = isOpen && bopis.pickupMethods?.length > 0;

  const resetState = () => {
    setError(initialErrorState);
    updateSelectedPickupMethod(null);
  };

  const isValid = () => {
    if (!selectedItem) {
      setError({
        hasError: true,
        message: t('pick_up_in_store.no_pickup_method_selected'),
      });
    } else {
      setError(initialErrorState);
    }
  };

  useEffect(() => {
    const restorePickupMethodFromLocalStorage = () => {
      if (bopis.selectedPickupMethod) {
        const selectedPickupMethod = bopis.pickupMethods.find(
          method => method.lookupKey === bopis.selectedPickupMethod.lookupKey,
        );
        if (selectedPickupMethod) {
          selectItem(selectedPickupMethod);
        }
      }
    };
    restorePickupMethodFromLocalStorage();
  }, []);

  useEffect(() => {
    const subscribeToResetCheckout = () => {
      on(EVENT.RESET_CHECKOUT, () => {
        resetState();
      });

      return () => {
        off(EVENT.RESET_CHECKOUT);
      };
    };
    subscribeToResetCheckout();
  }, []);

  useEffect(() => {
    const hasSinglePickupMethod = bopis.pickupMethods?.length === 1;
    if (isPickup && hasSinglePickupMethod) {
      // if/else is needed if not the executeWithPickupMethod will not be called on tab (shipping vs pickup) switch
      if (!selectedItem) {
        selectItem(bopis.pickupMethods[0]);
      } else {
        executeWithPickupMethod(bopis.pickupMethods[0]);
      }
    }
  }, [deliveryMethod]);

  useEffect(() => {
    const validationEffect = () => {
      if (triggerValidation) {
        isValid();
      }
    };
    validationEffect();
  }, [triggerValidation]);

  return (
    <div className={`nf-input-wrapper ${style['nf-input-wrapper-mg']}`}>
      <div
        className={classNames(`nf-select ${style.select}`, {
          'is-value': selectedItem,
          'has-error': error.hasError,
        })}
        style={{ borderColor: theme?.mainColor }}
        {...getToggleButtonProps({
          onFocus: () => setError(initialErrorState),
        })}
      >
        {selectedItem && (
          <>
            <p>
              <span className={style['dark-text']}>{selectedItem.name}</span>{' '}
              <span className={style['light-text']}>{selectedItem.address.line1}</span>
            </p>
          </>
        )}
        {isOpen ? <span className={style['up-arrow']}></span> : <span className={style['down-arrow']}></span>}
      </div>
      <label {...getLabelProps()}>
        {!selectedItem && <span className="placeholder">* Select Pickup Location</span>}
        <span className="placeholder-top" style={{ color: theme?.mainColor }}>
          {t('pick_up_in_store.pick_up_in_store')}
        </span>
      </label>
      <ul className={`${style['nf-ul']} ${!isOpen && 'hidden'}`} {...getMenuProps()}>
        {shouldMapAndRenderMethods ? (
          bopis.pickupMethods.map((item, index) => (
            <li
              className={highlightedIndex === index ? style['bg-li'] : ''}
              key={item.address.line1}
              {...getItemProps({ item, index })}
            >
              <span className={style['dark-text']}>{item.name}</span>{' '}
              <span className={style['light-text']}>{item.address.line1}</span>
            </li>
          ))
        ) : (
          <li>{t('pick_up_in_store.no_pickup_methods_found')}</li>
        )}
      </ul>
      {/*
      CHKOUT-1470, the business wants to remove this for now
      {selectedItem && (
        <p className={style['email-order-ready']}>{t('pick_up_in_store.email_when_your_order_is_ready_for_pickup')}</p>
      )}
      */}
      {error.hasError && <p className={`error ${style['no-mgb']}`}>{error.message}</p>}
    </div>
  );
};

PickUpInStore.propTypes = {
  saveToLocalStorage: PropTypes.func.isRequired,
  setDisableTab: PropTypes.func.isRequired,
  theme: PropTypes.oneOfType([PropTypes.object]),
  triggerValidation: PropTypes.number,
};

export default PickUpInStore;
