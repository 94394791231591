import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const CartAttributes = ({ quantity, attributes }) => {
  const { t } = useTranslation();

  const renderAttributes = () => {
    if (attributes) {
      return Object.keys(attributes).map(key => {
        const value = attributes[key];
        return (
          <React.Fragment key={`attribute-${key}`}>
            <br />
            {`${key}: ${value}`}
          </React.Fragment>
        );
      });
    }
    return null;
  };
  return (
    <div className="line-items-attributes">
      {`${t('cart_attributes.quantity')}: ${quantity}`}
      {renderAttributes()}
    </div>
  );
};

CartAttributes.propTypes = {
  quantity: PropTypes.number.isRequired,
  attributes: PropTypes.oneOfType([PropTypes.object]),
};

export default CartAttributes;
