import { status } from '../../helpers/constants';

export default {
  isEmailReady: status.IDLE,
  isNameReady: false,
  isShippingAddressReady: status.IDLE,
  isShippingMethodReady: status.IDLE,
  isCardInProgress: false,
  isCardReady: false,
  isBillingReady: status.IDLE,
  isSessionReady: status.READY,
};
