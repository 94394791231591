import _ from 'lodash';

import { postSpecialOffer } from '../../store/slice';
import { useEndpoint } from '../../hooks/useEndpoint';

const submitOrderWithOffer = async params => {
  const { appliedOfferResult, updateOfferedItem, checkoutData, setApiLoading } = params;
  const { postSubmit } = useEndpoint();

  try {
    const address = {
      ...checkoutData.address,
      name: checkoutData.address.name || appliedOfferResult?.checkout?.address?.name,
      company: checkoutData.address.company || appliedOfferResult?.checkout?.address?.company,
      line2: checkoutData.address.line2 || appliedOfferResult?.checkout?.address?.line2,
    };
    const mergedInfo = _.merge(checkoutData, appliedOfferResult.checkout);

    const result = await postSubmit({
      cartId: appliedOfferResult?.checkout?.id,
      address,
      id: appliedOfferResult?.checkout?.id,
      payment: {
        ...mergedInfo?.payment,
        context: {
          cardType: mergedInfo?.payment?.context.cardType,
          cvv: appliedOfferResult?.checkout?.payment?.context?.cvv || '411',
          onBehalfOf: mergedInfo?.payment?.context.onBehalfOf,
        },
      },
      mergedInfo,
    });
    if (result?.checkout) {
      setApiLoading(false);
      updateOfferedItem(undefined);
    }
  } catch (error) {
    setApiLoading(false);
    updateOfferedItem(undefined);
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const handleAddOfferedItem = async params => {
  const { setApiLoading, offeredItem, checkoutData, dispatch, dataContext, updateOfferedItem } = params;
  const { merchantId } = dataContext;

  try {
    setApiLoading(true);
    const result = await dispatch(
      postSpecialOffer({
        checkoutId: checkoutData.id,
        merchantId,
        itemId: offeredItem.id,
      }),
    ).unwrap();
    if (result?.ppo) {
      submitOrderWithOffer({
        appliedOfferResult: { ...result?.ppo },
        updateOfferedItem,
        checkoutData,
        dispatch,
        dataContext,
        setApiLoading,
      });
    }
  } catch (error) {
    setApiLoading(false);
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
