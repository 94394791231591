import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectCheckout, selectAdditionalData } from '../store/slice';
import { useProduct } from './useProduct';
import { selectBilling } from '../modules/billing/slice';
import DataContext from '../store/dataContext';

const useAddress = () => {
  const checkoutData = useSelector(selectCheckout);
  const billingSelector = useSelector(selectBilling);
  const { billingPhone, contextBillingAddress, sameBilling } = useContext(DataContext);
  const additionalData = useSelector(selectAdditionalData);
  const { allDigital } = useProduct();

  const getBilling = () => {
    const name =
      billingSelector.address?.name ||
      contextBillingAddress?.name ||
      checkoutData?.billing?.name ||
      checkoutData?.address?.name ||
      additionalData.shipping.name;
    const phone = billingPhone || checkoutData?.billing?.phone || '';
    const company =
      billingSelector.address?.company ||
      contextBillingAddress?.company ||
      checkoutData?.billing?.company ||
      checkoutData?.address?.company ||
      additionalData?.shipping?.company;

    if (!sameBilling || allDigital()) {
      if (billingSelector.address.line1) {
        return {
          address: {
            name,
            company,
            line1: billingSelector.address.line1,
            line2: billingSelector.address.line2,
            postal: billingSelector.address.postal,
            region: billingSelector.address.region,
            regionCode: billingSelector.address.regionCode,
            phone,
            singleLine: billingSelector.address.singleLine,
            city: billingSelector.address.city,
            country: billingSelector.address.country,
          },
          phone,
        };
      }
      if (checkoutData?.billing?.line1) {
        return {
          address: {
            ...checkoutData.billing,
            name,
          },
          phone,
        };
      }
    }
    return {
      address: {
        ...checkoutData.address,
        name,
      },
      phone,
    };
  };

  return { getBilling };
};

export default useAddress;
