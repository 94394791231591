import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withLDConsumer as featureFlagClient } from 'launchdarkly-react-client-sdk';

import { datadogRum } from '@datadog/browser-rum';
import { useSkeleton } from '../../../../hooks/useSkeleton';
import { retrieveData, saveData } from '../../../../commons/utils/local-storage-manager';
import { platformCartIdValue } from '../../../../helpers/prelaunchData';
import FullName from '../../../../commons/containers/full-name';
import GoogleAddressAutocomplete from '../../../../commons/containers/google-address-autocomplete';
import AddressAutocomplete from '../../../../commons/containers/address-autocomplete';
import { convertToGoogle } from '../../../../commons/containers/google-address-autocomplete/utils';
import ManualAddress from '../../../../commons/containers/manual-address/container';
import { initialErrorState } from '../../../../commons/utils/initial-error-state';
import { EVENT, on, off } from '../../../../commons/utils/custom-event';
import { autofillSource } from '../../../../commons/constants'; // deliveryMethod as deliveryMethodConst
import useCartTotals from '../../../../store/cartTotals/useCartTotals';
import { formatAsAutocompleteSuggestion } from '../../../../commons/containers/address-autocomplete/utils';
import useAutocompleteApi from '../../../../commons/containers/address-autocomplete/use-autocomplete-api';
import { refreshCertificates } from '../../../../components/Coupon/CouponServices';
import { analyticsStage1 } from '../../../../helpers/constants';
import {
  selectCart,
  selectCheckout,
  selectData,
  setFullName,
  setAddressDownshift,
  setAddressGoogle,
  selectAddressGoogle,
  selectAddressDownshift,
  selectApiStatus,
  resetShipping,
  setStatus,
  setAddressManual,
  selectError,
  setError as setNameError,
  removeAddress,
  setStatusEvent,
  selectAdditionalData,
  setShippingCompany,
  setShippingLine2,
} from '../../../../store/slice';
import { useEndpoint } from '../../../../hooks/useEndpoint';

import { convertAddressToBackend } from '../../utils';
import { resetKeys, selectShipping, setShippingMethodKey } from '../../slice';

import AdditionalData from '../additional-data';
import ShippingMethods from '../shipping-methods';
import NavTabs from '../nav-tabs';
import PickUpInStore from '../pickup-in-store';

import useAddressApi from './use-address-api';

const Shipping = ({
  cartId,
  flags,
  giftCertificates,
  isAdditionalServiceLoading,
  isFieldsetDisabled,
  setApiShippingAddressResponse,
  setPaymentMethodData,
  shopperCurrency,
  theme,
  updateContextShippingAddress,
  updateGiftInfo,
  updateShippingMethodId,
  isLoadingCoupons,
  isLoadingSignature,
  isLoadingInsurance,
  isLoadingRoute,
  updateCheckoutState,
  TO_SHIPPING_ADDRESS_ADD,
  isLoggedIn,
  setShowLoadingPage,
}) => {
  const sliceKey = 'shipping';
  const data = useSelector(selectData);
  const cartData = useSelector(selectCart);
  const checkoutData = useSelector(selectCheckout);
  const addressGoogle = useSelector(selectAddressGoogle);
  const addressDownshift = useSelector(selectAddressDownshift);
  const status = useSelector(selectApiStatus);
  const errorInfo = useSelector(selectError);
  const shippingState = useSelector(selectShipping);
  const additionalData = useSelector(selectAdditionalData);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { isAddressPayloadValid, getWithShippingAddressPayload, getWithShippingMethodPayload } = useAddressApi();
  const {
    setLoading: setCartTotalsLoading,
    setData: setCartTotalsData,
    setError: setCartTotalsError,
  } = useCartTotals();

  const [previousFullName, setPreviousFullName] = useState(undefined);
  const [autofillFullName, setAutofillFullName] = useState(undefined);
  const [autofillAddress, setAutofillAddress] = useState(undefined);
  const [allowEnterManually, setAllowEnterManually] = useState(false);
  const [addressAsTyped, setAddressAsTyped] = useState('');
  const [resetTimestamp, setResetTimestamp] = useState(0);
  const [validationTimestamp, setValidationTimestamp] = useState(0);
  const [acFocusTimestamp, setAcFocusTimestamp] = useState(0);
  const [previousAdditionalData, setPreviousAdditionalData] = useState(undefined);
  const [initialManualData, setInitialManualData] = useState(undefined);
  const [hasSuggestions, setHasSuggestions] = useState(false);
  const [closeTimestamp, setCloseTimestamp] = useState(0);
  const [openTimestamp, setOpenTimestamp] = useState(0);
  const [disableTab, setDisableTab] = useState(false);
  const [disableUserToChangePaymentMethod, setDisableUserToChangePaymentMethod] = useState(false);

  const { patchEnrich, postAnalyticsStage1 } = useEndpoint();
  const { setShowLoading } = useSkeleton();

  const { handleGoogleSelection } = useAutocompleteApi('', () => {});

  const addressSuggested = [];

  if (cartData?.suggestions?.length > 0) {
    addressSuggested.concat(cartData?.suggestions);
  }

  if (cartData?.customer?.addresses?.length > 0) {
    addressSuggested.concat(cartData?.customer?.addresses);
  }

  if (checkoutData?.address?.length > 0) {
    addressSuggested.concat(checkoutData?.address);
  }

  const handleApiResponse = result => {
    setCartTotalsLoading();
    setCartTotalsData(result.cartTotals);
    setPaymentMethodData(result.payment);
  };

  const saveFieldToLs = (fieldKey, fieldValue) => {
    // the local storage key is the platformCartIdValue
    // it must match the key used inside the slice
    // platformCartIdValue is different than cartId received as a prop
    const retrieved = retrieveData(sliceKey, platformCartIdValue, {});
    const updated = { ...retrieved };
    updated[fieldKey] = fieldValue;
    saveData(sliceKey, updated, platformCartIdValue);
  };

  const adUpdate = value => {
    dispatch(setShippingCompany(value.company));
    dispatch(setShippingLine2(value.line2));
    saveFieldToLs('additionalData', value);
  };

  const isPayloadComplete = payload =>
    [
      payload.address.city,
      payload.address.country,
      payload.address.line1,
      payload.address.postal,
      payload.address.regionCode,
    ]
      ?.map(item => item?.trim())
      ?.every(Boolean);

  const convertToManualData = payload => ({
    apartmentUnit: payload.address.line2?.trim(),
    city: payload.address.city?.trim(),
    companyName: payload.address.company?.trim(),
    country: payload.address.country?.trim(),
    street: payload.address.line1?.trim() || addressAsTyped?.split(',')[0],
    postalCode: payload.address.postal?.trim(),
    state: payload.address.regionCode?.trim() || payload.address.region?.trim(),
  });

  const executeWithShippingAddress = async payload => {
    try {
      dispatch(setStatus({ isLoadingWithShippingAddress: true }));
      const result = await patchEnrich({
        address: payload.address,
        shippingMethodKey: shippingState?.shippingMethodKey,
        removePickUpMethod: true,
      });
      postAnalyticsStage1({ step: analyticsStage1.ADDRESS });
      setApiShippingAddressResponse(result);
      return result;
    } catch (error) {
      if (error.message === 'nf_error_race_condition') {
        throw error;
      }
      setCartTotalsError(error.stack);
      return null;
    } finally {
      dispatch(setStatus({ isLoadingWithShippingAddress: false }));
    }
  };

  const executeWithShippingMethod = async payload => {
    try {
      dispatch(setStatus({ isLoadingWithShippingMethod: true }));
      const result = await patchEnrich({
        shippingMethodKey: payload,
        removePickUpMethod: true,
      });
      setApiShippingAddressResponse(result);
      const refreshedCertificates = await refreshCertificates(giftCertificates);
      const availableGifts =
        result.cart.giftCertificates.length > 0 ? result.cart.giftCertificates : refreshedCertificates;
      updateGiftInfo(availableGifts);
    } catch (error) {
      if (error.message === 'nf_error_race_condition') {
        throw error;
      }
      if (error.message !== 'Aborted') {
        setCartTotalsError(error.stack);
      }
    } finally {
      dispatch(setStatus({ isLoadingWithShippingMethod: false }));
    }
  };

  const smUpdateMethods = selectedKey => {
    dispatch(resetKeys());
    dispatch(setShippingMethodKey(selectedKey));
    updateShippingMethodId(selectedKey);
    saveFieldToLs('selectedMethod', selectedKey);
  };

  // #region full name (fn)
  const fnBlur = async (currentFullName, fnError) => {
    if (currentFullName !== previousFullName) {
      dispatch(setFullName(currentFullName));
    }
    if (!fnError.hasError && currentFullName && currentFullName !== previousFullName) {
      try {
        let address = checkoutData?.address;
        if (!address?.line1) {
          if (data.addressDownshift?.addressDownshift?.value?.legacy?.line1) {
            address = data.addressDownshift?.addressDownshift?.value?.legacy;
          } else if (data.addressGoogle?.value?.value) {
            address = await handleGoogleSelection(data.addressGoogle?.value?.value, true);
          }
        }
        if (address?.line1) {
          setCartTotalsLoading();
          const result = await executeWithShippingAddress(
            getWithShippingAddressPayload(
              checkoutData?.address,
              checkoutData?.currentFullName,
              checkoutData?.id,
              additionalData?.shipping?.company,
              additionalData?.shipping?.line2,
            ),
          );
          setApiShippingAddressResponse(result);
          handleApiResponse(result);
        }
      } catch (error) {
        if (error.message === 'nf_error_race_condition') {
          // error
        }
      }
    } else if (fnError.hasError) {
      dispatch(
        setStatus({
          isReady: false,
        }),
      );
    }
  };

  const fnFocus = e => {
    setPreviousFullName(e.target.value);
  };

  const fnSetFullName = fnValue => {
    if (fnValue !== previousFullName) {
      dispatch(setFullName(fnValue));
      saveFieldToLs('fullName', fnValue);
      datadogRum.addAction('entered-valid-name');
    }
  };

  const fnAfterValidationCb = (fnValue, fnError) => {
    if (fnError.hasError) {
      dispatch(setFullName(fnValue));
      setNameError({
        component: 'name',
        data: fnError?.message,
      });
      saveFieldToLs('fullName', '');
    } else {
      fnSetFullName(fnValue, fnError);
    }
  };

  const fnChange = value => {
    saveFieldToLs('fullName', value);
  };
  // #endregion

  // #region shipping methods (sm)
  const smSelectCb = async selectedKey => {
    try {
      setCartTotalsLoading();
      smUpdateMethods(selectedKey);
      await executeWithShippingMethod(selectedKey);
      setDisableUserToChangePaymentMethod(false);
    } catch (err) {
      setDisableUserToChangePaymentMethod(false);
      setCartTotalsError(err.stack);
    }
  };

  const handleTabChange = async tabId => {
    if (tabId === 'shipping') {
      await smSelectCb(cartData?.shippingMethods[0]?.lookupKey);
      dispatch(setStatusEvent({ event: 'pickup' }));
      setDisableTab(false);
    }
  };

  // #endregion

  // #region address (ss)
  const ssDoNotOverrideAdditionalData = value => {
    const cloned = {
      ...value,
    };
    for (const adKey in additionalData?.shipping) {
      if (adKey) {
        delete cloned[adKey];
      }
    }
    return {
      ...cloned,
      ...additionalData?.shipping,
    };
  };

  const ssUpdateAddressGoogle = ({ valueGoogle, errorGoogle }) => {
    dispatch(
      setAddressGoogle({
        addressGoogle: {
          error: errorGoogle,
          value: valueGoogle,
        },
      }),
    );
    saveFieldToLs('addressGoogle', valueGoogle);
  };

  const ssUpdateAddressDownshift = ({ error, value }) => {
    dispatch(
      setAddressDownshift({
        addressDownshift: {
          error,
          value,
        },
      }),
    );
    saveFieldToLs('addressDownshift', value);
  };

  const clearAddressRelatedState = () => {
    // abortApiCalls();
    setCartTotalsError('Address cleared, api calls aborted at clearAddressRelatedState');
    dispatch(resetKeys());
    dispatch(setShippingMethodKey(''));
    dispatch(removeAddress());
    dispatch(setShippingCompany(''));
    dispatch(setShippingLine2(''));
  };

  const clearGoogleAddress = () => {
    clearAddressRelatedState();
    ssUpdateAddressGoogle({
      valueGoogle: null,
      errorGoogle: initialErrorState,
    });
    dispatch(setShippingCompany(''));
    dispatch(setShippingLine2(''));
  };

  const clearDownshiftAddress = () => {
    clearAddressRelatedState();
    ssUpdateAddressDownshift({
      error: initialErrorState,
      value: null,
    });
    dispatch(setShippingCompany(''));
    dispatch(setShippingLine2(''));
  };

  const ssHandleSelectCb = async ({ valueGoogle, valueAddress, action }) => {
    setAddressAsTyped('');
    if (action === 'clear') {
      clearGoogleAddress();
    } else if (action === 'select-option' || action === 'nf-autofill') {
      const addressWithAdditionalData = ssDoNotOverrideAdditionalData(valueAddress);
      ssUpdateAddressGoogle({ valueGoogle, errorGoogle: initialErrorState });
      const valueAddressForContext = convertAddressToBackend(addressWithAdditionalData, checkoutData?.address?.name);
      updateContextShippingAddress(valueAddressForContext);

      const payload = getWithShippingAddressPayload(
        addressWithAdditionalData,
        checkoutData?.address?.name || checkoutData?.customer?.name,
        checkoutData?.id,
        additionalData?.shipping?.company,
        additionalData?.shipping?.line2,
        action,
      );

      const isComplete = isPayloadComplete(payload);
      const convertedPayload = convertToManualData(payload);

      setInitialManualData(convertedPayload);

      if (!isComplete) {
        setAllowEnterManually(true);
      } else {
        setCartTotalsLoading();
        if (action !== 'nf-autofill') {
          const result = await executeWithShippingAddress(payload);
          if (result) {
            smUpdateMethods(result.checkout.shippingMethodKey);
            handleApiResponse(result);
          }
        }
      }
    }
  };

  const handleIsComplete = async (payload, action) => {
    const isComplete = isPayloadComplete(payload);
    const convertedPayload = convertToManualData(payload);

    setInitialManualData(convertedPayload);
    if (!isComplete) {
      setAllowEnterManually(true);
    } else {
      setCartTotalsLoading();
      if (action !== 'nf-autofill') {
        const result = await executeWithShippingAddress(payload);
        if (result) {
          smUpdateMethods(result.checkout.shippingMethodKey);
          handleApiResponse(result);
        }
      }
    }
  };

  const ssHandleSelectDownshiftCb = async selection => {
    ssUpdateAddressDownshift({ error: initialErrorState, value: selection });
    const valueAddressForContext = convertAddressToBackend(selection.legacy, checkoutData?.address?.name);
    updateContextShippingAddress(valueAddressForContext);

    // ssUpdateAddress(selection.legacy);
    const payload = getWithShippingAddressPayload(
      selection.legacy,
      checkoutData?.address?.name,
      cartId,
      additionalData?.shipping?.company,
      additionalData?.shipping?.line2,
    );
    handleIsComplete(payload, 'downshift');
    datadogRum.addAction('entered-valid-address', { source: 'downshift' });
  };

  const sharedValidationSteps = validationResult => {
    if (validationResult.hasError) {
      ssUpdateAddressGoogle({
        valueGoogle: null,
        errorGoogle: validationResult,
      });
      // TODO: cover with test, we do not want to reset to empty when there is an error
      // setAddressAsTyped('');
      dispatch(setStatus({ isReady: false }));
    }
  };

  const ssAfterValidationGoogleCb = validationResult => {
    sharedValidationSteps(validationResult);
    if (validationResult.hasError) {
      ssUpdateAddressGoogle({
        valueGoogle: null,
        errorGoogle: validationResult,
      });
    }
  };

  const ssAfterValidationDownshiftCb = validationResult => {
    sharedValidationSteps(validationResult);
    if (validationResult.hasError) {
      ssUpdateAddressDownshift({
        error: validationResult,
        value: null,
      });
    }
  };

  useEffect(() => {
    const restoreAddress = async () => {
      if (isLoggedIn) {
        updateCheckoutState(TO_SHIPPING_ADDRESS_ADD);
      }
    };
    restoreAddress();
  }, [checkoutData]);
  // #endregion

  const handleReturningUser = () => {
    if (checkoutData?.customer?.email) {
      setHasSuggestions(true);
      // handle full name
      const shippingName = checkoutData?.address?.name;
      if (shippingName) {
        setAutofillFullName(shippingName);
        setPreviousFullName(shippingName);
        fnSetFullName(shippingName);
      }
      // handle address
      const shippingAddress = checkoutData?.address;
      const addressLabel = shippingAddress?.singleLine;
      if (addressLabel) {
        setAutofillAddress({
          source: autofillSource.EMAIL_RESPONSE,
          addressGoogle: convertToGoogle(addressLabel),
          addressDownshift: formatAsAutocompleteSuggestion(shippingAddress, autofillSource.EMAIL_RESPONSE),
          addressSuggestions: addressSuggested,
          addressManual: null,
        });
        // handle additional data
        const updatedAdditionalData = {
          line2: shippingAddress?.apartment || shippingAddress?.line2 || '',
          company: checkoutData?.address?.company || '',
        };
        if (updatedAdditionalData.line2 || updatedAdditionalData.company) {
          adUpdate(updatedAdditionalData);
        }
      }
    }
  };

  // #region autofill (ll)
  const llParseAutofill = response => {
    if (checkoutData?.address?.hash) {
      handleReturningUser();
    } else {
      let suggestions = {};
      if (response?.suggestions) {
        suggestions = response?.suggestions;
      } else {
        suggestions = cartData?.suggestions;
      }
      const emailHasSuggestions = suggestions && Object.values(suggestions).length > 0;
      if (emailHasSuggestions) {
        setHasSuggestions(true);
        // handle full name
        const shippingName = checkoutData?.address?.name || suggestions?.[0]?.address?.name;
        if (shippingName) {
          setAutofillFullName(shippingName);
          setPreviousFullName(shippingName);
          fnSetFullName(shippingName, initialErrorState);
        }
        // handle address
        const shippingAddress = suggestions?.addresses?.find(
          item => item.hash === suggestions?.autofill?.shipAddressHash,
        );
        const addressLabel = shippingAddress?.singleLine;
        if (addressLabel) {
          setAutofillAddress({
            source: autofillSource.EMAIL_RESPONSE,
            addressGoogle: convertToGoogle(addressLabel),
            addressSuggestions: suggestions.addresses,
            addressManual: null,
          });
          // handle additional data
          const updatedAdditionalData = {
            line2: checkoutData?.address?.apartment || checkoutData?.address?.line2 || shippingAddress?.line2,
            company: checkoutData?.address?.company || suggestions?.autofill?.shipCompany,
          };
          adUpdate(updatedAdditionalData);
        }
      } else if (!isAddressPayloadValid(checkoutData?.address)) {
        dispatch(
          setStatus({
            isLoadingWithShippingAddress: false,
          }),
        );
      }
      if (status?.event === 'createCart') {
        dispatch(setStatusEvent({ event: 'autofill' }));
        setShowLoading(false);
      }
    }
  };

  useEffect(() => {
    const waitForApiEmailResponse = () => {
      if (status?.event === 'suggestion-login') {
        handleReturningUser();
        dispatch(setStatusEvent({ event: 'autofilled' }));
      }
      if (
        checkoutData?.status === 'fulfilled' &&
        checkoutData?.customer?.email &&
        ((status?.event === 'login' && cartData?.customer?.addresses?.length > 0) ||
          status?.event === 'emailChange' ||
          status?.event === 'suggestion' ||
          status?.event === 'createCart' ||
          status?.event === 'pickup' ||
          status?.event === 'phoneChange')
      ) {
        llParseAutofill(checkoutData);
        dispatch(setStatusEvent({ event: 'autofilled' }));
      }
    };
    waitForApiEmailResponse();
  }, [checkoutData, status]);
  // #endregion

  // #region additional data (ad)
  const adChange = value => {
    adUpdate(value);
  };

  const adBlur = async e => {
    const fieldId = e.target.id;
    const previousValue = previousAdditionalData?.[fieldId];
    const currentValue = additionalData?.shipping?.[fieldId];
    if (previousValue !== currentValue && !!checkoutData.address.hash) {
      switch (fieldId) {
        case 'line2': {
          try {
            setCartTotalsLoading();
            // const addressWithAdditionalData = ssDoNotOverrideAdditionalData(address);
            const withShippingAddressPayload = getWithShippingAddressPayload(
              checkoutData?.address,
              checkoutData?.address?.name,
              checkoutData?.id,
              additionalData?.shipping?.company,
              currentValue,
            );
            const withShippingAddressResponse = await executeWithShippingAddress(withShippingAddressPayload);
            handleApiResponse(withShippingAddressResponse);
          } catch (error) {
            if (error.message === 'nf_error_race_condition') {
              // retryApiCalls(address, fullName.value, additionalData.company);
            }
          }
          break;
        }
        case 'company': {
          try {
            if (checkoutData?.shippingMethodKey) {
              const payload = getWithShippingMethodPayload(
                checkoutData?.address,
                checkoutData?.address?.name,
                checkoutData?.id,
                checkoutData?.shippingMethodKey,
                currentValue,
                checkoutData?.address?.line2,
              );
              // await checkRaceConditionAndAbortWithShippingMethod();
              await executeWithShippingMethod(payload);
            } else {
              throw new Error('nf_error_retry');
            }
          } catch (error) {
            if (error.message === 'nf_error_race_condition') {
              // retryApiCalls(address, fullName.value, additionalData.company);
            } else if (error.message === 'nf_error_retry') {
              // retryApiCalls(address, fullName.value, currentValue);
            }
          }
          break;
        }
        default:
          // eslint-disable-next-line no-useless-return
          return;
      }
    }
  };

  const adFocus = initialData => {
    setPreviousAdditionalData(initialData);
  };
  // #endregion

  // #region manual address (ma)
  const maCancelCb = () => {
    setAddressAsTyped('');
    setAllowEnterManually(false);
  };

  const maSaveData = value => {
    dispatch(setAddressManual(value));
  };

  const maTriggerAddressAutofill = value => {
    const label = value.singleLine;
    setAutofillAddress({
      source: autofillSource.MANUAL_ADDRESS,
      addressGoogle: convertToGoogle(label),
      addressDownshift: formatAsAutocompleteSuggestion(value, autofillSource.MANUAL_ADDRESS),
      addressSuggestions: [],
      addressManual: value,
    });
  };

  const maSubmitCb = async value => {
    maSaveData(value);
    maTriggerAddressAutofill(value);
    setAllowEnterManually(false);
    datadogRum.addAction('entered-valid-address', { source: 'manual' });
    const adAddress = {
      line2: value.apartmentUnit,
      company: value.companyName,
    };
    if (adAddress.line2 || adAddress.company) {
      adUpdate(adAddress);
      setOpenTimestamp(new Date());
      ssUpdateAddressDownshift({
        error: null,
        value: adAddress,
      });
    }
    await executeWithShippingAddress({
      address: {
        ...adAddress,
        name: checkoutData?.address?.name,
        city: value.city,
        country: value.country,
        line1: value.street,
        postal: value.postalCode,
        regionCode: value.state,
        region: value.regionName,
        singleLine: value.singleLine,
      },
    });
  };
  // #endregion

  // #region reset checkout
  const resetShippingData = () => {
    dispatch(resetShipping());
    saveData(sliceKey, {}, platformCartIdValue);
    setPreviousFullName(undefined);
    setAutofillFullName(undefined);
    setAutofillAddress(undefined);
    setAllowEnterManually(false);
    setAddressAsTyped('');
    setResetTimestamp(new Date().getTime());
    setValidationTimestamp(0);
    setAcFocusTimestamp(0);
    setPreviousAdditionalData(undefined);
    setInitialManualData(undefined);
    setHasSuggestions(false);
    setCloseTimestamp(0);
    setOpenTimestamp(0);
  };

  const renderAutocomplete = () => {
    if (flags['disable-downshift-autocomplete']) {
      return (
        <GoogleAddressAutocomplete
          additionalData={additionalData?.shipping}
          addressAsTyped={addressAsTyped}
          afterValidationCb={ssAfterValidationGoogleCb}
          autofillAddress={autofillAddress}
          disabled={isFieldsetDisabled}
          hasSuggestions={hasSuggestions}
          inputError={initialErrorState}
          inputId={`${sliceKey}-address`}
          inputValue={addressGoogle.value}
          onSelectCb={ssHandleSelectCb}
          placeholder={`* ${t('google_address_autocomplete.shipping')}`}
          placeholderTop={t('google_address_autocomplete.shipping')}
          setAddressAsTyped={setAddressAsTyped}
          setAllowEnterManually={setAllowEnterManually}
          setShouldOpenAdditionalData={setOpenTimestamp}
          theme={theme}
          triggerFocus={acFocusTimestamp}
          triggerReset={resetTimestamp}
          triggerValidation={validationTimestamp}
          setShowLoadingPage={setShowLoadingPage}
        />
      );
    }

    return (
      <AddressAutocomplete
        additionalData={additionalData?.shipping}
        afterValidationCb={ssAfterValidationDownshiftCb}
        autofillAddress={autofillAddress}
        inputId={`${sliceKey}-address`}
        inputValue={addressDownshift.value}
        isDisabled={isFieldsetDisabled}
        onClearCb={clearDownshiftAddress}
        onSelectCb={ssHandleSelectDownshiftCb}
        placeholder={`* ${t('google_address_autocomplete.shipping')}`}
        placeholderTop={t('google_address_autocomplete.shipping')}
        setAddressAsTyped={setAddressAsTyped}
        setAllowEnterManually={setAllowEnterManually}
        setShouldOpenAdditionalData={setOpenTimestamp}
        theme={theme}
        triggerFocus={acFocusTimestamp}
        triggerReset={resetTimestamp}
        triggerValidation={validationTimestamp}
        updateAdditionalData={adUpdate}
        setShowLoadingPage={setShowLoadingPage}
      />
    );
  };

  useEffect(() => {
    const subscribeToResetCheckout = () => {
      on(EVENT.RESET_CHECKOUT, () => {
        resetShippingData();
      });

      return () => {
        off(EVENT.RESET_CHECKOUT);
      };
    };
    subscribeToResetCheckout();
  }, []);
  // #endregion

  // #region validate checkout
  useEffect(() => {
    const subscribeToSubmitCheckout = () => {
      on(EVENT.SUBMIT_CHECKOUT, () => {
        setValidationTimestamp(new Date().getTime());
      });

      return () => {
        off(EVENT.SUBMIT_CHECKOUT);
      };
    };
    subscribeToSubmitCheckout();
  }, []);
  // #endregion

  const tabData = {
    shipping: {
      content: (
        <>
          {renderAutocomplete()}
          <ManualAddress
            checkoutData={checkoutData}
            additionalData={additionalData?.shipping}
            addressAsTyped={addressAsTyped}
            addressLabel={t('google_address_autocomplete.shipping')}
            allowEnterManually={allowEnterManually}
            handleCancelCb={maCancelCb}
            handleSubmitCb={maSubmitCb}
            initialManualData={initialManualData}
            textFillYourAddress={t('manual_address.please_fill_in_your_shipping_address_manually')}
            theme={theme}
            triggerReset={resetTimestamp}
          />
          <AdditionalData
            disabled={isFieldsetDisabled}
            inputValue={additionalData?.shipping}
            onBlurCb={adBlur}
            onChangeCb={adChange}
            onFocusCb={adFocus}
            theme={theme}
            triggerCloseEffect={closeTimestamp}
            triggerOpenEffect={openTimestamp}
            triggerResetEffect={resetTimestamp}
          />
          <ShippingMethods
            disabled={isFieldsetDisabled}
            isAdditionalServiceLoading={isAdditionalServiceLoading}
            isLoading={status.isLoadingWithShippingAddress}
            list={cartData?.shippingMethods}
            onSelectCb={smSelectCb}
            selectedKey={shippingState.shippingMethodKey || checkoutData.shippingMethodKey}
            setAcFocusTimestamp={setAcFocusTimestamp}
            shopperCurrency={shopperCurrency}
            theme={theme}
            isDisabled={
              disableUserToChangePaymentMethod ||
              isLoadingCoupons ||
              isLoadingSignature ||
              isLoadingRoute ||
              isLoadingInsurance
            }
            setIsDisabled={setDisableUserToChangePaymentMethod}
          />
        </>
      ),
    },
    pickup: {
      content: (
        <PickUpInStore
          setDisableTab={setDisableTab}
          theme={theme}
          saveToLocalStorage={saveFieldToLs}
          triggerValidation={validationTimestamp}
        />
      ),
    },
  };

  if (shippingState.bopis.isEnabled) {
    return (
      <>
        <FullName
          autoComplete={`${sliceKey} name`}
          autofillFullName={autofillFullName}
          afterValidationCb={fnAfterValidationCb}
          disabled={isFieldsetDisabled}
          inputError={errorInfo}
          inputId="shipping-name"
          inputValue={checkoutData?.address?.name || ''}
          onBlurCb={fnBlur}
          onChangeCb={fnChange}
          onFocusCb={fnFocus}
          theme={theme}
          triggerReset={resetTimestamp}
          triggerValidation={validationTimestamp}
        />
        <NavTabs
          tabData={tabData}
          setDisableTab={setDisableTab}
          isFieldsetDisabled={isFieldsetDisabled || disableTab}
          saveToLocalStorage={saveFieldToLs}
          onTabChange={handleTabChange}
        />
      </>
    );
  }

  return (
    <>
      <FullName
        autoComplete={`${sliceKey} name`}
        autofillFullName={autofillFullName}
        afterValidationCb={fnAfterValidationCb}
        disabled={isFieldsetDisabled}
        inputError={errorInfo}
        inputId="shipping-name"
        inputValue={checkoutData?.address?.name || ''}
        onBlurCb={fnBlur}
        onChangeCb={fnChange}
        onFocusCb={fnFocus}
        theme={theme}
        triggerReset={resetTimestamp}
        triggerValidation={validationTimestamp}
      />
      {renderAutocomplete()}
      <ManualAddress
        checkoutData={checkoutData}
        additionalData={additionalData?.shipping}
        addressAsTyped={addressAsTyped}
        addressLabel={t('google_address_autocomplete.shipping')}
        allowEnterManually={allowEnterManually}
        handleCancelCb={maCancelCb}
        handleSubmitCb={maSubmitCb}
        initialManualData={initialManualData || {}}
        textFillYourAddress={t('manual_address.please_fill_in_your_shipping_address_manually')}
        theme={theme}
        triggerReset={resetTimestamp}
      />
      <AdditionalData
        disabled={isFieldsetDisabled}
        inputValue={additionalData?.shipping}
        onBlurCb={adBlur}
        onChangeCb={adChange}
        onFocusCb={adFocus}
        theme={theme}
        triggerCloseEffect={closeTimestamp}
        triggerOpenEffect={openTimestamp}
        triggerResetEffect={resetTimestamp}
      />
      <ShippingMethods
        disabled={isFieldsetDisabled}
        isAdditionalServiceLoading={isAdditionalServiceLoading}
        isLoading={status.isLoadingWithShippingAddress}
        list={cartData?.shippingMethods}
        onSelectCb={smSelectCb}
        selectedKey={checkoutData.shippingMethodKey}
        setAcFocusTimestamp={setAcFocusTimestamp}
        shopperCurrency={shopperCurrency}
        theme={theme}
        isDisabled={
          disableUserToChangePaymentMethod ||
          isLoadingCoupons ||
          isLoadingSignature ||
          isLoadingRoute ||
          isLoadingInsurance
        }
        setIsDisabled={setDisableUserToChangePaymentMethod}
      />
    </>
  );
};

Shipping.propTypes = {
  cartId: PropTypes.string.isRequired,
  flags: PropTypes.shape({
    'disable-downshift-autocomplete': PropTypes.bool,
  }),
  giftCertificates: PropTypes.oneOfType([PropTypes.array]),
  isAdditionalServiceLoading: PropTypes.bool,
  isFieldsetDisabled: PropTypes.bool.isRequired,
  setApiShippingAddressResponse: PropTypes.func.isRequired,
  setPaymentMethodData: PropTypes.func.isRequired,
  shopperCurrency: PropTypes.oneOfType([PropTypes.object]).isRequired,
  theme: PropTypes.oneOfType([PropTypes.object]),
  updateContextShippingAddress: PropTypes.func.isRequired,
  updateGiftInfo: PropTypes.func.isRequired,
  updateShippingMethodId: PropTypes.func.isRequired,
  isLoadingCoupons: PropTypes.bool,
  isLoadingSignature: PropTypes.bool,
  isLoadingInsurance: PropTypes.bool,
  isLoadingRoute: PropTypes.bool,
  updateCheckoutState: PropTypes.func,
  setShowLoadingPage: PropTypes.func,
  TO_SHIPPING_ADDRESS_ADD: PropTypes.string,
  isLoggedIn: PropTypes.bool,
};

export default featureFlagClient()(Shipping);
