import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { RegionDropdown } from 'react-country-region-selector';

const StateList = ({ country, onChange, value, error, placeholder, placeholderTop, theme }) => (
  <>
    <div className="nf-input-wrapper">
      <RegionDropdown
        aria-label={placeholderTop}
        autoComplete="address-level1"
        name="state"
        countryValueType="short"
        country={country}
        data-testid="state"
        id="state"
        onChange={onChange}
        value={value}
        valueType="short"
        className={classNames({
          'has-error': error,
          'is-value': value,
        })}
        defaultOptionLabel=""
        style={{ borderColor: theme?.colour?.outline }}
      />
      <label htmlFor="state">
        <span className="placeholder" data-testid="placeholder">
          {placeholder}
        </span>
        <span className="placeholder-top" data-testid="placeholder-top" style={{ color: theme?.colour?.main }}>
          {placeholderTop}
        </span>
      </label>
      {error && <div className="error">{error}</div>}
    </div>
  </>
);

StateList.propTypes = {
  country: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderTop: PropTypes.string,
  theme: PropTypes.oneOfType([PropTypes.object]),
};

export default StateList;
