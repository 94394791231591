import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setPaymentMethod } from '../../modules/payment/slice';
import { useBroadcastChannel } from '../useBroadcastChannel';
import { paymentIdList } from '../../helpers/constants';
import DataContext from '../../store/dataContext';

export const usePaypal = () => {
  const { t } = useTranslation();
  const { postMessage } = useBroadcastChannel();
  const dispatch = useDispatch();

  const { paymentMethodError, setPaymentMethodError, broadcastErrorMessage } = useContext(DataContext);

  useEffect(() => {
    if (broadcastErrorMessage?.paypal) {
      dispatch(setPaymentMethod(paymentIdList.PAYPAL));
      setPaymentMethodError({ ...paymentMethodError, paypal: broadcastErrorMessage?.paypal });
    }
  }, [broadcastErrorMessage]);

  const isPaypal = () => window.location.href.indexOf(paymentIdList.PAYPAL) > -1;

  const handlePaypalUrl = () => {
    if (window.location.href.indexOf(paymentIdList.PAYPAL) > -1) {
      const queryString = window.location.href;
      const urlParams = new URLSearchParams(queryString);
      const paypal = urlParams.get('paypal');
      if (paypal) {
        if (paypal === 'cancel') {
          postMessage({
            error: {
              paypal: t('payment_method.paypal.error'),
            },
          });
          window.close();
        } else if (paypal === 'success') {
          const paymentId = urlParams.get('token');
          const payerId = urlParams.get('PayerID');
          postMessage({ paymentId, payerId });
          window.close();
        }
      }
    }
  };

  return { handlePaypalUrl, isPaypal };
};
