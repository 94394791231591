import { CROSS_MESSAGE, crossRequest } from '../commons/utils/cross-origin-communication';
import { platformList } from '../commons/constants';

import { defaultShopperCurrency } from './constants';

const location = new URL(document.location);
const params = location.searchParams;

export const platformCartIdValue = params.get('cartId');
export const merchantIdValue = params.get('merchantIdV2');
export const storeUrlValue = params.get('storeUrl');
export const platformValue = params.get('platform');
export const accessTokenValue = params.get('accessToken');
export const customerIsLoggedInValue = params.get('customerIsLoggedIn');
export const paymentName = params.get('paymentName');
export const invoiceId = params.get('invoiceId');
export const apiMethod = params.get('apiMethod');
export const forceNf = params.get('force_nf_checkout');
export const forcePlat = params.get('force_plat_checkout');
export const storeLanguage = params.get('lang');
export const shouldLoadPaymentButtons = params.get('slpb');
export const customerCodeUserId = params.get('customerCodeUserId');

export const getBigCommerceCurrency = async setShopperCurrency => {
  try {
    if (platformValue === platformList.bigcommerce) {
      await crossRequest(
        CROSS_MESSAGE.FETCH_FROM_WINDOW,
        {
          url: '/api/storefront/checkout-settings',
          options: {
            headers: {
              'x-api-internal': 'This API endpoint is for internal use only and may change in the future',
            },
          },
        },
        5000,
      ).then(result => {
        const shopperCurrency = JSON.parse(result.text).storeConfig.shopperCurrency || {};
        setShopperCurrency(shopperCurrency);
      });
    }
  } catch (err) {
    setShopperCurrency(defaultShopperCurrency);
  }
};

export const getGiftInfo = async () => {
  try {
    let checkoutResponse = [];
    if (platformValue === platformList.bigcommerce) {
      checkoutResponse = await crossRequest(
        CROSS_MESSAGE.FETCH_FROM_WINDOW,
        {
          url: `/api/storefront/checkouts/${platformCartIdValue}`,
          options: {
            headers: {
              'x-api-internal': 'This API endpoint is for internal use only and may change in the future',
            },
          },
        },
        5000,
      ).then(result => JSON.parse(result.text)?.giftCertificates);
    }
    return checkoutResponse;
  } catch (err) {
    return [];
  }
};
