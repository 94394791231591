import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEndpoint } from '../../hooks/useEndpoint';
import { selectCart } from '../../store/slice';
import useCartTotals from '../../store/cartTotals/useCartTotals';
import { amountFormatter } from '../../helpers/format';

import DataContext from '../../store/dataContext';
import useCurrency from '../../modules/currency/hooks/use-currency';

import './style.scss';

const StoreCredit = () => {
  const { t } = useTranslation();

  const { getShoppercurrency } = useCurrency();
  const { setData: setCartTotalsData, setLoading: setCartTotalsLoading } = useCartTotals();

  const cartData = useSelector(selectCart);
  const { patchEnrich } = useEndpoint();

  const { applyStoreCredit, setApplyStoreCredit, contextShippingAddress } = useContext(DataContext);

  const handleCreditToShow = () => {
    if (cartData?.customer?.storeCredit?.applied >= cartData?.totals?.total && contextShippingAddress?.line1) {
      return amountFormatter({
        shopperCurrency: getShoppercurrency(),
        value: cartData?.totals?.total,
      });
    }
    if (cartData?.customer?.storeCredit?.applied >= cartData?.totals?.total) {
      return amountFormatter({
        shopperCurrency: getShoppercurrency(),
        value: cartData?.totals?.total - cartData?.totals?.shipping,
      });
    }
    if (cartData?.customer?.storeCredit?.applied > cartData?.totals?.subtotal) {
      return amountFormatter({
        shopperCurrency: getShoppercurrency(),
        value: cartData?.totals?.subtotal,
      });
    }
    return amountFormatter({
      shopperCurrency: getShoppercurrency(),
      value: cartData?.customer?.storeCredit?.applied,
    });
  };

  useEffect(() => {
    const handleStoreCredit = async () => {
      setCartTotalsLoading();
      const result = await patchEnrich({
        shouldApplyStoreCredit: applyStoreCredit,
      });
      setCartTotalsData(result.totals);
    };
    if (applyStoreCredit !== null) {
      handleStoreCredit();
    }
  }, [applyStoreCredit]);

  return (
    <div className="store-credit">
      <input
        className="store-checkbox"
        type="checkbox"
        checked={applyStoreCredit}
        onChange={() => setApplyStoreCredit(!applyStoreCredit)}
      />
      <span className="store-span">{t('store_credit.apply')}</span>
      <span className="store-value">
        {t('store_credit.empty')}
        {handleCreditToShow()}
        {t('store_credit.empty')}
      </span>
      <span className="store-span">{t('store_credit.store_credit')}</span>
    </div>
  );
};

export default StoreCredit;
