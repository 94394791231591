import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectCart } from '../../store/slice';
import { status } from '../../helpers/constants';

export const values = {
  code: '',
};

export const validateForm = (formValues, formStatus, apiError) => {
  const { t } = useTranslation();
  const result = {
    isValid: true,
    fields: {},
  };
  if (formStatus === status.SUBMITTED) {
    if (apiError) {
      result.fields.api = [];
      result.fields.api.push(`${apiError.errorMsg}`);
      if (apiError.details) {
        result.fields.api.push(`\n${apiError.details}`);
      }
    }
    if (!formValues.code) result.fields.code = t('coupon_gift_card_is_required');
    if (Object.keys(result.fields).length > 0) {
      result.isValid = false;
    }
  }
  return result;
};

export const isValidAddress = contextShippingAddress =>
  contextShippingAddress &&
  Object.keys(contextShippingAddress).length > 0 &&
  [
    contextShippingAddress.fullName,
    contextShippingAddress.line1,
    contextShippingAddress.city,
    contextShippingAddress.region,
    contextShippingAddress.region,
    contextShippingAddress.postal,
    contextShippingAddress.country,
    contextShippingAddress.singleLine,
  ].every(Boolean);

export const showCodeTitle = () => {
  const { t } = useTranslation();
  const cartData = useSelector(selectCart);
  const portalSettings = cartData?.merchant?.settings;
  if (portalSettings.coupon.isEnabled && portalSettings.giftCard.isEnabled) {
    return portalSettings.coupon.name?.substring(0, 20) || `${t('coupon_title')} / ${t('gift_title')}`;
  }
  if (portalSettings.coupon.isEnabled) {
    return portalSettings.coupon.name?.substring(0, 20) || t('coupon_title');
  }
  if (portalSettings.giftCard.isEnabled) {
    return t('gift_title');
  }
  return `${t('coupon_title')} / ${t('gift_title')}`;
};

export const convertGiftsToBackend = giftCertificates =>
  giftCertificates?.reduce((acc, item) => [...acc, item.code], []);
