import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { selectCheckout, selectHasPluginSMS } from '../../store/slice';
import useApiPostOrderError from '../../hooks/useApiPostOrderError';
import useErrors from '../../store/errors/useErrors';
import { setStatus } from '../../modules/billing/slice';
import usePortalSettings from '../../hooks/usePortalSettings';
import UiContext from '../../store/uiContext';

import Newsletter from '../Newsletter';
import BillingPhoneWithDDI from '../BillingPhoneWithDDI';

const BillingPhone = ({ isBillingAddress }) => {
  const uiContext = useContext(UiContext);
  const { portalSettings } = usePortalSettings();
  const { errors, hasErrorOnField, hasHighlightOnField } = useErrors();
  const { hasPhoneError } = useApiPostOrderError();
  const dispatch = useDispatch();
  const checkoutData = useSelector(selectCheckout);
  const hasPluginSMS = useSelector(selectHasPluginSMS);

  const isDisabled = uiContext.checkoutStage === uiContext.TO_USER_ADD;
  const phoneLabel = portalSettings.contactPhone.isEnabled && !isBillingAddress ? 'Phone number' : 'Billing phone';
  const isPhoneRequired = portalSettings.contactPhone.isRequired || hasPhoneError();

  useEffect(() => {
    const waitForEmailResponse = () => {
      if (checkoutData?.status === 'fulfilled' && checkoutData?.suggestions?.[0]?.phone) {
        dispatch(setStatus({ isReady: true }));
      }
    };
    waitForEmailResponse();
  }, [checkoutData]);

  const getError = () => {
    if (hasErrorOnField('billingPhone')) {
      return errors.billingPhone.message;
    }
    return '';
  };

  return (
    <div className="container-phone">
      <BillingPhoneWithDDI
        classNameInput={classNames({
          'has-error': hasErrorOnField('billingPhone'),
          'has-highlight': hasHighlightOnField('billingPhone'),
        })}
        disabled={isDisabled}
        error={getError()}
        phoneLabel={phoneLabel}
        themeColor={portalSettings.theme?.mainColor}
        outlineColor={portalSettings?.theme?.colour?.outline}
        isPhoneRequired={isPhoneRequired}
      />
      {hasPluginSMS && <Newsletter />}
    </div>
  );
};

BillingPhone.propTypes = {
  isBillingAddress: PropTypes.bool,
  flags: PropTypes.shape({
    'disable-ddi-phone': PropTypes.bool,
  }),
};

export default BillingPhone;
