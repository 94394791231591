import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import LogInLogOutBtn from '../../../modules/authentication/components/login-logout-btn';
import MobileMiniCart from '../../../components/Checkout/MobileMiniCart';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ReactComponent as Arrow } from '../../../components/Checkout/icon/Arrow.svg';
import { selectCart } from '../../../store/slice';
import { useCloseIframe } from '../../hooks/use-close-iframe';

import style from './index.module.scss';

const Header = () => {
  const { t } = useTranslation();
  const { isMobileDevice } = useWindowDimensions();
  const { handleClick } = useCloseIframe();
  const cartData = useSelector(selectCart);
  const portalSettings = cartData?.merchant?.settings;

  const findCorrectFontColor = () => {
    if (portalSettings?.theme?.colour?.background) {
      const r = parseInt(portalSettings?.theme.colour.background.slice(1, 3), 16);
      const g = parseInt(portalSettings?.theme.colour.background.slice(3, 5), 16);
      const b = parseInt(portalSettings?.theme.colour.background.slice(5, 7), 16);
      const brightness = (r * 299 + g * 587 + b * 114) / 1000;
      if (brightness > 128) {
        return 'black';
      }
      return 'white';
    }
    return '';
  };

  const textColor = findCorrectFontColor();

  const getStyles = () => {
    const arrStyles = [];

    if (portalSettings?.theme?.colour?.background) {
      arrStyles.push({ backgroundColor: portalSettings?.theme.colour.background });
    }
    if (portalSettings?.theme.colour.background?.toLowerCase().indexOf('#fff') === -1) {
      arrStyles.push({ paddingBottom: 25 });
    }
    if (textColor) {
      arrStyles.push({ color: textColor });
    }

    return arrStyles.reduce((acc, curr) => ({ ...acc, ...curr }), {});
  };

  const renderButtonsOrText = () => {
    if (isMobileDevice && !cartData?.customer?.email) {
      return (
        <>
          <LogInLogOutBtn />
          <MobileMiniCart />
        </>
      );
    }
    if (isMobileDevice) {
      return <MobileMiniCart />;
    }
    if (cartData?.customer?.email) {
      return <span className="required-title">{`*${t('checkout.required_fields')}`}</span>;
    }
    return <LogInLogOutBtn />;
  };

  return (
    <div className={style.wrapper} style={getStyles()}>
      <div className={style.brand}>
        {isMobileDevice && <Arrow className="arrow-left" width={22} height={15} opacity={0.7} onClick={handleClick} />}
        {portalSettings?.theme.branding?.logoURL ? (
          <img
            src={portalSettings?.theme.branding.logoURL}
            alt={t('header.company_logo')}
            width={120}
            height={40}
            data-test-id="custom-logo"
          />
        ) : (
          <h1>{t('checkout.checkout')}</h1>
        )}
      </div>
      {renderButtonsOrText()}
    </div>
  );
};

export default Header;
