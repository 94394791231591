import { datadogRum } from '@datadog/browser-rum';
import React, { useContext, useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import LogInLogOutBtn from '../../modules/authentication/components/login-logout-btn';
import { EVENT, trigger } from '../../commons/utils/custom-event';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import InputWrapper from '../../commons/components/input-wrapper';
import usePlatform from '../../commons/hooks/use-platform';
import { currentBaseUrl } from '../../helpers/urlConst';
import { selectCart, setStatusEvent } from '../../store/slice';
import DataContext from '../../store/dataContext';
import { analyticsStage1 } from '../../helpers/constants';
import useValidate from '../../hooks/useValidate';
import useAutofill from '../../hooks/useAutofill';
import { useEndpoint } from '../../hooks/useEndpoint';
import UiContext from '../../store/uiContext';

import './style.scss';

const EditEmail = () => {
  const dataContext = useContext(DataContext);
  const { isAuthenticationSupported, logIn } = usePlatform();
  const { isMobileDevice } = useWindowDimensions();
  const uiContext = useContext(UiContext);
  const { validateEmail } = useValidate();
  const { t } = useTranslation();
  const cartData = useSelector(selectCart);
  const { autofillForm } = useAutofill();
  const { postAnalyticsStage1, patchEnrich } = useEndpoint();
  const dispatch = useDispatch();
  const portalSettings = cartData?.merchant?.settings;

  const [emailValue, setEmailValue] = useState(dataContext.email);
  const [isEmailValid, setIsEmailValid] = useState(!validateEmail(emailValue).error);
  const [oldEmail, setOldEmail] = useState(dataContext.email);
  const [showError, setShowError] = useState(false);
  const [responseError, setResponseError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const emailInputRef = useRef(null);
  const editBtnRef = useRef(null);

  const handleEmailChange = value => {
    setEmailValue(value);
    const validated = validateEmail(value);
    setIsEmailValid(!validated.error);
  };

  const updateEmail = async response => {
    if (response.cart?.suggestions?.length > 0) {
      dataContext.setShowLoading(true);
      const autofillResult = await patchEnrich({
        email: emailValue,
        address: response.cart?.suggestions[0].address,
        payment: response.cart?.suggestions[0].payment,
      });
      postAnalyticsStage1({ step: analyticsStage1.FAST_TRACK });
      dispatch(setStatusEvent({ event: 'suggestion' }));
      autofillForm(autofillResult.checkout, autofillResult.cart);
    } else {
      dataContext.reset();
      autofillForm(response.checkout, response.cart);
    }
    dataContext.updateEmail(emailValue);
    uiContext.setEditEmail(false);
    trigger(EVENT.EDIT_EMAIL);
    uiContext.updateLocalError(false, false, false);
  };

  const handleKeyDown = async e => {
    if (e.key === 'Enter' || e.target.localName === 'button' || e.target.localName === 'img') {
      if (isEmailValid) {
        handleEmailChange(emailValue);
        setIsLoading({ event: 'emailChange' });
        const result = await patchEnrich({
          email: emailValue,
        });

        datadogRum.addAction('entered-email', { email: emailValue });
        postAnalyticsStage1({ step: analyticsStage1.EMAIL });

        updateEmail(result);
        if (!result.cart?.customer) {
          dataContext.setCustomer(null);
        } else {
          dataContext.setCustomer(result.cart?.customer);
        }
      } else {
        setShowError(true);
      }
    } else if (e.target.localName === 'div') {
      handleEmailChange(oldEmail);
      uiContext.setEditEmail(false);
    }
  };

  const getError = () => {
    if (showError) {
      if (emailValue) {
        return t('validate.invalid_email_address');
      }
      return t('user_add.please_enter_a_email_address');
    }
    if (responseError) {
      return responseError;
    }
    return '';
  };

  useEffect(() => {
    setOldEmail(dataContext.email);
  }, []);

  useEffect(() => {
    if (emailInputRef) {
      emailInputRef?.current?.focus();
    }
  }, [emailInputRef]);

  return (
    <div className="edit-email-wrapper">
      <div className="edit-wrapper">
        <InputWrapper
          autoComplete="email"
          classNameInput={classNames({
            'is-value': dataContext.email,
            'has-error': showError || responseError,
            unauthorized: !dataContext.isLoggedIn && isAuthenticationSupported(),
          })}
          error={getError()}
          inputId="user-email"
          onChange={event => handleEmailChange(event.target.value)}
          onFocus={() => {
            setResponseError('');
            setShowError(false);
          }}
          onKeyDown={handleKeyDown}
          placeholder="* E-Mail"
          placeholderTop="E-Mail"
          theme={portalSettings.theme}
          type="email"
          value={emailValue}
          ref={emailInputRef}
        >
          {!dataContext.isLoggedIn && isAuthenticationSupported() && !emailValue && isMobileDevice && (
            <div className="modification-input">
              <LogInLogOutBtn handleClick={logIn} label={t('checkout.log_in')} />
            </div>
          )}
          <button
            className={`edit-btn ${isLoading && 'loading'}`}
            type="button"
            ref={editBtnRef}
            onClick={handleKeyDown}
            style={{ backgroundColor: portalSettings.theme.colour?.main }}
          >
            {!isLoading ? (
              <img alt="check-icon" src={`${currentBaseUrl()}assets/check.svg`} />
            ) : (
              <img alt="Loading..." src={`${currentBaseUrl()}loaders/spinner-white.png`} width={30} />
            )}
          </button>
        </InputWrapper>
      </div>
      <div className="edit-overlay" onClick={handleKeyDown} aria-hidden="true" />
    </div>
  );
};

export default EditEmail;
