/* global google */

import { googlePayError } from '../../../../helpers/constants';

export const getDefaultGoogle = ({
  credentials,
  settings,
  cartDetails,
  cartTotals,
  shopperCurrency,
  getOrderTotal,
  postSubmit,
  contextShippingAddress,
  billingPhone,
  broadcastMessage,
  gatewayData,
  cartData,
  checkoutData,
  id,
  additionalData,
  setOpenPreOrder,
  setApiGoogleExecuteLoading,
  setApiGoogleExecuteResponse,
  setApiGoogleExecuteError,
  t,
}) => {
  const { gateway, ...rest } = credentials;
  const {
    allowedCardNetworks = ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
    allowedCardAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
    apiVersion = 2,
    apiVersionMinor = 0,
    merchantId,
    merchantName,
    type = 'PAYMENT_GATEWAY',
    environment,
  } = settings;

  const baseRequest = {
    apiVersion,
    apiVersionMinor,
  };

  const tokenizationSpecification = {
    type,
    parameters: {
      gateway,
      ...rest,
    },
  };

  const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks,
    },
  };
  const cardPaymentMethod = { ...baseCardPaymentMethod, tokenizationSpecification };
  const getGoogleIsReadyToPayRequest = () => ({ ...baseRequest, allowedPaymentMethods: [baseCardPaymentMethod] });

  const getGoogleTransactionInfo = () => ({
    displayItems: [
      {
        label: t('cart_total.subtotal'),
        type: 'SUBTOTAL',
        price: parseFloat(
          String(cartDetails?.lineItems.reduce((partial, a) => partial + a.price * a.qty - a.discount, 0)) -
            ((cartDetails?.discount || 0) + (cartTotals?.coupons?.reduce((partial, a) => partial + a.amount, 0) || 0)),
        ).toFixed(2),
      },
      {
        label: t('cart_total.shipping'),
        type: 'LINE_ITEM',
        price: (cartTotals?.data?.shipping || 0).toFixed(2),
      },
      {
        label: t('cart_total.handling'),
        type: 'LINE_ITEM',
        price: (cartTotals?.data?.handling || 0).toFixed(2),
      },
      {
        label: t('protection_container.title_insurance'),
        type: 'LINE_ITEM',
        price: (cartTotals?.data?.insurance || 0).toFixed(2),
      },
      {
        label: t('cart_total.tax'),
        type: 'TAX',
        price: (cartTotals?.data?.tax !== undefined
          ? cartTotals.data.tax
          : (cartDetails && cartDetails.tax) || 0
        ).toFixed(2),
      },
    ],
    countryCode: contextShippingAddress.country,
    currencyCode: shopperCurrency?.code || 'USD',
    totalPriceStatus: 'FINAL',
    totalPrice: getOrderTotal().toFixed(2),
    totalPriceLabel: t('cart_total.order_total'),
  });

  const getGooglePaymentDataRequest = () => {
    const paymentDataRequest = { ...baseRequest };
    paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
    paymentDataRequest.emailRequired = true;
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
    paymentDataRequest.merchantInfo = {
      merchantName,
    };

    paymentDataRequest.callbackIntents = ['PAYMENT_AUTHORIZATION'];
    return paymentDataRequest;
  };

  const processPayment = paymentData =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(paymentData.paymentMethodData.tokenizationData.token);
      }, 3000);
    });

  const postGpay = async token => {
    const payment = {};
    payment.context = {
      ...gatewayData,
      ...broadcastMessage,
      cvv: '111',
      onBehalfOf: cartData?.payment?.direct?.onBehalfOf,
      cardType: 'alt',
    };
    payment.id = JSON.parse(token)?.androidPayCards?.[0]?.nonce;
    payment.type = 'gpay';
    try {
      setApiGoogleExecuteLoading(true);
      const address = {
        ...checkoutData.address,
        name: checkoutData.address.name || additionalData.shipping.name,
        company: checkoutData.address.company || additionalData.shipping.company,
        line2: checkoutData.address.line2 || additionalData.shipping.line2,
      };

      const result = await postSubmit({
        address,
        billing: {
          phone: billingPhone,
        },
        payment,
      });
      setApiGoogleExecuteResponse(result);
    } catch (err) {
      setApiGoogleExecuteError(err);
    }
    setApiGoogleExecuteLoading(false);
  };

  const onPaymentAuthorized = paymentData =>
    new Promise(resolve => {
      processPayment(paymentData)
        .then(token => {
          if (!token.error) {
            if (cartData?.items?.find(item => item?.preOrder?.expectedAvailability)) {
              setOpenPreOrder({
                payload: token,
                callback: postGpay,
              });
            } else {
              postGpay(token);
            }
            resolve({ transactionState: 'SUCCESS' });
          }
          resolve({
            ...googlePayError(t),
          });
        })
        .catch(() => {
          resolve({
            ...googlePayError(t),
          });
        });
    });

  const getGooglePaymentsClient = () =>
    new google.payments.api.PaymentsClient({
      environment,
      merchantInfo: {
        merchantName,
        merchantId,
      },
      paymentDataCallbacks: {
        onPaymentAuthorized,
      },
    });

  const onGooglePaymentButtonClicked = () => {
    const paymentDataRequest = getGooglePaymentDataRequest();
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo();

    const paymentsClient = getGooglePaymentsClient();
    paymentsClient.loadPaymentData(paymentDataRequest);
  };

  const removePreviousButton = () => {
    const node = document.getElementById(id);
    while (node.firstChild) {
      node.removeChild(node.lastChild);
    }
  };

  const addGooglePayButton = () => {
    const paymentsClient = getGooglePaymentsClient();
    const button = paymentsClient.createButton({
      onClick: onGooglePaymentButtonClicked,
      allowedPaymentMethods: [baseCardPaymentMethod],
    });
    removePreviousButton();
    document.getElementById(id).appendChild(button);
  };

  const onGooglePayLoaded = async () => {
    const paymentsClient = getGooglePaymentsClient();
    try {
      const response = await paymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest());
      if (response.result) {
        addGooglePayButton();
      }
    } catch (err) {
      // err
    }
  };
  onGooglePayLoaded();
};
