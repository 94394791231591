import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';

import { currentBaseUrl } from '../helpers/urlConst';

import { EVENT, trigger } from '../commons/utils/custom-event';
import { setStatus, selectBilling, setFullName, setAddressGoogle } from '../modules/billing/slice';
import { currentPaymentMethod } from '../modules/payment/slice';
import { selectShipping } from '../modules/shipping/slice';
import { usePickup } from '../modules/shipping/containers/pickup-in-store/use-pickup';
import useApiStatus from '../store/apiStatus/useApiStatus';
import useErrors from '../store/errors/useErrors';
import {
  postTrackCrash,
  selectCart,
  selectCheckout,
  selectError,
  selectApiStatus,
  setStatus as setStoreSliceStatus,
  selectAdditionalData,
} from '../store/slice';
import DataContext from '../store/dataContext';
import UiContext from '../store/uiContext';
import { defaultUrl } from '../commons/hooks/use-platform';
import { paymentIdList } from '../helpers/constants';
import { useProduct } from './useProduct';
import useCartTotalsUtils from './useCartTotalsUtils';
import useBillingPhone from '../commons/hooks/use-billing-phone';
import { useModal } from '../modules/modal/hooks/use-modal';

import useAddress from './useAddress';
import { useBroadcastChannel } from './useBroadcastChannel';
import useApiPostOrderError from './useApiPostOrderError';
import useTagManager from './useTagManager';
import { useEndpoint } from './useEndpoint';
import useValidate from './useValidate';

const useSubmit = () => {
  const { hasBillingError } = useApiPostOrderError();
  const { addErrorToField } = useErrors();
  const { apiStatus } = useApiStatus();
  const { receiveMessage } = useBroadcastChannel();
  const cartData = useSelector(selectCart);
  const checkoutData = useSelector(selectCheckout);
  const additionalData = useSelector(selectAdditionalData);
  const { getBilling } = useAddress();
  const dataContext = useContext(DataContext);
  const { dataLayer } = useTagManager();
  const uiContext = useContext(UiContext);
  const { allDigital } = useProduct();
  const { t } = useTranslation();
  const [apiPostOrderLoading, setApiPostOrderLoading] = useState(false);
  const { isBillingPhoneOk } = useBillingPhone();
  const { postSubmit } = useEndpoint();

  const shippingStatus = useSelector(selectApiStatus);

  const billingSelector = useSelector(selectBilling);
  const selectedMethod = useSelector(currentPaymentMethod);
  const shippingState = useSelector(selectShipping);
  const submitError = useSelector(selectError);
  const dispatch = useDispatch();
  const { handlePickupSubmit, isPickupActive } = usePickup();
  const { showProductsOutOfStock } = useModal();
  const { validateName } = useValidate();

  const {
    isCheckoutReady,
    contextBillingAddress,
    isThirdPartyReady,
    storeUrl,
    setShouldRedirectOnError,
    moveToPlatform,
    registeredPaymentMethods,
  } = dataContext;

  const creditCardType = selectedMethod === paymentIdList.CREDIT_CARD;

  const { getOrderTotal } = useCartTotalsUtils();

  const submitPayment = async payload => {
    try {
      setApiPostOrderLoading(true);
      const result = await postSubmit(payload);
      if (result.checkout.payment.type === 'direct' || result.checkout.payment.type === 'platform') {
        dataLayer(result);
        uiContext.updateCheckoutState(uiContext.TO_DONE);
        dataContext.reset();
        dataContext.orderSubmitting(false);
        datadogRum.addAction('successful-order');
      }
      setApiPostOrderLoading(false);
    } catch (e) {
      datadogRum.addAction('submit-error', e);
      setApiPostOrderLoading(false);
    }
  };

  const handleBilling = (address, billing) => {
    const payload = {};
    payload.address = address;
    if (address?.line1 === billing?.address?.line1) {
      payload.billing = { phone: billing?.phone || payload.billing?.phone };
    } else {
      payload.billing = billing;
    }
    return payload;
  };

  const handlePaymentAdd = async type => {
    const payment = {};
    if (type === 'submit') {
      payment.context = {
        cvv: getOrderTotal() === 0 ? undefined : dataContext.userCardCvv,
        onBehalfOf: getOrderTotal() === 0 ? undefined : cartData?.payment?.direct?.onBehalfOf,
        cardType: getOrderTotal() === 0 ? undefined : dataContext.cardType,
      };
      payment.id = getOrderTotal() === 0 ? dataContext.cartId : dataContext.userCardId;
      payment.type = getOrderTotal() === 0 ? 'platform' : 'direct';
    }
    try {
      const billing = getBilling();

      const address = {
        ...checkoutData.address,
        name: checkoutData.address.name || additionalData.shipping.name,
        company: checkoutData.address.company || additionalData.shipping.company,
        line2: checkoutData.address.line2 || additionalData.shipping.line2,
      };

      const payload = {
        address,
        payment,
      };

      // Handle address
      if (isPickupActive()) {
        const billingData = handleBilling(address, billing);
        payload.address = billingData.address;
        payload.billing = billingData.billing;
      } else if (allDigital()) {
        payload.address = billing?.address;
        payload.billing = { phone: billing?.phone || payload.billing?.phone };
      } else if (billing) {
        payload.billing = billing;
      } else {
        payload.address = {
          ...checkoutData?.address,
          ...address,
        };
      }

      // Handle Shipping/Pickup method
      if (isPickupActive()) {
        payload.pickUpMethodKey = shippingState.bopis.selectedPickupMethod.lookupKey;
        payload.shippingMethodKey = undefined;
      } else {
        payload.pickUpMethodKey = undefined;
      }

      if (cartData?.items?.find(item => item?.preOrder?.expectedAvailability)) {
        uiContext.setOpenPreOrder({
          payload,
          callback: submitPayment,
        });
      } else {
        submitPayment(payload);
      }
    } catch (err) {
      // err
    }
  };

  const handleVenmoCheckout = () => {
    const div = document.getElementById('venmo-button-container');
    div.click();
  };

  const handleAmazonCheckout = () => {
    const popupRef = window.open(
      `${window.location.href}&amazon=openPopup`,
      'PromoteFirefoxWindowName',
      `popup, resizable=yes, height=678, width=487, top=73, left=524`,
    );
    uiContext.setShowOverlay({
      logo: null,
      titleLogo: `${currentBaseUrl()}assets/amazonpay-text.svg`,
      text: t('payment_method.amazonpay.overlay'),
      button: 'Continue',
      window: popupRef,
    });
    const timer = setInterval(() => {
      if (popupRef.closed) {
        clearInterval(timer);
        uiContext.setShowOverlay(null);
      }
    }, 1000);
    receiveMessage(popupRef);
  };

  const handleGoogleCheckout = () => {
    const div = document.getElementsByClassName('gpay-card-info-container black long en')[0];
    div.click();
  };

  const handlePaypalCheckout = () => {
    const popupRef = window.open(
      cartData.payment.paypal.href,
      'PromoteFirefoxWindowName',
      `popup, resizable=yes, height=678, width=487, top=73, left=524`,
    );
    uiContext.setShowOverlay({
      logo: `${currentBaseUrl()}assets/paypal-logo.svg`,
      titleLogo: `${currentBaseUrl()}assets/paypal-text.svg`,
      text: t('payment_method.paypal.overlay'),
      button: 'Continue',
      window: popupRef,
    });
    const timer = setInterval(() => {
      if (popupRef.closed) {
        clearInterval(timer);
        uiContext.setShowOverlay(null);
      }
    }, 1000);
    receiveMessage(popupRef);
  };

  const scrollToError = () => {
    document.getElementById('checkout.billing_information')?.scrollIntoView({ behavior: 'smooth' });
  };

  const isBillingNameOk = () => {
    if (hasBillingError() && !billingSelector.fullName.value && creditCardType) {
      dispatch(
        setFullName({
          error: {
            hasError: true,
            message: t('payment_add.please_enter_billing_name'),
          },
          value: billingSelector.fullName.value,
        }),
      );
      return false;
    }
    return true;
  };

  const isBillingAddressOk = () => {
    const hasContextBillingAddress = contextBillingAddress && Object.values(contextBillingAddress).length > 0;
    if (hasBillingError() && !hasContextBillingAddress && creditCardType) {
      dispatch(
        setAddressGoogle({
          addressGoogle: {
            error: {
              hasError: true,
              message: t('payment_add.please_enter_billing_address'),
            },
            value: billingSelector.addressGoogle.value,
          },
        }),
      );
      return false;
    }
    return true;
  };

  const checkBillingInformation = () => {
    const isBnOk = isBillingNameOk();
    const isBaOk = isBillingAddressOk();
    const isBpOk = isBillingPhoneOk();
    return isBnOk && isBaOk && isBpOk;
  };

  const handleClick = type => {
    const SubmitBtnType = type === 'submit';
    const PayPalType = type === 'paypal';
    const VenmoType = type === 'venmo';
    const AmazonPayType = type === paymentIdList.AMAZONPAY;
    const GooglePayType = type === paymentIdList.GOOGLEPAY;

    const orderTotal = getOrderTotal();
    const zeroTotal = orderTotal === 0;
    const orderIsCovered = (apiStatus.isCardReady && !!dataContext.userCardId) || zeroTotal || !SubmitBtnType;
    const isBpOk = isBillingPhoneOk();
    const isAllDigital = allDigital();
    const hasActivePickUp = isPickupActive();
    const isShippingValid = checkoutData?.shippingMethodKey || checkoutData?.pickUpMethodKey || isAllDigital;
    const isNameValid = validateName(checkoutData.address.name).error;
    const isBillingReady = checkBillingInformation();
    const isShippingReady = (checkoutData.address.hash && !isNameValid) || hasActivePickUp || isAllDigital;
    const canSubmitCheckout = isCheckoutReady({
      isShippingReady,
      isBillingReady,
      zeroTotal,
      isAdditionalServiceLoading:
        uiContext.isLoadingInsurance || uiContext.isLoadingRoute || uiContext.isLoadingSignature,
    });
    const canSubmitThirdParty = isThirdPartyReady({
      isShippingReady,
      isBillingReady,
      isAdditionalServiceLoading:
        uiContext.isLoadingInsurance || uiContext.isLoadingRoute || uiContext.isLoadingSignature,
    });

    const readyToSubmit =
      orderIsCovered &&
      isBpOk &&
      isShippingValid &&
      ((SubmitBtnType && canSubmitCheckout) || (!SubmitBtnType && canSubmitThirdParty));

    datadogRum.addAction('submission-checks', {
      readyToSubmit,
      orderIsCovered,
      isBpOk,
      isShippingValid,
      isBillingReady,
      SubmitBtnType,
      canSubmitCheckout,
      canSubmitThirdParty,
      addressHash: !!checkoutData.address.hash,
      isNameValid,
      hasActivePickUp,
      isAllDigital,
      zeroTotal,
      isLoadingInsurance: uiContext.isLoadingInsurance,
      isLoadingRoute: uiContext.isLoadingRoute,
      isLoadingSignature: uiContext.isLoadingSignature,
      isShippingReady,
    });

    if (SubmitBtnType) {
      // Preventing the ability to trigger the Submit Event
      // for different payment types except for the credit card.
      // According to the task CHKOUT-1687
      trigger(EVENT.SUBMIT_CHECKOUT);
    }

    if (readyToSubmit) {
      datadogRum.addAction('ready-to-submit', { type });
      if (isBillingPhoneOk() && PayPalType) {
        if (!registeredPaymentMethods.paypal && moveToPlatform?.paypal) {
          window.top.location.href = `${storeUrl}/${defaultUrl}?preventIframeLoad=true`;
        } else {
          handlePaypalCheckout();
        }
        return;
      }
      if (isBillingPhoneOk() && VenmoType) {
        if (!registeredPaymentMethods.venmo && moveToPlatform?.venmo) {
          window.top.location.href = `${storeUrl}/${defaultUrl}?preventIframeLoad=true`;
        } else {
          handleVenmoCheckout();
        }
        return;
      }

      if (isBillingPhoneOk() && AmazonPayType) {
        if (!registeredPaymentMethods.amazonpay && moveToPlatform?.amazonpay) {
          window.top.location.href = `${storeUrl}/${defaultUrl}?preventIframeLoad=true`;
        } else {
          handleAmazonCheckout();
        }
        return;
      }

      if (isBillingPhoneOk() && GooglePayType) {
        if (!registeredPaymentMethods.googlepay && moveToPlatform?.googlepay) {
          window.top.location.href = `${storeUrl}/${defaultUrl}?preventIframeLoad=true`;
        } else {
          handleGoogleCheckout();
        }
      }

      if (submitError.data === 'PAYMENT DECLINED') {
        if (isBillingPhoneOk() && SubmitBtnType) {
          handlePaymentAdd(type);
        } else {
          scrollToError();
        }
      } else if (
        submitError.data === 'INVALID BILLING ADDRESS' ||
        submitError.data === 'INVALID BILLING ADDRESS / PAYMENT DECLINED'
      ) {
        if (checkBillingInformation() && SubmitBtnType) {
          handlePaymentAdd(type);
        } else {
          scrollToError();
        }
      } else if (isPickupActive()) {
        handlePickupSubmit({
          creditCard: {
            isActive: type === 'submit',
            submitCallback: () => handlePaymentAdd(type),
          },
          googlePay: {
            isActive: type === paymentIdList.GOOGLEPAY,
            submitCallback: () => handleGoogleCheckout(),
          },
        });
      } else if (SubmitBtnType) {
        handlePaymentAdd(type);
      } else if (
        isThirdPartyReady({
          isShippingReady: shippingStatus.isReady || allDigital(),
          isBillingReady: checkBillingInformation(),
          isAdditionalServiceLoading:
            uiContext.isLoadingInsurance || uiContext.isLoadingRoute || uiContext.isLoadingSignature,
        }) &&
        GooglePayType
      ) {
        if (!registeredPaymentMethods.googlepay && moveToPlatform?.googlepay) {
          window.top.location.href = `${storeUrl}/${defaultUrl}?preventIframeLoad=true`;
        } else {
          handleGoogleCheckout();
        }
      }
    } else {
      datadogRum.addAction('not-ready-to-submit', { type });
    }
  };

  const highlightBillingFields = () => {
    const hasContextBillingAddress =
      dataContext.contextBillingAddress && Object.values(dataContext.contextBillingAddress).length > 0;
    uiContext.updateCheckoutState(uiContext.TO_BILLING_ADDRESS_ADD);
    uiContext.setIsBillingError(true);
    addErrorToField({
      billingName: {
        hasHighlight: !dataContext.billingName,
      },
      billingAddress: {
        hasHighlight: !hasContextBillingAddress,
      },
      billingPhone: {
        hasHighlight: !dataContext.billingPhone,
      },
    });
  };

  const setIsNotReady = (error, warning) => {
    uiContext.updateCardDeclined(error, warning);
    dataContext.setSubmitFailedTimestamp(new Date().getTime());
    dataContext.orderSubmitting(false);
    dispatch(setStatus({ isReady: false }));
    dispatch(
      setStoreSliceStatus({
        isLoading: false,
        value: {
          isLoading: false,
        },
      }),
    );
  };

  useEffect(() => {
    if (submitError?.request === 'postSubmit' && submitError?.status === 422) {
      if (submitError.data === 'This order requires a shipping address.') {
        dispatch(
          postTrackCrash({
            merchantId: dataContext.merchantId,
            lookupKey: dataContext.platformCartId,
            storeUrl: dataContext.storeUrl,
          }),
        ).unwrap();
      } else if (submitError.message?.code === 'OUT_OF_STOCK') {
        showProductsOutOfStock({ products: submitError.message?.products, cartItems: cartData.items });
        setApiPostOrderLoading(false);
        setIsNotReady(true, false);
      } else if (submitError.data === 'PAYMENT DECLINED') {
        setIsNotReady(true, false);
        addErrorToField({
          billingPhone: {
            hasHighlight: !dataContext.billingPhone,
          },
        });
      } else if (submitError.data === 'INVALID BILLING ADDRESS') {
        highlightBillingFields();
        setIsNotReady(false, true);
        datadogRum.addAction('requested-billing-info', { error: submitError.data });
      } else if (submitError.data === 'INVALID BILLING ADDRESS / PAYMENT DECLINED') {
        highlightBillingFields();
        // we still track if CVV was changed (setCvvChanged)
        // but we should not prevent the user from submitting the order even if he decides not to change his CVV
        setIsNotReady(true, false);
        dataContext.setCvvChanged(false);
        datadogRum.addAction('requested-billing-info', { error: submitError.data });
      } else if (submitError.data === 'INVALID PAYMENT METHOD') {
        setShouldRedirectOnError(true);
      } else {
        setIsNotReady(true, false);
      }
    } else if (submitError?.request === 'postSubmit' && submitError?.status === 400) {
      setIsNotReady(true, false);
    }
  }, [submitError]);

  useEffect(() => {
    if (apiPostOrderLoading) {
      dataContext.orderSubmitting(apiPostOrderLoading);
    }
  }, [apiPostOrderLoading]);

  return { handlePaymentAdd, handleClick, checkBillingInformation };
};

export default useSubmit;
