import { createSlice } from '@reduxjs/toolkit';

import { defaultCurrency } from '../../../commons/constants';

export const sliceKey = 'currency';

const initialState = {
  baseCurrency: '',
  displayCurrency: '',
  exchangeRate: 0.0,
  precision: 0,
  separator: {
    radix: '',
    thousand: '',
  },
  symbol: {
    token: '',
    position: '',
  },
};

export const currencySlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      const {
        baseCurrency = defaultCurrency.baseCurrency,
        displayCurrency = defaultCurrency.displayCurrency,
        exchangeRate = defaultCurrency.exchangeRate,
        precision = defaultCurrency.precision,
        separator: { radix = defaultCurrency.separator.radix, thousand = defaultCurrency.separator.thousand } = {},
        symbol: { token = defaultCurrency.symbol.token, position = defaultCurrency.symbol.position } = {},
      } = action.payload || {};

      state.baseCurrency = baseCurrency;
      state.displayCurrency = displayCurrency;
      state.exchangeRate = exchangeRate;
      state.precision = precision;
      state.separator.radix = radix;
      state.separator.thousand = thousand;
      state.symbol.token = token;
      state.symbol.position = position;
    },
  },
});

export const { setCurrency } = currencySlice.actions;

export const selectCurrency = state => state.currency;

export default currencySlice.reducer;
