import React, { useContext } from 'react';

import useApiPostOrderError from '../../hooks/useApiPostOrderError';
import UiContext from '../../store/uiContext';
import useSubmit from '../../hooks/useSubmit';
import SubmitBtn from '../Button/SubmitBtn';

import './style.scss';

const PaymentAdd = () => {
  const { hasBillingError } = useApiPostOrderError();
  const { checkBillingInformation } = useSubmit();
  const uiContext = useContext(UiContext);

  const callOfFadeAnimation = () => {
    if (!checkBillingInformation() && hasBillingError()) {
      uiContext.updateCardDeclined(uiContext.isCardDeclined.error, uiContext.isCardDeclined.warning);
    }
  };

  return <SubmitBtn type="submit" handleFunc={callOfFadeAnimation} />;
};

export default PaymentAdd;
