export const platformList = Object.freeze({
  bigcommerce: 'bigcommerce',
  magento: 'magento',
  woocommerce: 'woocommerce',
  commercecloud: 'commercecloud',
  sdk: 'sdk',
});

export const pluginList = {
  customerSignature: 'customerSignature',
  packageInsurance: 'packageInsurance',
  route: 'route',
};

export const addressShape = {
  city: '',
  company: '',
  country: '',
  regionCode: '',
  region: '',
  line1: '',
  line2: '',
  street: '',
  streetName: '',
  streetNumber: '',
  name: '',
  singleLine: '',
  postal: '',
};

export const autofillSource = Object.freeze({
  EMAIL_RESPONSE: 'EMAIL_RESPONSE',
  MANUAL_ADDRESS: 'MANUAL_ADDRESS',
  GOOGLE_AUTOCOMPLETION_SERVICE: 'GOOGLE_AUTOCOMPLETION_SERVICE',
  SMARTY_US_AUTOCOMPLETE: 'SMARTY_US_AUTOCOMPLETE',
});

export const validationSource = Object.freeze({
  FROM_TYPING: 'FROM_TYPING',
  FROM_SUBMIT: 'FROM_SUBMIT',
});

export const defaultCurrency = {
  baseCurrency: 'USD',
  displayCurrency: 'USD',
  exchangeRate: 1,
  precision: 2,
  separator: {
    radix: '.',
    thousand: ',',
  },
  symbol: {
    token: '$',
    position: 'left',
  },
};

export const deliveryMethod = Object.freeze({
  shipping: 'shipping',
  pickup: 'pickup',
});
