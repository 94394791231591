import { CSSTransition, SwitchTransition } from 'react-transition-group';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { datadogRum } from '@datadog/browser-rum';
import { currentBaseUrl } from '../../helpers/urlConst';
import { waitForElms } from '../../helpers/dom';

import LogInLogOutBtn from '../../modules/authentication/components/login-logout-btn';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import InputWrapper from '../../commons/components/input-wrapper';
import useApiStatus from '../../store/apiStatus/useApiStatus';
import usePlatform from '../../commons/hooks/use-platform';
import DataContext from '../../store/dataContext';
import useValidate from '../../hooks/useValidate';
import { status, inputs } from '../../helpers/constants';
import UiContext from '../../store/uiContext';
import { selectCart, selectCheckout, selectApiStatus, deleteData, saveRef } from '../../store/slice';
import useReset from '../../hooks/useReset';
import useAutofill from '../../hooks/useAutofill';

import './style.scss';

const UserAdd = ({ emailChangeCallback, handleNext, errorResponse, changeEmailStatus }) => {
  const { isAuthenticationSupported } = usePlatform();
  const { isMobileDevice } = useWindowDimensions();
  const { email, isLoggedIn, isAutofill, merchantId, cartId } = useContext(DataContext);
  const {
    allowUserAdd,
    setEditEmail,
    showUserAddControl,
    setShowUserAddControl,
    updateCheckoutState,
    TO_SHIPPING_ADDRESS_ADD,
    TO_USER_ADD,
  } = useContext(UiContext);
  const dispatch = useDispatch();
  const { validateEmail } = useValidate();
  const { apiStatus, set: setApiStatus } = useApiStatus();
  const { handleReset } = useReset();
  const { t } = useTranslation();
  const cartData = useSelector(selectCart);
  const checkoutData = useSelector(selectCheckout);
  const statusApi = useSelector(selectApiStatus);
  const { autofillForm } = useAutofill();
  const portalSettings = cartData?.merchant?.settings;

  const [emailTerm, setEmailTerm] = useState(email || checkoutData?.customer?.email || cartData?.customer?.email || '');
  const [isEmailValid, setIsEmailValid] = useState(!validateEmail(emailTerm).error);
  const [showError, setShowError] = useState(false);
  const [errorFromResponse, setErrorFromResponse] = useState(null);

  const userEmailRef = useRef(null);
  const nextBtnRef = useRef(null);

  const errorResponseData = errorResponse?.data;
  const emailInput = userEmailRef?.current;

  useEffect(() => {
    if (userEmailRef.current && !checkoutData.email) {
      userEmailRef.current.focus();
    }
  }, [checkoutData]);

  const handleEmailChange = value => {
    setShowError(false);
    emailInput?.classList.remove('has-error');
    emailChangeCallback(value);
    setEmailTerm(value);
    const validated = validateEmail(value);
    setIsEmailValid(!validated.error);
  };

  const handleSubmit = () => {
    if (isEmailValid) {
      setApiStatus({ isEmailReady: status.LOADING });
      setEmailTerm(emailTerm);
      handleNext(emailTerm);
      datadogRum.addAction('user-clicked-next', { isEmailValid: true });
    } else if (!isEmailValid && emailInput) {
      setShowError(true);
      emailInput.classList.add('has-error');
      datadogRum.addAction('user-clicked-next', { isEmailValid: false });
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && isEmailValid) {
      handleSubmit();
    }
  };

  const renderSignInWrapper = type => {
    const authorized = type === 'logged-in';
    const guest = type === 'default';

    const renderButtonsOrText = () => {
      if (isLoggedIn || (isMobileDevice && cartData?.customer?.email)) {
        return <LogInLogOutBtn />;
      }
      return null;
    };

    const getErrorMessage = () => {
      if (showError) {
        if (errorFromResponse) {
          return errorFromResponse;
        }
        if (emailInput?.value) {
          return t('user_add.invalid_email_address');
        }
        return t('user_add.please_enter_a_email_address');
      }
      return '';
    };

    return (
      <div className="section-email-login">
        <InputWrapper
          autoComplete="email"
          autoFocus={!checkoutData.email}
          classNameInput={classNames({
            'is-value': emailTerm,
            'has-error': showError || errorFromResponse,
            unauthorized: !cartData?.customer?.email,
          })}
          classNameWrapper={classNames({
            authorized,
          })}
          // disabled={!allowUserAdd || authorized || cartData?.customer?.email}
          disabled={!guest}
          error={getErrorMessage()}
          inputId="user-email"
          onChange={event => handleEmailChange(event.target.value)}
          onFocus={() => setErrorFromResponse(null)}
          onKeyDown={handleKeyDown}
          placeholder={`* ${t('user_add.email')}`}
          placeholderTop={guest ? t('user_add.email') : t('user_add.logged_in_as')}
          ref={userEmailRef}
          theme={portalSettings.theme}
          type="email"
          value={emailTerm}
        >
          <div className="default-input" style={{ color: '#707070' }}>
            {renderButtonsOrText()}
          </div>
        </InputWrapper>
        {guest && (
          <button
            className="anchor-button"
            onClick={handleSubmit}
            id="next-btn"
            ref={nextBtnRef}
            disabled={!allowUserAdd || apiStatus.isEmailReady === status.LOADING || !emailTerm}
            type="button"
            style={{
              backgroundColor: portalSettings.theme.colour?.main,
              borderColor: portalSettings.theme.colour?.main,
              color: portalSettings.theme.colour?.text,
            }}
          >
            {apiStatus.isEmailReady !== status.LOADING ? (
              t('user_add.next')
            ) : (
              <img alt="Loading..." src={`${currentBaseUrl()}loaders/spinner-white.png`} />
            )}
          </button>
        )}
      </div>
    );
  };

  const handleEmailReset = async () => {
    await dispatch(
      deleteData({
        merchantId,
        cartId,
      }),
    ).unwrap();
    setEmailTerm('');
    handleReset();
  };

  const renderKnownEmail = () => (
    <div className="section-checked-out-as">
      <div className="section-checked-out-as__d-flex">
        <p className="email-title">{`${t('user_add.email_checkout')}: `}</p>
        <p className="email-address">{checkoutData?.customer?.email}</p>
      </div>
      {isAutofill ? (
        <button onClick={handleEmailReset} type="button" className="btn-reset">
          <div>
            <p>{t('user_add.reset')}</p>
            <img src={`${currentBaseUrl()}assets/refresh.svg`} alt="Refresh" />
          </div>
        </button>
      ) : (
        <button onClick={() => setEditEmail(true)} type="button" className="btn-reset">
          <div style={{ alignItems: 'center' }}>
            <p>{t('user_add.edit')}</p>
            <img src={`${currentBaseUrl()}assets/edit-pencil.svg`} alt="Refresh" width={9} height={9} />
          </div>
        </button>
      )}
    </div>
  );

  useEffect(() => {
    if (errorResponseData?.success === false) {
      setErrorFromResponse(t('user_add.please_enter_a_valid_email'));
    }
  }, [errorResponseData]);

  const handleEmailInput = target => {
    const validated = validateEmail(emailTerm);
    if (!validated?.error && !email) {
      dispatch(saveRef(target));
      handleSubmit();
    }
  };

  const checkClickOnInputs = type => {
    const ids = [
      inputs.disableName,
      inputs.name,
      inputs.creditCard,
      inputs.googleAddress,
      inputs.downshiftAddress,
      inputs.tabList,
      inputs.shippingMethod,
    ];
    for (let x = 0; x < ids?.length; x++) {
      let inputType = document.getElementById(inputs.googleAddress) ? inputs.googleAddress : inputs.downshiftAddress;
      const el = document.getElementById(ids[x]);
      if (ids[x]?.toLowerCase()?.indexOf('name') > -1 || ids[x]?.toLowerCase()?.indexOf('clickable') > -1) {
        inputType = inputs.name;
      }
      if (ids[x]?.toLowerCase()?.indexOf('card') > -1) {
        inputType = inputs.creditCardContainer;
      }
      if (el) {
        if (type === 'add') {
          el.onclick = () => handleEmailInput(inputType);
        } else {
          el.onclick = evt => evt.target.focus();
        }
      }
    }
  };

  useEffect(() => {
    const checkLogOutButton = element => {
      let isLogOutButton = false;
      if (element) {
        element.forEach(className => {
          if (className.indexOf('login-logout-btn') > -1) {
            isLogOutButton = true;
          }
        });
      }
      return isLogOutButton;
    };
    checkLogOutButton();
  }, []);

  useEffect(() => {
    if (!email) {
      waitForElms([inputs.name, inputs.creditCard, inputs.tabList, inputs.shippingMethod]).then(() => {
        checkClickOnInputs('add');
      });
    }
    if (email) {
      checkClickOnInputs('remove');
    }
  }, [emailTerm, email]);

  useEffect(() => {
    if (!email || email === '') {
      if (cartData?.customer?.email) {
        setEmailTerm(cartData?.customer?.email);
      } else if (checkoutData?.customer?.email) {
        setEmailTerm(checkoutData?.customer?.email);
        setShowUserAddControl(false);
        changeEmailStatus();
        updateCheckoutState(TO_SHIPPING_ADDRESS_ADD);
        autofillForm(checkoutData, cartData);
      }
    } else if (
      cartData?.status === 'fulfilled' &&
      !checkoutData?.customer?.email &&
      statusApi?.event === 'createCart'
    ) {
      handleNext(email);
    } else if (cartData?.status === 'reset') {
      updateCheckoutState(TO_USER_ADD);
      setShowUserAddControl(true);
      changeEmailStatus(status.IDLE);
      setEmailTerm('');

      if (emailInput) {
        emailInput.value = '';
      }
    }
  }, [email, cartData]);

  return isLoggedIn && isAuthenticationSupported() ? (
    renderSignInWrapper('logged-in')
  ) : (
    <SwitchTransition mode="out-in">
      <CSSTransition
        addEndListener={node => {
          node.addEventListener('transitionend', () => null, false);
        }}
        timeout={250}
        classNames="animation"
        key={showUserAddControl}
      >
        {showUserAddControl ? renderSignInWrapper('default') : renderKnownEmail()}
      </CSSTransition>
    </SwitchTransition>
  );
};

UserAdd.propTypes = {
  emailChangeCallback: PropTypes.func.isRequired,
  changeEmailStatus: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  errorResponse: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default UserAdd;
