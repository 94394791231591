export const emptyAdditionalData = {
  line2: '',
  company: '',
};

export const returnMethod = Object.freeze({
  key: 'key',
  object: 'object',
});

export const findSelectedMethod = ({ list, returnType = returnMethod.key, selectedKey }) => {
  let filtered = [];
  if (selectedKey) {
    filtered = list && list.filter(item => item.lookupKey === selectedKey);
  } else {
    filtered = list && list.filter(item => item.isSelected);
  }
  if (returnType === returnMethod.object) {
    return filtered.length > 0 ? filtered[0] : {};
  }
  return filtered.length > 0 ? filtered[0].lookupKey : '';
};

export const convertAddressToBackend = (address, fullName) => {
  if (!address) {
    return {
      name: fullName,
    };
  }
  return {
    name: fullName || address?.name,
    line1: address.line1,
    line2: address.line2,
    city: address.city,
    regionCode: address.regionCode,
    region: address.region,
    postal: address.postal,
    country: address.country || 'US',
    singleLine: address.singleLine,
  };
};

export const parseTypeAsText = (type, label) => {
  if (!label.includes('.' || '_')) {
    return label;
  }
  if (!type.includes('.' || '_')) {
    return type;
  }
  return '';
};

export const areRequiredFieldsReady = (fullName, address, methods, selectedMethodKey) => {
  const c1 = [fullName, address].every(Boolean);
  const c2 = methods.length > 0 && !!selectedMethodKey;
  return c1 && c2;
};
