/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';

import { removeAddress } from '../../../store/slice';
import { initialErrorState } from '../../utils/initial-error-state';
import { autofillSource, validationSource } from '../../constants';
import { simulateMouseClick } from '../../utils/simulate-mouse-click';

import { NoOptionsMessage, Menu, Input } from './custom-components';
import { convertToGoogle, parseGeoData, validateAddress, joinSuggestions } from './utils';

import style from './index.module.scss';

const GoogleAddressAutocomplete = ({
  additionalData,
  addressAsTyped,
  afterValidationCb,
  autofillAddress,
  disabled,
  hasSuggestions,
  inputError,
  inputId,
  inputValue,
  inputWarning,
  onSelectCb,
  placeholder,
  placeholderTop,
  setAddressAsTyped,
  setAllowEnterManually,
  setShouldOpenAdditionalData,
  theme,
  triggerFocus,
  triggerReset,
  triggerValidation,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(inputValue);
  const [error, setError] = useState(inputError);
  const [warning, setWarning] = useState(inputWarning);
  const [defaultOptions, setDefaultOptions] = useState(null);
  const [backendSuggestions, setBackendSuggestions] = useState(null);
  const [isFocused, setIsFocused] = useState(false);

  const changeActionRef = useRef(null);

  const { t } = useTranslation();

  const possibleErrors = {
    'google_address_autocomplete.please_enter_a_valid_address': t(
      'google_address_autocomplete.please_enter_a_valid_address',
    ),
  };

  const handleReset = () => {
    setValue(null);
    setError(initialErrorState);
    setWarning(false);
    setDefaultOptions(null);
    setBackendSuggestions(null);
    changeActionRef.current = null;
  };

  const validateAndReturn = (inputText, source = validationSource.FROM_TYPING) => {
    const validationResult = validateAddress(value, possibleErrors, inputText, source);
    setError(validationResult);
    if (typeof afterValidationCb === 'function') {
      afterValidationCb(validationResult);
    }
    return validationResult;
  };

  const handleInputChange = (input, rawAction) => {
    const { action } = rawAction;
    switch (action) {
      case 'input-change':
        setAddressAsTyped(input);
        if (input === '') {
          setDefaultOptions(backendSuggestions);
        }
        break;
      case 'set-value':
        setError(initialErrorState);
        setDefaultOptions(backendSuggestions);
        break;
      case 'input-blur': {
        // useful to present the error on incomplete addresses
        if (changeActionRef.current !== 'select-option') {
          validateAndReturn(addressAsTyped);
        }
        break;
      }
      default:
        // eslint-disable-next-line no-useless-return
        return;
    }
  };

  const fetchGeoData = async labelAddress => {
    if (!labelAddress) {
      return null;
    }
    const result = await geocodeByAddress(labelAddress);
    if (result && result[0]) {
      return result[0];
    }
    return null;
  };

  const handleChangeClear = async (selected, action) => {
    setValue(selected);
    onSelectCb({
      valueGoogle: null,
      valueAddress: null,
      action,
    });
    setDefaultOptions(backendSuggestions);
    dispatch(removeAddress());
  };

  const checkOpenAdditionalData = isTypePremise => {
    const hasAdditionalData = [additionalData?.line2, additionalData?.company].some(Boolean);
    const c1 = typeof setShouldOpenAdditionalData === 'function';
    const c2 = (hasSuggestions && hasAdditionalData) || !hasSuggestions;
    const c3 = isTypePremise || (!isTypePremise && hasAdditionalData);
    const shouldOpen = c1 && c2 && c3;
    if (shouldOpen) {
      setShouldOpenAdditionalData(new Date().getTime());
    }
  };

  const addressMatchHandler = (selected, response) => {
    const streetAsTyped = selected?.value?.structured_formatting?.main_text || selected?.label;
    const streetGoogleLong = `${response.address_components[0].long_name} ${response.address_components[1].long_name}`;
    const streetGoogleShort = `${response.address_components[0].short_name} ${response.address_components[1].short_name}`;
    return streetAsTyped === streetGoogleLong || streetAsTyped === streetGoogleShort;
  };

  const handleChangeGeoData = async (selected, action) => {
    const response = await fetchGeoData(selected?.label);
    const addressComponents = response.address_components;
    const isTypePremise = response?.types?.includes('premise');
    const hasValuesInResponse = response && Object.values(response).length > 0;
    const shouldParseResponse = hasValuesInResponse && addressComponents;
    if (shouldParseResponse) {
      checkOpenAdditionalData(isTypePremise);
      const isMatch = addressMatchHandler(selected, response);
      if (isMatch) {
        const parsedAddress = parseGeoData(addressComponents);
        selected.label = parsedAddress.singleLine;
        selected.value.description = parsedAddress.singleLine;
        setValue(selected);
        setError(initialErrorState);
        onSelectCb({
          valueGoogle: selected,
          valueAddress: parsedAddress,
          action,
        });
      } else {
        // Present the manual screen if there's a address mismatch
        setAllowEnterManually(true);
      }
    }
  };

  const handleChangeAutofill = async (selected, action) => {
    if (selected.source && selected.source === autofillSource.MANUAL_ADDRESS) {
      setValue(selected.addressGoogle);
      setError(initialErrorState);
      onSelectCb({
        valueGoogle: selected.addressGoogle,
        valueAddress: selected.addressManual,
        action,
      });
    }
  };

  const handleChange = (selected, rawAction) => {
    const { action } = rawAction;
    changeActionRef.current = action;
    if (typeof onSelectCb === 'function') {
      if (action === 'clear') {
        handleChangeClear(selected, action);
      } else if (action === 'nf-autofill') {
        handleChangeAutofill(selected, action);
      } else {
        handleChangeGeoData(selected, action);
      }
    }
  };

  const handleFocus = () => {
    setError(initialErrorState);
    setWarning(false);
    setIsFocused(true);
    const shouldListOnFocus = !defaultOptions && value?.label;
    if (shouldListOnFocus) {
      setDefaultOptions([
        {
          label: value.label,
          value: {
            place_id: '',
          },
        },
      ]);
    }
  };

  const handleLoadFailed = gpaError => {
    // eslint-disable-next-line no-console
    console.error('Could not initialize react-google-places-autocomplete', gpaError);
  };

  const formatOptionLabel = (rawLabel, rawValue) => {
    const { label } = rawLabel;
    const { inputValue: term } = rawValue;
    const labelText = label || '';
    return <Highlighter searchWords={[term]} textToHighlight={labelText} autoEscape />;
  };

  const customStyles = {
    clearIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? theme?.colour?.main : 'inherit',
    }),
    container: () => ({
      background: '#fff',
    }),
    control: provided => ({
      ...provided,
      display: 'inline-block',
      width: '100%',
      borderRadius: '8px',
    }),
    crossIcon: () => ({}),
    downChevron: () => ({}),
    dropdownIndicator: () => ({
      display: 'none',
    }),
    group: () => ({}),
    groupHeading: () => ({}),
    indicatorsContainer: () => ({
      position: 'absolute',
      top: '7px',
      right: '2px',
      cursor: 'pointer',
      backgroundColor: '#fff',
      display: inputValue ? 'inline-block' : 'none',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    input: () => ({
      width: '100%',
      height: '50px',
      padding: '8px 10px',
      fontSize: '16px',
      background: 'inherit',
      lineHeight: '32px',
      color: '#262626',
    }),
    loadingIndicator: () => ({
      display: 'none',
    }),
    loadingMessage: () => ({
      fontSize: '14px',
      marginTop: '10px',
      textAlign: 'center',
    }),
    menu: () => ({
      top: '100%',
      backgroundColor: '#fff',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
      boxShadow: '0 2px 4px #ccc',
      margin: '1px 0 0 0',
      position: 'absolute',
      width: '100%',
      zIndex: '1',
      boxSizing: 'border-box',
    }),
    menuList: () => ({}),
    menuPortal: () => ({}),
    multiValue: () => ({}),
    multiValueContainer: () => ({}),
    multiValueLabel: () => ({}),
    multiValueRemove: () => ({}),
    noOptionsMessage: () => ({}),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#f2f2f2' : '#fff',
    }),
    placeholder: () => ({
      display: 'none',
    }),
    selectContainer: () => ({}),
    singleValue: () => ({
      color: disabled ? '#e0e0e0' : '#262626',
      margin: '0',
      maxWidth: 'calc(100% - 45px)',
      overflow: 'hidden',
      position: 'absolute',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      top: '50%',
      transform: 'translateY(-50%)',
      boxSizing: 'border-box',
      left: '10px',
    }),
    valueContainer: () => ({
      position: 'relative',
      padding: '0',
    }),
  };

  const focusInput = () => {
    const inputElement = document.getElementById(inputId);
    simulateMouseClick(inputElement);
  };

  const handleCompleteAddress = () => {
    const { google } = window;
    if (!google?.maps?.places?.AutocompleteService) return null;
    const service = new window.google.maps.places.AutocompleteService();
    service.getQueryPredictions({ input: addressAsTyped }, result => {
      if (result && result.length > 0) {
        const predictions = result.map(item => {
          const converted = convertToGoogle(item.description);
          return converted;
        });
        const joinedOptions = joinSuggestions(backendSuggestions, addressAsTyped, predictions);
        setDefaultOptions(joinedOptions);
      }
      focusInput();
    });
    return null;
  };

  const renderError = () => {
    if (error.hasError) {
      return (
        <>
          {addressAsTyped && (
            <button type="button" className={style['btn-complete-address']} onClick={handleCompleteAddress}>
              {t('google_address_autocomplete.complete_address')}
            </button>
          )}

          <div className={style.error}>{error.message}</div>
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    const parseBackendSuggestions = data => {
      const hasBackendSuggestions =
        data?.source === autofillSource.EMAIL_RESPONSE &&
        data?.addressSuggestions &&
        Object.values(data.addressSuggestions).length > 0;
      if (hasBackendSuggestions) {
        const convertedToGoogle = convertToGoogle(data.addressSuggestions[0].singleLine);
        const convertedToAutocompleteValue = {
          line2: data.addressSuggestions[0].line2,
          city: data.addressSuggestions[0].city,
          company: data.addressSuggestions[0].company,
          country: data.addressSuggestions[0].country,
          region: data.addressSuggestions[0].region,
          regionCode: data.addressSuggestions[0].regionCode,
          line1: data.addressSuggestions[0].line1,
          singleLine: data.addressSuggestions[0].singleLine,
          postal: data.addressSuggestions[0].postal,
        };
        setBackendSuggestions(convertedToGoogle);
        setDefaultOptions(convertedToGoogle);
        setValue(convertedToGoogle);
        onSelectCb({
          valueGoogle: convertedToGoogle,
          valueAddress: convertedToAutocompleteValue,
          action: 'nf-autofill',
        });
      }
    };
    const triggerAutofillChange = data => {
      const c1 = data?.source === autofillSource.MANUAL_ADDRESS;
      const c2 = data?.source === autofillSource.EMAIL_RESPONSE && value?.label === undefined;
      const shouldChange = () => c1 || c2;
      if (shouldChange()) {
        const rawAction = {
          action: 'nf-autofill',
        };
        handleChange(data, rawAction);
      }
    };
    const autofillEffect = () => {
      triggerAutofillChange(autofillAddress);
      parseBackendSuggestions(autofillAddress);
    };
    autofillEffect();
  }, [autofillAddress]);

  useEffect(() => {
    const resetEffect = () => {
      if (triggerReset) {
        handleReset();
      }
    };
    resetEffect();
  }, [triggerReset]);

  useEffect(() => {
    const focusEffect = () => {
      if (triggerFocus) {
        focusInput();
      }
    };
    focusEffect();
  }, [triggerFocus]);

  useEffect(() => {
    const validationEffect = () => {
      if (triggerValidation) {
        validateAndReturn(addressAsTyped, validationSource.FROM_SUBMIT);
      }
    };
    validationEffect();
  }, [triggerValidation]);

  return (
    <div
      className={classNames({
        'nf-google-has-error': error.hasError,
        'nf-google-gap': !error.hasError,
        'nf-google-has-highlight': warning && !error.hasError,
        'nf-google-wrapper': true,
      })}
    >
      <GooglePlacesAutocomplete
        apiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
        onLoadFailed={gpaError => handleLoadFailed(gpaError)}
        selectProps={{
          'aria-label': placeholder,
          blurInputOnSelect: true,
          className: classNames({
            'nf-google-is-focused': isFocused,
            'nf-google-is-value': addressAsTyped || value?.label,
          }),
          classNamePrefix: 'nf-google',
          components: {
            NoOptionsMessage,
            Menu,
            Input,
          },
          defaultOptions,
          formatOptionLabel,
          isClearable: true,
          isDisabled: disabled,
          isMulti: false,
          onChange: handleChange,
          onFocus: handleFocus,
          onInputChange: handleInputChange,
          onBlur: () => setIsFocused(false),
          styles: customStyles,
          value,
          inputId,
          inputValue: addressAsTyped,
          nfAutocompleteId: `${inputId} address-line1`,
          nfTheme: { theme },
          nfSetAllowEnterManually: isVisible => setAllowEnterManually(isVisible),
          nfTranslation: {
            cant_find_your_address: t('google_address_autocomplete.cant_find_your_address'),
            enter_manually: t('google_address_autocomplete.enter_manually'),
            powered_by_google: t('google_address_autocomplete.powered_by_google'),
          },
          placeholder,
          theme: {
            colors: {
              primary: theme?.colour?.main,
              neutral10: '#ccc',
              neutral20: '#aaa',
            },
          },
        }}
      />

      <label className={style.label}>
        <span className={style.placeholder}>{placeholder}</span>
        <span
          className={style['placeholder-top']}
          style={{ color: (isFocused && theme?.colour?.main) || (error.hasError && '#eb001b') || '' }}
        >
          {placeholderTop}
        </span>
      </label>
      {renderError()}
    </div>
  );
};

GoogleAddressAutocomplete.propTypes = {
  additionalData: PropTypes.oneOfType([PropTypes.object]),
  addressAsTyped: PropTypes.string,
  afterValidationCb: PropTypes.func,
  autofillAddress: PropTypes.oneOfType([PropTypes.object]),
  disabled: PropTypes.bool.isRequired,
  hasSuggestions: PropTypes.bool,
  inputError: PropTypes.oneOfType([PropTypes.object]),
  inputId: PropTypes.string.isRequired,
  inputValue: PropTypes.oneOfType([PropTypes.object]),
  inputWarning: PropTypes.bool,
  onSelectCb: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  placeholderTop: PropTypes.string.isRequired,
  setAddressAsTyped: PropTypes.func.isRequired,
  setAllowEnterManually: PropTypes.func.isRequired,
  setShouldOpenAdditionalData: PropTypes.func,
  theme: PropTypes.oneOfType([PropTypes.object]),
  triggerFocus: PropTypes.number,
  triggerReset: PropTypes.number,
  triggerValidation: PropTypes.number,
};

export default GoogleAddressAutocomplete;
