import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import usePlatform, { defaultUrl } from '../../commons/hooks/use-platform';
import { currentBaseUrl } from '../../helpers/urlConst';
import DataContext from '../../store/dataContext';
import UiContext from '../../store/uiContext';

import { ReactComponent as First } from './icon/1.svg';
import { ReactComponent as Second } from './icon/2.svg';
import { ReactComponent as Third } from './icon/3.svg';
import { ReactComponent as Fourth } from './icon/4.svg';

import './style.scss';

const OrderSubmit = () => {
  const { isOrderSubmitting, shouldRedirectOnError, storeUrl, orderSubmitInfo } = useContext(DataContext);
  const { done } = useContext(UiContext);

  // here you can manually change duration of animation, there is only one rule:
  // duration of animation cannot be less than forth seconds
  const animationDuration = 15;

  const { t } = useTranslation();
  const { isCommercecloud } = usePlatform();

  const [showAnimationStep, setShowAnimationStep] = useState('first');

  const innerStepDuration = animationDuration / 4;
  const firstDelay = (animationDuration / 4) * 1000;
  const secondDelay = (animationDuration / 4) * 2 * 1000;
  const thirdDelay = (animationDuration / 4) * 3 * 1000;

  const defaultDuration = () => {
    setTimeout(() => {
      setShowAnimationStep('second');
    }, firstDelay);
    setTimeout(() => {
      setShowAnimationStep('third');
    }, secondDelay);
    setTimeout(() => {
      setShowAnimationStep('fourth');
    }, thirdDelay);
  };

  const resetFunc = () => {
    setShowAnimationStep('first');
  };

  useEffect(() => {
    // Condition was updated according to the checkout-2042
    if (isOrderSubmitting) {
      defaultDuration();
    } else {
      resetFunc();
    }
    return () => resetFunc();
  }, [isOrderSubmitting]);

  const renderInProgress = () => (
    <div className={`order-submit ${showAnimationStep}`}>
      <h1>{t('order_submit.hold_on_a_moment')}</h1>
      <h2>{t('order_submit.while_we_process_your_order')}</h2>
      <div className="animation-wrapper">
        <div className="animation-wrapper-block">
          <img
            className="spiner"
            alt="Loading..."
            src={`${currentBaseUrl()}loaders/spinner-blue.png`}
            draggable={false}
          />
          <div
            className="icons-wrapper"
            style={{
              animationDuration: `${innerStepDuration}s`,
            }}
          >
            <First
              style={{
                animationDuration: `${innerStepDuration}s`,
              }}
            />
            <Second
              style={{
                animationDuration: `${innerStepDuration}s`,
              }}
            />
            <Third
              style={{
                animationDuration: `${innerStepDuration}s`,
              }}
            />
            <Fourth
              style={{
                animationDuration: `${innerStepDuration}s`,
              }}
            />
          </div>
        </div>
        <div
          className="text-wrapper"
          style={{
            animationDuration: `${innerStepDuration}s`,
          }}
        >
          <p
            style={{
              animationDuration: `${innerStepDuration}s`,
            }}
          >
            {t('order_submit.receiving_order_info')}
          </p>
          <p
            style={{
              animationDuration: `${innerStepDuration}s`,
            }}
          >
            {t('order_submit.confirming_payment')}
          </p>
          <p
            style={{
              animationDuration: `${innerStepDuration}s`,
            }}
          >
            {t('order_submit.were_almost_done')}
          </p>
          <p
            style={{
              animationDuration: `${innerStepDuration}s`,
            }}
          >
            {t('order_submit.your_all_set')}
          </p>
        </div>
      </div>
    </div>
  );

  const renderError = () => {
    setTimeout(() => {
      if (isCommercecloud) {
        window.parent.postMessage('OPEN_DEFAULT_CHECKOUT', '*');
      } else {
        window.top.location.href = `${storeUrl}/${defaultUrl}?preventIframeLoad=true`;
      }
    }, 2000);
    return (
      <div className="order-submit">
        <h1 className="error-title">{t('order_submit.sorry_something_went_wrong')}</h1>
        <h2>{t('order_submit.redirecting')}</h2>
        <div className="animation-wrapper">
          <div className="animation-wrapper-block">
            <img
              className="spiner"
              alt={t('order_submit.redirecting')}
              src={`${currentBaseUrl()}loaders/spinner-blue.png`}
              draggable={false}
            />
          </div>
        </div>
      </div>
    );
  };

  const chooseWhatToRender = () => {
    if (shouldRedirectOnError) {
      return <div className="order-submit-wrapper">{renderError()}</div>;
    }
    if ((isOrderSubmitting && !done) || (isOrderSubmitting && orderSubmitInfo?.redirectUrl)) {
      if (orderSubmitInfo?.redirectUrl) {
        window.top.location = storeUrl + orderSubmitInfo?.redirectUrl;
      }
      return <div className="order-submit-wrapper">{renderInProgress()}</div>;
    }
    return null;
  };

  return chooseWhatToRender();
};

export default OrderSubmit;
