import { rest } from 'msw';

import {
  mock_smarty_autocomplete_01,
  mock_smarty_autocomplete_02,
  mock_smarty_autocomplete_03,
  mock_smarty_autocomplete_04,
  mock_smarty_usstreet_01,
  mock_smarty_usstreet_02,
  mock_whoapi_01,
  mock_whoapi_02,
  mock_create_cart_with_bopis,
} from './mocked-responses';

export const smarty_autocomplete_01 = rest.get(
  /us-autocomplete-pro.api.smartystreets.com\/lookup(.*)/,
  async (req, res, ctx) => res(ctx.status(200), ctx.json(mock_smarty_autocomplete_01)),
);

export const smarty_autocomplete_02 = rest.get(
  /us-autocomplete-pro.api.smartystreets.com\/lookup(.*)/,
  async (req, res, ctx) => res(ctx.status(200), ctx.json(mock_smarty_autocomplete_02)),
);

export const smarty_autocomplete_03 = rest.get(
  /us-autocomplete-pro.api.smartystreets.com\/lookup(.*)/,
  async (req, res, ctx) => res(ctx.status(200), ctx.json(mock_smarty_autocomplete_03)),
);

export const smarty_autocomplete_03_04 = rest.get(
  /us-autocomplete-pro.api.smartystreets.com\/lookup(.*)/,
  async (req, res, ctx) => {
    const hasSelectedParam = req.url.searchParams.has('selected');
    const mockResponse = hasSelectedParam ? mock_smarty_autocomplete_04 : mock_smarty_autocomplete_03;
    return res(ctx.status(200), ctx.json(mockResponse));
  },
);

export const smarty_usstreet_01 = rest.get(
  /us-street.api.smartystreets.com\/street-address(.*)/,
  async (req, res, ctx) => res(ctx.status(200), ctx.json(mock_smarty_usstreet_01)),
);

export const smarty_usstreet_02 = rest.get(
  /us-street.api.smartystreets.com\/street-address(.*)/,
  async (req, res, ctx) => res(ctx.status(200), ctx.json(mock_smarty_usstreet_02)),
);

export const whoapi = rest.get(/api.whoapi.com(.*)/, async (req, res, ctx) => {
  const domain = req.url.searchParams.get('domain');
  const mockResponse = domain ? mock_whoapi_02 : mock_whoapi_01;
  return res(ctx.status(200), ctx.json(mockResponse));
});

export const create_cart_with_bopis = rest.post(/\/api\/v1\/merchants\/(.*)\/create_cart/, async (req, res, ctx) =>
  res(ctx.status(200), ctx.json(mock_create_cart_with_bopis)),
);

export const handlers = [smarty_autocomplete_03_04, create_cart_with_bopis];
