export function waitForElm(selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
    return true;
  });
}

export function waitForElms(list) {
  const selectors = list.map(item => `#${item}`);
  return new Promise(resolve => {
    const elements = selectors.map(selector => document.querySelector(selector));
    if (elements.every(el => el)) {
      return resolve(elements);
    }

    const observer = new MutationObserver(() => {
      const foundElements = selectors.map(selector => document.querySelector(selector));
      if (foundElements.every(el => el)) {
        resolve(foundElements);
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
    return true;
  });
}
