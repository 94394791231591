import _ from 'lodash';

const autofillParser = data => {
  const { suggestions } = data;
  const isAvailable = _.isObject(suggestions);
  const suggestion = suggestions?.[0];
  const name = suggestion?.address?.name || '';
  const billName = suggestion?.billing?.name;
  const billPhone = suggestion?.phone;
  const cardId = suggestion?.payment?.id;

  return {
    isAvailable,
    name,
    address1: suggestion?.address?.singleLine,
    address2: suggestion?.address?.line2,
    company: suggestion?.address?.company,
    address: suggestion?.address,
    billing: suggestion?.billing,
    billName,
    billPhone,
    cardId,
  };
};

export default autofillParser;
