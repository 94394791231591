const responsesKey = 'apiResponses';
const orderDataKey = 'orderData';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const cartId = urlParams.get('cartId');

const saveData = (baseKey, key, data) => {
  try {
    const savedData = JSON.parse(window.localStorage.getItem(cartId)) || {};
    const parsedData = savedData && savedData[baseKey] ? JSON.parse(savedData[baseKey]) : {};
    parsedData[key] = data;
    const dataToken = {
      [baseKey]: JSON.stringify(parsedData),
    };
    window.localStorage.setItem(cartId, JSON.stringify(dataToken));
    return true;
  } catch (e) {
    return false;
  }
};

const retrieveData = (baseKey, key) => {
  try {
    const savedData = JSON.parse(window.localStorage.getItem(cartId)) || {};
    const parsedData = JSON.parse(savedData[baseKey]) || {};
    return parsedData[key];
  } catch (e) {
    return null;
  }
};

export const storeData = (key, data) => saveData(orderDataKey, key, data);

export const getStoredData = dataName => retrieveData(orderDataKey, dataName);

export const getStoredOrBlankData = key => getStoredData(key) ?? '';

export const getStoredOrEmptyObject = key => getStoredData(key) ?? {};

export const getStoredOrEmptyCollection = key => getStoredData(key) ?? [];

// eslint-disable-next-line consistent-return
export const clearStoredData = () => {
  try {
    window.localStorage.setItem(orderDataKey, JSON.stringify({}));
  } catch (e) {
    return false;
  }
};

export const storeApiResponse = (key, data) => saveData(responsesKey, key, data);

export const getApiResponse = key => retrieveData(responsesKey, key);
