import React, { useContext } from 'react';

import UiContext from '../../store/uiContext';

import CustomModal from './CustomModal';
import Privacy from './Privacy';
import Terms from './Terms';
import About from './About';

const TermsPrivacyAbout = () => {
  const uiContext = useContext(UiContext);

  return (
    <CustomModal
      activeItem={uiContext.isCheckoutTermsModalOpen}
      handleClose={uiContext.setIsCheckoutTermsModalOpen}
      setActive={uiContext.setIsCheckoutTermsModalOpen}
    >
      <Terms title="Terms of Use" setActive={uiContext.setIsCheckoutTermsModalOpen} />
      <Privacy title="Privacy Policy" setActive={uiContext.setIsCheckoutTermsModalOpen} />
      <About title="About Checkout" setActive={uiContext.setIsCheckoutTermsModalOpen} />
    </CustomModal>
  );
};

export default TermsPrivacyAbout;
