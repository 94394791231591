import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { platformList } from '../../../commons/constants';
import { platformValue } from '../../../helpers/prelaunchData';
import DataContext from '../../../store/dataContext';
import { selectCart } from '../../../store/slice';

import { setCurrency, selectCurrency } from '../slice';

const useCurrency = () => {
  const { shopperCurrency } = useContext(DataContext);

  const dispatch = useDispatch();
  const currencyState = useSelector(selectCurrency);
  const cartData = useSelector(selectCart);

  const parseCurrency = detailsApiResponse => {
    if (platformValue !== platformList.bigcommerce && detailsApiResponse?.cart?.currency) {
      dispatch(setCurrency(detailsApiResponse.cart.currency));
    }
  };

  /**
   * Responsible for keeping compatibility between the old shopperCurrency object
   * and the new currencyState from Redux store
   * @returns {object} In the old shape (i.e. shopperCurrency shape)
   */
  const getShoppercurrency = () => {
    if (platformValue === platformList.bigcommerce && shopperCurrency) {
      if (shopperCurrency?.baseCode) {
        return shopperCurrency;
      }
      const {
        code,
        decimalPlaces,
        decimalSeparator,
        exchangeRate,
        isTransactional,
        symbol,
        symbolLocation,
        thousandsSeparator,
      } = shopperCurrency;
      return {
        baseCode: code || 'USD',
        displayCode: code || 'USD',
        precision: decimalPlaces || '2',
        separator: {
          radix: decimalSeparator || '.',
          thousand: thousandsSeparator || ',',
        },
        symbol: {
          position: symbolLocation || 'left',
          token: symbol || '$',
        },
        exchangeRate: exchangeRate || 1,
        isTransactional,
      };
    }
    if (cartData?.currency) {
      return cartData?.currency;
    }
    return {
      baseCode: currencyState.displayCurrency || 'USD',
      displayCode: currencyState.displayCurrency || 'USD',
      precision: currencyState.precision || '2',
      separator: {
        radix: currencyState.separator.radix || '.',
        thousand: currencyState.separator.thousand || ',',
      },
      symbol: {
        position: currencyState.symbol.position || 'left',
        token: currencyState.symbol.token || '$',
      },
      exchangeRate: currencyState.exchangeRate || 1,
      isTransactional: true,
    };
  };

  return { parseCurrency, getShoppercurrency };
};

export default useCurrency;
