import { platformCartIdValue, accessTokenValue, customerIsLoggedInValue } from '../../../helpers/prelaunchData';

export const postMessage = message => {
  window.parent.postMessage(message, '*');
};

export const getCreateCartPayload = () => {
  const payload = {
    platformCartId: platformCartIdValue,
  };
  if (customerIsLoggedInValue !== '0') {
    return {
      ...payload,
      customerToken: accessTokenValue,
    };
  }
  return payload;
};

export const isUserAuthenticated = () => {
  const isUserLoggedIn = customerIsLoggedInValue !== '0';
  return isUserLoggedIn;
};
