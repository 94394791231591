import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import UiContext from '../../store/uiContext';
import { selectCart } from '../../store/slice';
import { formatDate } from '../../helpers/format';
import { CROSS_MESSAGE } from '../../commons/utils/cross-origin-communication';
import { storeUrlValue } from '../../helpers/prelaunchData';
import { currentBaseUrl } from '../../helpers/urlConst';

import './style.scss';

const PreOrderModal = () => {
  const { t } = useTranslation();
  const { openPreOrder, setOpenPreOrder } = useContext(UiContext);
  const button = useRef(null);
  const cartData = useSelector(selectCart);
  const portalSettings = cartData?.merchant?.settings;

  const handleSubmit = () => {
    openPreOrder.callback(openPreOrder.payload);
    setOpenPreOrder(false);
  };

  const handleEditCart = () => {
    window.parent.postMessage(CROSS_MESSAGE.REDIRECT_EDIT_CART, storeUrlValue);
  };

  const closePreOrder = () => {
    setOpenPreOrder(false);
  };

  const getMessage = () => {
    const message = cartData?.merchant?.settings?.preOrder?.message;
    if (message != null) return message;

    const format = cartData?.merchant?.settings?.preOrder?.format || 'MMMM YYYY';
    const furthestPreOrderDate = cartData?.merchant?.items?.reduce((maxDate, item) => {
      const preOrderDate = item?.preOrder?.expectedAvailability;
      if (preOrderDate) {
        const currentDate = new Date(preOrderDate);
        return currentDate > maxDate ? currentDate : maxDate;
      }
      return maxDate;
    }, new Date(0));

    const date = formatDate(furthestPreOrderDate, format);
    const dataString = date.charAt(0).toUpperCase() + date.slice(1);
    return t('order_submit.expected') + dataString;
  };

  const preOrderLabel = portalSettings?.preOrder?.name ?? t('cart.pre_order');

  return (
    <div className="order-submit-wrapper">
      <div className="pre-order-modal">
        <button className="close-wrapper-btn" type="button" onClick={closePreOrder}>
          <img alt="close-icon" src={`${currentBaseUrl()}assets/close.svg`} />
          <span>{t('checkout.close_checkout')}</span>
        </button>
        {portalSettings?.preOrder?.title ? (
          <>
            <div className="pre-order-title">
              <p
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(portalSettings.preOrder.title) }}
              />
            </div>
            <br />
          </>
        ) : (
          <h1>{t('order_submit.pre_order_items', { preOrderLabel })}</h1>
        )}
        {portalSettings?.preOrder?.body ? (
          <div className="pre-order-body">
            <p
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(portalSettings.preOrder.body) }}
            />
          </div>
        ) : (
          <>
            <div>
              <h2>
                {t('order_submit.some_items')}
                <b>{t('order_submit.pre_order', { preOrderLabel })}</b>
                {t('order_submit.will_ship')}
              </h2>
            </div>
            <div>{getMessage()}</div>
          </>
        )}
        <div className="button-container">
          <div role="button" tabIndex="0" onKeyDown={null} onClick={handleEditCart}>
            {t('order_submit.edit')}
          </div>
          <button
            className="submit-button"
            onClick={handleSubmit}
            type="button"
            ref={button}
            style={{
              backgroundColor: portalSettings?.theme?.colour?.main,
              color: portalSettings?.theme?.colour?.text,
            }}
          >
            {portalSettings?.preOrder?.title
              ? t('submit_btn.submit_order')
              : t('order_submit.submit_pre_order', { preOrderLabel })}
          </button>
        </div>
        {portalSettings?.preOrder?.footer && (
          <div className="pre-order-footer">
            <i
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(portalSettings?.preOrder?.footer) }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PreOrderModal;
