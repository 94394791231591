import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { allCountries } from 'country-region-data';

import DataContext from '../../../../store/dataContext';
import UiContext from '../../../../store/uiContext';

import InputWrapper from '../../../components/input-wrapper';

import CountryList from '../country-list';
import StateList from '../state-list';

import { status, capitalizeText, emptyManualAddress } from './utils';
import { parseGeoCodeData } from './api';

import style from './index.module.scss';

const ManualAddress = ({
  additionalData,
  addressAsTyped,
  addressLabel,
  handleCancelCb,
  handleSubmitCb,
  allowEnterManually,
  textFillYourAddress,
  theme,
  initialManualData,
  triggerReset,
  checkoutData,
}) => {
  const { t } = useTranslation();
  const { formStatus, setFormStatus } = useContext(UiContext);
  const { cartDetails } = useContext(DataContext);

  const [manualData, setManualData] = useState(emptyManualAddress);
  const [countries, setCountries] = useState([]);

  const handleReset = () => {
    setManualData(emptyManualAddress);
    setFormStatus(status.IDLE);
  };

  const fieldName = {
    apartmentUnit: t('manual_address.apartment_unit'),
    companyName: t('manual_address.company_name'),
    city: t('manual_address.city'),
    country: t('manual_address.country'),
    postalCode: t('manual_address.postal_code'),
    state: manualData.country === 'US' ? t('manual_address.state') : t('manual_address.province'),
    street: addressLabel,
  };

  const fieldsToValidate = ['street', 'city', 'country', 'state', 'postalCode'];

  const getErrors = data => {
    const result = {};
    if (formStatus === status.SUBMITTED) {
      for (const key of fieldsToValidate) {
        if (!data[key]) result[key] = `${fieldName[key]} ${t('manual_address.is_required')}`;
      }
    }
    return result;
  };

  const errors = getErrors(manualData);

  const isValid = () => {
    let hasError = false;
    for (const key of fieldsToValidate) {
      if (key !== 'apartmentUnit' && key !== 'companyName' && !manualData[key]) {
        hasError = true;
      }
    }
    return !hasError && Object.keys(errors).length === 0;
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormStatus(status.SUBMITTED);
    if (isValid() && typeof handleSubmitCb === 'function') {
      const payload = {
        apartmentUnit: manualData.apartmentUnit,
        city: manualData.city,
        companyName: manualData.companyName,
        country: manualData.country,
        state: manualData.state,
        region: manualData.region,
        regionName: manualData.regionName,
        street: manualData.street,
        singleLine: `${manualData.street}, ${manualData.city}, ${manualData.state}, ${manualData.postalCode}, ${manualData.country}`, // eslint-disable-line max-len
        postalCode: manualData.postalCode,
      };
      handleSubmitCb(payload);
    }
  };

  const handleChange = e => {
    if (e.target.id !== 'country') {
      setManualData({
        ...manualData,
        [e.target.id]: e.target.value,
      });
    } else {
      setManualData({
        ...manualData,
        country: e.target.value,
        state: '',
        city: '',
        postalCode: '',
      });
    }
  };

  const handleChangeRegion = val => {
    setManualData({
      ...manualData,
      state: val,
      regionName: countries.filter(item => item[1] === manualData.country)[0][2].filter(item => item[1] === val)[0][0],
    });
  };

  const handlePostalCodeBlur = async e => {
    const postalCode = e.target.value;
    if (postalCode) {
      const geoCodeData = await parseGeoCodeData(postalCode);
      if (geoCodeData && Object.values(geoCodeData).length > 0) {
        setManualData({
          ...manualData,
          country: geoCodeData.country,
          state: geoCodeData?.regionCode,
          regionName: geoCodeData?.regionName,
          city: capitalizeText(geoCodeData.city),
        });
      }
    }
  };

  const handleCancel = () => {
    setFormStatus(status.IDLE);
    if (typeof handleCancelCb === 'function') {
      handleCancelCb(false);
    }
  };

  const isContiguousTerritory = () => {
    const contiguousTerritories = ['AS', 'AA', 'AE', 'AP', 'FM', 'GU', 'MH', 'MP', 'PW', 'PR', 'VI'];
    const isUsTerritory = contiguousTerritories.includes(initialManualData.country);
    return isUsTerritory ? 'US' : initialManualData.country;
  };

  useEffect(() => {
    const useAlreadyTypedValues = () => {
      const typedValues = {};
      const beAddress = checkoutData?.address;
      if (beAddress?.hash && beAddress?.singleLine === addressAsTyped) {
        typedValues.city = beAddress?.city;
        typedValues.country = beAddress?.country;
        typedValues.postalCode = beAddress?.postal;
        typedValues.state = beAddress?.regionCode;
        typedValues.street = beAddress?.line1;
        typedValues.singleLine = beAddress?.singleLine;
      } else if (addressAsTyped) {
        typedValues.city = '';
        typedValues.country = 'US';
        typedValues.postalCode = '';
        typedValues.state = '';
        typedValues.street = addressAsTyped;
        typedValues.singleLine = '';
      }
      if (additionalData?.apartmentUnit) {
        typedValues.apartmentUnit = additionalData?.apartmentUnit;
      }
      if (additionalData?.companyName) {
        typedValues.companyName = additionalData?.companyName;
      }
      setManualData({
        ...manualData,
        ...typedValues,
      });
    };
    useAlreadyTypedValues();
  }, [addressAsTyped, additionalData, allowEnterManually]);

  useEffect(() => {
    const resetEffect = () => {
      if (triggerReset) {
        handleReset();
      }
    };
    resetEffect();
  }, [triggerReset]);

  useEffect(() => {
    const initialManualDataEffect = () => {
      const hasInitialManualData = initialManualData && Object.values(initialManualData).length > 0;
      if (hasInitialManualData) {
        setManualData({
          ...emptyManualAddress,
          ...initialManualData,
          country: isContiguousTerritory(),
        });
        setFormStatus(status.SUBMITTED);
      }
    };
    initialManualDataEffect();
  }, [initialManualData]);

  useEffect(() => {
    const storeCountries = () => {
      if (cartDetails?.allowedShippingDestinations?.length > 0) {
        const filteredCountries = allCountries.filter(item =>
          cartDetails?.allowedShippingDestinations.find(value => value[1].toLowerCase() === item[1]),
        );
        setCountries(filteredCountries);
      } else {
        setCountries(allCountries);
      }
    };
    storeCountries();
  }, [cartDetails]);

  return allowEnterManually ? (
    <div className={style['manual-address']}>
      <p>{t('manual_address.enter_address_manually')}</p>
      <small>{textFillYourAddress}</small>
      <form onSubmit={handleSubmit}>
        <CountryList
          error={errors.country}
          handleChange={handleChange}
          inputId="country"
          theme={theme}
          value={manualData.country}
          countries={countries}
        />
        <InputWrapper
          classNameInput={classNames({
            'has-error': errors.street,
            'is-value': manualData.street,
          })}
          error={errors.street}
          inputId="street"
          onChange={handleChange}
          placeholder={`* ${fieldName.street}`}
          placeholderTop={fieldName.street}
          theme={theme}
          type="text"
          value={manualData.street}
        />
        <div className={style['custom-grid']}>
          <InputWrapper
            classNameInput={classNames({
              'is-value': manualData.apartmentUnit,
            })}
            classNameWrapper={style['flex-1']}
            inputId="apartmentUnit"
            onChange={handleChange}
            placeholder={fieldName.apartmentUnit}
            placeholderTop={fieldName.apartmentUnit}
            theme={theme}
            type="text"
            value={manualData.apartmentUnit}
          />
          <InputWrapper
            classNameInput={classNames({
              'is-value': manualData.companyName,
            })}
            classNameWrapper={style['flex-1']}
            inputId="companyName"
            onChange={handleChange}
            placeholder={fieldName.companyName}
            placeholderTop={fieldName.companyName}
            theme={theme}
            type="text"
            value={manualData.companyName}
          />
        </div>
        <InputWrapper
          classNameInput={classNames({
            'has-error': errors.postalCode,
            'is-value': manualData.postalCode,
          })}
          error={errors.postalCode}
          inputId="postalCode"
          onBlur={handlePostalCodeBlur}
          onChange={handleChange}
          placeholder={`* ${fieldName.postalCode}`}
          placeholderTop={fieldName.postalCode}
          theme={theme}
          type={manualData.country === 'US' ? 'number' : 'text'}
          value={manualData.postalCode}
        />
        <div className={style['custom-grid']}>
          <InputWrapper
            classNameInput={classNames({
              'has-error': errors.city,
              'is-value': manualData.city,
            })}
            error={errors.city}
            inputId="city"
            onChange={handleChange}
            placeholder={`* ${fieldName.city}`}
            placeholderTop={fieldName.city}
            theme={theme}
            type="text"
            value={manualData.city}
          />
          <StateList
            country={manualData.country}
            onChange={handleChangeRegion}
            value={manualData.state}
            error={errors.state}
            placeholder={`* ${fieldName.state}`}
            placeholderTop={fieldName.state}
            theme={theme}
          />
        </div>
        <div className={style['custom-grid']}>
          <button type="button" onClick={handleCancel} className={`${style['btn-cancel']} ${style['flex-1']}`}>
            {t('manual_address.cancel')}
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            className={`${style['btn-save']} ${style['flex-2']}`}
            style={{
              color: theme?.colour?.main,
              borderColor: theme?.colour?.main,
            }}
          >
            {t('manual_address.save_address')}
          </button>
        </div>
      </form>
    </div>
  ) : null;
};

ManualAddress.propTypes = {
  additionalData: PropTypes.oneOfType([PropTypes.object]),
  addressAsTyped: PropTypes.string,
  addressLabel: PropTypes.string.isRequired,
  handleCancelCb: PropTypes.func.isRequired,
  handleSubmitCb: PropTypes.func.isRequired,
  allowEnterManually: PropTypes.bool,
  textFillYourAddress: PropTypes.string.isRequired,
  checkoutData: PropTypes.oneOfType([PropTypes.object]),
  theme: PropTypes.oneOfType([PropTypes.object]),
  initialManualData: PropTypes.oneOfType([PropTypes.object]),
  triggerReset: PropTypes.number,
};

export default ManualAddress;
