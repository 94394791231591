import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js/max';

import { datadogRum } from '@datadog/browser-rum';
import usePortalSettings from '../../hooks/usePortalSettings';
import DataContext from '../../store/dataContext';
import useApiPostOrderError from '../../hooks/useApiPostOrderError';
import useErrors from '../../store/errors/useErrors';

const useBillingPhone = () => {
  const { portalSettings } = usePortalSettings();
  const dataContext = useContext(DataContext);
  const { hasPhoneError } = useApiPostOrderError();
  const { errors, addErrorToField, hasErrorOnField } = useErrors();
  const { t } = useTranslation();

  const { billingPhone } = dataContext;

  const validatePhone = phone => {
    try {
      const phoneLengthValMsg = validatePhoneNumberLength(phone);
      if (phoneLengthValMsg) return phoneLengthValMsg;
      return isValidPhoneNumber(phone) ? 'VALID_NUMBER' : 'INVALID_NUMBER';
    } catch (error) {
      datadogRum.addError(error);
      return 'INVALID_NUMBER';
    }
  };

  const isBillingPhoneOk = ({ shouldShowError } = { shouldShowError: true }) => {
    const hasPhoneRequiredAndValueIsEmpty =
      (portalSettings.contactPhone.isRequired && !billingPhone) || (dataContext.consent?.hasSms && !billingPhone);

    const hasPhoneRequiredAndInvlaidValue =
      (portalSettings.contactPhone.isRequired || dataContext.consent?.hasSms) &&
      billingPhone &&
      validatePhone(billingPhone) !== 'VALID_NUMBER';
    const phoneHasError = () =>
      // ApiPostOrderError and phone was not typed
      (hasPhoneError() && !billingPhone) ||
      // Phone is Required and there is no phone value
      hasPhoneRequiredAndValueIsEmpty ||
      // Local phone error and phone input field is visible
      (hasErrorOnField('billingPhone') && portalSettings.contactPhone.isVisible) ||
      // Phone is Required and phone value is invalid. This check is necessary because
      // the onBlur event could happen after the click is registered on the submit button
      hasPhoneRequiredAndInvlaidValue;

    if (phoneHasError()) {
      if (shouldShowError) {
        addErrorToField({
          billingPhone: {
            hasHighlight: false,
            message: errors.billingPhone.message || t('payment_add.please_enter_valid_phone_number'),
          },
        });
      }
      return false;
    }
    return true;
  };

  return { isBillingPhoneOk, validatePhone };
};

export default useBillingPhone;
