import React from 'react';
import { useTranslation } from 'react-i18next';

import './style.scss';

const closeRedirect = () => {
  window.parent.postMessage('CLOSE_REDIRECT', '*');
};

const ContinueShoppingBtn = () => {
  const { t } = useTranslation();
  return (
    <button className="continue-shopping-btn" type="button" onClick={closeRedirect}>
      {t('order_display.continue_shopping')}
    </button>
  );
};

export default ContinueShoppingBtn;
