import React, { useContext, useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import useCartTotalsUtils from '../../hooks/useCartTotalsUtils';
import { currentPaymentMethod, setPaymentMethod } from '../../modules/payment/slice';
import { selectCheckout, selectApiStatus } from '../../store/slice';
import { selectBilling } from '../../modules/billing/slice';
import { useCheckEligibility } from '../../hooks/paymentMethods/useCheckEligibility';
import { useProduct } from '../../hooks/useProduct';
import { EVENT, on, off } from '../../commons/utils/custom-event';
import { paymentIdList } from '../../helpers/constants';
import DataContext from '../../store/dataContext';
import UiContext from '../../store/uiContext';
import { usePickup } from '../../modules/shipping/containers/pickup-in-store/use-pickup';

import { ReactComponent as ACHIcon } from './logos/ach.svg';
import { ReactComponent as AcimaIcon } from './logos/acima.svg';
import { ReactComponent as AffirmIcon } from './logos/affirm.svg';
import { ReactComponent as AfterPayIcon } from './logos/afterpay.svg';
import { ReactComponent as AmazonPayIcon } from './logos/amazonpay.svg';
import { ReactComponent as ApplePay } from './logos/applepay.svg';
import { ReactComponent as CreditCardIcon } from './logos/credit_card.svg';
import { ReactComponent as GooglePayIcon } from './logos/googlepay.svg';
import { ReactComponent as KlarnaIcon } from './logos/klarna.svg';
import { ReactComponent as CreditKeyIcon } from './logos/creditkey.svg';
import { ReactComponent as CredovaIcon } from './logos/credova.svg';
import { ReactComponent as PayTomorrowIcon } from './logos/paytomorrow.svg';
import { ReactComponent as PayPalIcon } from './logos/paypal.svg';
import { ReactComponent as PurchaseIcon } from './logos/purchase.svg';
import { ReactComponent as SelectIcon } from './logos/select_icon.svg';
import { ReactComponent as SezzleIcon } from './logos/sezzle.svg';
import { ReactComponent as SnapFinanceIcon } from './logos/snapfinance.svg';
import { ReactComponent as VenmoIcon } from './logos/venmo.svg';

import './style.scss';

const PaymentMethods = () => {
  const { t } = useTranslation();
  const { checkoutStage, TO_USER_ADD } = useContext(UiContext);
  const { registeredPaymentMethods, moveToPlatform, updateIsAutofill } = useContext(DataContext);
  const checkoutData = useSelector(selectCheckout);
  const status = useSelector(selectApiStatus);
  const address = checkoutData?.address;
  const { address: billAddress } = useSelector(selectBilling);
  const { getOrderTotal } = useCartTotalsUtils();
  const cartTotals = getOrderTotal();

  const { checkMethod } = useCheckEligibility();
  const { allDigital } = useProduct();
  const selectedMethod = useSelector(currentPaymentMethod);
  const dispatch = useDispatch();

  const { isPickupActive } = usePickup();

  const icons = {
    [paymentIdList.ACH]: <ACHIcon />,
    [paymentIdList.ACIMA]: <AcimaIcon />,
    [paymentIdList.AFFIRM]: <AffirmIcon />,
    [paymentIdList.AFTERPAY]: <AfterPayIcon />,
    [paymentIdList.APPLEPAY]: <ApplePay />,
    [paymentIdList.GOOGLEPAY]: <GooglePayIcon />,
    [paymentIdList.AMAZONPAY]: <AmazonPayIcon />,
    [paymentIdList.PAYPAL]: <PayPalIcon />,
    [paymentIdList.VENMO]: <VenmoIcon />,
    [paymentIdList.PAYLATER]: <PayPalIcon />,
    [paymentIdList.KLARNA]: <KlarnaIcon />,
    [paymentIdList.CREDITKEY]: <CreditKeyIcon />,
    [paymentIdList.CREDOVA]: <CredovaIcon />,
    [paymentIdList.PAYTOMORROW]: <PayTomorrowIcon />,
    [paymentIdList.PURCHASE]: <PurchaseIcon />,
    [paymentIdList.SEZZLE]: <SezzleIcon />,
    [paymentIdList.SNAPFINANCE]: <SnapFinanceIcon />,
    [paymentIdList.VENMO]: <VenmoIcon />,
  };

  const moveToPlatformMethods = Object.entries(moveToPlatform || {})
    ?.filter(item => item[1])
    ?.filter(
      item =>
        !Object.keys(registeredPaymentMethods || {})
          ?.filter(i => registeredPaymentMethods[i]?.isEnabled)
          ?.includes(item[0]),
    )
    ?.map(item => item[0]);

  const options = () =>
    [
      {
        id: paymentIdList.CREDIT_CARD,
        label: t('payment_method.credit_card.label'),
        icon: <CreditCardIcon />,
        paymentLogo: <img alt="credit_card_image" src="payment-options/horizontal-buttons/credit_card.svg" />,
        available: true,
      },
      ...(Object.keys(registeredPaymentMethods || {})?.length > 0
        ? Object.keys(registeredPaymentMethods).map(item => {
            const method = item.toLowerCase();
            return {
              id: paymentIdList[item.toUpperCase()],
              label: t(`payment_method.${method}.label`),
              icon: icons[method] || <CreditCardIcon />,
              paymentLogo: <img alt={`${method}_image`} src={`payment-options/horizontal-buttons/${method}.svg`} />,
              available: registeredPaymentMethods[item]?.isEnabled && checkMethod(method?.toLowerCase()),
            };
          })
        : []),
      ...(moveToPlatformMethods?.length > 0
        ? moveToPlatformMethods.map(item => {
            if (item === 'applepay' && typeof ApplePaySession !== 'function') {
              return null;
            }
            return {
              id: paymentIdList[item.toUpperCase()],
              label: t(`payment_method.${item}.label`),
              icon: icons[item] || <CreditCardIcon />,
              paymentLogo: <img alt={`${item}_image`} src={`payment-options/horizontal-buttons/${item}.svg`} />,
              available: true,
            };
          })
        : []),
    ].filter(item => item?.available && item?.id);

  const [showPaymentItems, setShowPaymentItems] = useState(false);
  const [currentOption, setCurrentOption] = useState(undefined);

  useEffect(() => {
    if (updateIsAutofill) {
      setCurrentOption(options().find(value => value.id === selectedMethod));
    }
  }, [updateIsAutofill]);

  useEffect(() => {
    const isPreSelectedMethod = options().find(value => value.id === selectedMethod);
    if (!currentOption && selectedMethod && isPreSelectedMethod) {
      // default implementation to be able to reopen the payment method
      setCurrentOption(isPreSelectedMethod);
    } else if (!currentOption && selectedMethod && !isPreSelectedMethod) {
      // implementation related to fix: CHKOUT-2046
      setCurrentOption(options()[0]);
      dispatch(setPaymentMethod(options()[0].id));
    }
  }, [registeredPaymentMethods, moveToPlatformMethods, selectedMethod, options]);

  const selectAndClose = id => {
    setShowPaymentItems(false);
    if (id === paymentIdList.PAYLATER) {
      dispatch(setPaymentMethod(paymentIdList.PAYLATER));
      setCurrentOption(options().find(value => value.id === paymentIdList.PAYLATER));
    } else if (id === paymentIdList.AMAZONPAY) {
      dispatch(setPaymentMethod(paymentIdList.AMAZONPAY));
      setCurrentOption(options().find(value => value.id === paymentIdList.AMAZONPAY));
    } else if (id === paymentIdList.VENMO) {
      dispatch(setPaymentMethod(paymentIdList.VENMO));
      setCurrentOption(options().find(value => value.id === paymentIdList.VENMO));
    } else {
      dispatch(setPaymentMethod(id));
      setCurrentOption(options().find(value => value.id === id));
    }
  };

  const handleClick = type => {
    selectAndClose(type);
  };

  const PaymentsWrapper = ({ selected }) => {
    const useOutsideAlerter = ref => {
      useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = event => {
          const preventActionClasses = [
            'payment_type_description',
            'select-icon',
            '-icon',
            'selected-icon',
            'icon-arrow',
            'option_btn',
          ];
          if (
            preventActionClasses.includes(event.target?.className) ||
            preventActionClasses.includes(event.target?.parentNode?.className) ||
            preventActionClasses.includes(event.target?.parentNode?.parentNode?.className)
          ) {
            return;
          }
          if (ref.current && !ref.current.contains(event.target)) {
            handleClick(options().find(item => selected === item.label)?.id);
          }
        };
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [ref]);
    };

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
      <div className="dropdown" ref={wrapperRef}>
        {options().map(item => (
          <div
            className={`payment_btn${selected === item.label ? ' option-selected' : ''}`}
            tabIndex="0"
            onKeyDown={null}
            key={item.label}
            role="button"
            onClick={() => handleClick(item.id)}
          >
            {item.icon}
            <p className="payment_title">{item.label}</p>
          </div>
        ))}
      </div>
    );
  };

  PaymentsWrapper.propTypes = {
    selected: PropTypes.string,
  };

  useEffect(() => {
    if (selectedMethod && selectedMethod === paymentIdList.PAYPAL) {
      dispatch(setPaymentMethod(paymentIdList.PAYPAL));
      setCurrentOption(options().find(value => value.id === paymentIdList.PAYPAL));
    } else if (selectedMethod && selectedMethod === paymentIdList.VENMO) {
      dispatch(setPaymentMethod(paymentIdList.VENMO));
      setCurrentOption(options().find(value => value.id === paymentIdList.VENMO));
    }
  }, []);

  useEffect(() => {
    const subscribeToResetCheckout = () => {
      on(EVENT.RESET_CHECKOUT, () => {
        if (selectedMethod) {
          setCurrentOption(options().find(value => value.id === selectedMethod));
        } else {
          setCurrentOption(options().find(value => value.id === paymentIdList.CREDIT_CARD));
        }
      });

      return () => {
        off(EVENT.RESET_CHECKOUT);
      };
    };
    subscribeToResetCheckout();
  }, []);

  if (
    options()?.length > 1 &&
    currentOption &&
    cartTotals > 0 &&
    (address || (allDigital() && billAddress) || isPickupActive())
  ) {
    const isDisabled = checkoutStage === TO_USER_ADD || status?.isLoading;
    return (
      <div className="payment_type_wrapper">
        <div className="dropdown_wrapper">
          <p className={`${'payment_type_title'} ${isDisabled ? 'c-disabled' : 'c-enabled'}`}>
            {t(`payment_method.payment`)}:
          </p>
          <button
            type="button"
            className={`${'option_btn'} ${isDisabled ? 'c-disabled' : 'c-enabled'}`}
            onClick={() => setShowPaymentItems(!showPaymentItems)}
            disabled={isDisabled}
          >
            <span className="selected-icon">{currentOption.icon}</span>
            <p className="payment_type_description">{currentOption.label}</p>
            <span className="icon-arrow">
              <SelectIcon className={showPaymentItems ? 'select-icon' : '-icon'} />
            </span>
          </button>
        </div>
        {showPaymentItems && <PaymentsWrapper selected={currentOption.label} />}
      </div>
    );
  }

  return null;
};

export default PaymentMethods;

PaymentMethods.propTypes = {
  flags: PropTypes.shape({
    'disable-paypal': PropTypes.bool,
    'disable-amazon': PropTypes.bool,
    'disable-venmo': PropTypes.bool,
  }),
};
