import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectPlugin } from '../../store/slice';
import UiContext from '../../store/uiContext';

import { getRandomValue } from './SpacialOfferUtils';
import SpecialOfferButton from './SpecialOfferButton';
import SpecialOfferBanner from './SpecialOfferBanner';

import styles from './index.module.scss';

const SpecialOffer = () => {
  const { done } = useContext(UiContext);
  const { ppo } = useSelector(selectPlugin);

  const [offeredItem, setOfferedItem] = useState(undefined);
  const [initTime, setInitTime] = useState(undefined);
  const [minutes, setMinutes] = useState(undefined);
  const [seconds, setSeconds] = useState(undefined);
  const [isAvailableTime, setIsAvailableTime] = useState(true);
  const [isAppliedOffer, setIsAppliedOffer] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);

  const timeLimit = ppo?.settings?.timeLimit;
  const ppoItems = ppo?.recommendation?.recommendedItems;
  const deadline = timeLimit * 1000 + 1000;
  const showSpecialOffer = !!(done && isAvailableTime && offeredItem);

  const setUpdatedTime = time => {
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  const updateOfferedItem = item => {
    setOfferedItem(item);
  };

  const getTime = () => {
    const time = initTime - Date.now();
    const timeIsOver = time < 1000;
    if (timeIsOver) {
      updateOfferedItem(undefined);
    }
    setUpdatedTime(time);
  };

  const handleApplieOffer = () => {
    setIsAppliedOffer(prevState => !prevState);
  };

  // Ability to set a random offered item
  useEffect(() => {
    if (ppoItems) {
      updateOfferedItem(getRandomValue(ppoItems));
    }
  }, [ppoItems]);

  // PPO counter
  useEffect(() => {
    let interval;
    if (done && ppo && !initTime) {
      setTimeout(() => {
        setInitTime(Date.now() + deadline);
        setIsAppliedOffer(true);
      }, 1000);
    } else if (done && ppo && initTime) {
      interval = setInterval(() => getTime(), 1000);
    } else if (!offeredItem && interval) {
      setIsAvailableTime(false);
      setInitTime(undefined);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [done, minutes, seconds, initTime, ppo, offeredItem]);

  return showSpecialOffer ? (
    <div className={styles['special-offer-wrapper']}>
      <SpecialOfferBanner
        handleApplieOffer={handleApplieOffer}
        isAppliedOffer={isAppliedOffer}
        minutes={minutes}
        seconds={seconds}
        offeredItem={offeredItem}
        apiLoading={apiLoading}
        setApiLoading={setApiLoading}
        updateOfferedItem={updateOfferedItem}
      />
      <SpecialOfferButton
        handleApplieOffer={handleApplieOffer}
        isAvailableTime={isAvailableTime}
        isAppliedOffer={isAppliedOffer}
        minutes={minutes}
        seconds={seconds}
      />
    </div>
  ) : null;
};

export default SpecialOffer;
