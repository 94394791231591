import _ from 'lodash';

const vault = {
  local: 'https://sv-publicapi.nofraud-test.com',
  dev: 'https://sv-qe2-publicapi.nofraud-test.com',
  dev1: 'https://sv-qe2-publicapi.nofraud-test.com',
  dev2: 'https://sv-publicapi.nofraud-test.com',
  qe1: 'https://sv-qe2-publicapi.nofraud-test.com',
  staging2: 'https://sv-qe2-publicapi.nofraud-test.com',
  staging1: 'https://sv-qe2-publicapi.nofraud-test.com',
  prod: 'https://sv-publicapi.nofraud.com',
  prod2: 'https://sv-publicapi.nofraud.com',
};

const webUrls = {
  local: 'http://localhost:3100',
  dev: 'https://cdn-checkout-dev1.nofraud-test.com',
  dev1: 'https://cdn-checkout-dev1.nofraud-test.com',
  qe: 'https://cdn-checkout-qe2.nofraud-test.com',
  qe1: 'https://cdn-checkout-qe1.nofraud-test.com',
  prod: 'https://cdn-checkout.nofraud.com',
  prod2: 'https://checkout.nofraud.com',
};

const ccUrls = {
  local: 'http://localhost:3110',
  dev: 'https://cdn-cardinput-checkout-dev1.nofraud-test.com',
  dev1: 'https://cdn-cardinput-checkout-dev1.nofraud-test.com',
  qe: 'https://cdn-cardinput-checkout-qe2.nofraud-test.com',
  qe1: 'https://cdn-cardinput-checkout-qe1.nofraud-test.com',
  prod: 'https://cdn-cardinput-checkout.nofraud.com',
  prod2: 'https://checkout-cardinput.nofraud.com',
};

const apiUrls = {
  local: 'https://dynamic-api-checkout-dev1.nofraud-test.com/v2',
  dev: 'https://dynamic-api-checkout-dev1.nofraud-test.com/v2',
  dev1: 'https://dynamic-api-checkout-dev1.nofraud-test.com/v2',
  dev2: 'https://dynamic-api-checkout-dev1.nofraud-test.com/v2',
  qe1: 'https://checkout-api-qe1.nofraud-test.com/v2',
  prod: 'https://dynamic-api-checkout.nofraud.com/v2',
  prod2: 'https://checkout-api.nofraud.com/v2',
};

export const environmentId = Object.freeze({
  local: 'local',
  dev: 'dev',
  dev1: 'dev1',
  qe: 'qe',
  qe1: 'qe1',
  prod: 'prod',
  prod2: 'prod2',
});

const getEnvironment = () => {
  const webUrlsMap = _.invert(webUrls);
  const matchedUrl = Object.keys(webUrlsMap).find(urlPrefix => window?.location?.href.startsWith(urlPrefix));
  return webUrlsMap[matchedUrl];
};

export const environment = getEnvironment();

export const currentBaseUrl = () =>
  `${window.location.protocol}//${window.location.host}/${environment === environmentId.dev ? 'latest/' : ''}`;

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const param = urlParams.get('applepay');
export const isPopup = param === 'openPopup';

export const CHECKOUT_WEB_URL = webUrls[environment];
export const CHECKOUT_API_URL = apiUrls[environment];
export const CHECKOUT_CC_URL = ccUrls[environment];
export const CHECKOUT_VAULT_URL = vault[environment];
export const VAULT_TEST_PUBLIC_APP_URL = '';
export const SMARTY_US_AUTOCOMPLETE_URL = 'https://us-autocomplete-pro.api.smartystreets.com/lookup';
export const SMARTY_US_STREET_URL = 'https://us-street.api.smartystreets.com/street-address?license=us-core-cloud';
export const WHOAPI_URL = 'https://api.whoapi.com';
