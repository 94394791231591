import { useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';

import DataContext from '../../../../store/dataContext';
import UiContext from '../../../../store/uiContext';
import { status } from '../../../../helpers/constants';
import { deliveryMethod } from '../../../../commons/constants';
import useCartTotalsUtils from '../../../../hooks/useCartTotalsUtils';
import { EVENT, trigger } from '../../../../commons/utils/custom-event';
import { selectApiStatus, selectCheckout } from '../../../../store/slice';

import { selectBilling } from '../../../billing/slice';

import { selectShipping } from '../../slice';

export const usePickup = () => {
  const checkoutData = useSelector(selectCheckout);
  const shippingState = useSelector(selectShipping);
  const billingState = useSelector(selectBilling);
  const statusState = useSelector(selectApiStatus);

  const { device, apiStatus, applyStoreCredit } = useContext(DataContext);
  const { isLoadingInsurance, isLoadingRoute, isLoadingSignature } = useContext(UiContext);

  const { getOrderTotal } = useCartTotalsUtils();

  const isPickupActive = useCallback(
    () => shippingState.bopis.isEnabled && shippingState.deliveryMethod === deliveryMethod.pickup,
    [shippingState],
  );

  const handlePickupSubmit = paymentConfig => {
    const { creditCard, googlePay } = paymentConfig;

    const zeroTotal = getOrderTotal() === 0;
    const isAdditionalServiceLoading = isLoadingInsurance || isLoadingRoute || isLoadingSignature;
    const isNotLoading = isPickupActive() && !statusState.isLoading;
    const isPaymentReady =
      (creditCard.isActive && apiStatus.isCardReady) || (applyStoreCredit && zeroTotal) || googlePay.isActive;
    const isAddressFilled =
      Object.values(billingState.address).length > 0 || Object.values(checkoutData?.address).length > 0;
    const canPickupSubmit =
      checkoutData?.pickUpMethodKey &&
      device.isReady &&
      apiStatus.isEmailReady === status.READY &&
      isPaymentReady &&
      apiStatus.isSessionReady === status.READY &&
      !isAdditionalServiceLoading &&
      isNotLoading &&
      (billingState.address || checkoutData?.address) &&
      isAddressFilled;

    if (isPickupActive() && canPickupSubmit) {
      if (creditCard.isActive) {
        creditCard.submitCallback();
      } else if (googlePay.isActive) {
        googlePay.submitCallback();
      }
    } else {
      trigger(EVENT.SUBMIT_CHECKOUT);
    }
  };

  return { handlePickupSubmit, isPickupActive };
};
