import React, { useContext } from 'react';
import UiContext from '../../store/uiContext';

import './style.scss';

const Popup = () => {
  const { openPopup, setOpenPopup } = useContext(UiContext);

  const handleClose = evt => {
    if (evt.target.className === 'overlay-popup' || evt.target.className === 'app-cover') {
      setOpenPopup(null);
    }
  };

  return (
    <div tabIndex={0} role="button" className="overlay-popup" onClick={evt => handleClose(evt)} onKeyDown={null}>
      <div className="app-cover"></div>
      <div className="popup-box">{openPopup}</div>
    </div>
  );
};

export default Popup;
