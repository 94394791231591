import { CROSS_MESSAGE, crossRequest } from '../../commons/utils/cross-origin-communication';
import { platformValue, platformCartIdValue } from '../../helpers/prelaunchData';
import { platformList } from '../../commons/constants';

export const applyGiftCard = async params => {
  const { code, shippingMethodId, i18nMessages, methods } = params;
  try {
    let giftPromise = Promise.resolve();
    if (platformValue === platformList.bigcommerce) {
      giftPromise = crossRequest(
        CROSS_MESSAGE.FETCH_FROM_WINDOW,
        {
          url: `/api/storefront/checkouts/${platformCartIdValue}/gift-certificates`,
          options: {
            method: 'POST',
            headers: {
              'x-api-internal': 'This API endpoint is for internal use only and may change in the future',
            },
            body: JSON.stringify({ giftCertificateCode: code }),
          },
        },
        5000,
      );
    }
    const promises = [giftPromise];
    const promisesResponse = await Promise.allSettled(promises);
    const didSucceed =
      promisesResponse &&
      promisesResponse.length > 0 &&
      promisesResponse.filter(p => p.status === 'fulfilled').length > 0;
    const firstFufilled = promisesResponse.filter(p => p.status === 'fulfilled')[0];
    if (didSucceed) {
      const hasError = () => {
        if (firstFufilled?.value?.text) {
          return JSON.parse(firstFufilled?.value?.text)?.status === 400;
        }
        if (!firstFufilled.value) {
          return true;
        }
        return false;
      };

      if (hasError()) {
        throw new Error(i18nMessages.invalid_code);
      }
      const BigCommerceCertificate = () =>
        firstFufilled.value?.text ? JSON.parse(firstFufilled.value?.text)?.giftCertificates : false;
      if (BigCommerceCertificate()) {
        return {
          success: true,
          shippingMethods: methods,
          shippingMethodKey: shippingMethodId || {},
          giftCertificates: BigCommerceCertificate(),
        };
      }
      return {
        success: true,
        cart: firstFufilled?.value?.data?.cart,
        totals: firstFufilled?.value?.data?.cartTotals,
        shippingMethods: methods,
        shippingMethodKey: shippingMethodId || {},
      };
    }
    throw new Error(i18nMessages.invalid_code);
  } catch (err) {
    const error = err.response?.data
      ? err.response?.data
      : {
          errorMsg: err.message,
        };
    return {
      success: false,
      error,
    };
  }
};

export const removeGiftCard = async params => {
  const { code, shippingMethodId, methods } = params;
  try {
    // Gift Certificate
    let giftResponse;
    if (platformValue === platformList.bigcommerce) {
      giftResponse = await crossRequest(
        CROSS_MESSAGE.FETCH_FROM_WINDOW,
        {
          url: `/api/storefront/checkouts/${platformCartIdValue}/gift-certificates/${code}`,
          options: {
            method: 'DELETE',
            headers: {
              'x-api-internal': 'This API endpoint is for internal use only and may change in the future',
            },
          },
        },
        5000,
      ).then(result => JSON.parse(result.text)?.giftCertificates);
    }

    return {
      success: true,
      shippingMethods: methods,
      shippingMethodKey: shippingMethodId || {},
      giftCertificates: giftResponse && Array.isArray(giftResponse) && giftResponse,
    };
  } catch (err) {
    return {
      success: false,
      error: err.response?.data,
    };
  }
};

export const refreshCertificates = async giftCertificates => {
  const hasCertificates = giftCertificates && giftCertificates.length > 0;
  if (hasCertificates) {
    if (platformValue === platformList.bigcommerce) {
      const refreshedCertificates = await crossRequest(
        CROSS_MESSAGE.FETCH_FROM_WINDOW,
        {
          url: `/api/storefront/checkouts/${platformCartIdValue}`,
          options: {
            method: 'GET',
            headers: {
              'x-api-internal': 'This API endpoint is for internal use only and may change in the future',
            },
          },
        },
        5000,
      );
      const parsedCertificates = JSON.parse(refreshedCertificates.text);
      if (parsedCertificates && parsedCertificates.giftCertificates) {
        return parsedCertificates.giftCertificates;
      }
      return [];
    }
  }
  return [];
};

export const refreshCertificatesAndCoupons = async ({ giftCertificates, coupons, isBopisEnabled }) => {
  const newData = {
    refreshedCertificates: [],
    refreshedCoupons: [],
    refreshedGrandTotal: 0,
  };

  const hasCertificates = giftCertificates && giftCertificates.length > 0;
  const hasCoupons = coupons && coupons.length > 0;
  const shouldRefresh = platformValue === platformList.bigcommerce && (hasCertificates || hasCoupons || isBopisEnabled);

  if (shouldRefresh) {
    const resultAsText = await crossRequest(
      CROSS_MESSAGE.FETCH_FROM_WINDOW,
      {
        url: `/api/storefront/checkouts/${platformCartIdValue}`,
        options: {
          method: 'GET',
          headers: {
            'x-api-internal': 'This API endpoint is for internal use only and may change in the future',
          },
        },
      },
      5000,
    );
    const resultAsObject = JSON.parse(resultAsText.text);

    if (resultAsObject?.giftCertificates) {
      newData.refreshedCertificates = resultAsObject.giftCertificates || [];
      newData.refreshedGrandTotal = resultAsObject.grandTotal;
    }
    if (resultAsObject?.coupons[0]) {
      newData.refreshedCoupons = {
        code: resultAsObject.coupons[0]?.code,
        amount: resultAsObject.coupons[0]?.discountedAmount,
      };
    }
  }

  return newData;
};
