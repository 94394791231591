import { useContext } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import DataContext from '../../store/dataContext';
import UiContext from '../../store/uiContext';
import { storeUrlValue } from '../../helpers/prelaunchData';

import { CROSS_MESSAGE } from '../utils/cross-origin-communication';

export const useCloseIframe = () => {
  const { done } = useContext(UiContext);
  const { userWasAuthorized } = useContext(DataContext);

  const closeWrapper = () => {
    window.parent.postMessage(CROSS_MESSAGE.CLOSE_IFRAME, storeUrlValue);
  };

  const closeRedirect = () => {
    window.parent.postMessage(CROSS_MESSAGE.CLOSE_REDIRECT, storeUrlValue);
  };

  const closeRefresh = () => {
    window.parent.postMessage(CROSS_MESSAGE.CLOSE_REFRESH, storeUrlValue);
  };

  const handleClick = () => {
    datadogRum.addAction('checkout-closed');
    if (!done && userWasAuthorized) {
      closeRefresh();
    } else if (!done) {
      closeWrapper();
    } else if (done) {
      closeRedirect();
    }
  };

  return { handleClick };
};
