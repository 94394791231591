import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { currentBaseUrl } from '../../../../helpers/urlConst';

import { CouponContainer } from '../../../../components/Coupon';
import UiContext from '../../../../store/uiContext';
import { selectCart } from '../../../../store/slice';
import CartTotals from '../totals';

import '../totals/styles.scss';

const CartTotalsMobile = () => {
  const uiContext = useContext(UiContext);
  const [collapseDropdown, setCollapseDropdown] = useState(true);
  const cart = useSelector(selectCart);

  useEffect(() => {
    if (cart?.cartData?.additionalCharges?.route?.name || cart?.cartData?.additionalCharges?.packageInsurance?.name) {
      setCollapseDropdown(false);
    }
  }, [cart]);

  return (
    <div className={classNames({ 'mobile-wrapper': true })}>
      <div
        className={classNames({
          'cart-totals-wrapper': true,
          active: uiContext.allowShippingAddressAdd,
          collapsed: collapseDropdown,
        })}
      >
        <button
          className="dropdown-btn reset-styles"
          type="button"
          onClick={() => setCollapseDropdown(prevState => !prevState)}
        >
          <img alt="dropdown-arrow" src={`${currentBaseUrl()}assets/arrow.svg`} />
        </button>
        <CartTotals isMobile isCollapsed={collapseDropdown} cart={cart} />
      </div>
      <CouponContainer cart={cart} />
    </div>
  );
};

export default CartTotalsMobile;
