import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { datadogRum } from '@datadog/browser-rum';
import useCurrency from '../../../modules/currency/hooks/use-currency';

import BillingPhone from '../../BillingPhone';
import SubmitBtn from '../../Button/SubmitBtn';
import { selectCart, selectCheckout, selectAdditionalData, selectError } from '../../../store/slice';
import DataContext from '../../../store/dataContext';
import UiContext from '../../../store/uiContext';
import useCartTotalsUtils from '../../../hooks/useCartTotalsUtils';

import { useEndpoint } from '../../../hooks/useEndpoint';
import useTagManager from '../../../hooks/useTagManager';
import useAddressApi from '../../../commons/hooks/use-address-api';
import { paymentIdList } from '../../../helpers/constants';

import amazonPayLogo from './amazon-pay-button.svg';
import './style.scss';

const AmazonCheckout = ({ setOrderSubmitting, flags }) => {
  const {
    billingPhone,
    broadcastMessage,
    paymentMethodError,
    setPaymentMethodError,
    reset,
    validNumber,
    orderSubmitting,
    updateAmazonButtonData,
    additionalCharges,
  } = useContext(DataContext);
  const cartData = useSelector(selectCart);
  const checkoutData = useSelector(selectCheckout);
  const submitError = useSelector(selectError);

  const additionalData = useSelector(selectAdditionalData);
  const portalSettings = cartData?.merchant?.settings;
  const { updateCheckoutState, TO_DONE, allowBillingAddressAdd, setOpenPreOrder } = useContext(UiContext);

  const { dataLayer } = useTagManager();
  const { t } = useTranslation();
  const { getOrderTotal } = useCartTotalsUtils();
  const { getWithShippingMethodPayload, executeWithShippingMethod } = useAddressApi();

  const [getSignature, setGetSignature] = useState(false);
  const [error, setError] = useState('');

  const [apiAmazonExecuteLoading, setApiAmazonExecuteLoading] = useState(false);
  const [apiAmazonExecuteResponse, setApiAmazonExecuteResponse] = useState({});
  const [apiAmazonExecuteError, setApiAmazonExecuteError] = useState(undefined);

  const { postSubmit } = useEndpoint();

  const { getShoppercurrency } = useCurrency();

  const isPhoneVisible = () =>
    (submitError.data === 'PAYMENT DECLINED' || portalSettings.contactPhone.isEnabled) && !allowBillingAddressAdd;

  const showButton = () => {
    const { name, line1, line2, city, region, postal, country } = checkoutData?.address;
    const amazonButton = {
      button: {
        merchantId: cartData?.payment?.amazonpay?.credentials?.merchantID,
        ledgerCurrency: getShoppercurrency()?.code || 'USD',
        publicKeyId: cartData?.payment?.amazonpay?.credentials?.publicKeyID,
        checkoutLanguage: 'en_US',
        productType: 'PayAndShip',
        placement: 'Checkout',
        buttonColor: 'Gold',
        popup: false,
      },
      createCheckoutSessionConfig: {
        payloadJSON: {
          addressDetails: {
            addressLine1: line1,
            addressLine2: line2,
            city,
            countryCode: country,
            name,
            phoneNumber: billingPhone || '(212) 555-5555',
            postalCode: postal,
            stateOrRegion: region,
          },
          merchantMetadata: {
            merchantReferenceId: cartData?.payment?.amazonpay?.credentials?.merchantID,
            noteToBuyer: '',
          },
          paymentDetails: {
            canHandlePendingAuthorization: false,
            chargeAmount: {
              amount: getOrderTotal(),
              currencyCode: getShoppercurrency()?.code || 'USD',
            },
            paymentIntent: 'AuthorizeWithCapture',
            softDescriptor: 'Descriptor',
          },
          storeId: cartData?.payment?.amazonpay?.credentials?.storeID,
          webCheckoutDetails: {
            checkoutMode: 'ProcessOrder',
            checkoutResultReturnUrl: `${window.location.href}&amazon=checkoutReturn`,
          },
        },
        signature: cartData?.payment?.amazonpay?.signature,
      },
    };
    updateAmazonButtonData(amazonButton);
  };

  useEffect(() => {
    if (apiAmazonExecuteError) {
      setError(apiAmazonExecuteError);
    }
  }, [apiAmazonExecuteError]);

  useEffect(() => {
    if (apiAmazonExecuteLoading) {
      setOrderSubmitting(apiAmazonExecuteLoading);
    }
  }, [apiAmazonExecuteLoading]);

  useEffect(() => {
    if (apiAmazonExecuteResponse?.success) {
      updateCheckoutState(TO_DONE);
      dataLayer(apiAmazonExecuteResponse);
      reset();
      if (!apiAmazonExecuteResponse?.redirectUrl) {
        orderSubmitting(false);
      }
      datadogRum.addAction('successful-order', { 'payment-method': 'amazonpay' });
    }
  }, [apiAmazonExecuteResponse]);

  useEffect(() => {
    const postAmazon = async () => {
      const payment = {};
      payment.context = {
        cvv: undefined,
        cardType: 'alt',
        onBehalfOf: cartData?.payment?.direct?.onBehalfOf,
        amazonCheckoutSessionId: broadcastMessage.amazonCheckoutSessionId,
        id: broadcastMessage.amazonCheckoutSessionId,
        method: 'amazonpay',
      };
      payment.id = broadcastMessage.amazonCheckoutSessionId;
      payment.type = 'amazonpay';
      const address = {
        ...checkoutData.address,
        name: checkoutData.address.name || additionalData.shipping.name,
        company: checkoutData.address.company || additionalData.shipping.company,
        line2: checkoutData.address.line2 || additionalData.shipping.line2,
      };
      try {
        const result = await postSubmit({
          address,
          billing: {
            name: checkoutData?.address?.name,
            phone: billingPhone || '(212) 555-5555',
          },
          payment,
        });
        setApiAmazonExecuteResponse(result);
      } catch (err) {
        setApiAmazonExecuteError(err);
      }
      setApiAmazonExecuteLoading(false);
    };
    if (broadcastMessage?.execute === 'amazon') {
      setApiAmazonExecuteLoading(true);
      if (cartData?.items?.find(item => item?.preOrder?.expectedAvailability)) {
        setOpenPreOrder({
          payload: {},
          callback: postAmazon,
        });
      } else {
        postAmazon();
      }
    }
  }, [broadcastMessage, billingPhone]);

  useEffect(async () => {
    if (validNumber) {
      const address = {
        name: checkoutData?.address?.name,
        line1: checkoutData?.address?.line1,
        line2: checkoutData?.address?.line2,
        city: checkoutData?.address?.city,
        region: checkoutData?.address?.region,
        regionCode: checkoutData?.address?.regionCode,
        postal: checkoutData?.address?.postal,
        country: checkoutData?.address?.country,
        singleLine: checkoutData?.address?.singleLine,
        company: checkoutData?.address?.company,
      };

      const payload = getWithShippingMethodPayload(
        address,
        checkoutData?.address?.name,
        checkoutData?.id,
        checkoutData?.shippingMethodKey,
        checkoutData?.address?.company,
        additionalCharges,
      );
      await executeWithShippingMethod(payload);
      setGetSignature(!getSignature);
    }
  }, [validNumber]);

  useEffect(() => {
    if (cartData?.payment?.amazonpay?.signature && (validNumber || !isPhoneVisible())) {
      showButton();
    } else {
      setPaymentMethodError(t('payment_method.amazonpay.error'));
    }
  }, [getSignature, cartData]);

  return (
    <div id="amazon-input-wrapper" className="input-wrapper">
      {isPhoneVisible() && <BillingPhone flags={flags} />}
      {paymentMethodError?.amazonpay && <div className="amazon-error">{paymentMethodError?.amazonpay || error}</div>}
      <SubmitBtn
        type={paymentIdList.AMAZONPAY}
        style={{
          layout: 'horizontal',
          label: 'checkout',
          tagline: 'false',
          marginTop: isPhoneVisible() ? '0px' : '30px',
          marginBottom: '20px',
        }}
        image={amazonPayLogo}
      />
      <p className="use-your-account">{t('payment_method.amazonpay.use_your_amazon_account')}</p>
      <p className="will-open-new-tab">{t('payment_method.amazonpay.amazon_pay_will_open_in_a_new_tab')}</p>
    </div>
  );
};

export default AmazonCheckout;

AmazonCheckout.propTypes = {
  setOrderSubmitting: PropTypes.func.isRequired,
  flags: PropTypes.shape({
    'disable-ddi-phone': PropTypes.bool,
  }),
};
