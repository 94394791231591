const AddressUpdated = 'address.added';
const CartUpdated = 'cart.updated';
const CheckoutCompleted = 'checkout.completed';
const CheckoutStarted = 'checkout.started';
const CustomerUpdated = 'customer.updated';
const PageInitiated = 'page.initiated';
const PaymentFailed = 'payment.failed';
const PaymentSelected = 'payment.selected';
const PaymentSuccessful = 'payment.successful';
const PaymentReady = 'payment.ready';

const types = {
  AddressUpdated,
  CartUpdated,
  CheckoutCompleted,
  CheckoutStarted,
  CustomerUpdated,
  PageInitiated,
  PaymentFailed,
  PaymentSelected,
  PaymentSuccessful,
  PaymentReady,
};

const list = [
  AddressUpdated,
  CartUpdated,
  CheckoutCompleted,
  CheckoutStarted,
  CustomerUpdated,
  PageInitiated,
  PaymentFailed,
  PaymentSelected,
  PaymentSuccessful,
  PaymentReady,
];

export { types, list };
