import { initialErrorState } from '../../utils/initial-error-state';
import { addressShape, validationSource } from '../../constants';

export const validateAddress = (valueGoogle, addressErrors, inputText, source) => {
  const c1 = valueGoogle === null;
  const c2 = inputText !== '';
  const c3 = source === validationSource.FROM_SUBMIT;
  if ((c1 && c2) || (c1 && c3)) {
    return {
      hasError: true,
      message: addressErrors['google_address_autocomplete.please_enter_a_valid_address'],
    };
  }
  return initialErrorState;
};

export const isValidParsedAddress = parsedAddress =>
  parsedAddress?.line1 != null &&
  parsedAddress?.line1 !== '' &&
  parsedAddress?.city != null &&
  parsedAddress?.city !== '' &&
  parsedAddress?.regionCode != null &&
  parsedAddress?.regionCode !== '' &&
  parsedAddress?.postal != null &&
  parsedAddress?.postal !== '' &&
  parsedAddress?.country != null &&
  parsedAddress?.country !== '';

export const parseGeoData = addressComponents => {
  const parsedAddress = {
    ...addressShape,
  };

  if (addressComponents) {
    addressComponents.forEach(data => {
      switch (true) {
        case data.types.includes('locality'): {
          parsedAddress.city = data.long_name;
          break;
        }
        case !parsedAddress.city && data.types.includes('sublocality'): {
          parsedAddress.city = data.long_name;
          break;
        }
        case !parsedAddress.city && data.types.includes('postal_town'): {
          parsedAddress.city = data.long_name;
          break;
        }
        case !parsedAddress.city && data.types.includes('administrative_area_level_3'): {
          parsedAddress.city = data.long_name;
          break;
        }
        case data.types.includes('country'): {
          parsedAddress.country = data.long_name;
          parsedAddress.country = data.short_name;
          break;
        }
        case data.types.includes('administrative_area_level_1'): {
          parsedAddress.regionCode = data.short_name;
          parsedAddress.region = data.long_name;
          break;
        }
        case data.types.includes('route'): {
          parsedAddress.streetName = data.short_name;
          break;
        }
        case data.types.includes('street_number'): {
          parsedAddress.streetNumber = data.long_name;
          break;
        }
        case data.types.includes('premise') && !parsedAddress.streetNumber: {
          parsedAddress.streetNumber = data.short_name;
          break;
        }
        case data.types.includes('postal_code') && !data.types.includes('postal_code_prefix'): {
          parsedAddress.postal = data.long_name;
          break;
        }
        case data.types.includes('subpremise'): {
          parsedAddress.apartmentUnit = data.long_name;
          break;
        }
        default: {
          return true;
        }
      }
      return false;
    });

    parsedAddress.line1 = `${parsedAddress.streetNumber} ${parsedAddress.streetName}`;
    // eslint-disable-next-line max-len
    parsedAddress.singleLine = `${parsedAddress.line1}, ${parsedAddress.city}, ${parsedAddress.regionCode}, ${parsedAddress.postal}, ${parsedAddress.country}`;
  }

  return parsedAddress;
};

export const convertToGoogle = label => {
  if (label === '') {
    return null;
  }

  return {
    label,
    value: {
      place_id: '',
    },
  };
};

export const joinSuggestions = (backendSuggestions, addressAsTyped, predictions) => {
  let filtered = null;
  let hasFieltered = null;
  const hasPredictions = predictions.length > 0;

  if (backendSuggestions) {
    filtered = backendSuggestions.filter(item => item.label.includes(addressAsTyped));
    hasFieltered = filtered.length > 0;
  }

  if (!hasFieltered && hasPredictions) {
    return predictions;
  }

  if (!hasPredictions && hasFieltered) {
    return filtered;
  }

  const joined = [...filtered];

  if (hasFieltered && hasPredictions) {
    for (const p of predictions) {
      let hasItem = false;
      // looping through filtered suggestions to avoid duplication
      for (const f of filtered) {
        if (f.label.includes(p.label)) {
          hasItem = true;
        }
      }
      // if prediction is not at the current suggestions list, then add it
      if (!hasItem) {
        joined.push(p);
      }
    }
    if (joined.length > 5) {
      return joined.slice(0, 5);
    }
  }

  return joined;
};
