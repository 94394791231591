import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectPluginSMSDefault } from '../../store/slice';
import DataContext from '../../store/dataContext';
import UiContext from '../../store/uiContext';
import useErrors from '../../store/errors/useErrors';
import { setStatus } from '../../modules/billing/slice';

import './style.scss';

const Newsletter = () => {
  const { t } = useTranslation();
  const { resetErrorOnField } = useErrors();
  const smsDefault = useSelector(selectPluginSMSDefault);

  const { consent, updateConsent, setApiPostOrderError } = useContext(DataContext);
  const { setOpenPopup, setIsCheckoutTermsModalOpen, checkoutStage, TO_USER_ADD } = useContext(UiContext);

  const dispatch = useDispatch();

  const isDisabled = checkoutStage === TO_USER_ADD;

  const handleCheck = check => {
    if (!check) {
      setApiPostOrderError({});
      resetErrorOnField('billingPhone');
      dispatch(setStatus({ isReady: true }));
    }
    updateConsent({
      hasSms: check,
    });
  };

  const closePopup = check => {
    setOpenPopup(null);
    handleCheck(check);
  };

  const handleClick = content => {
    if (!consent?.hasSms) {
      setOpenPopup(content);
    } else {
      handleCheck(false);
    }
  };

  useEffect(() => {
    if (consent?.hasSms === undefined) {
      updateConsent({
        hasSms: smsDefault,
      });
    }
  }, [smsDefault]);

  const content = (
    <div className="klaviyo-popup">
      <span>{t('newsletter.popup')}</span>
      <a className="newsletter-link" href="#fake" onClick={() => setIsCheckoutTermsModalOpen('Privacy Policy')}>
        {t('newsletter.policy')}
      </a>
      <span>{t('newsletter.and')}</span>
      <a className="newsletter-link" href="#fake" onClick={() => setIsCheckoutTermsModalOpen('Terms of Use')}>
        {t('newsletter.terms')}
      </a>
      .
      <div className="button-popup">
        <div className="popup-button-container">
          <button className="button-cancel" type="button" onClick={() => closePopup(false)}>
            {t('newsletter.cancel')}
          </button>
          <button type="button" onClick={() => closePopup(true)}>
            {t('newsletter.confirm')}
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className="klaviyo"
      tabIndex={0}
      role="button"
      onClick={() => (!isDisabled ? handleClick(content) : null)}
      onKeyDown={null}
    >
      <span className="warn">{t('newsletter.send_me_message')}</span>
      <input
        type="checkbox"
        checked={consent?.hasSms}
        onChange={evt => (!isDisabled ? handleCheck(evt.target.checked) : null)}
      />
      <span className="checkmark" />
    </div>
  );
};

export default Newsletter;
