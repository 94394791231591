import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import UiContext from '../../../store/uiContext';
import DataContext from '../../../store/dataContext';
import { selectCheckout, selectAdditionalData } from '../../../store/slice';
import { currentBaseUrl } from '../../../helpers/urlConst';
import { paymentIdList } from '../../../helpers/constants';
import useTagManager from '../../../hooks/useTagManager';
import { useEndpoint } from '../../../hooks/useEndpoint';
import { useBroadcastChannel } from '../../../hooks/useBroadcastChannel';
import { currentPaymentMethod } from '../../../modules/payment/slice';

import './style.scss';

const AppleCheckout = ({ setOrderSubmitting, flags }) => {
  const { t } = useTranslation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const param = urlParams.get('applepay');
  const { setShowOverlay } = useContext(UiContext);
  const { billingPhone, broadcastMessage, handleOrderSubmit, paymentMethodError, reset, orderSubmitting } =
    useContext(DataContext);
  const { updateCheckoutState, TO_DONE } = useContext(UiContext);
  const selectedMethod = useSelector(currentPaymentMethod);
  const checkoutData = useSelector(selectCheckout);
  const { receiveMessage } = useBroadcastChannel();
  const { dataLayer } = useTagManager(!flags['disable-google-tag-manager']);
  const [apiApplepayExecuteLoading, setApplepayExecuteLoading] = useState(false);
  const [apiApplepayExecuteResponse, setApiApplepayExecuteResponse] = useState(null);
  const additionalData = useSelector(selectAdditionalData);
  const [error, setError] = useState('');
  const { postSubmit } = useEndpoint();
  const openPopup = () => {
    const popupRef = window.open(
      `${window.location.href}&applepay=openPopup`,
      'PromoteFirefoxWindowName',
      `popup, resizable=yes, height=678, width=900, top=73, left=524`,
    );
    setShowOverlay({
      logo: null,
      titleLogo: `${currentBaseUrl()}assets/applepay-text.svg`,
      text: t('payment_method.applepay.overlay'),
      button: 'Continue',
      window: popupRef,
    });
    const timer = setInterval(() => {
      if (popupRef?.closed) {
        clearInterval(timer);
        setShowOverlay(null);
      }
    }, 1000);
    receiveMessage(popupRef);
  };

  useEffect(() => {
    if (apiApplepayExecuteLoading) {
      setOrderSubmitting(apiApplepayExecuteLoading);
    }
  }, [apiApplepayExecuteLoading]);

  const submitApplePay = async () => {
    setApplepayExecuteLoading(true);
    const address = {
      ...checkoutData.address,
      name: checkoutData.address.name || additionalData.shipping.name,
      company: checkoutData.address.company || additionalData.shipping.company,
      line2: checkoutData.address.line2 || additionalData.shipping.line2,
    };

    const result = await postSubmit({
      address,
      billing: {
        phone: billingPhone,
      },
      payment: {
        type: 'applepay',
        additionalInformation: {
          ...broadcastMessage,
        },
        id: broadcastMessage.applepayNonce,
      },
    });
    setApplepayExecuteLoading(false);
    setApiApplepayExecuteResponse(result);
  };

  useEffect(() => {
    if (broadcastMessage?.applepay?.error) {
      setError(broadcastMessage?.applepay?.error);
    } else if (broadcastMessage?.execute === 'applepay' && param !== 'openPopup') {
      submitApplePay();
    } else if (param !== 'openPopup' && selectedMethod === paymentIdList.APPLEPAY) {
      openPopup();
    }
  }, [broadcastMessage]);

  useEffect(() => {
    if (apiApplepayExecuteResponse?.success) {
      handleOrderSubmit({
        ...apiApplepayExecuteResponse,
        paymentMethod: t('payment_method.applepay.payed'),
        paymentMethodId: paymentIdList.APPLEPAY,
      });
      updateCheckoutState(TO_DONE);
      dataLayer(apiApplepayExecuteResponse);
      reset();
      if (!apiApplepayExecuteResponse?.redirectUrl) {
        orderSubmitting(false);
      }
      datadogRum.addAction('successful-order', { 'payment-method': 'applepay' });
    }
  }, [apiApplepayExecuteResponse]);

  return (
    <>
      <button className="apple-pay-open-popup" type="button" onClick={openPopup}>
        {t('payment_method.applepay.continue')}
      </button>
      {paymentMethodError?.applepay && <div className="google-error">{paymentMethodError?.applepay || error}</div>}
    </>
  );
};

export default AppleCheckout;

AppleCheckout.propTypes = {
  setOrderSubmitting: PropTypes.func.isRequired,
  flags: PropTypes.shape({
    'disable-google-tag-manager': PropTypes.bool,
  }),
};
