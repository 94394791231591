/* global braintree */
import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { isPopup } from '../../helpers/urlConst';
import { useBroadcastChannel } from '../useBroadcastChannel';
import { setPaymentMethod } from '../../modules/payment/slice';
import { useCheckoutSession } from '../../helpers/sessionHelper';
import { paymentIdList } from '../../helpers/constants';
import useCartTotalsUtils from '../useCartTotalsUtils';
import DataContext from '../../store/dataContext';
import ShippingMethodImage from '../../commons/components/shipping-method-image';
import { selectCart, selectCheckout } from '../../store/slice';

import SkeletonLoading from '../../components/SkeletonLoading';
import WarnTerms from '../../components/Checkout/WarnTerms';
import Header from '../../commons/components/header';

import '../../components/PaymentMethods/AppleCheckout';

export const useApplepay = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const checkoutData = useSelector(selectCheckout);
  const cartData = useSelector(selectCart);
  const { resumeSession } = useCheckoutSession();
  const {
    paymentMethodError,
    registeredPaymentMethods,
    setPaymentMethodError,
    cartTotals,
    broadcastErrorMessage,
    email,
    storeUrl,
  } = useContext(DataContext);
  const { getOrderTotal } = useCartTotalsUtils();
  const { postMessage } = useBroadcastChannel();
  const [appleInstance, setAppleInstance] = useState(undefined);

  useEffect(() => {
    if (email && isPopup) {
      resumeSession();
    }
  }, [email, isPopup]);

  useEffect(() => {
    if (broadcastErrorMessage?.applepay) {
      dispatch(setPaymentMethod(paymentIdList.APPLEPAY));
      setPaymentMethodError({ ...paymentMethodError, applepay: broadcastErrorMessage?.applepay });
    }
  }, [broadcastErrorMessage]);

  useEffect(() => {
    const createApplePayButton = applepayKeys => {
      const auth = applepayKeys.clientToken;
      braintree.client.create(
        {
          authorization: auth,
        },
        (clientErr, clientInstance) => {
          if (clientErr) {
            setPaymentMethodError(JSON.stringify(clientErr));
            return;
          }
          braintree.applePay.create(
            {
              client: clientInstance,
            },
            (applePayErr, applePayInstance) => {
              if (applePayErr) {
                setPaymentMethodError(JSON.stringify(applePayErr));
                return;
              }
              setAppleInstance(applePayInstance);
            },
          );
        },
      );
    };

    if (registeredPaymentMethods?.applepay?.isEnabled) {
      const applepayKeys = registeredPaymentMethods?.applepay?.applepayKeys;
      if (braintree && applepayKeys) {
        if (window.location.href.indexOf('applepay') > -1) {
          if (isPopup && cartData?.shippingMethods?.list?.length > 0 && !appleInstance) {
            dispatch(setPaymentMethod(paymentIdList.APPLEPAY));
            createApplePayButton(applepayKeys);
          }
        }
      }
    }
  }, [registeredPaymentMethods, cartTotals, braintree, cartData]);

  const handleApplepayUrl = () => {
    if (window.location.href.indexOf('applepay') > -1) {
      if (isPopup) {
        if (document.getElementById('app-main-checkout')) {
          document.getElementById('app-main-checkout').style.display = 'none';
        }
      }
    }
  };

  const handleClick = () => {
    if (!appleInstance) {
      return;
    }
    const paymentRequest = {
      currencyCode: 'USD',
      countryCode: 'US',
      merchantCapabilities: ['supports3DS'],
      supportedNetworks: ['VISA', 'MASTERCARD', 'AMEX', 'DISCOVER'],
      allowedCardNetworks: ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA'],
      allowedCardAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
      apiVersion: 2,
      apiVersionMinor: 0,
      type: 'PAYMENT_GATEWAY',
      environment: 'sandbox',
      total: {
        type: 'final',
        label: storeUrl,
        amount: getOrderTotal().toFixed(2),
      },
    };

    const session = new window.ApplePaySession(3, paymentRequest);
    session.oncancel = event => {
      setPaymentMethodError(JSON.stringify(event.sessionError.info));
    };

    session.onvalidatemerchant = event => {
      appleInstance.performValidation(
        {
          validationURL: event.validationURL,
          displayName: storeUrl,
        },
        (err, merchantSession) => {
          if (err) {
            setPaymentMethodError(JSON.stringify(err));
            return;
          }
          try {
            session.completeMerchantValidation(merchantSession);
          } catch (error) {
            setPaymentMethodError(JSON.stringify(error));
          }
        },
      );
    };

    session.onpaymentauthorized = event => {
      appleInstance.tokenize(
        {
          token: event.payment.token,
        },
        (tokenizeErr, payload) => {
          if (tokenizeErr) {
            setPaymentMethodError(JSON.stringify(tokenizeErr));
            session.completePayment(window.ApplePaySession.STATUS_FAILURE);
            return;
          }

          session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
          postMessage({
            applepayNonce: payload.nonce,
            execute: 'applepay',
          });
          window.close();
        },
      );
    };
    session.begin();
  };

  const method = cartData?.shippingMethods?.find(item => item.lookupKey === checkoutData?.shippingMethodKey);
  const applePaySumary = () =>
    cartData?.shippingMethods?.length > 0 ? (
      <div className="apple-pay-container">
        <Header />
        <div className="section">
          <div className="summary">{t('payment_method.applepay.summary')}</div>
          <div className="shipping-info">{t('payment_method.applepay.shippingInfo')}</div>
          <div className="info">{email}</div>
          <div className="info">
            {checkoutData?.address?.name}
            {checkoutData?.address?.company ? ` - ${checkoutData?.address?.company}` : ''}
          </div>
          <div className="info">{checkoutData?.address?.singleLine}</div>
          <div className="shipping-details">
            <p className="address-title">{t('order_display.shipping_method')}</p>
            <div className="shipping-company-and-method">
              <ShippingMethodImage type={method?.type} label={method?.label} />
              <h2>{method?.label}</h2>
            </div>
          </div>
          <div className="button-container">
            <button
              style={{ display: appleInstance ? 'flex' : 'none' }}
              type="button"
              id="apple-pay-i-button"
              onClick={handleClick}
              className="apple-pay-button"
              lang="us"
            ></button>
          </div>
          <WarnTerms />
        </div>
      </div>
    ) : (
      <div className="apple-pay-container">
        <SkeletonLoading hideRight />
      </div>
    );

  return { handleApplepayUrl, applePaySumary, isPopup };
};
