import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const CountryList = ({ error, handleChange, inputId, theme, value, countries }) => {
  const { t } = useTranslation();

  return (
    <div className="nf-input-wrapper">
      <select
        aria-label={t('manual_address.country')}
        autoComplete="new-password"
        className={classNames({
          'has-error': error,
          'is-value': value,
          hidden: value === '',
          'mb-20': true,
        })}
        id={inputId}
        name={inputId}
        onChange={handleChange}
        style={{ borderColor: theme?.colour?.main }}
        value={value}
      >
        {countries?.map(item => (
          <option key={item[1]} value={item[1].toUpperCase()}>
            {item[0]}
          </option>
        ))}
      </select>
      <label htmlFor={inputId}>
        <span className="placeholder" data-testid="placeholder">
          * {t('manual_address.country')}
        </span>
        <span className="placeholder-top" data-testid="placeholder-top" style={{ color: theme?.colour?.main }}>
          {t('manual_address.country')}
        </span>
      </label>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

CountryList.propTypes = {
  error: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  inputId: PropTypes.string.isRequired,
  theme: PropTypes.oneOfType([PropTypes.object]),
  value: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])),
};

export default CountryList;
