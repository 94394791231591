import React, { useEffect, useState, useRef } from 'react';
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import InputWrapper from '../../components/input-wrapper';
import { initialErrorState } from '../../utils/initial-error-state';
import { validationSource } from '../../constants';
// import { selectRefToFocus } from '../../../store/slice';
// import { inputs } from '../../../helpers/constants';
// import { waitForElm } from '../../../helpers/dom';

import { validateFullName } from './utils';

const FullName = ({
  autoComplete,
  autofillFullName,
  afterValidationCb,
  disabled,
  inputError,
  inputId,
  inputValue,
  inputWarning,
  onBlurCb,
  onChangeCb,
  onFocusCb,
  theme,
  triggerReset,
  triggerValidation,
}) => {
  const [value, setValue] = useState(inputValue);
  const [error, setError] = useState(inputError || initialErrorState);
  const [warning, setWarning] = useState(inputWarning);
  // const refToFocus = useSelector(selectRefToFocus);
  const inputNameRef = useRef(null);
  const { t } = useTranslation();

  const possibleErrors = {
    'full_name.name_too_short': t('full_name.name_too_short'),
    'full_name.please_fill_in_first_and_last_name': t('full_name.please_fill_in_first_and_last_name'),
  };

  /*
  useEffect(() => {
    if (refToFocus === inputs.name) {
      waitForElm(`#${refToFocus}`).then(() => {
        document.getElementById(refToFocus).setAttribute('tabindex', '0');
        console.log('lets click', document.getElementById(refToFocus));
        document.getElementById(refToFocus).focus();
        inputNameRef.current.focus();
        document.getElementById(refToFocus).click();
        inputNameRef.current.click();
        window.requestAnimationFrame(() => inputNameRef.current.focus());
        window.requestAnimationFrame(() => document.getElementById(refToFocus).focus());
        console.log(document.activeElement === document.getElementById(refToFocus));
      });
    }
  }, [refToFocus]);
  */

  const handleReset = () => {
    setValue('');
    setError(initialErrorState);
    setWarning(false);
  };

  const handleChange = e => {
    setValue(e.target.value);
    if (typeof onChangeCb === 'function') {
      onChangeCb(e.target.value);
    }
  };

  const validateAndReturn = (data, source = validationSource.FROM_TYPING) => {
    const validationResult = validateFullName(data, possibleErrors, source);
    setError(validationResult);
    if (typeof afterValidationCb === 'function') {
      afterValidationCb(data, validationResult);
    }
    return validationResult;
  };

  const handleBlur = e => {
    const name = e.target.value;
    const validationResult = validateAndReturn(name);
    if (typeof onBlurCb === 'function') {
      onBlurCb(name, validationResult);
    }
  };

  const handleFocus = e => {
    setError(initialErrorState);
    setWarning(false);
    if (typeof onFocusCb === 'function') {
      onFocusCb(e);
    }
  };

  useEffect(() => {
    const autofillEffect = () => {
      const shouldChange = () => autofillFullName;
      if (shouldChange()) {
        setValue(autofillFullName);
        validateAndReturn(autofillFullName);
      }
    };
    autofillEffect();
  }, [autofillFullName]);

  useEffect(() => {
    const resetEffect = () => {
      if (triggerReset) {
        handleReset();
      }
    };
    resetEffect();
  }, [triggerReset]);

  useEffect(() => {
    const validationEffect = () => {
      if (triggerValidation) {
        validateAndReturn(value, validationSource.FROM_SUBMIT);
      }
    };
    validationEffect();
  }, [triggerValidation]);

  return (
    <InputWrapper
      autoComplete={autoComplete}
      classNameInput={classNames({
        'is-value': value,
        'has-error': error.hasError,
        'has-highlight': warning,
      })}
      ref={inputNameRef}
      disabled={disabled}
      error={error.message}
      inputId={inputId}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      placeholder={`* ${t('full_name.name_first_and_last')}`}
      placeholderTop={`${t('full_name.name_first_and_last')}`}
      type="text"
      value={value}
      theme={theme}
    />
  );
};

FullName.propTypes = {
  autoComplete: PropTypes.string,
  autofillFullName: PropTypes.string,
  afterValidationCb: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  inputError: PropTypes.oneOfType([PropTypes.object]),
  inputId: PropTypes.string.isRequired,
  inputValue: PropTypes.string.isRequired,
  inputWarning: PropTypes.bool,
  onBlurCb: PropTypes.func,
  onChangeCb: PropTypes.func,
  onFocusCb: PropTypes.func,
  theme: PropTypes.oneOfType([PropTypes.object]),
  triggerReset: PropTypes.number,
  triggerValidation: PropTypes.number,
};

export default FullName;
