import { eventEmitter, eventSubscriber, list, types } from './events';

const sdk = {
  events: eventSubscriber,
};

const init = () => {
  const firstTime = window.NoFraud == null;
  if (firstTime) {
    window.NoFraud = sdk;
  }
};

const events = {
  ...eventSubscriber,
  ...eventEmitter,
  list,
  types,
};

export { init, events };
