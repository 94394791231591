import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { isValidParsedAddress } from '../../../../commons/containers/google-address-autocomplete/utils';
import ShippingMethodImage from '../../../../commons/components/shipping-method-image';
import { amountFormatter } from '../../../../helpers/format';
import { selectCart, selectCheckout, selectAddressDownshift } from '../../../../store/slice';

import useScrollMethods from './use-scroll-methods';

import { ReactComponent as ShippingTruckIcon } from './shipping-truck.svg';

import style from './index.module.scss';

const ShippingMethods = ({
  disabled,
  isAdditionalServiceLoading,
  isLoading,
  list,
  onSelectCb,
  selectedKey,
  setAcFocusTimestamp,
  shopperCurrency,
  theme,
  isDisabled,
  setIsDisabled,
}) => {
  const { t } = useTranslation();
  const cartData = useSelector(selectCart);
  const checkoutData = useSelector(selectCheckout);
  const addressDownshift = useSelector(selectAddressDownshift);

  const listContainerRef = useRef(null);

  useScrollMethods(listContainerRef, list);

  const hasAvailableMethods = list && list.length > 0;

  const handleChange = e => {
    if (typeof onSelectCb === 'function') {
      setIsDisabled(true);
      const selectedMethodKey = e.target.id;
      onSelectCb(selectedMethodKey);
    }
  };

  const focusGoogleAddressInput = () => {
    setAcFocusTimestamp(new Date().getTime());
  };

  const renderDisabledView = () => (
    <div
      id="container-shipping-method"
      className={`${style.container} ${style['c-tin']} ${style['b-ash']} ${style['h-50']}`}
    >
      <ShippingTruckIcon className={style['s-truck']} />
      <p>{t('shipping_methods.title')}</p>
    </div>
  );

  const renderIdleView = () => (
    <div className={`${style['c-dim']} ${style['b-tin']}`}>
      <div className={`${style.container} ${style.fog} ${style['h-100']}`}>
        <ShippingTruckIcon className={style['s-truck']} width={34} height={16} />
        <p>{t('shipping_methods.idle')}</p>
      </div>
    </div>
  );

  const renderLoadingView = () => (
    <div className={style['skeleton-loader']} aria-busy={isLoading}>
      <ShippingMethodImage type="free" label="free" />
      <p className={style['skeleton-description']}>{t('shipping_methods.loading')}</p>
    </div>
  );

  const renderFailView = () => (
    <div
      className={`${style.container} ${style['b-fail']} ${style['h-100']} ${style.button}`}
      onClick={focusGoogleAddressInput}
      aria-hidden="true"
    >
      <ShippingTruckIcon className={`${style['c-dim']} ${style['s-truck']}`} />
      <div>
        <p className={`${style['c-dark']} ${style.description}`}>
          {cartData?.merchant?.settings?.customErrorMessages?.shipping_method_select
            ?.looks_like_we_cant_ship_to_this_address || t('shipping_methods.looks_like_we_cant_ship_to_this_address')}
        </p>
        {!cartData?.merchant?.settings?.customErrorMessages?.shipping_method_select
          ?.looks_like_we_cant_ship_to_this_address && (
          <p className={style['c-fail']}>{t('shipping_methods.please_choose_a_different_address')}</p>
        )}
      </div>
    </div>
  );

  const parseTypeAsMarkup = (type, label) => {
    const hasAsterisk = label.indexOf('*') > -1 && label.indexOf('*') !== label.lastIndexOf('*');
    if (hasAsterisk) {
      return (
        <>
          <span className={style['title-shipping-method']}>
            {label.substring(label.indexOf('*') + 1, label.lastIndexOf('*'))}
          </span>
          <span className={style['text-shipping-method']}>{label.substring(label.lastIndexOf('*') + 1)}</span>
        </>
      );
    }
    return label;
  };

  const getDisplayPrice = price => {
    if (price !== 0 || cartData?.merchant?.settings?.freeShippingFormat?.format === 'number') {
      return amountFormatter({
        shopperCurrency,
        value: price.toFixed(2),
      });
    }

    return t('shipping_methods.free');
  };

  const renderListItem = (item, isLargeBox, i) => {
    const isSelected = selectedKey === item.lookupKey;
    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label
        key={`${item.lookupKey}-${i}`}
        className={classNames({
          item: true,
          selected: isSelected,
          large: isLargeBox,
          [style['disable-click']]: isAdditionalServiceLoading,
        })}
        htmlFor={`${item.lookupKey}`}
        style={isSelected ? { borderColor: theme?.colour?.outline, borderWidth: 2 } : null}
      >
        <input
          className={style.checkbox}
          checked={isSelected}
          id={`${item.lookupKey}`}
          onChange={handleChange}
          type="radio"
          value={`${item.lookupKey}`}
          disabled={isDisabled}
        />
        <ShippingMethodImage type={item.type} label={item.label} />
        <p className={style['name-delivery-date']}>
          <span className={style['type-label']}>
            {parseTypeAsMarkup(t(`shipping_methods.${item.type}`), item.label)}
          </span>
          <span className={style['transit-time']}>{item.transitTime}</span>
          <span className={style.price}>{getDisplayPrice(item.price)}</span>
        </p>
      </label>
    );
  };

  const renderList = () => {
    let listStyle = null;
    switch (list.length) {
      case 1:
        listStyle = 'column-1';
        break;
      case 2:
        listStyle = 'column-2';
        break;
      case 3:
        listStyle = 'column-3';
        break;
      default:
        listStyle = 'column-4-more';
        break;
    }
    const markupList = list.map((method, i) => {
      const c1 = method.label.length >= 13 && !!method.transitTime;
      const c2 = method.label.length >= 28;
      const isLargeBox = c1 || c2;
      if (listStyle !== 'column-4-more' && isLargeBox) {
        listStyle = 'column-4-more';
      }
      return renderListItem(method, isLargeBox, i);
    });
    const markup = (
      <div className={`${style['list-container']} ${style[listStyle]}`} ref={listContainerRef}>
        {markupList}
      </div>
    );
    return markup;
  };

  if (isLoading) {
    return renderLoadingView();
  }

  if (disabled) {
    return renderDisabledView();
  }

  if (
    isValidParsedAddress(addressDownshift.addressDownshift?.value?.legacy) &&
    (!list || list?.length === 0) &&
    checkoutData?.address?.hash
  ) {
    return renderFailView();
  }

  if (hasAvailableMethods) {
    return renderList();
  }

  return renderIdleView();
};

ShippingMethods.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isAdditionalServiceLoading: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      type: PropTypes.string,
      label: PropTypes.string,
      transitTime: PropTypes.string,
      price: PropTypes.number,
    }),
  ),
  onSelectCb: PropTypes.func.isRequired,
  selectedKey: PropTypes.string,
  setAcFocusTimestamp: PropTypes.func,
  shopperCurrency: PropTypes.oneOfType([PropTypes.object]).isRequired,
  theme: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ShippingMethods;
