import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useCurrency from '../../../currency/hooks/use-currency';
import AdditionalServiceTotals from '../../../additional-service/containers/additional-service-totals';
import { amountFormatter, formatAsTotal } from '../../../../helpers/format';
import { defaultShopperCurrency } from '../../../../helpers/constants';
import useCartTotalsUtils from '../../../../hooks/useCartTotalsUtils';
import DataContext from '../../../../store/dataContext';
import UiContext from '../../../../store/uiContext';
import { pluginList } from '../../../../commons/constants';
import usePlatform from '../../../../commons/hooks/use-platform';
import { selectCart, selectCheckout } from '../../../../store/slice';

import { CouponTotalContainer } from '../../../../components/Coupon';

const CartTotals = ({ isMobile, isCollapsed, cart, checkout }) => {
  const { applyStoreCredit, giftCertificates, cartTotals, customer } = useContext(DataContext);
  const { done, isLoadingInsurance, isLoadingRoute, isLoadingSignature } = useContext(UiContext);
  const { shouldRenderBilledInUSD, getOrderTotal } = useCartTotalsUtils();
  const { t } = useTranslation();
  const { getShoppercurrency } = useCurrency();
  const { logIn } = usePlatform();
  const cartData = useSelector(selectCart);
  const checkoutData = useSelector(selectCheckout);

  const isGiftAvailable = () => (giftCertificates ? giftCertificates?.length > 0 : false);
  const renderAmount = ({ label, labelClass, apiAmount, isShipping, isNegative }) => {
    const { isPending, amount } = formatAsTotal({
      apiAmount,
      shopperCurrency: getShoppercurrency(),
      isShipping,
      isNegative,
      free: t('format.free'),
      freeShippingFormat: cart?.merchant?.settings?.freeShippingFormat?.format,
    });

    const isZero = amount === '0.00' || amount === '$0.00' || amount === 0 || amount === 0.0;
    let value = isZero && !checkout?.shippingMethodKey && !checkout?.pickUpMethodKey ? '---' : amount;

    if (!checkoutData?.shippingMethodKey && isShipping && !done) {
      value = '---';
    }

    return (
      <div className={labelClass}>
        <span>{label}</span>
        <span className={isPending ? 'tbd' : ''}>{value}</span>
      </div>
    );
  };

  const renderSkeletonAmount = (label, labelClass) => (
    <SkeletonTheme color="#e5e5e5" highlightColor="#bbb">
      <div className={labelClass}>
        <span>{label}</span>
        <Skeleton width={43} height={20} duration={1.75} />
      </div>
    </SkeletonTheme>
  );

  const renderSkeletonCoupon = () => {
    if (cart?.totals?.coupons?.length > 0) {
      return (
        <SkeletonTheme color="#e5e5e5" highlightColor="#bbb">
          <div className="default">
            <span>{t('cart_total.coupon')}</span>
            <Skeleton width={43} height={20} duration={1.75} />
          </div>
        </SkeletonTheme>
      );
    }
    return null;
  };

  const renderSkeletonGift = () => {
    if (isGiftAvailable()) {
      return (
        <SkeletonTheme color="#e5e5e5" highlightColor="#bbb">
          <div className="default">
            <span>{t('gift_total_title')}</span>
            <Skeleton width={43} height={20} duration={1.75} />
          </div>
        </SkeletonTheme>
      );
    }
    return null;
  };

  const isRouteApplied = cart?.additionalCharges.find(item => item.name === cart?.plugin?.route?.charge?.name);

  const isInsuranceApplied = cart?.additionalCharges.find(
    item => item.name === cart?.plugin?.packageInsurance?.charge?.name,
  );

  const isSignatureApplied = cart?.additionalCharges.find(
    item => item.name === cart?.plugin?.customerSignature?.charge?.name,
  );

  const renderSkeletonRoute = () => {
    if (isRouteApplied) {
      return (
        <SkeletonTheme color="#e5e5e5" highlightColor="#bbb">
          <div className="default">
            <span>{t('protection_total_container.title')}</span>
            <Skeleton width={43} height={20} duration={1.75} />
          </div>
        </SkeletonTheme>
      );
    }
    return null;
  };

  const renderSkeletonInsurance = () => {
    if (isRouteApplied) {
      return (
        <SkeletonTheme color="#e5e5e5" highlightColor="#bbb">
          <div className="default">
            <span>{t('protection_total_container.title')}</span>
            <Skeleton width={43} height={20} duration={1.75} />
          </div>
        </SkeletonTheme>
      );
    }
    return null;
  };

  const renderSkeletonSignature = () => {
    if (isSignatureApplied) {
      return (
        <SkeletonTheme color="#e5e5e5" highlightColor="#bbb">
          <div className="default">
            <span>{t('signature_total_container.title')}</span>
            <Skeleton width={43} height={20} duration={1.75} />
          </div>
        </SkeletonTheme>
      );
    }
    return null;
  };

  const renderHandlingTotal = () => {
    if (cart?.totals?.handling) {
      return renderAmount({
        label: t('cart_total.handling'),
        labelClass: 'default',
        apiAmount: cart?.totals?.handling,
        isShipping: false,
        isNegative: false,
      });
    }
    return null;
  };

  const formatingDiscount = number => {
    const stringDiscount = number.toString();
    if (stringDiscount.includes('-')) {
      const formatedDiscount = Number(stringDiscount.slice(1));
      return formatedDiscount;
    }
    return number;
  };

  const renderDiscountTotal = () => {
    if (cart?.totals?.discount) {
      return renderAmount({
        label: t('cart_total.discount'),
        labelClass: 'default',
        apiAmount: formatingDiscount(cart?.totals?.discount),
        isShipping: false,
        isNegative: true,
      });
    }
    return null;
  };

  const handleStoreCredit = () => {
    if (cart?.customer?.storeCredit?.applied >= cart?.totals?.total && checkout?.address?.line1) {
      return cart?.totals?.total;
    }
    if (cart?.customer?.storeCredit?.applied >= cart?.totals?.total) {
      return cart?.totals?.total - cart?.totals?.shipping;
    }
    if (cart?.customer?.storeCredit?.applied >= cart?.totals?.subtotal) {
      return cart?.totals?.subtotal;
    }
    return cart?.customer?.storeCredit?.applied;
  };

  const renderStoreCredit = () => {
    if (applyStoreCredit && cart?.customer?.storeCredit?.applied) {
      return renderAmount({
        label: t('cart_total.store_credit'),
        labelClass: 'default',
        apiAmount: handleStoreCredit(),
        isShipping: false,
        isNegative: true,
      });
    }
    return null;
  };

  const renderCouponTotal = () => {
    if (cart?.coupons?.length > 0) {
      return <CouponTotalContainer />;
    }
    return null;
  };

  const renderGiftTotal = () => {
    if (cart?.giftCards?.length > 0) {
      return cart.giftCards.map(gift => <CouponTotalContainer gift={gift} key={gift.code} />);
    }
    return null;
  };

  const renderRouteTotal = () => {
    if (isRouteApplied) {
      return (
        <AdditionalServiceTotals
          type={pluginList.route}
          shopperCurrency={getShoppercurrency()}
          portalSettings={cart?.merchant?.settings}
          isLoadingInsurance={isLoadingInsurance}
          isLoadingRoute={isLoadingRoute}
          isLoadingSignature={isLoadingSignature}
          done={done}
          cart={cart}
          checkout={checkout}
        />
      );
    }
    return null;
  };

  const renderInsuranceTotal = () => {
    if (isInsuranceApplied) {
      return (
        <AdditionalServiceTotals
          type={pluginList.packageInsurance}
          shopperCurrency={getShoppercurrency()}
          portalSettings={cart?.merchant?.settings}
          isLoadingInsurance={isLoadingInsurance}
          isLoadingRoute={isLoadingRoute}
          isLoadingSignature={isLoadingSignature}
          done={done}
          cart={cart}
          checkout={checkout}
        />
      );
    }
    return null;
  };

  const renderSignatureTotal = () => {
    if (isSignatureApplied) {
      return (
        <AdditionalServiceTotals
          type={pluginList.customerSignature}
          shopperCurrency={getShoppercurrency()}
          portalSettings={cart?.merchant?.settings}
          isLoadingInsurance={isLoadingInsurance}
          isLoadingRoute={isLoadingRoute}
          isLoadingSignature={isLoadingSignature}
          done={done}
          cart={cart}
          checkout={checkout}
        />
      );
    }
    return null;
  };

  const renderTotal = () => {
    const apiAmount = cartData.totals.billed || cart.totals.billed;

    const { isPending, amount } = formatAsTotal({
      labelClass: 'total',
      apiAmount,
      shopperCurrency: getShoppercurrency(),
    });

    if (shouldRenderBilledInUSD()) {
      return (
        <div className="total">
          <div className="d-flex">
            <span>
              {t('cart_total.order_total')} <span className="normal">({getShoppercurrency().baseCode})</span>
            </span>
            <span className={isPending ? 'tbd' : ''}>{amount}</span>
          </div>
          <p className="billed-usd">
            {t('cart_total.you_will_be_billed')}{' '}
            {amountFormatter({
              shopperCurrency: defaultShopperCurrency,
              value: getOrderTotal(),
            })}{' '}
            {t('cart_total.for_this_order')}
          </p>
        </div>
      );
    }
    return (
      <div className="total d-flex">
        <span>{t('cart_total.order_total')}</span>
        <span className={isPending ? 'tbd' : ''}>{amount}</span>
      </div>
    );
  };

  const renderLoadingExpanded = () => (
    <div className="collapse-wrapper">
      {renderAmount({
        label: t('cart_total.subtotal'),
        labelClass: 'default mgt-0',
        apiAmount: cart?.totals?.subtotal,
        isShipping: false,
        isNegative: false,
      })}
      {renderSkeletonAmount(t('cart_total.shipping'), 'default')}
      {renderSkeletonRoute()}
      {renderSkeletonInsurance()}
      {renderSkeletonSignature()}
      {renderSkeletonAmount(t('cart_total.tax'), 'default')}
      {renderStoreCredit()}
      {renderHandlingTotal()}
      {renderDiscountTotal()}
      {renderSkeletonCoupon()}
      {renderSkeletonGift()}
    </div>
  );

  const renderLoading = () => (
    <>
      {(!isMobile || (isMobile && !isCollapsed)) && renderLoadingExpanded()}
      {renderSkeletonAmount(t('cart_total.order_total'), 'total d-flex')}
    </>
  );

  const renderCompleted = () => (
    <>
      <div className="collapse-wrapper">
        {renderAmount({
          label: t('cart_total.subtotal'),
          labelClass: 'default mgt-0',
          apiAmount: cart?.totals?.subtotal,
          isShipping: false,
          isNegative: false,
        })}
        {renderAmount({
          label: t('cart_total.shipping'),
          labelClass: 'default',
          apiAmount: cart?.totals?.shipping,
          isShipping: true,
          isNegative: false,
        })}
        {renderRouteTotal()}
        {renderInsuranceTotal()}
        {renderSignatureTotal()}
        {renderAmount({
          label: t('cart_total.tax'),
          labelClass: 'default',
          apiAmount: cart?.totals?.tax === 0 && !checkout?.address?.line1 ? 0 : cart?.totals?.tax,
          isShipping: false,
          isNegative: false,
        })}
        {renderStoreCredit()}
        {renderHandlingTotal()}
        {renderDiscountTotal()}
        {renderCouponTotal()}
        {renderGiftTotal()}
      </div>
      {customer?.isInGroup && !done && (
        <button type="button" className="nf-login-to-custom-pricing" onClick={logIn}>
          {t('cart_total.login_to_apply_custom_pricing')}
        </button>
      )}
      {renderTotal()}
    </>
  );

  return (
    <div className="cart-totals" aria-busy={cart?.totals?.isLoading ? 'true' : 'false'}>
      {cart?.totals?.isLoading || cartTotals?.isLoading ? renderLoading() : renderCompleted()}
    </div>
  );
};

CartTotals.propTypes = {
  isMobile: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  cart: PropTypes.oneOfType([PropTypes.object]),
  checkout: PropTypes.oneOfType([PropTypes.object]),
};

export default CartTotals;
