import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './default.module.scss';

export const DefaultTemplate = () => {
  const { t } = useTranslation();

  return (
    <div className={style.wrapper}>
      <h4>{t('modal.error')}</h4>
      <p>{t('modal.we_apologies_for_this_inconvenience')}</p>
    </div>
  );
};
