import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { CROSS_MESSAGE } from '../commons/utils/cross-origin-communication';

import { selectCart } from '../store/slice';

const useTagManager = () => {
  const flags = useFlags();
  const cartData = useSelector(selectCart);

  const initialize = () => {
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line func-names
    window.gtag = function () {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };
  };

  const postGAMessage = (event, data) => {
    const measurementID = cartData?.plugin?.googleAnalytics?.settings?.measurementID;
    if (measurementID) {
      window.parent.postMessage(
        {
          type: CROSS_MESSAGE.HANDLE_GA,
          payload: {
            event,
            data: {
              ...data,
              page_title: 'NoFraud Checkout',
              page_location: window.location.href,
              event_id: Date.now(),
              send_to: data?.send_to || measurementID,
            },
          },
        },
        '*',
      );
    }
  };

  const dataLayer = response => {
    const { googleAds } = response?.cart?.plugin?.googleAnalytics?.settings || {};
    if (!flags['disable-google-analytics']) {
      const track = response?.cart?.plugin?.googleAnalytics?.event?.track?.[2];
      if (track && googleAds?.isEnabled) {
        let conversionId = googleAds?.conversionID;
        const conversionLabel = googleAds?.conversionLabel;
        if (conversionId && conversionLabel) {
          if (!conversionId.startsWith('AW-')) {
            conversionId = `AW-${conversionId}`;
          }
          postGAMessage('conversion', {
            send_to: `${conversionId}/${conversionLabel}`,
            value: track?.value,
            currency: track?.currency,
            transaction_id: track?.transaction_id,
          });
        }
      }
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return { dataLayer, postGAMessage };
};

export default useTagManager;
