import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ReactComponent as ShoppingCartIcon } from '../../../commons/assets/shopping-cart-no-fill.svg';
import { CROSS_MESSAGE } from '../../../commons/utils/cross-origin-communication';
import { storeUrlValue } from '../../../helpers/prelaunchData';
import { currentBaseUrl } from '../../../helpers/urlConst';

import { concatenateItemNames } from '../utils';

import style from './products-out-of-stock.module.scss';

export const ProductsOutOfStockTemplate = ({ data, onDeleteOutOfStockItems, isLoadingDeleteItemFromCart }) => {
  const { t } = useTranslation();

  const qtyItemsOutOfStock = data?.products?.length;
  const qtyItemsInCart = data?.cartItems?.length;
  const itemsName = concatenateItemNames(data?.products);
  const isRemoveBtnVisible = qtyItemsInCart > 1 && qtyItemsOutOfStock === 1;

  const handleRemoveAndContinue = () => {
    onDeleteOutOfStockItems();
  };

  const handleEditCart = () => {
    window.parent.postMessage(CROSS_MESSAGE.REDIRECT_EDIT_CART, storeUrlValue);
  };

  return (
    <div className={style.wrapper}>
      <h4>{t('modal.product_out_of_stock')}</h4>
      <p>{`${t('modal.we_do_not_have_enough')} "${itemsName}" ${t(
        'modal.in_stock_to_fulfil_your_order_right_now',
      )}`}</p>
      <p>{t('modal.please_adjust_your_cart')}</p>
      <p>{t('modal.we_apologies_for_this_inconvenience')}</p>
      <div className={style.actions}>
        {isRemoveBtnVisible && (
          <button type="button" className={style['btn-as-text']} onClick={handleRemoveAndContinue}>
            {t('modal.remove_items_and_continue_checkout')}
            {isLoadingDeleteItemFromCart ? (
              <img
                className={style.loading}
                alt={t('loading')}
                src={`${currentBaseUrl()}loaders/spinner-blue.png`}
                width={15}
                height={15}
              />
            ) : null}
          </button>
        )}
        <button type="button" className={style['btn-edit-cart']} onClick={handleEditCart}>
          <ShoppingCartIcon className="" width={20} height={20} /> {t('modal.edit_cart')}
        </button>
      </div>
    </div>
  );
};

ProductsOutOfStockTemplate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onDeleteOutOfStockItems: PropTypes.func.isRequired,
  isLoadingDeleteItemFromCart: PropTypes.bool.isRequired,
};
