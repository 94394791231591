/* global braintree */
export const handleGateway = (gpayKeys, setGatewayData) => {
  const auth = gpayKeys['braintree:clientKey'];
  if (auth) {
    try {
      return braintree.client.create(
        {
          authorization: auth,
        },
        (_error, clientInstance) =>
          braintree.dataCollector.create(
            {
              client: clientInstance,
            },
            (err, dataCollectorInstance) => {
              if (err) {
                return err;
              }
              setGatewayData({
                deviceData: JSON.parse(dataCollectorInstance.deviceData).correlation_id,
              });
              return true;
            },
          ),
      );
    } catch (err) {
      setGatewayData(null);
      return err;
    }
  } else {
    setGatewayData(null);
    return null;
  }
};
