import cartTotalsInitialState from './initialState';
import * as actionTypes from './actionTypes';

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case actionTypes.CartTotals_SetLoading:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.CartTotals_SetData:
      return {
        ...state,
        data: payload,
        isLoading: false,
        isReady: true,
      };
    case actionTypes.CartTotals_SetError:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case actionTypes.CartTotals_Abort:
      return {
        ...state,
        error: payload,
        isLoading: true,
      };
    case actionTypes.CartTotals_Reset:
      return {
        ...cartTotalsInitialState,
      };
    default:
      throw new Error(`Unhandled type: ${type}`);
  }
};
