import { list } from './types';

export const EventSubscriber = () => {
  const subscribe = (eventType, callback) => {
    window.document.addEventListener(eventType, data => {
      callback(data.detail);
    });
  };

  return {
    subscribe,
    list,
  };
};

export const eventSubscriber = EventSubscriber();
