import React, { useContext } from 'react';

import UiContext from '../../store/uiContext';

import './style.scss';

const Overlay = () => {
  const { showOverlay } = useContext(UiContext);
  const { logo, titleLogo, text, button, window } = showOverlay;

  const handleClick = () => {
    window.focus();
  };

  return (
    <div className="overlay">
      <div className="overlay-container">
        <div className="overlay-title">
          {logo && <img className="overlay-logo" alt="PayPal logo" src={logo} />}
          {titleLogo && <img className="overlay-logo-title" alt="PayPal" src={titleLogo} />}
        </div>
        <div className="overlay-text">{text}</div>
        <button
          className="underline-button"
          type="button"
          onClick={handleClick}
          style={{ layout: 'horizontal', color: '#fff', label: 'checkout', tagline: 'false' }}
        >
          {button}
        </button>
      </div>
    </div>
  );
};

export default Overlay;
