import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectPlugin } from '../store/slice';
import DataContext from '../store/dataContext';

export const usePlugin = () => {
  const plugin = useSelector(selectPlugin);
  const { consent } = useContext(DataContext);

  const selectPluginConsent = type => {
    if (plugin?.[type]) {
      return {
        [type]: {
          consent: {
            hasNewsletter: plugin?.[type]?.newsletter?.isEnabled ? consent.hasNewsletter : undefined,
            hasSMS: plugin?.[type]?.sms?.isEnabled ? consent.hasSms : undefined,
          },
        },
      };
    }
    return {};
  };

  return { selectPluginConsent };
};
