import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { currentPaymentMethod } from '../../../modules/payment/slice';
import { defaultUrl } from '../../../commons/hooks/use-platform';
import { paymentIdList } from '../../../helpers/constants';
import { selectCart, selectError, selectHasPluginSMS } from '../../../store/slice';
import DataContext from '../../../store/dataContext';
import UiContext from '../../../store/uiContext';
import BillingPhone from '../../BillingPhone';
import CartTotalsMobile from '../../../modules/cart/components/cartMobile';

import './style.scss';

import VenmoIcon from './submit-logo/venmo.svg';
import ApplePayIcon from './submit-logo/applepay.svg';
import GooglePayIcon from './submit-logo/googlepay.svg';
import KlarnaIcon from './submit-logo/klarna.svg';
import CreditKeyIcon from './submit-logo/creditkey.svg';
import CredovaIcon from './submit-logo/credova.svg';
import PayTomorrowIcon from './submit-logo/paytomorrow.svg';
import AmazonPayIcon from './submit-logo/amazonpay.svg';
import AfterPayIcon from './submit-logo/afterpay.svg';
import PurchaseIcon from '../logos/purchase.svg';
import AffirmIcon from './submit-logo/affirm.svg';
import PayPalIcon from './submit-logo/paypal.svg';
import PayLaterIcon from './submit-logo/paylater.svg';
import SezzleIcon from './submit-logo/sezzle.svg';
import SnapFinanceIcon from './submit-logo/snapfinance.svg';
import AcimaIcon from './submit-logo/acima.svg';
import ACHIcon from './submit-logo/ach.svg';

const paymentMethods = {
  [paymentIdList.ACH]: {
    icon: <ACHIcon />,
    title: 'ACH',
  },
  [paymentIdList.ACIMA]: {
    icon: <AcimaIcon />,
    title: 'Acima',
  },
  [paymentIdList.AFFIRM]: {
    icon: <AffirmIcon />,
    title: 'Afirm',
  },
  [paymentIdList.AFTERPAY]: {
    icon: <AfterPayIcon />,
    title: 'After Pay',
  },
  [paymentIdList.APPLEPAY]: {
    icon: <ApplePayIcon />,
    title: 'Apple Pay',
  },
  [paymentIdList.GOOGLEPAY]: {
    icon: <GooglePayIcon />,
    title: 'Google Pay',
  },
  [paymentIdList.AMAZONPAY]: {
    icon: <AmazonPayIcon />,
    title: 'Amazon Pay',
  },
  [paymentIdList.PAYPAL]: {
    icon: <PayPalIcon />,
    title: 'PayPal',
  },
  [paymentIdList.VENMO]: {
    icon: <VenmoIcon />,
    title: 'Venmo',
  },
  [paymentIdList.PAYLATER]: {
    icon: <PayLaterIcon />,
    title: 'PayLater',
  },
  [paymentIdList.KLARNA]: {
    icon: <KlarnaIcon />,
    title: 'Klarna',
  },
  [paymentIdList.CREDITKEY]: {
    icon: <CreditKeyIcon />,
    title: 'Credit Key',
  },
  [paymentIdList.CREDOVA]: {
    icon: <CredovaIcon />,
    title: 'Credova',
  },
  [paymentIdList.PAYTOMORROW]: {
    icon: <PayTomorrowIcon />,
    title: 'Pay Tomorrow',
  },
  [paymentIdList.PURCHASE]: {
    icon: <PurchaseIcon />,
    title: 'Purchase',
  },
  [paymentIdList.SEZZLE]: {
    icon: <SezzleIcon />,
    title: 'Sezzle',
  },
  [paymentIdList.SNAPFINANCE]: {
    icon: <SnapFinanceIcon />,
    title: 'Snap Finance',
  },
};

export const PaymentDescription = () => {
  const { t } = useTranslation();
  const selectedMethod = useSelector(currentPaymentMethod);

  return (
    <div className="transaction-title-wrapper">
      <p>
        {t('global_payment_info.title')} {paymentMethods[selectedMethod]?.title}.
      </p>
      <span>
        {paymentMethods[selectedMethod]?.title} {t('global_payment_info.new_tab')}.
      </span>
    </div>
  );
};

const DefaultView = ({ flags }) => {
  const { t } = useTranslation();
  const { storeUrl } = useContext(DataContext);
  const uiContext = useContext(UiContext);
  const { allowBillingAddressAdd } = uiContext;
  const selectedMethod = useSelector(currentPaymentMethod);
  const cartData = useSelector(selectCart);
  const hasPluginSMS = useSelector(selectHasPluginSMS);
  const submitError = useSelector(selectError);

  const portalSettings = cartData?.merchant?.settings;

  const currentMethodSrc = paymentMethods[selectedMethod]?.icon ? (
    <img alt="method-icon" src={paymentMethods[selectedMethod]?.icon.type} />
  ) : null;

  const handleClick = () => {
    window.top.location.href = `${storeUrl}/${defaultUrl}?preventIframeLoad=true`;
  };

  const isPhoneVisible = () =>
    ((submitError.data === 'PAYMENT DECLINED' || portalSettings.contactPhone.isEnabled) && !allowBillingAddressAdd) ||
    hasPluginSMS;

  return (
    <>
      {isPhoneVisible() && <BillingPhone flags={flags} />}
      <CartTotalsMobile />
      <div className="method-wrapper">
        <div
          tabIndex={0}
          onKeyDown={null}
          role="button"
          type="button"
          className="redirection-btn"
          onClick={handleClick}
        >
          {t('submit_btn.checkout_with')} {currentMethodSrc}
        </div>
        <PaymentDescription />
      </div>
    </>
  );
};

DefaultView.propTypes = {
  flags: PropTypes.shape({
    'disable-ddi-phone': PropTypes.bool,
  }),
};

export default DefaultView;
