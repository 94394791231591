import React from 'react';
import PropTypes from 'prop-types';

import { currentBaseUrl } from '../../../helpers/urlConst';

function ShippingMethodImage({ type, label, fallbackImage = 'shipping-companies/other.svg' }) {
  return (
    <img
      src={`${currentBaseUrl()}shipping-companies/${type}.svg`}
      alt={label}
      onError={e => (e.target.src = fallbackImage)}
    />
  );
}

ShippingMethodImage.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fallbackImage: PropTypes.string,
};

export default ShippingMethodImage;
