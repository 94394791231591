import React from 'react';
import ReactDOM from 'react-dom';
import { datadogRum } from '@datadog/browser-rum';

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { setupWorker } from 'msw';

import { shouldAbTestRedirect } from './commons/utils/ab-testing';
import {
  platformCartIdValue,
  invoiceId,
  shouldLoadPaymentButtons,
  apiMethod,
  storeUrlValue,
  merchantIdValue,
} from './helpers/prelaunchData';
import { CHECKOUT_API_URL } from './helpers/urlConst';
import { analyticsLocalStorageKey } from './helpers/constants';
import { handleInvoiceId, handleApiMethod } from './commons/utils/cross-origin-communication';
import App from './App';
import PaymentButtonsApp from './commons/components/payment-buttons-app';
import './helpers/i18n';
import { handlers } from './mocks/handlers';
import * as SDK from './sdk';

SDK.init();

class AppInitializer {
  constructor() {
    this.LDProvider = null;
    this.shouldRedirect = null;
  }

  async initialize() {
    if (process.env.REACT_APP_NODE_ENV === 'msw') {
      const msw = setupWorker(...handlers);
      msw.start();
    }

    if (shouldLoadPaymentButtons) {
      this.renderPaymentButtonsApp();
    } else if (invoiceId) {
      const platformLookupKey = localStorage.getItem(analyticsLocalStorageKey);

      if (platformLookupKey && platformLookupKey !== 'null') {
        handleInvoiceId({
          CHECKOUT_API_URL,
          merchantIdValue,
          invoiceId,
          platformCartIdValue: platformLookupKey,
        });

        localStorage.removeItem(analyticsLocalStorageKey);
      }
    } else if (apiMethod) {
      localStorage.setItem(analyticsLocalStorageKey, platformCartIdValue);

      handleApiMethod({
        apiMethod,
        CHECKOUT_API_URL,
        merchantIdValue,
        platformCartIdValue,
      });
    } else {
      this.initializeDatadogRum();
      await this.initializeLaunchDarkly();
      await this.handleAbTesting();
      this.renderCheckoutApp();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  initializeDatadogRum() {
    // eslint-disable-next-line global-require
    const { version } = require('../package.json') ?? '';
    if (!process.env.REACT_APP_DATADOG_APPLICATION_ID) {
      return;
    }
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: process.env.REACT_APP_DATADOG_SITE,
      service: process.env.REACT_APP_DATADOG_SERVICE,
      proxy: process.env.REACT_APP_DATADOG_PROXY_URL,
      env: process.env.REACT_APP_DATADOG_ENV,
      version,
      sessionSampleRate: process.env.REACT_APP_DATADOG_SESSION_SAMPLE_RATE
        ? Number(process.env.REACT_APP_DATADOG_SESSION_SAMPLE_RATE)
        : 100,
      sessionReplaySampleRate: process.env.REACT_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE
        ? Number(process.env.REACT_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE)
        : 100,
      trackSessionAcrossSubdomains: true,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      usePartitionedCrossSiteSessionCookie: true,
      enableExperimentalFeatures: ['feature_flags'],
      beforeSend: (event, context) => {
        if (event.type === 'resource' && event.resource.type === 'fetch') {
          // eslint-disable-next-line no-param-reassign
          event.context.responseHeaders = Object.fromEntries(context.response.headers);
        }

        return true;
      },
    });
  }

  async initializeLaunchDarkly() {
    this.LDProvider = await asyncWithLDProvider({
      clientSideID: process.env.REACT_APP_FEATURE_TOGGLE_CLIENT_ID,
      options: {
        inspectors: [
          {
            type: 'flag-details-changed',
            name: 'dd-inspector',
            method: details => {
              for (const [key, detail] of Object.entries(details)) {
                datadogRum.addFeatureFlagEvaluation(key, detail.value);
              }
            },
          },
        ],
      },
      user: {
        name: storeUrlValue,
        key: merchantIdValue,
      },
      reactOptions: {
        useCamelCaseFlagKeys: false,
      },
    });
  }

  async handleAbTesting() {
    this.shouldRedirect = await shouldAbTestRedirect();
  }

  renderCheckoutApp() {
    if (typeof this.shouldRedirect === 'boolean') {
      if (this.shouldRedirect) {
        window.parent.postMessage('REDIRECT_AB_TEST', '*');
      } else {
        this.renderUi();
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderPaymentButtonsApp() {
    document.querySelector('#payment-buttons-app').setAttribute('style', '');

    ReactDOM.render(
      <PaymentButtonsApp section={shouldLoadPaymentButtons} />,
      document.querySelector('#payment-buttons-app'),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderUi() {
    document.querySelector('#checkout-app').setAttribute('style', 'align-items:center;height:100vh;');
    window.parent.postMessage('LOAD_IFRAME_CONTENTS', '*');

    ReactDOM.render(
      <this.LDProvider>
        <App />
      </this.LDProvider>,
      document.querySelector('#checkout-app'),
    );
  }
}

const appInitializer = new AppInitializer();
appInitializer.initialize();
