import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { selectError } from '../store/slice';
import DataContext from '../store/dataContext';

const useApiPostOrderError = () => {
  const { apiPostOrderError } = useContext(DataContext);
  const submitError = useSelector(selectError);

  const hasCreditCardError = () => submitError.data === 'PAYMENT DECLINED';

  const hasBillingError = () =>
    submitError.data === 'INVALID BILLING ADDRESS' || submitError.data === 'INVALID BILLING ADDRESS / PAYMENT DECLINED';

  const hasPhoneError = () =>
    submitError.data === 'PAYMENT DECLINED' ||
    submitError.data === 'INVALID BILLING ADDRESS' ||
    submitError.data === 'INVALID BILLING ADDRESS / PAYMENT DECLINED';

  const getApiPostOrderErrorCode = () => apiPostOrderError?.errorCode?.id;

  return { hasCreditCardError, hasBillingError, hasPhoneError, getApiPostOrderErrorCode };
};

export default useApiPostOrderError;
