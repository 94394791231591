import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React, { useContext } from 'react';

import { currentBaseUrl } from '../../../helpers/urlConst';
import UiContext from '../../../store/uiContext';
import { useCloseIframe } from '../../../commons/hooks/use-close-iframe';

import './style.scss';

const CloseWrapperBtn = () => {
  const { allowManualAddress, showExtraInfoBackground } = useContext(UiContext);
  const { t } = useTranslation();
  const { handleClick } = useCloseIframe();

  return (
    <button
      className={`close-wrapper-btn ${classNames({
        'd-none': allowManualAddress,
        'has-extra-info': showExtraInfoBackground,
      })}`}
      type="button"
      onClick={handleClick}
    >
      <img alt="close-icon" src={`${currentBaseUrl()}assets/close.svg`} />
      <span>{t('checkout.close_checkout')}</span>
    </button>
  );
};

export default CloseWrapperBtn;
