import { errorsInitialStateSingle, errorsInitialState } from './initialState';
import * as actionTypes from './actionTypes';

const getNewStateAddToField = errorPayload => {
  let newErrorStateOnField = {};
  const hasError = !!errorPayload?.message;
  newErrorStateOnField = {
    ...errorsInitialStateSingle,
    ...errorPayload,
    hasError,
  };
  return newErrorStateOnField;
};

const parseAddToField = payload => {
  const newState = {};
  if (payload) {
    for (const [errorKey, errorPayload] of Object.entries(payload)) {
      newState[errorKey] = getNewStateAddToField(errorPayload);
    }
  }
  return newState;
};

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case actionTypes.Errors_AddToField: {
      const newState = parseAddToField(payload);
      return {
        ...state,
        ...newState,
      };
    }
    case actionTypes.Errors_ResetField:
      return {
        ...state,
        [payload.key]: errorsInitialStateSingle,
      };
    case actionTypes.Errors_ResetAll:
      return errorsInitialState;
    default:
      throw new Error(`Unhandled type: ${type}`);
  }
};
