import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import DataContext from '../store/dataContext';
import UiContext from '../store/uiContext';
import useApiStatus from '../store/apiStatus/useApiStatus';
import { selectCart, selectCheckout } from '../store/slice';
import { EVENT, trigger } from '../commons/utils/custom-event';
import useReset from '../hooks/useReset';
import { CHECKOUT_VAULT_URL, isPopup } from './urlConst';

import { status } from './constants';
import { getStoredData } from './localStorageHelper';

export const ONE_HOUR_MS = 3600000;

const currentTime = new Date().getTime();

export const useCheckoutSession = () => {
  const { updateCheckoutState, TO_SHIPPING_ADDRESS_ADD, TO_SHIPPING_METHOD_SELECT, TO_CARD_ADD } =
    useContext(UiContext);
  const { shippingName, shippingAddress1, shippingMethodId, email, updateCheckoutSession, device, isIframeLoaded } =
    useContext(DataContext);
  const { set: setApiStatus } = useApiStatus();
  const cartData = useSelector(selectCart);
  const checkoutData = useSelector(selectCheckout);

  const { handleReset } = useReset();
  const checkoutSession = getStoredData('checkoutSession');
  const isSessionActive = currentTime < checkoutSession?.expiryDate || isPopup;

  const updateUi = () => {
    if (checkoutSession?.name && shippingName) {
      updateCheckoutState(TO_SHIPPING_ADDRESS_ADD);
    }
    if (checkoutSession?.address && shippingAddress1) {
      updateCheckoutState(TO_SHIPPING_METHOD_SELECT);
    } else {
      updateCheckoutState(TO_SHIPPING_ADDRESS_ADD);
    }
    if (checkoutSession?.shippingMethodSelected && shippingMethodId) {
      updateCheckoutState(TO_CARD_ADD);
    }
  };

  const resumeSession = async () => {
    if (device.isIncognito) {
      setApiStatus({ isSessionReady: status.READY });
      return;
    }
    if (isSessionActive) {
      setApiStatus({ isSessionReady: status.LOADING });
      updateUi();
    } else {
      await handleReset();
    }
  };

  useEffect(() => {
    if (checkoutData?.status === 'fulfilled' && checkoutData?.customer?.email && isIframeLoaded) {
      const apiResponse = {
        availableStoreCredit: 0,
        cardTokenization: {
          onBehalfOf: cartData?.payment?.direct?.onBehalfOf,
          tokenizedFor: cartData?.payment?.direct?.tokenisedFor,
          url: CHECKOUT_VAULT_URL,
        },
        newCardOnBehalfOf: cartData?.payment?.direct?.onBehalfOf,
        suggestions: cartData?.suggestions,
        cartTotals: cartData?.totals,
        success: true,
      };
      trigger(EVENT.EMAIL_RESPONSE_READY, apiResponse);
      updateCheckoutSession('email', email);
      setApiStatus({ isSessionReady: status.READY });
    }
  }, [cartData, isIframeLoaded]);

  return { isSessionActive, resumeSession };
};
