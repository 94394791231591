import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeGiftCard } from '../components/Coupon/CouponServices';
import { EVENT, trigger } from '../commons/utils/custom-event';
import useCartTotals from '../store/cartTotals/useCartTotals';
import { setPaymentMethod } from '../modules/payment/slice';
import useApiStatus from '../store/apiStatus/useApiStatus';
import { paymentIdList } from '../helpers/constants';
import useErrors from '../store/errors/useErrors';
import DataContext from '../store/dataContext';
import UiContext from '../store/uiContext';
import { selectCart, clearStore } from '../store/slice'; // deleteData

const useReset = () => {
  const {
    reset,
    cartId,
    device,
    email,
    contextShippingAddress,
    shippingMethodId,
    coupon,
    updateCoupon,
    setResetTimestamp,
    updateBillingPhone,
    setApiPostOrderError,
  } = useContext(DataContext);
  const { resetErrorFromCredit, updateCheckoutState, TO_USER_ADD } = useContext(UiContext);
  const dispatch = useDispatch();
  const cartData = useSelector(selectCart);

  const { reset: resetApiStatus } = useApiStatus();
  const { reset: resetCartTotals } = useCartTotals();
  const { resetAll: resetAllErrors } = useErrors();

  const handleReset = async () => {
    const location = new URL(document.location);
    const params = location.searchParams;
    const paymentName = params.get('paymentName');
    setResetTimestamp(Date.now());
    resetApiStatus();
    resetCartTotals();
    dispatch(setPaymentMethod(paymentName || paymentIdList.CREDIT_CARD));
    dispatch(clearStore());
    const removeSavedCoupon = cartData?.coupons?.find(item => item.code !== coupon.code);
    if (removeSavedCoupon) {
      await removeGiftCard({
        cartId,
        code: coupon.code,
        deviceId: device?.id,
        email,
        contextShippingAddress,
        shippingMethodId,
        methods: cartData?.shippingMethods,
        updateCoupon,
      });
    }
    updateBillingPhone('');
    reset();
    resetErrorFromCredit();
    updateCheckoutState(TO_USER_ADD);
    setApiPostOrderError({});
    resetAllErrors();
    trigger(EVENT.RESET_CHECKOUT);
  };

  return { handleReset };
};

export default useReset;
