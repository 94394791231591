import { useContext } from 'react';

import DataContext from '../dataContext';
import * as actionTypes from './actionTypes';

const useErrors = () => {
  const { errors, errorsDispatch } = useContext(DataContext);

  const addErrorToField = payload =>
    errorsDispatch({
      type: actionTypes.Errors_AddToField,
      payload,
    });

  const resetErrorOnField = key => errorsDispatch({ type: actionTypes.Errors_ResetField, payload: { key } });

  const resetAll = () => errorsDispatch({ type: actionTypes.Errors_ResetAll });

  const hasErrorOnField = key => errors[key].hasError;

  const hasHighlightOnField = key => errors[key].hasHighlight;

  return { errors, addErrorToField, resetErrorOnField, resetAll, hasErrorOnField, hasHighlightOnField };
};

export default useErrors;
