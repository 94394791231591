const inFlight = {};

let inFlightCounter = 0;

export const CROSS_MESSAGE = Object.freeze({
  FETCH_FROM_WINDOW: 'FETCH_FROM_WINDOW',
  FETCH_LOCAL_STORAGE: 'FETCH_LOCAL_STORAGE',
  SET_LOCAL_STORAGE: 'SET_LOCAL_STORAGE',
  REMOVE_LOCAL_STORAGE: 'SET_LOCAL_STORAGE',
  FETCH_SESSION_STORAGE: 'FETCH_SESSION_STORAGE',
  SET_SESSION_STORAGE: 'SET_SESSION_STORAGE',
  REMOVE_SESSION_STORAGE: 'REMOVE_SESSION_STORAGE',
  WINDOW_RESPONSE: 'WINDOW_RESPONSE',
  CLOSE_IFRAME: 'CLOSE_IFRAME',
  CLOSE_REDIRECT: 'CLOSE_REDIRECT',
  CLOSE_REFRESH: 'CLOSE_REFRESH',
  SET_COOKIE: 'SET_COOKIE',
  REQUEST_CHECKOUTAPI_CARTID: 'REQUEST_CHECKOUTAPI_CARTID',
  RECEIVE_CHECKOUTAPI_CARTID: 'RECEIVE_CHECKOUTAPI_CARTID',
  GET_LANGUAGE: 'GET_LANGUAGE',
  TRACK_ACTIVE_CART: 'TRACK_ACTIVE_CART',
  HANDLE_GA: 'HANDLE_GA',
  REDIRECT_EDIT_CART: 'REDIRECT_EDIT_CART',
});

export const crossRequest = (type, content, timeout) => {
  const id = `_${inFlightCounter++}`;
  const p = new Promise((r, x) => (inFlight[id] = { resolver: r, rejecter: x }));
  if (timeout) {
    setTimeout(() => {
      const pending = inFlight[id];
      if (!pending) return;
      delete inFlight[id];
      pending.rejecter('timeout');
    }, timeout);
  }
  window.parent.postMessage({ type, content, id }, '*');
  return p;
};

window.addEventListener('message', e => {
  const messageData = e.data;
  if (messageData?.type === 'WINDOW_RESPONSE') {
    const pending = inFlight[messageData.id];
    if (!pending) return;
    delete inFlight[messageData.id];
    pending.resolver(messageData.content);
  }
});

export const handleResetPassword = async email => {
  try {
    if (email) {
      window.parent.postMessage(
        {
          type: 'HANDLE_RESET',
          email,
        },
        '*',
      );
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export const handleInvoiceId = async ({ CHECKOUT_API_URL, invoiceId, merchantIdValue, platformCartIdValue }) => {
  try {
    const url = `${CHECKOUT_API_URL}/analytics/merchant:${merchantIdValue}/checkout/${platformCartIdValue}/native-complete`;
    const postAnalyticsComplete = await fetch(url, {
      body: JSON.stringify({
        order: { lookupKey: invoiceId },
      }),
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: false,
    });
    if (postAnalyticsComplete.ok) {
      const data = await postAnalyticsComplete.json();

      if (data.skipCheckout) {
        window.parent.postMessage('REDIRECT_AB_TEST', '*');
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

const createCart = async (CHECKOUT_API_URL, merchantIdValue, platformCartIdValue) => {
  try {
    const response = await fetch(`${CHECKOUT_API_URL}/checkout/merchant:${merchantIdValue}`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        lookupKey: platformCartIdValue,
      }),
      withCredentials: false,
    });
    const content = await response.json();
    const { lookupKey: cartId } = content.cart;
    return cartId;
  } catch (err) {
    return merchantIdValue;
  }
};

export const handleApiMethod = async ({ apiMethod, CHECKOUT_API_URL, merchantIdValue, platformCartIdValue }) => {
  switch (apiMethod) {
    case CROSS_MESSAGE.REQUEST_CHECKOUTAPI_CARTID: {
      const cartId = await createCart(CHECKOUT_API_URL, merchantIdValue, platformCartIdValue);
      window.parent.postMessage(
        {
          type: CROSS_MESSAGE.RECEIVE_CHECKOUTAPI_CARTID,
          payload: {
            cartId,
            merchantId: merchantIdValue,
          },
        },
        '*',
      );
      break;
    }
    case CROSS_MESSAGE.TRACK_ACTIVE_CART: {
      const url = `${CHECKOUT_API_URL}/analytics/merchant:${merchantIdValue}/checkout/${platformCartIdValue}/native-start`;
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        withCredentials: false,
      });
      break;
    }
    default:
      // eslint-disable-next-line no-console
      console.error(`Api method ${apiMethod} not supported`);
      break;
  }
};
