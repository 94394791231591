import React from 'react';
import { Provider } from 'react-redux';
import { withLDConsumer as featureFlagClient } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { setupStore as newSetupStore } from './commons/redux/store';
import NewApp from './NewApp';

import { DataContextProvider as NewDataContextProvider } from './store/dataContext';
import { UiContextProvider as NewUiContextProvider } from './store/uiContext';

const App = ({ flags }) => (
  <NewUiContextProvider>
    <NewDataContextProvider>
      <Provider store={newSetupStore()}>
        <NewApp flags={flags} />
      </Provider>
    </NewDataContextProvider>
  </NewUiContextProvider>
);

App.propTypes = {
  flags: PropTypes.shape({
    'disable-bigcom-checkout-form': PropTypes.bool,
  }),
};

export default featureFlagClient()(App);
