import apiStatusInitialState from './initialState';
import * as actionTypes from './actionTypes';

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case actionTypes.ApiStatus_Set:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.ApiStatus_Reset:
      return {
        ...apiStatusInitialState,
      };
    default:
      throw new Error(`Unhandled type: ${type}`);
  }
};
