import { memoizeDebounce } from '../../commons/utils/memoize-debounce';

export const EventEmitter = options => {
  const doEmit = (type, content) => {
    if (window.parent && process.env.JEST_WORKER_ID == null) {
      const location = new URL(document.location);
      const params = location.searchParams;
      const storeUrl = params.get('storeUrl') ?? '*';
      window.parent.postMessage(
        {
          type: 'NoFraudEvent',
          detail: content.detail,
        },
        storeUrl,
      );
    } else {
      [type, 'all', '*'].forEach(eventType => {
        const event = new CustomEvent(eventType, content);
        window.document.dispatchEvent(event);
      });
    }
  };

  const memoizeDebounceResolver = (type, content) =>
    JSON.stringify({
      type,
      payload: { ...content.detail.payload, timestamp: undefined },
    });

  const emitFunc = options?.deduplication
    ? memoizeDebounce(doEmit, options.deduplication.wait, options.deduplication.options, memoizeDebounceResolver)
    : doEmit;

  const emit = (type, payload) => {
    const content = { detail: { type, payload } };
    emitFunc(type, content);
  };

  return {
    emit,
  };
};

export const eventEmitter = EventEmitter({ deduplication: { wait: 500, options: { maxWait: 1000 } } });
