import React, { useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { currentPaymentMethod } from '../../modules/payment/slice';
import usePortalSettings from '../../hooks/usePortalSettings';
import { paymentIdList } from '../../helpers/constants';
import DataContext from '../../store/dataContext';
import UiContext from '../../store/uiContext';
import {
  selectCheckout,
  selectHasPluginNewsletter,
  selectPluginNewsletterDefault,
  setShouldSave,
} from '../../store/slice';

const WarnTerms = () => {
  const { t } = useTranslation();
  const { portalSettings } = usePortalSettings();
  const { setIsCheckoutTermsModalOpen } = useContext(UiContext);
  const { registeredPaymentMethods, consent, updateConsent } = useContext(DataContext);
  const dispatch = useDispatch();
  const selectedMethod = useSelector(currentPaymentMethod);
  const newsletterDefault = useSelector(selectPluginNewsletterDefault);
  const showNewsletterCheckbox = useSelector(selectHasPluginNewsletter);
  const checkoutData = useSelector(selectCheckout);

  const showCustomTerms = portalSettings.termsAndConditions.isEnabled;

  const customTermsRef = useRef(null);
  const customTerms = customTermsRef?.current;

  const updateShouldSave = payload => {
    dispatch(setShouldSave(payload));
  };

  useEffect(() => {
    if (consent?.hasNewsletter === undefined) {
      updateConsent({
        hasNewsletter: newsletterDefault,
      });
    }
  }, [newsletterDefault]);

  useEffect(() => {
    if (customTerms) {
      customTerms.querySelectorAll('a').forEach(elem => {
        elem.setAttribute('target', '_blank');
      });
    }
  }, [customTerms]);

  const showPayment = () => {
    const platforms = {
      [paymentIdList.ACH]: `${t('submit_btn.checkout_with')} ${t('payment_method.ach.label')}`,
      [paymentIdList.ACIMA]: `${t('submit_btn.checkout_with')} ${t('payment_method.acima.label')}`,
      [paymentIdList.AFFIRM]: `${t('submit_btn.checkout_with')} ${t('payment_method.affirm.label')}`,
      [paymentIdList.AFTERPAY]: `${t('submit_btn.checkout_with')} ${t('payment_method.afterpay.label')}`,
      [paymentIdList.CREDIT_CARD]: t('warn_terms.credit_card'),
      [paymentIdList.AMAZONPAY]: t('payment_method.amazonpay.label'),
      [paymentIdList.VENMO]: t('payment_method.venmo.label'),
      [paymentIdList.KLARNA]: `${t('submit_btn.checkout_with')} ${t('payment_method.klarna.label')}`,
      [paymentIdList.CREDITKEY]: `${t('submit_btn.checkout_with')} ${t('payment_method.creditkey.label')}`,
      [paymentIdList.CREDOVA]: `${t('submit_btn.checkout_with')} ${t('payment_method.credova.label')}`,
      [paymentIdList.PAYTOMORROW]: `${t('submit_btn.checkout_with')} ${t('payment_method.paytomorrow.label')}`,
      [paymentIdList.SEZZLE]: `${t('submit_btn.checkout_with')} ${t('payment_method.sezzle.label')}`,
      [paymentIdList.SNAPFINANCE]: `${t('submit_btn.checkout_with')} ${t('payment_method.snapfinance.label')}`,
      [paymentIdList.APPLEPAY]: `${t('submit_btn.checkout_with')} ${t('payment_method.applepay.label')}`,
      [paymentIdList.PAYLATER]: `${t('submit_btn.checkout_with')} ${t('payment_method.paylater.label')}`,
    };

    if (selectedMethod === paymentIdList.PAYPAL) {
      if (registeredPaymentMethods?.paylater?.isEnabled) {
        return t('warn_terms.paypalOrPaylater');
      }
      return t('warn_terms.paypal');
    }
    if (platforms[selectedMethod]) {
      return platforms[selectedMethod];
    }

    return '';
  };

  return (
    <>
      <div className="warn-terms">
        <div className="warn mr-5">{`${t('warn_terms.powered_by')} ${t('warn_terms.by_pressing')} ${showPayment()} ${t(
          'warn_terms.you_agree',
        )}`}</div>
        <div className="warn flex">
          NoFraud&apos;s
          <button className="warn-link" type="button" onClick={() => setIsCheckoutTermsModalOpen('Terms of Use')}>
            {t('warn_terms.terms_of_use')}
            {showCustomTerms ? '' : '.'}
          </button>
        </div>
      </div>
      {showCustomTerms && (
        <div className="warn-terms">
          <div
            className="warn"
            ref={customTermsRef}
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: portalSettings.termsAndConditions.value }}
          />
        </div>
      )}
      <div className="warn-terms checkbox">
        <label className="container">
          <p className="warn">{t('warn_terms.save_my_info')}</p>
          <input
            type="checkbox"
            checked={checkoutData.shouldSave}
            onChange={() => updateShouldSave(!checkoutData.shouldSave)}
          />
          <span className="checkmark" />
        </label>
        <button className="warn-link" type="button" onClick={() => setIsCheckoutTermsModalOpen('Privacy Policy')}>
          {t('warn_terms.privacy_policy')}
        </button>
      </div>
      {showNewsletterCheckbox && (
        <div className="warn-terms checkbox">
          <label className="container">
            <p className="warn">{t('warn_terms.subscribe_to_newsletter')}</p>
            <input
              type="checkbox"
              checked={consent?.hasNewsletter || false}
              onChange={evt =>
                updateConsent({
                  hasNewsletter: evt.target.checked,
                })
              }
            />
            <span className="checkmark" />
          </label>
        </div>
      )}
    </>
  );
};

export default WarnTerms;
