export const errorsInitialStateSingle = {
  hasError: false,
  hasHighlight: false,
  message: '',
};

export const errorsInitialState = {
  billingName: errorsInitialStateSingle,
  billingAddress: errorsInitialStateSingle,
  billingPhone: errorsInitialStateSingle,
};
