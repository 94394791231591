export const messageType = Object.freeze({
  CC_INITIAL_STATE: 'CC_INITIAL_STATE',
  CC_UPDATE_STATE: 'CC_UPDATE_STATE',
  CC_RESET: 'CC_RESET',
  CC_SUBMIT: 'CC_SUBMIT',
  CC_CARD_TOKENIZATION: 'CC_CARD_TOKENIZATION',
  CC_IS_READY: 'CC_IS_READY',
  CC_AUTOFILL: 'CC_AUTOFILL',
  CC_EMPTY_ADDRESS: 'CC_EMPTY_ADDRESS',
  CC_SUBMIT_FAILED: 'CC_SUBMIT_FAILED',
  CC_EDIT_EMAIL: 'CC_EDIT_EMAIL',
  CC_SUGGESTIONS_ARE_VISIBLE: 'CC_SUGGESTIONS_ARE_VISIBLE',
  CC_UPDATE_LANGUAGE: 'CC_UPDATE_LANGUAGE',
  CC_TOKENIZE_STATUS: 'CC_TOKENIZE_STATUS',
});
