import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { currentBaseUrl } from '../../helpers/urlConst';

import './CustomModal.scss';

const CustomModal = ({ activeItem, setActive, handleClose, children }) => {
  const { t } = useTranslation();
  return (
    activeItem && (
      <div className="modal-wrapper">
        <div className="modal">
          <div className="modal-header">
            <nav className="modal-navigation">
              {children?.map(item => (
                <button
                  type="button"
                  className={item?.props?.title === activeItem ? 'tab-terms active' : 'tab-terms'}
                  key={item?.props?.title}
                  onClick={() => setActive(item?.props?.title)}
                >
                  {item?.props?.title}
                </button>
              ))}
            </nav>
            <div
              className="close-modal-buttons"
              onClick={() => handleClose(null)}
              onKeyDown={null}
              role="button"
              tabIndex={-1}
            >
              <p className="close-button-p">{t('modal.close')}</p>
              <button type="button" alt="Close Terms and Conditions" className="card-edit">
                <img className="edit-card" src={`${currentBaseUrl()}assets/close.svg`} alt="Edit Card" />
              </button>
            </div>
          </div>
          {children.find(item => item.props.title === activeItem)}
        </div>
      </div>
    )
  );
};

CustomModal.propTypes = {
  activeItem: PropTypes.string,
  setActive: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default CustomModal;
