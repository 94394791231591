import { pluginList } from '../../../commons/constants';
import { status } from '../../../helpers/constants';

export const useAdditionalService = ({
  type,
  isAppMainLoading,
  isLoadingInsurance,
  isLoadingRoute,
  isLoadingSignature,
  shippingStatus,
  formStatus,
  isLoadingCoupons,
}) => {
  const isRoute = type === pluginList.route;
  const isPackageInsurance = type === pluginList.packageInsurance;
  const isCustomerSignature = type === pluginList.customerSignature;
  const isAnyShippingEndpointPending =
    shippingStatus?.isLoadingWithShippingAddress || shippingStatus?.isLoadingWithShippingMethod;
  const isDisabled =
    isAppMainLoading ||
    (isRoute && (isLoadingSignature || isLoadingInsurance)) ||
    (isPackageInsurance && (isLoadingSignature || isLoadingRoute)) ||
    (isCustomerSignature && (isLoadingInsurance || isLoadingRoute)) ||
    formStatus === status.LOADING ||
    isLoadingCoupons ||
    isAnyShippingEndpointPending;
  return { isRoute, isPackageInsurance, isCustomerSignature, isDisabled };
};
