import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { status } from '../commons/containers/manual-address/container/utils';

const UiContext = React.createContext({});

export const UiContextProvider = props => {
  const TO_USER_ADD = 'to_user_add';
  const TO_SHIPPING_ADDRESS_ADD = 'to_shipping_address_add';
  const TO_SHIPPING_METHOD_SELECT = 'to_shipping_method_select';
  const TO_CARD_ADD = 'to_card_add';
  const TO_BILLING_ADDRESS_ADD = 'to_billing_address_add';
  const TO_PAYMENT_ADD = 'to_payment_add';
  const TO_PAYMENT_ADD_WITH_BILLING = 'to_payment_add_with_billing';
  const TO_DONE = 'to_done';
  const [checkoutStage, setCheckoutStage] = useState(TO_USER_ADD);

  const [allowUserAdd, setAllowUserAdd] = useState(true);
  const [allowShippingAddressAdd, setAllowShippingAddressAdd] = useState(false);
  const [allowBillingAddressAdd, setAllowBillingAddressAdd] = useState(false);
  const [done, setDone] = useState(false);
  const [hasExtraInfo, setHasExtraInfo] = useState(false);
  const [showExtraInfoBackground, setShowExtraInfoBackground] = useState(false);
  const [checkCardRules, setCheckCardRules] = useState(false);
  const [isShippingSectionCollapsed, setIsShippingSectionCollapsed] = useState(false);
  const [showUserAddControl, setShowUserAddControl] = useState(true);
  const [isCheckoutItemsCollapsed, setIsCheckoutItemsCollapsed] = useState(false);
  const [allowManualAddress, setAllowManualAddress] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [isCheckoutTermsModalOpen, setIsCheckoutTermsModalOpen] = useState(null);
  const [showOverlay, setShowOverlay] = useState(null);
  const [isLoadingRoute, setIsLoadingRoute] = useState(false);
  const [isLoadingInsurance, setIsLoadingInsurance] = useState(false);
  const [isLoadingSignature, setIsLoadingSignature] = useState(false);
  const [isLoadingCoupons, setIsLoadingCoupons] = useState(false);
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const [openPopup, setOpenPopup] = useState(null);
  const [openPreOrder, setOpenPreOrder] = useState(false);
  const [isBillingError, setIsBillingError] = useState(false);
  const [formStatus, setFormStatus] = useState(status.IDLE);

  const [localError, setLocalError] = useState({
    nameError: false,
    addressError: false,
    creditCardError: false,
  });
  const [isCardDeclined, setIsCardDeclined] = useState({
    error: false,
    warning: false,
  });

  const updateLocalError = (nameError, addressError, creditCardError) => {
    setLocalError({
      nameError,
      addressError,
      creditCardError,
    });
  };

  const updateCardDeclined = (error, warning) => {
    setIsCardDeclined({
      error,
      warning,
    });
  };

  const resetErrorFromCredit = () => {
    updateLocalError(false, false, false);
    updateCardDeclined(false, false);
  };

  const { children } = props;

  const updateCheckoutState = state => {
    switch (state) {
      case TO_USER_ADD:
        setCheckoutStage(TO_USER_ADD);
        setAllowUserAdd(true);
        setAllowShippingAddressAdd(false);
        setAllowBillingAddressAdd(false);
        setDone(false);
        setShowUserAddControl(true);
        break;
      case TO_SHIPPING_ADDRESS_ADD:
        setCheckoutStage(TO_SHIPPING_ADDRESS_ADD);
        setAllowUserAdd(true);
        setAllowShippingAddressAdd(true);
        setAllowBillingAddressAdd(false);
        setDone(false);
        setShowUserAddControl(false);
        break;
      case TO_SHIPPING_METHOD_SELECT:
        setCheckoutStage(TO_SHIPPING_METHOD_SELECT);
        setAllowUserAdd(true);
        setAllowShippingAddressAdd(true);
        setAllowBillingAddressAdd(allowBillingAddressAdd);
        setDone(false);
        setShowUserAddControl(false);
        break;
      case TO_CARD_ADD:
        setCheckoutStage(TO_CARD_ADD);
        setAllowUserAdd(true);
        setAllowShippingAddressAdd(true);
        setAllowBillingAddressAdd(allowBillingAddressAdd);
        setDone(false);
        setShowUserAddControl(false);
        break;
      case TO_PAYMENT_ADD:
        setCheckoutStage(TO_PAYMENT_ADD);
        setAllowUserAdd(true);
        setAllowShippingAddressAdd(true);
        setAllowBillingAddressAdd(allowBillingAddressAdd);
        setDone(false);
        setShowUserAddControl(false);
        break;
      case TO_PAYMENT_ADD_WITH_BILLING:
        setCheckoutStage(TO_PAYMENT_ADD_WITH_BILLING);
        setAllowUserAdd(true);
        setAllowShippingAddressAdd(true);
        setAllowBillingAddressAdd(true);
        setDone(false);
        setShowUserAddControl(false);
        break;
      case TO_BILLING_ADDRESS_ADD:
        setCheckoutStage(TO_BILLING_ADDRESS_ADD);
        setAllowUserAdd(true);
        setAllowShippingAddressAdd(true);
        setAllowBillingAddressAdd(true);
        setDone(false);
        setShowUserAddControl(false);
        break;
      case TO_DONE:
        setCheckoutStage(TO_DONE);
        setAllowUserAdd(false);
        setAllowShippingAddressAdd(false);
        setAllowBillingAddressAdd(false);
        setDone(true);
        setShowUserAddControl(false);
        break;
      default:
        setCheckoutStage(TO_USER_ADD);
        setAllowUserAdd(false);
        setAllowShippingAddressAdd(false);
        setAllowBillingAddressAdd(false);
        setDone(false);
        setShowUserAddControl(true);
        setEditEmail(false);
    }
  };

  const value = {
    TO_USER_ADD,
    TO_SHIPPING_ADDRESS_ADD,
    TO_SHIPPING_METHOD_SELECT,
    TO_CARD_ADD,
    TO_BILLING_ADDRESS_ADD,
    TO_PAYMENT_ADD,
    TO_PAYMENT_ADD_WITH_BILLING,
    TO_DONE,
    allowUserAdd,
    allowShippingAddressAdd,
    isShippingSectionCollapsed,
    isCheckoutItemsCollapsed,
    allowBillingAddressAdd,
    done,
    showUserAddControl,
    allowManualAddress,
    showOverlay,
    openPopup,
    checkCardRules,
    showLoadingPage,
    formStatus,
    isLoadingCoupons,
    hasExtraInfo,
    editEmail,
    localError,
    isCardDeclined,
    isCheckoutTermsModalOpen,
    isLoadingInsurance,
    isLoadingRoute,
    isLoadingSignature,
    checkoutStage,
    showExtraInfoBackground,
    openPreOrder,
    isBillingError,
    setIsBillingError,
    setOpenPreOrder,
    setShowExtraInfoBackground,
    setHasExtraInfo,
    setIsLoadingCoupons,
    setFormStatus,
    setShowLoadingPage,
    setAllowBillingAddressAdd,
    setCheckCardRules,
    setOpenPopup,
    setShowOverlay,
    setIsShippingSectionCollapsed,
    setIsCheckoutItemsCollapsed,
    updateCheckoutState,
    setShowUserAddControl,
    setAllowManualAddress,
    setEditEmail,
    updateLocalError,
    updateCardDeclined,
    resetErrorFromCredit,
    setIsCheckoutTermsModalOpen,
    setIsLoadingInsurance,
    setIsLoadingRoute,
    setIsLoadingSignature,
  };

  return <UiContext.Provider value={value}>{children}</UiContext.Provider>;
};

UiContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default UiContext;
