import React, { useContext } from 'react';

import useCartTotalsUtils from '../../hooks/useCartTotalsUtils';
import { currentBaseUrl } from '../../helpers/urlConst';
import { reformatedAmount } from '../../helpers/format';
import useCurrency from '../../modules/currency/hooks/use-currency';

import DataContext from '../../store/dataContext';
import UiContext from '../../store/uiContext';

import { ReactComponent as Arrow } from './icon/Arrow.svg';

import style from './MobileMiniCart.module.scss';

const MobileMiniCart = () => {
  const { getOrderTotal } = useCartTotalsUtils();

  const { getShoppercurrency } = useCurrency();

  const { cartDetails } = useContext(DataContext);
  const { setIsCheckoutItemsCollapsed, isCheckoutItemsCollapsed } = useContext(UiContext);

  const formatedAmount = reformatedAmount(getShoppercurrency(), getOrderTotal);

  const toggleCheckoutItemsCollapse = () => {
    setIsCheckoutItemsCollapsed(!isCheckoutItemsCollapsed);
  };

  const getLineItemsQuantity = () => {
    const lineItemQuantityReducer = (currentQuantity, lineItem) => currentQuantity + lineItem.qty;
    return cartDetails?.lineItems?.reduce(lineItemQuantityReducer, 0);
  };

  return (
    <button type="button" onClick={() => toggleCheckoutItemsCollapse()} className={style['mini-cart-btn']}>
      <span className={style['cart-icon']}>
        <img src={`${currentBaseUrl()}assets/shopping_cart.svg`} alt="Shopping Cart Icon" />
        <span>{getLineItemsQuantity()}</span>
      </span>
      <p className={style['cart-subtotal']}>
        <span className={style['mobile-sign']}>{formatedAmount.sign}</span>
        <span>{formatedAmount.amount}</span>
        <span className={style['mobile-rest']}>{formatedAmount.rest}</span>
      </p>
      <Arrow className="cart-arrow-down" />
    </button>
  );
};

export default MobileMiniCart;
