import { CROSS_MESSAGE, crossRequest } from '../commons/utils/cross-origin-communication';

export const useStoreApi = () => {
  //
  const getLocalStorage = async key => {
    try {
      const apiResponse = await crossRequest(
        CROSS_MESSAGE.FETCH_LOCAL_STORAGE,
        {
          key,
        },
        5000,
      );
      return apiResponse;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return null;
    }
  };
  //
  const setLocalStorage = async (key, value) => {
    try {
      const apiResponse = await crossRequest(
        CROSS_MESSAGE.SET_LOCAL_STORAGE,
        {
          key,
          value,
        },
        5000,
      );
      return apiResponse;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return null;
    }
  };
  //
  const removeLocalStorage = async key => {
    try {
      const apiResponse = await crossRequest(
        CROSS_MESSAGE.REMOVE_LOCAL_STORAGE,
        {
          key,
        },
        5000,
      );
      return apiResponse;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return null;
    }
  };
  //
  const getSessionStorage = async key => {
    try {
      const apiResponse = await crossRequest(
        CROSS_MESSAGE.FETCH_SESSION_STORAGE,
        {
          key,
        },
        5000,
      );
      return apiResponse;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return null;
    }
  };
  //
  const setSessionStorage = async (key, value) => {
    try {
      const apiResponse = await crossRequest(
        CROSS_MESSAGE.SET_SESSION_STORAGE,
        {
          key,
          value,
        },
        5000,
      );
      return apiResponse;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return null;
    }
  };
  //
  const removeSessionStorage = async key => {
    try {
      const apiResponse = await crossRequest(
        CROSS_MESSAGE.REMOVE_SESSION_STORAGE,
        {
          key,
        },
        5000,
      );
      return apiResponse;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return null;
    }
  };

  return {
    getLocalStorage,
    setLocalStorage,
    removeLocalStorage,
    getSessionStorage,
    setSessionStorage,
    removeSessionStorage,
  };
};
