/* eslint-disable no-console */

import { autofillSource } from '../../constants';

export const formatAsText = item => {
  if (item) {
    const addressParts = [item.street_line, item.secondary, item.city, item.state, item.zipcode];
    const filteredAddressParts = addressParts.filter(part => part !== '');

    return filteredAddressParts.join(', ');
  }

  return '';
};

export const formatSmartySelectionAsQueryParams = item => {
  if (item) {
    const queryParams = new URLSearchParams();

    queryParams.set('street', item.street_line);
    queryParams.set('street2', item.secondary);
    queryParams.set('city', item.city);
    queryParams.set('state', item.state);
    queryParams.set('zipcode', item.zipcode);

    return queryParams.toString();
  }

  return '';
};

export const getSmartySecondaryQueryParams = (search, selected) => {
  if (search && selected) {
    const formattedSearch = search.replace(/\s+/g, '+');
    const formattedStreet = selected.street_line.replace(/\s+/g, '+');
    const formattedSecondary = selected.secondary ? selected.secondary.replace(/\s+/g, '+') : '';
    const formattedEntries = `(${selected.entries})`;
    const formattedCity = selected.city.replace(/\s+/g, '+');
    const formattedState = selected.state.replace(/\s+/g, '+');
    const formattedZipcode = selected.zipcode.replace(/\s+/g, '+');

    // eslint-disable-next-line max-len
    return `search=${formattedSearch}&selected=${formattedStreet}+${formattedSecondary}+${formattedEntries}+${formattedCity}+${formattedState}+${formattedZipcode}`;
  }

  return '';
};

export const getStateName = stateCode => {
  const stateCodeToName = {
    // regular states
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
    // contiguous us territories
    AS: 'American Samoa',
    AA: 'Armed Forces Americas',
    AE: 'Armed Forces Europe',
    AP: 'Armed Forces Pacific',
    FM: 'Federated States Of Micronesia',
    GU: 'Guam',
    MH: 'Marshall Islands',
    MP: 'Northern Mariana Islands',
    PW: 'Palau',
    PR: 'Puerto Rico',
    VI: 'Virgin Islands',
  };

  return stateCodeToName[stateCode] || 'Unknown State';
};

export const formatSmartyForLegacy = item => {
  const formattedAddress = {
    name: '',
    line2: item.secondary,
    city: item.city,
    company: '',
    country: 'US',
    regionCode: item.state,
    region: getStateName(item.state),
    line1: item.street_line,
    singleLine: [item.street_line, item.city, item.state, item.zipcode, 'US'].filter(part => part !== '').join(', '),
    postal: item.zipcode,
  };

  return formattedAddress;
};

export const formatAsAutocompleteSuggestion = (fromObject, fromSource) => {
  if (fromSource === autofillSource.EMAIL_RESPONSE) {
    const formatted = {
      description: fromObject.singleLine,
      source: autofillSource.EMAIL_RESPONSE,
      original: fromObject,
    };
    return formatted;
  }

  if (fromSource === autofillSource.MANUAL_ADDRESS) {
    const formatted = {
      description: fromObject.singleLine,
      source: autofillSource.MANUAL_ADDRESS,
      original: fromObject,
    };
    return formatted;
  }

  if (fromSource === autofillSource.GOOGLE_AUTOCOMPLETION_SERVICE) {
    const formatted = {
      description: fromObject.description,
      source: autofillSource.GOOGLE_AUTOCOMPLETION_SERVICE,
      original: fromObject,
    };
    return formatted;
  }

  if (fromSource === autofillSource.SMARTY_US_AUTOCOMPLETE) {
    let newSecondary = '';

    if (fromObject.secondary) {
      if (fromObject.entries > 1) {
        newSecondary = `, ${fromObject.secondary} (${fromObject.entries} entries)`;
      } else {
        newSecondary = `, ${fromObject.secondary}`;
      }
    }

    const formatted = {
      // eslint-disable-next-line max-len
      description: `${fromObject.street_line}${newSecondary}, ${fromObject.city}, ${fromObject.state}, ${fromObject.zipcode}`,
      // eslint-disable-next-line max-len
      descriptionWithoutSecondary: `${fromObject.street_line}, ${fromObject.city}, ${fromObject.state}, ${fromObject.zipcode}`,
      source: autofillSource.SMARTY_US_AUTOCOMPLETE,
      original: fromObject,
    };

    return formatted;
  }

  return null;
};
