import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCombobox } from 'downshift';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { initialErrorState } from '../../utils/initial-error-state';
import { autofillSource } from '../../constants';
import { inputs } from '../../../helpers/constants';
import { useSkeleton } from '../../../hooks/useSkeleton';
import { selectCart, selectRefToFocus } from '../../../store/slice';

import Menu from './menu';
import useAutocompleteApi from './use-autocomplete-api';
import { formatAsAutocompleteSuggestion } from './utils';

import style from './index.module.scss';

function AddressAutocomplete({
  additionalData,
  afterValidationCb,
  autofillAddress,
  inputId,
  inputValue,
  inputWarning,
  isDisabled,
  onClearCb,
  onSelectCb,
  placeholder,
  placeholderTop,
  setAddressAsTyped,
  setAllowEnterManually,
  setShouldOpenAdditionalData,
  theme,
  triggerFocus,
  triggerReset,
  triggerValidation,
  updateAdditionalData,
  setShowLoadingPage,
}) {
  const cartData = useSelector(selectCart);
  const [value, setValue] = useState(inputValue);
  const [inputItems, setInputItems] = useState([]);
  const [backendSuggestions, setBackendSuggestions] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [search, setSearch] = useState('');
  const [error, setError] = useState(initialErrorState);
  const [warning, setWarning] = useState(inputWarning);
  const { setShowLoading } = useSkeleton();
  const refToFocus = useSelector(selectRefToFocus);

  const inputRef = useRef(null);

  const didDescriptionChange = useRef(false);

  const { t } = useTranslation();

  const { debouncedRequest, handleSmartySelection, handleGoogleSelection, expandSmartySecondary } = useAutocompleteApi(
    search,
    setInputItems,
  );

  useEffect(() => {
    if (refToFocus === inputs.downshiftAddress) {
      inputRef.current.click();
    }
  }, [refToFocus]);

  const selectedItem = value ?? null;
  const isInputFilled = value?.description || search;
  const hasSelection = value?.description;

  const handleSelectedItem = async (newSelectedItem, isAutofill) => {
    const shouldSkipEarly =
      !newSelectedItem ||
      (newSelectedItem.source === autofillSource.SMARTY_US_AUTOCOMPLETE && newSelectedItem.original.entries > 1);
    if (shouldSkipEarly) {
      return;
    }

    const clonedSelectedItem = { ...newSelectedItem };
    let legacyFormat;

    if (newSelectedItem.source === autofillSource.SMARTY_US_AUTOCOMPLETE) {
      legacyFormat = await handleSmartySelection(
        newSelectedItem.original,
        setShouldOpenAdditionalData,
        updateAdditionalData,
        additionalData,
      );
    } else if (newSelectedItem.source === autofillSource.GOOGLE_AUTOCOMPLETION_SERVICE) {
      legacyFormat = await handleGoogleSelection(newSelectedItem.original, setShouldOpenAdditionalData);
    } else if (
      newSelectedItem.source === autofillSource.MANUAL_ADDRESS ||
      newSelectedItem.source === autofillSource.EMAIL_RESPONSE
    ) {
      legacyFormat = newSelectedItem.original;
    }

    if (!legacyFormat) {
      const validationResult = {
        hasError: true,
        message: t('google_address_autocomplete.please_enter_a_valid_address'),
      };
      setError(validationResult);
    } else {
      clonedSelectedItem.legacy = legacyFormat;
      setError(initialErrorState);
      if (typeof onSelectCb === 'function') {
        const cloned = {
          ...clonedSelectedItem,
          description: clonedSelectedItem.description
            ?.split(',\n')
            ?.map(item => ` ${item.trim()}`)
            ?.join()
            ?.trim(),
        };
        setValue(cloned);
        if (!isAutofill) {
          onSelectCb(cloned);
        } else {
          setShowLoadingPage(false);
          setShowLoading(false);
        }
      }
    }

    inputRef.current.blur();
  };

  const { isOpen, getMenuProps, getLabelProps, getInputProps, highlightedIndex, getItemProps } = useCombobox({
    items: inputItems,
    onInputValueChange: ({ inputValue: inputText }) => {
      setSearch(inputText);
      setAddressAsTyped(inputText);
      if (inputText) {
        debouncedRequest(highlightedIndex);
      }
    },
    selectedItem,
    itemToString: item => {
      if (item?.original?.entries === 1) {
        return item ? item.descriptionWithoutSecondary : '';
      }
      return item ? item.description : '';
    },
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      handleSelectedItem(newSelectedItem, false);
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick: {
          if (inputItems?.length > 0 && state.highlightedIndex > -1) {
            const c1 = inputItems[state.highlightedIndex].original.entries > 1;
            const c2 = inputItems[state.highlightedIndex].source === autofillSource.SMARTY_US_AUTOCOMPLETE;
            const shouldKeepMenuOpen = c1 && c2;
            if (shouldKeepMenuOpen) {
              expandSmartySecondary(inputItems[state.highlightedIndex].original);
              return {
                ...changes,
                isOpen: true,
                inputValue: '',
              };
            }
          }
          return {
            ...changes,
          };
        }
        default:
          return changes;
      }
    },
  });

  const clearSuggestions = () => {
    setInputItems([...backendSuggestions]);
  };

  const handleBlur = () => {
    setIsInputFocused(false);

    const shouldClearSuggestions = inputItems.length > 0 && !search;
    if (shouldClearSuggestions) {
      clearSuggestions();
    }
  };

  useEffect(() => {
    const shouldShowError = !isInputFocused && search && !(value?.description || value?.legacy?.singleLine);
    if (shouldShowError) {
      const validationResult = {
        hasError: true,
        message: t('google_address_autocomplete.please_enter_a_valid_address'),
      };
      setError(validationResult);
      if (typeof afterValidationCb === 'function') {
        afterValidationCb(validationResult);
      }
    } else {
      setError(initialErrorState);
    }
  }, [isInputFocused, search, value]);

  useEffect(() => {
    if (selectedItem && !isOpen && !didDescriptionChange.current) {
      if (selectedItem?.original?.types?.includes('subpremise')) {
        setValue(previousValue => ({
          ...previousValue,
          description: selectedItem.legacy.userInputSingleLine,
        }));
        didDescriptionChange.current = true;
      } else if (selectedItem?.original?.secondary !== '') {
        setValue(previousValue => ({
          ...previousValue,
          description: previousValue.description?.replace(`${selectedItem.original.secondary}, `, ''),
        }));
        didDescriptionChange.current = true;
      }
    }
  }, [selectedItem, isOpen]);

  useEffect(() => {
    if (isOpen) {
      didDescriptionChange.current = false;
    }
  }, [isOpen]);

  const clearAutocomplete = () => {
    clearSuggestions();
    setSearch('');
    setValue(null);
    if (typeof onClearCb === 'function') {
      onClearCb();
    }
  };

  const resetAutocomplete = () => {
    setValue(null);
    setInputItems([]);
    setBackendSuggestions([]);
    setIsInputFocused(false);
    setSearch('');
    setError(initialErrorState);
    setWarning(false);
  };

  const handleChange = e => {
    if (e.target.value === '') {
      clearAutocomplete();
    }
  };

  const handleFocus = () => {
    setIsInputFocused(true);
    setError(initialErrorState);
    setWarning(false);
    if (!search) {
      clearSuggestions();
    }
  };

  const getPlaceholderStyles = () => {
    const css = {};
    if (isInputFocused || isInputFilled) {
      css.display = 'none';
    }
    return css;
  };

  const getPlaceholderTopStyles = () => {
    const css = {};
    if (isInputFocused) {
      css.color = `${theme.colour?.main}`;
      css.display = 'block';
      css.fontSize = '12px';
      css.lineHeight = '16px';
      css.backgroundColor = '#fff';
    } else if (isInputFilled) {
      css.display = 'block';
      css.fontSize = '12px';
      css.lineHeight = '16px';
      css.backgroundColor = '#fff';
    }
    return css;
  };

  const getDivInputStyles = () => {
    const css = {};
    if (isInputFocused) {
      css.border = `2px solid ${theme.colour?.outline}`;
    } else if (error.hasError || warning) {
      css.borderWidth = '2px';
      css.borderStyle = 'solid';
    } else {
      css.borderWidth = '1px';
      css.borderStyle = 'solid';
    }
    return css;
  };

  const getInputStyles = () => {
    const css = {};
    if (hasSelection) {
      css.width = `calc(100% - 30px)`;
    }
    return css;
  };

  const getButtonStyles = () => (hasSelection ? { display: 'block' } : {});

  const handleClear = () => {
    clearAutocomplete();
    inputRef.current.focus();
  };

  const handleComplete = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    const focusEffect = () => {
      if (triggerFocus) {
        inputRef.current.focus();
      }
    };
    focusEffect();
  }, [triggerFocus]);

  useEffect(() => {
    const parseBackendSuggestions = () => {
      if (cartData?.suggestions?.length > 0) {
        const formatted = cartData?.suggestions.map(item =>
          formatAsAutocompleteSuggestion(item.address, autofillSource.EMAIL_RESPONSE),
        );
        setBackendSuggestions(formatted);
      }
    };
    parseBackendSuggestions();
  }, [cartData]);

  useEffect(() => {
    const triggerAutofillChange = () => {
      if (autofillAddress?.addressDownshift) {
        handleSelectedItem(autofillAddress.addressDownshift, true);
      }
    };
    triggerAutofillChange();
  }, [autofillAddress]);

  useEffect(() => {
    const resetEffect = () => {
      if (triggerReset) {
        resetAutocomplete();
      }
    };
    resetEffect();
  }, [triggerReset]);

  useEffect(() => {
    const validationEffect = () => {
      if (triggerValidation) {
        const shouldShowError = !value?.description;
        if (shouldShowError) {
          const validationResult = {
            hasError: true,
            message: t('google_address_autocomplete.please_enter_a_valid_address'),
          };
          setError(validationResult);
          if (typeof afterValidationCb === 'function') {
            afterValidationCb(validationResult);
          }
        }
      }
    };
    validationEffect();
  }, [triggerValidation]);

  return (
    <section
      className={classNames(style.section, {
        [style.disabled]: isDisabled,
        [style.error]: error.hasError,
        'nf-sm-gap': !error.hasError,
        [style.highlighted]: warning && !error.hasError,
      })}
    >
      <div>
        <label
          className={classNames({
            [style['label-focused']]: isInputFocused,
            [style['label-filled']]: isInputFilled,
          })}
          {...getLabelProps()}
        >
          <span className={style.placeholder} style={getPlaceholderStyles()}>
            {placeholder}
          </span>
          <span className={style['placeholder-top']} style={getPlaceholderTopStyles()}>
            {placeholderTop}
          </span>
        </label>
        <div className={style.divInput} style={getDivInputStyles()}>
          <input
            className={style.input}
            style={getInputStyles()}
            {...getInputProps({
              onBlur: handleBlur,
              onChange: handleChange,
              onFocus: handleFocus,
              disabled: isDisabled,
              ref: inputRef,
              autoComplete: `${inputId} address-line1`,
            })}
          />
        </div>
        <Menu
          highlightedIndex={highlightedIndex}
          isOpen={isOpen}
          inputItems={inputItems}
          getItemProps={getItemProps}
          search={search}
          theme={theme}
          setAllowEnterManually={setAllowEnterManually}
          getMenuProps={getMenuProps}
        />
        <button
          type="button"
          onClick={handleClear}
          className={style['btn-clear']}
          style={getButtonStyles()}
          aria-label={t('google_address_autocomplete.clear_address')}
        >
          <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
            {/* eslint-disable-next-line max-len */}
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
          </svg>
        </button>
        {error.hasError && search && (
          <button
            type="button"
            onClick={handleComplete}
            className={style['btn-complete']}
            aria-label={t('google_address_autocomplete.complete_address')}
          >
            {t('google_address_autocomplete.complete_address')}
          </button>
        )}
      </div>
      {error.hasError && <p className={style.message}>{error.message}</p>}
    </section>
  );
}

AddressAutocomplete.propTypes = {
  additionalData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  afterValidationCb: PropTypes.func,
  autofillAddress: PropTypes.oneOfType([PropTypes.object]),
  inputId: PropTypes.string.isRequired,
  inputValue: PropTypes.oneOfType([PropTypes.object]),
  inputWarning: PropTypes.bool,
  isDisabled: PropTypes.bool.isRequired,
  onClearCb: PropTypes.func.isRequired,
  onSelectCb: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  placeholderTop: PropTypes.string.isRequired,
  setAddressAsTyped: PropTypes.func.isRequired,
  setAllowEnterManually: PropTypes.func.isRequired,
  setShouldOpenAdditionalData: PropTypes.func.isRequired,
  theme: PropTypes.oneOfType([PropTypes.object]),
  triggerFocus: PropTypes.number,
  triggerReset: PropTypes.number,
  triggerValidation: PropTypes.number,
  updateAdditionalData: PropTypes.func.isRequired,
  setShowLoadingPage: PropTypes.func.isRequired,
};

export default AddressAutocomplete;
