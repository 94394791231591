import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { currentBaseUrl } from '../../helpers/urlConst';

import { selectCheckout } from '../../store/slice';
import useAutofill from '../../hooks/useAutofill';
import DataContext from '../../store/dataContext';
import useReset from '../../hooks/useReset';

import './index.scss';

const WelcomeBack = () => {
  const { isAutofill } = useContext(DataContext);
  const { handleAutofill } = useAutofill();
  const { handleReset } = useReset();
  const { t } = useTranslation();
  const [currentClass, setCurrentClass] = useState('');
  const checkoutData = useSelector(selectCheckout);
  const nameOfUser = checkoutData?.address?.name?.split(' ')[0];

  const hideAndReset = () => {
    setCurrentClass('');
    handleReset();
  };

  useEffect(() => {
    if (currentClass === 'active') {
      setTimeout(() => {
        setCurrentClass('');
      }, 10000);
    }
  }, [currentClass]);

  useEffect(() => {
    if (isAutofill) {
      handleAutofill();
      setCurrentClass('active');
    } else {
      setCurrentClass('');
    }
  }, [isAutofill]);

  return (
    <div className={`welcome-back-wrapper ${currentClass}`}>
      <div className="welcome-content">
        <div className="welcome-container">
          <p className="welcome-text">{`${t('welcome_back.welcome_text')} ${nameOfUser}!`}</p>
          <img className="welcome-image" alt="question" src={`${currentBaseUrl()}assets/question.svg`} />
        </div>
        <button className="welcome-btn welcome-text reset-styles" type="button" onClick={hideAndReset}>
          {`${t('welcome_back.not')} ${nameOfUser}?`}
        </button>
      </div>
      <button className="reset-styles welcome-close" type="button" onClick={() => setCurrentClass('')}>
        <img alt="close" src={`${currentBaseUrl()}assets/close-white.svg`} />
      </button>
    </div>
  );
};

export default WelcomeBack;
