import { DEFAULT_TIMEOUT_MS } from '../constants';

export const logOutPayload = {
  url: '/login.php?action=logout',
  options: {
    headers: {
      'x-api-internal': 'This API endpoint is for internal use only and may change in the future',
    },
  },
};

export const logOut = (crossRequest, message, timeout = DEFAULT_TIMEOUT_MS) =>
  crossRequest(message, logOutPayload, timeout);

export const isUserAuthenticated = apiResponse => apiResponse?.isUserLoggedIn;
