import React, { useState, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import InputWrapper from '../../../../commons/components/input-wrapper';
import { selectCart } from '../../../../store/slice';
import DataContext from '../../../../store/dataContext';

import './OrderNotesInput.scss';

const OrderNotesInput = () => {
  const { orderNotes, updateOrderNotes } = useContext(DataContext);
  const cartData = useSelector(selectCart);
  const portalSettings = cartData?.merchant?.settings;
  const { t } = useTranslation();
  const [isEnabled, setIsEnabled] = useState(false);
  const codeRef = useRef(null);

  const handleEnbled = () => {
    setIsEnabled(true);
  };

  useEffect(() => {
    if (codeRef?.current && isEnabled) {
      codeRef.current.focus();
    }
  }, [isEnabled]);

  return (
    <section className="order-notes-section">
      {isEnabled ? (
        <InputWrapper
          classNameInput={classNames({
            'order-notes-input no-margin': true,
            'is-value': orderNotes,
          })}
          type="text"
          placeholder={t('order_notes')}
          maxLength={100}
          value={orderNotes}
          onChange={updateOrderNotes}
          inputId="note"
          placeholderTop={t('order_notes')}
          theme={portalSettings.theme}
          ref={codeRef}
        />
      ) : (
        <button className="order-notes-button" type="button" onClick={handleEnbled}>
          + {t('order_notes')}
        </button>
      )}
    </section>
  );
};

export default OrderNotesInput;
