import React from 'react';
import PropTypes from 'prop-types';

const About = ({ setActive }) => (
  <div className="rules-wrapper">
    <div className="rules-wrapper-header">
      <h1>About NoFraud Checkout</h1>
    </div>
    <div className="rules-wrapper-body">
      <p className="rules-section-header">Who We Are</p>
      <p>
        NoFraud is a fraud prevention and checkout solution that protects consumers against unauthorized credit card
        use. Thousands of eCommerce retailers partner with NoFraud to screen transactions for suspicious activity and
        make the checkout experience easier for trustworthy shoppers.
      </p>
      <p>
        NoFraud screens hundreds of thousands of orders every day and has prevented countless fraudsters from making
        purchases using stolen credit cards.
      </p>
      <p className="rules-section-header">How NoFraud Checkout Works</p>
      <p>
        When you shop with a business that uses NoFraud Checkout, NoFraud screens the order in real-time to determine
        whether there are any indications of fraud. This process begins as soon as you start entering your information
        into the checkout field.
      </p>
      <p>
        If NoFraud has already vetted you as a trustworthy shopper, you may notice that you have the option to auto-fill
        your checkout information the next time you shop. This just means that, based on your prior shopping history,
        you are considered a low fraud risk and can enjoy a faster checkout experience as a result.
      </p>
      <p className="rules-section-header">The Benefits of NoFraud Checkout</p>
      <p>
        As consumers, we all benefit when businesses take a serious interest in preventing fraud. In some cases, NoFraud
        had even alerted victims of suspicious activity before they received notification from their bank! Rest assured,
        if the store you are shopping with is partnering with NoFraud, they are doing their part to protect you from
        payment & credit card theft.
      </p>
      <p>
        Additionally, by shopping with retailers that use NoFraud Checkout, you can experience the convenience of a
        one-click checkout even if you don’t have a store account with that business. If you are recognized as a trusted
        shopper by <b>any store in the NoFraud network</b>, NoFraud will give you the option to autofill your
        information for an effortless checkout experience.
      </p>
      <p className="rules-section-header">What NoFraud Does with Your Information</p>
      <p>
        NoFraud takes the privacy of our users very seriously.{' '}
        <b>We will never sell personal information to third parties for advertising or marketing purposes.</b> When you
        use NoFraud Checkout, your payment information is encrypted and stored securely in a token vault used for
        transactions. Shop easy knowing Merchants do not have access to sensitive payment information. Please refer to
        our{' '}
        <a href="#face" role="button" onClick={() => setActive('Privacy Policy')}>
          Privacy page
        </a>{' '}
        for more details.
      </p>
    </div>
  </div>
);

About.propTypes = {
  setActive: PropTypes.func.isRequired,
};
export default About;
