import React, { useContext } from 'react';
import classNames from 'classnames';

import UiContext from '../../store/uiContext';

import './styles.scss';

const ExtraInfo = () => {
  const { showExtraInfoBackground, hasExtraInfo } = useContext(UiContext);
  return (
    <div
      className={classNames({
        'has-background': showExtraInfoBackground,
        hide: !hasExtraInfo,
      })}
    >
      <div
        className={classNames({
          box: showExtraInfoBackground,
        })}
      >
        <div id="custom-plugin" />
      </div>
    </div>
  );
};

export default ExtraInfo;
