import { httpClient } from '../../../adapter/http-request';

const fetchGoogleApi = async address => {
  const { data } = await httpClient.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
  );
  return data.results;
};

export const parseGeoCodeData = async zipCode => {
  const parsedAddress = {};
  try {
    const results = await fetchGoogleApi(zipCode);
    if (results && results.length > 0) {
      results[0].address_components.forEach(data => {
        if (data.types.includes('country')) {
          parsedAddress.country = data.short_name;
        }
        if (data.types.includes('administrative_area_level_1')) {
          parsedAddress.regionCode = data.short_name;
          parsedAddress.regionName = data.long_name;
        }
        if (
          data.types.includes('locality') ||
          (!parsedAddress.city && data.types.includes('sublocality')) ||
          (!parsedAddress.city && data.types.includes('postal_town')) ||
          (!parsedAddress.city && data.types.includes('administrative_area_level_3'))
        ) {
          parsedAddress.city = data.long_name;
        } else if (data.types.includes('sublocality') || data.types.includes('postal_town')) {
          parsedAddress.city = data.long_name;
        }
      });
    }
    return parsedAddress;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Could not parse address data from Google Api', e);
    return parsedAddress;
  }
};
