import { createSlice } from '@reduxjs/toolkit';

import { retrieveData, saveData } from '../../../commons/utils/local-storage-manager';
import { platformCartIdValue } from '../../../helpers/prelaunchData';
import { paymentIdList } from '../../../helpers/constants';

export const sliceKey = 'payment';

// #region initial state + local storage
const retrieved = retrieveData(sliceKey, platformCartIdValue, {});

const location = new URL(document.location);
const params = location.searchParams;
const paymentName = params.get('paymentName');

const initialState = {
  selectedMethod: retrieved.selectedMethod || paymentName || paymentIdList.CREDIT_CARD,
};

const saveFieldToLs = (fieldKey, fieldValue) => {
  // the local storage key is the platformCartIdValue
  // it must match the key used inside the slice
  // platformCartIdValue is different than cartId received as a prop
  const retrievedFromLs = retrieveData(sliceKey, platformCartIdValue, {});
  const updated = { ...retrievedFromLs };
  updated[fieldKey] = fieldValue;
  saveData(sliceKey, updated, platformCartIdValue);
};

export const paymentSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    setPaymentMethod: (state, action) => {
      saveFieldToLs('selectedMethod', action.payload);
      state.selectedMethod = action.payload;
    },
  },
});

export const { setPaymentMethod } = paymentSlice.actions;

export const currentPaymentMethod = state => {
  if (state.payment.selectedMethod && state.payment.selectedMethod !== 'undefined') {
    return state.payment.selectedMethod;
  }
  return paymentIdList.CREDIT_CARD;
};

export default paymentSlice.reducer;
