import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import {
  platformCartIdValue as platformCartId,
  merchantIdValue as merchantId,
  storeUrlValue as storeUrl,
  getGiftInfo,
  getBigCommerceCurrency,
} from '../helpers/prelaunchData';
import { status, paymentIdList } from '../helpers/constants';
import { getDevice } from '../helpers/device';
import {
  getStoredOrEmptyCollection,
  getStoredOrEmptyObject,
  getStoredOrBlankData,
  clearStoredData,
  getStoredData,
  storeData,
} from '../helpers/localStorageHelper';

import { cartTotalsInitialState, cartTotalsReducer } from './cartTotals';
import { apiStatusInitialState, apiStatusReducer } from './apiStatus';
import { deviceInitialState, deviceReducer } from './device';
import { errorsInitialState, errorsReducer } from './errors';
import { deviceActions } from './rootActionTypes';

const DataContext = React.createContext({});
const ONE_HOUR_MS = 3600000;

export const DataContextProvider = props => {
  const platformCartIdKey = 'platformCartId';
  const merchantIdKey = 'merchantIdV2';
  const storeUrlKey = 'storeUrl';
  const cartIdKey = 'cartId';
  const cartDetailsKey = 'cartDetails';
  const isAutofillKey = 'isAutofill';
  const emailKey = 'email';
  const shippingNameKey = 'shippingName';
  const contextShippingAddressKey = 'contextShippingAddress';
  const shippingAddress1Key = 'shippingAddress1';
  const shippingAddress2Key = 'shippingAddress2';
  const companyKey = 'shipCompany';
  const billingPhoneKey = 'billingPhone';
  const shippingMethodIdKey = 'shippingMethodId';
  const userCardIdKey = 'userCardId';
  const checkoutSessionKey = 'checkoutSession';
  const wasLoggedInKey = 'loggedIn';
  const amazonButtonDataKey = 'amazon';
  const additionalChargesDataKey = 'additionalCharges';

  const storedCartId = getStoredData(cartIdKey);
  const storedIsAutofill = getStoredData(isAutofillKey);
  const storedCartDetails = getStoredData(cartDetailsKey);
  const storedEmail = getStoredOrBlankData(emailKey);
  const storedShippingName = getStoredOrBlankData(shippingNameKey);
  const storedContextShippingAddress = getStoredOrBlankData(contextShippingAddressKey);
  const storedShippingAddress1 = getStoredOrBlankData(shippingAddress1Key);
  const storedShippingAddress2 = getStoredOrBlankData(shippingAddress2Key);
  const storedCompanyName = getStoredOrBlankData(companyKey);
  const storedBillingPhone = getStoredOrBlankData(billingPhoneKey);
  const storedShippingMethodId = getStoredOrBlankData(shippingMethodIdKey);
  const storedUserCardId = getStoredOrBlankData(userCardIdKey);
  const storedCheckoutSession = getStoredOrEmptyObject(checkoutSessionKey);
  const storedLoggedIn = getStoredOrEmptyObject(wasLoggedInKey);
  const storedAmazonButtonData = getStoredData(amazonButtonDataKey);
  const storedAdditionalCharges = getStoredOrEmptyCollection(additionalChargesDataKey);

  const [cartId, setCartId] = useState(storedCartId);
  const [cartDetails, setCartDetails] = useState(storedCartDetails);
  const [email, setEmail] = useState(storedEmail);
  const [retokenize, setRetokenize] = useState(false);
  const [shippingName, setShippingName] = useState(storedShippingName);
  const [shippingAddress1, setShippingAddress1] = useState(storedShippingAddress1);
  const [shippingAddress2, setShippingAddress2] = useState(storedShippingAddress2);
  const [billingAddressValue, setBillingAddressValue] = useState(undefined);
  const [shipCompany, setShipCompany] = useState(storedCompanyName);
  const [contextShippingAddress, setContextShippingAddress] = useState(storedContextShippingAddress);
  const [cardType, setCardType] = useState(null);
  const [last4Numbers, setLast4Numbers] = useState(null);
  const [first6Numbers, setFirst6Numbers] = useState(null);
  const [billingName, setBillingName] = useState('');
  const [billingAddress1, setBillingAddress1] = useState('');
  const [billingPhone, setBillingPhone] = useState(storedBillingPhone);
  const [contextBillingAddress, setContextBillingAddress] = useState({});
  const [shippingMethods, setShippingMethods] = useState([]);
  const [shippingMethodId, setShippingMethodId] = useState(storedShippingMethodId);
  const [userCardId, setUserCardId] = useState(storedUserCardId);
  const [checkoutSession, setCheckoutSession] = useState(storedCheckoutSession);
  const [isAutofill, setIsAutofill] = useState(storedIsAutofill);
  const [userCardCvv, setUserCardCvv] = useState('');
  const [clearCardDetails, setClearCardDetails] = useState(false);
  const [apiShippingAddressResponse, setApiShippingAddressResponse] = useState({});
  const [cardList, setCardList] = useState([]);
  const [apiPostOrderError, setApiPostOrderError] = useState({});
  const [cvvChanged, setCvvChanged] = useState(false);
  const [submitFailedTimestamp, setSubmitFailedTimestamp] = useState(0);
  const [helpfulLinks, setHelpfulLinks] = useState([]);
  const [resetTimestamp, setResetTimestamp] = useState('');
  const [shopperCurrency, setShopperCurrency] = useState({});
  const [coupon, setCoupon] = useState({});
  const [orderNotes, setOrderNotes] = useState('');
  const [isUserSaveInfo, setIsUserSaveInfo] = useState(true);
  const [wasLoggedIn, setWasLoggedIn] = useState(storedLoggedIn);
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  const [broadcastMessage, setBroadcastMessage] = useState(undefined);
  const [broadcastErrorMessage, setBroadcastErrorMessage] = useState(undefined);
  const [paymentMethodError, setPaymentMethodError] = useState({});
  const [paymentMethodData, setPaymentMethodData] = useState({});
  const [registeredPaymentMethods, setRegisteredPaymentMethods] = useState({});
  const [cartTotals, cartTotalsDispatch] = useReducer(cartTotalsReducer, cartTotalsInitialState);
  const [apiStatus, apiStatusDispatch] = useReducer(apiStatusReducer, apiStatusInitialState);
  const [device, deviceDispatch] = useReducer(deviceReducer, deviceInitialState);
  const [errors, errorsDispatch] = useReducer(errorsReducer, errorsInitialState);
  const [isOrderSubmitting, setIsOrderSubmitting] = useState(false);
  const [validNumber, setValidNumber] = useState(true);
  const [userWasAuthorized, setUserWasAuthorized] = useState(false);
  const [shouldRedirectOnError, setShouldRedirectOnError] = useState(false);
  const [moveToPlatform, setMoveToPlatform] = useState({});
  const [applyStoreCredit, setApplyStoreCredit] = useState(null);
  const [showLoading, setShowLoading] = useState(true);
  const [sameBilling, setSameBilling] = useState(false);
  const [storeVar, setStoreVar] = useState({});
  const [amazonButtonData, setAmazonButtonData] = useState(storedAmazonButtonData);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [additionalCharges, setAdditionalCharges] = useState(storedAdditionalCharges);
  const [consent, setConsent] = useState({
    hasNewsletter: undefined,
    hasSms: undefined,
  });
  const [areSuggestionsVisible, setAreSuggestionsVisible] = useState(false);
  const [giftCertificates, setGiftCertificates] = useState([]);
  const [customer, setCustomer] = useState(null);

  const { children } = props;

  const preLoadData = async () => {
    await getBigCommerceCurrency(setShopperCurrency);
    const deviceInformation = await getDevice();
    deviceDispatch(deviceActions.setDevice(deviceInformation));
    if (deviceInformation?.id) {
      localStorage.setItem('deviceInformation', deviceInformation);
    }
    const giftInfo = await getGiftInfo();
    setGiftCertificates(giftInfo);
  };

  const updateGiftInfo = gift => {
    setGiftCertificates(gift);
  };

  const updateCartId = newCartId => {
    storeData(cartIdKey, newCartId);
    setCartId(newCartId);
  };

  const updateIsAutofill = fill => {
    storeData(isAutofillKey, fill);
    setIsAutofill(fill);
  };

  const updateAmazonButtonData = amazonButton => {
    storeData(amazonButtonDataKey, amazonButton);
    setAmazonButtonData(amazonButton);
  };

  const updateCartDetails = newCartDetails => {
    storeData(cartDetailsKey, newCartDetails);
    setCartDetails(newCartDetails);
  };

  const updateWasLoggedIn = loggedIn => {
    storeData(wasLoggedInKey, loggedIn);
    setWasLoggedIn(loggedIn);
  };

  const updateSameBilling = sameBill => {
    setSameBilling(sameBill);
  };

  const updateConsent = newConsent => {
    setConsent({
      ...consent,
      ...newConsent,
    });
  };

  const updateHelpfulLinks = links => {
    setHelpfulLinks(links);
  };

  const updateEmail = newEmail => {
    storeData(emailKey, newEmail);
    setEmail(newEmail);
  };

  const updateShippingName = newName => {
    storeData(shippingNameKey, newName);
    setShippingName(newName);
  };

  const updateContextShippingAddress = addressObject => {
    storeData(contextShippingAddressKey, addressObject);
    setContextShippingAddress(addressObject);
  };

  const updateShippingAddress1 = newAddress => {
    storeData(shippingAddress1Key, newAddress);
    setShippingAddress1(newAddress);
  };

  const updateShippingAddress2 = newAddress => {
    storeData(shippingAddress2Key, newAddress);
    setShippingAddress2(newAddress);
  };

  const updateAdditionalCharges = charges => {
    storeData(additionalChargesDataKey, charges);
    setAdditionalCharges(charges);
  };

  const updateCompanyName = value => {
    storeData(companyKey, value);
    setShipCompany(value);
  };

  const updateBillingName = newName => {
    setBillingName(newName);
  };

  const updateBillingAddress1 = newAddress => {
    setBillingAddress1(newAddress);
  };

  const updateBillingPhone = newPhone => {
    storeData(billingPhoneKey, newPhone);
    setBillingPhone(newPhone);
  };

  const retokenizeData = () => {
    setRetokenize(!retokenize);
  };

  const updateShippingMethods = newShippingMethods => {
    setShippingMethods(newShippingMethods);
  };

  const updateShippingMethodId = methodId => {
    storeData(shippingMethodIdKey, methodId);
    setShippingMethodId(methodId);
  };

  const updateUserCardId = newUserCardId => {
    storeData(userCardIdKey, newUserCardId);
    setUserCardId(newUserCardId);
  };

  const updateCheckoutSession = (key, value) => {
    setCheckoutSession(prevSession => {
      const expiryDate = new Date().getTime() + ONE_HOUR_MS;
      const updatedCheckoutSession = { ...prevSession, expiryDate, [key]: value };
      storeData(checkoutSessionKey, updatedCheckoutSession);
      return updatedCheckoutSession;
    });
  };

  const updateCoupon = value => {
    setCoupon(value);
  };

  const updateOrderNotes = evt => {
    setOrderNotes(evt.target.value);
  };

  const isCouponAvailable = () => coupon && Object.keys(coupon).length > 0;

  const resetCheckoutSession = () => {
    storeData(checkoutSessionKey, {});
    setCheckoutSession({});
  };

  const orderSubmitting = bool => {
    setIsOrderSubmitting(bool);
  };

  const isCheckoutReady = ({ isShippingReady, isBillingReady, zeroTotal, isAdditionalServiceLoading }) =>
    device.isReady &&
    apiStatus.isEmailReady === status.READY &&
    (apiStatus.isCardReady || zeroTotal) &&
    isBillingReady &&
    cartTotals.isReady &&
    apiStatus.isSessionReady === status.READY &&
    isShippingReady &&
    !isAdditionalServiceLoading;

  const isThirdPartyReady = ({ isShippingReady, isBillingReady, isAdditionalServiceLoading }) =>
    device.isReady &&
    apiStatus.isEmailReady === status.READY &&
    !apiStatus.isCardInProgress &&
    isBillingReady &&
    cartTotals.isReady &&
    apiStatus.isSessionReady === status.READY &&
    isShippingReady &&
    !isAdditionalServiceLoading;

  const isAnyCheckoutReady = ({ type, isShippingReady, isBillingReady, isAdditionalServiceLoading }) => {
    if (type === paymentIdList.PAYPAL || type === paymentIdList.AMAZONPAY || type === paymentIdList.GOOGLEPAY) {
      return isThirdPartyReady({ isShippingReady, isBillingReady, isAdditionalServiceLoading });
    }
    return isCheckoutReady({ isShippingReady, isBillingReady, isAdditionalServiceLoading });
  };

  const hasShippingData = () => !!(shippingName || shippingAddress1 || shippingAddress2);

  const reset = () => {
    updateEmail('');
    updateBillingName('');
    updateBillingAddress1('');
    resetCheckoutSession();
    updateShippingName('');
    updateShippingAddress1('');
    updateShippingAddress2('');
    updateCompanyName('');
    updateContextShippingAddress({});
    updateShippingMethods([]);
    updateShippingMethodId('');
    updateUserCardId('');
    setCheckoutSession({});
    setUserCardCvv('');
    setApiShippingAddressResponse({});
    clearStoredData();
    updateIsAutofill(false);
    updateWasLoggedIn(false);
    // do not reset:
    //   - cartTotalsDispatch, because we need to read it on the thank you page
  };

  useEffect(() => {
    preLoadData();
    storeData(platformCartIdKey, platformCartId);
    storeData(merchantIdKey, merchantId);
    storeData(storeUrlKey, storeUrl);
    storeData(cartIdKey, cartId);
    storeData(cartDetailsKey, cartDetails);
    storeData(emailKey, email);
    storeData(shippingNameKey, shippingName);
    storeData(shippingAddress1Key, shippingAddress1);
    storeData(shippingAddress2Key, shippingAddress2);
    storeData(companyKey, shipCompany);
    storeData(contextShippingAddressKey, contextShippingAddress);
    storeData(billingPhoneKey, billingPhone);
    storeData(shippingMethodIdKey, shippingMethodId);
    storeData(userCardIdKey, userCardId);
    storeData(checkoutSessionKey, checkoutSession);
  }, []);

  const value = {
    platformCartId,
    merchantId,
    storeUrl,
    cartId,
    isAutofill,
    cartDetails,
    email,
    retokenize,
    shippingName,
    shippingAddress1,
    shippingAddress2,
    orderNotes,
    moveToPlatform,
    showLoading,
    shipCompany,
    contextShippingAddress,
    shippingMethods,
    shippingMethodId,
    billingName,
    billingAddress1,
    billingPhone,
    contextBillingAddress,
    userCardId,
    userCardCvv,
    clearCardDetails,
    helpfulLinks,
    paymentMethodError,
    broadcastMessage,
    broadcastErrorMessage,
    paymentMethodData,
    cardList,
    consent,
    applyStoreCredit,
    wasLoggedIn,
    sameBilling,
    apiShippingAddressResponse,
    apiPostOrderError,
    registeredPaymentMethods,
    validNumber,
    cvvChanged,
    resetTimestamp,
    shopperCurrency,
    coupon,
    cartTotals,
    apiStatus,
    isUserSaveInfo,
    cardType,
    last4Numbers,
    first6Numbers,
    device,
    billingAddressValue,
    errors,
    isLoggedIn,
    isOrderSubmitting,
    userWasAuthorized,
    shouldRedirectOnError,
    amazonButtonData,
    submitFailedTimestamp,
    areSuggestionsVisible,
    giftCertificates,
    storeVar,
    isIframeLoaded,
    setIsIframeLoaded,
    additionalCharges,
    updateAdditionalCharges,
    setStoreVar,
    setUserCardId,
    updateAmazonButtonData,
    setShopperCurrency,
    setClearCardDetails,
    setShowLoading,
    setValidNumber,
    setApplyStoreCredit,
    setPaymentMethodData,
    setApiPostOrderError,
    setCvvChanged,
    setMoveToPlatform,
    updateConsent,
    updateHelpfulLinks,
    updateCartId,
    updateCartDetails,
    updateEmail,
    retokenizeData,
    updateSameBilling,
    updateShippingName,
    updateContextShippingAddress,
    updateShippingAddress1,
    updateShippingAddress2,
    updateCompanyName,
    updateBillingName,
    updateBillingAddress1,
    updateBillingPhone,
    setContextBillingAddress,
    setRegisteredPaymentMethods,
    updateShippingMethods,
    updateShippingMethodId,
    updateUserCardId,
    updateCheckoutSession,
    updateOrderNotes,
    setUserCardCvv,
    setPaymentMethodError,
    setBroadcastMessage,
    setBroadcastErrorMessage,
    hasShippingData,
    setApiShippingAddressResponse,
    isCheckoutReady,
    isAnyCheckoutReady,
    reset,
    setResetTimestamp,
    updateIsAutofill,
    updateCoupon,
    isCouponAvailable,
    cartTotalsDispatch,
    apiStatusDispatch,
    setIsUserSaveInfo,
    setCardList,
    setCardType,
    setFirst6Numbers,
    setLast4Numbers,
    deviceDispatch,
    setBillingAddressValue,
    errorsDispatch,
    setIsLoggedIn,
    isThirdPartyReady,
    orderSubmitting,
    setUserWasAuthorized,
    setShouldRedirectOnError,
    updateWasLoggedIn,
    setSubmitFailedTimestamp,
    setAreSuggestionsVisible,
    updateGiftInfo,
    setCustomer,
    customer,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

DataContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default DataContext;
