export const status = {
  IDLE: 'IDLE',
  SUBMITTED: 'SUBMITTED',
};

export const capitalizeText = text => {
  const capitalizeWords = text.toLowerCase().replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()));
  return capitalizeWords;
};

export const emptyManualData = {
  line2: '',
  city: '',
  company: '',
  country: 'US',
  postal: '',
  region: '',
  regionCode: '',
  line1: '',
  singleLine: '',
};

export const emptyManualAddress = {
  apartmentUnit: '',
  city: '',
  companyName: '',
  country: 'US',
  postalCode: '',
  region: '',
  regionName: '',
  state: '',
  street: '',
  singleLine: '',
};

export const emptyAddress = {
  name: '',
};
