import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { setDeliveryMethod, selectShipping } from '../../slice';

import { setPaymentMethod } from '../../../payment/slice';
import { paymentIdList } from '../../../../helpers/constants';
import { deliveryMethod as deliveryMethodConstant } from '../../../../commons/constants';
import { EVENT, on, off } from '../../../../commons/utils/custom-event';

import style from './index.module.scss';

const NavTabs = ({ tabData, isFieldsetDisabled, setDisableTab, saveToLocalStorage, onTabChange }) => {
  const { t } = useTranslation();

  const { deliveryMethod } = useSelector(selectShipping);

  const dispatch = useDispatch();

  const updateDeliveryMethod = newDeliveryMethod => {
    dispatch(setDeliveryMethod(newDeliveryMethod));
    saveToLocalStorage('deliveryMethod', newDeliveryMethod);
  };

  const handleTabClick = tabId => {
    if (isFieldsetDisabled) {
      return;
    }
    dispatch(setPaymentMethod(paymentIdList.CREDIT_CARD));
    setDisableTab(true);
    updateDeliveryMethod(tabId);
    if (typeof onTabChange === 'function') {
      onTabChange(tabId);
    }
  };

  const resetState = () => {
    updateDeliveryMethod(deliveryMethodConstant.shipping);
  };

  useEffect(() => {
    const subscribeToResetCheckout = () => {
      on(EVENT.RESET_CHECKOUT, () => {
        resetState();
      });

      return () => {
        off(EVENT.RESET_CHECKOUT);
      };
    };
    subscribeToResetCheckout();
  }, []);

  return (
    <>
      <ul
        className={classNames(style['nav-tabs'], {
          [style.disabled]: isFieldsetDisabled,
        })}
        role="tablist"
      >
        <li
          className={classNames({
            [style.active]: deliveryMethod === deliveryMethodConstant.shipping,
          })}
          role="presentation"
        >
          <a href="#shipping" role="tab" onClick={() => handleTabClick(deliveryMethodConstant.shipping)}>
            {t('pick_up_in_store.shipping')}
          </a>
        </li>
        <li
          className={classNames({
            [style.active]: deliveryMethod === deliveryMethodConstant.pickup,
          })}
          role="presentation"
        >
          <a href="#pickup" role="tab" onClick={() => handleTabClick(deliveryMethodConstant.pickup)}>
            {t('pick_up_in_store.pick_up')}
          </a>
        </li>
      </ul>
      <div className={style['tab-content']} id="tablistcontent">
        <div
          className={classNames(style['tab-pane'], {
            [style.show]: deliveryMethod === deliveryMethodConstant.shipping,
          })}
          role="tabpanel"
        >
          {tabData.shipping.content}
        </div>
        <div
          className={classNames(style['tab-pane'], {
            [style.show]: deliveryMethod === deliveryMethodConstant.pickup,
          })}
          role="tabpanel"
        >
          {tabData.pickup.content}
        </div>
      </div>
    </>
  );
};

NavTabs.propTypes = {
  tabData: PropTypes.shape({
    shipping: PropTypes.oneOfType([PropTypes.object]),
    pickup: PropTypes.oneOfType([PropTypes.object]),
  }).isRequired,
  isFieldsetDisabled: PropTypes.bool.isRequired,
  saveToLocalStorage: PropTypes.func.isRequired,
  setDisableTab: PropTypes.func,
  onTabChange: PropTypes.func.isRequired,
};

export default NavTabs;
