import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import InputWrapper from '../../../../commons/components/input-wrapper';
import { emptyAdditionalData } from '../../../../commons/utils/empty-additional-data';

import style from './index.module.scss';

const AdditionalData = ({
  disabled,
  inputValue,
  onBlurCb,
  onChangeCb,
  onFocusCb,
  theme,
  triggerCloseEffect,
  triggerOpenEffect,
  triggerResetEffect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [additionalData, setAdditionalData] = useState('');

  const line2Ref = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (inputValue.line2 !== additionalData.line2 || inputValue.company !== additionalData.company) {
      setAdditionalData(inputValue);

      if (!inputValue.line2 && !inputValue.company) {
        setIsOpen(false);
      }
    }
  }, [inputValue]);

  const handleReset = () => {
    setIsOpen(false);
    setAdditionalData(emptyAdditionalData);
  };

  const handleChange = e => {
    const changedData = {
      ...additionalData,
      [e.target.id]: e.target.value,
    };
    setAdditionalData(changedData);
    if (typeof onChangeCb === 'function') {
      onChangeCb(changedData);
    }
  };

  const handleFocus = () => {
    if (typeof onFocusCb === 'function') {
      onFocusCb(additionalData);
    }
  };

  const handleBlur = e => {
    if (typeof onBlurCb === 'function') {
      onBlurCb(e);
    }
  };

  const triggerOpen = triggerOpenCb => {
    setIsOpen(true);
    if (typeof triggerOpenCb === 'function') {
      triggerOpenCb();
    }
  };

  const triggerClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (inputValue?.line2 || inputValue?.company) {
      setAdditionalData(inputValue);
      if (inputValue && Object.values(inputValue).length > 0) {
        const filterValues = Object.values(inputValue).filter(item => !!item);
        const hasValues = filterValues.length > 0;
        if (hasValues) {
          triggerOpen();
        }
      }
    }
  }, [inputValue]);

  useEffect(() => {
    const focusOnUnit = () => {
      if (line2Ref && line2Ref.current) {
        line2Ref.current.focus();
      }
    };
    const openEffect = () => {
      if (triggerOpenEffect) {
        triggerOpen(focusOnUnit);
      }
    };
    openEffect();
  }, [triggerOpenEffect]);

  useEffect(() => {
    const resetEffect = () => {
      if (triggerResetEffect) {
        handleReset();
      }
    };
    resetEffect();
  }, [triggerResetEffect]);

  useEffect(() => {
    const closeEffect = () => {
      if (triggerCloseEffect && !additionalData.company) {
        triggerClose();
      }
    };
    closeEffect();
  }, [triggerCloseEffect]);

  return (
    <div className={style.container}>
      <SwitchTransition mode="out-in">
        <CSSTransition
          addEndListener={node => {
            node.addEventListener('transitionend', () => null, false);
          }}
          timeout={250}
          classNames="animation"
          key={isOpen}
        >
          {isOpen ? (
            <div className={style['custom-grid']}>
              <InputWrapper
                classNameInput={classNames({
                  'is-value': additionalData.line2,
                })}
                disabled={disabled}
                inputId="line2"
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                placeholder={`${t('address_add.apt_or_unit')} #`}
                placeholderTop={`${t('address_add.apt_or_unit')} #`}
                ref={line2Ref}
                theme={theme}
                type="text"
                value={additionalData.line2}
              />
              <InputWrapper
                classNameInput={classNames({
                  'is-value': additionalData.company,
                })}
                disabled={disabled}
                inputId="company"
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                placeholder={`${t('address_add.company_name')}`}
                placeholderTop={`${t('address_add.company_name')}`}
                theme={theme}
                type="text"
                value={additionalData.company}
              />
            </div>
          ) : (
            <>
              {disabled && <div className="nf-disabled-but-clickable" />}
              <button type="button" onClick={triggerOpen} disabled={disabled} className={style['add-button']}>
                <span>+ {t('address_add.apartment_company_name')}</span>
              </button>
            </>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

AdditionalData.propTypes = {
  disabled: PropTypes.bool.isRequired,
  inputValue: PropTypes.oneOfType([PropTypes.object]),
  onBlurCb: PropTypes.func.isRequired,
  onChangeCb: PropTypes.func.isRequired,
  onFocusCb: PropTypes.func.isRequired,
  theme: PropTypes.oneOfType([PropTypes.object]).isRequired,
  triggerCloseEffect: PropTypes.number,
  triggerOpenEffect: PropTypes.number,
  triggerResetEffect: PropTypes.number,
};

export default AdditionalData;
