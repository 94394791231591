import { useDispatch, useSelector } from 'react-redux';

import { setModal, selectModal } from '../slice';
import { templates } from '../constants';

export const useModal = () => {
  const dispatch = useDispatch();
  const modalState = useSelector(selectModal);

  const showProductsOutOfStock = data => {
    dispatch(
      setModal({
        isVisible: true,
        template: templates.productsOutOfStock,
        data,
      }),
    );
  };

  const resetModal = () => {
    dispatch(
      setModal({
        isVisible: false,
        template: '',
      }),
    );
  };

  return { showProductsOutOfStock, resetModal, modalState };
};
