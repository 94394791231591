import React from 'react';
import PropTypes from 'prop-types';

import './Privacy.scss';

const Privacy = ({ setActive }) => (
  <div className="rules-wrapper">
    <div className="rules-wrapper-header">
      <h1>NoFraud Privacy Policy</h1>
    </div>
    <div className="rules-wrapper-body">
      <p>
        No Fraud LLC and its affiliates (“NoFraud”, “we”, “our”, or “us”) respect your concerns about privacy. Our fraud
        prevention solution helps our merchant customers (“Customers”) identify fraudulent transactions on their
        eCommerce websites and mobile applications. This Privacy Policy applies to the personal information we collect
        about personnel of our Customers or prospective business leads and users of our website (
        <a href="https://www.nofraud.com" target="_blank" rel="noreferrer">
          www.nofraud.com
        </a>
        ). The Policy describes the personal information we collect about these individuals, how we use the information,
        with whom we may share it and the choices available to you regarding our use of the information. We also
        describe measures we take to protect the security of the information and how you can contact us about our
        privacy practices.
      </p>
      <p>
        For information about how we use personal information about end users in connection with certain services we
        offer, please read our{' '}
        <a href="https://www.nofraud.com/privacy/" target="_blank" rel="noreferrer">
          End User Privacy Policy
        </a>
        .
      </p>
      <p>Click on one of the links below to jump to the listed section:</p>
      <ol className="privacy-list">
        <li>
          Personal Information We Obtain
          <ol className="privacy-list">
            <li>Automated Data Collection</li>
          </ol>
        </li>
        <li>
          How We Use the Information We Obtain
          <ol className="privacy-list">
            <li>Third-Party Analytics Services</li>
            <li>Interest-Based Advertising</li>
          </ol>
        </li>
        <li>Information We Share </li>
        <li>Data Transfers</li>
        <li>How We Protect Personal Information</li>
        <li>Retention of Personal Information</li>
        <li>Your Rights and Choices</li>
        <li>Children’s Privacy</li>
        <li>Links to Third-Party Services and Features</li>
        <li>Updates to Our Privacy Policy</li>
        <li>How to Contact Us</li>
      </ol>
      <p className="rules-section-header">Personal Information We Obtain</p>
      <p>The personal information we may obtain includes:</p>
      <ol className="privacy-list">
        <li>Contact information, such as your name, telephone number and postal and email address.</li>
        <li>
          Professional information, such as your job title, company name, industry, and professional and employment
          background.
        </li>
        <li>Account information, such as login information, account preferences and other account details.</li>
        <li>
          Device information, as described in the{' '}
          <a
            href="#fake"
            onClick={() =>
              document.getElementById('automated-data-collection').scrollIntoView({
                behavior: 'smooth',
              })
            }
          >
            Automated Data Collection
          </a>{' '}
          section below;
        </li>
        <li>
          Job application information, such as your resume and any additional application information provided to us
          (for example, employment history and education).
        </li>
        <li>
          Any other information you submit through our website, such as through forms, surveys, registration pages,
          emails, calls, comments and other features on the website.
        </li>
      </ol>
      <p>
        We may collect this information when you use our website, apply for jobs with NoFraud, or otherwise interact
        with us (for example, call or email us, or attend our trade shows or other events). In some cases, we also may
        obtain your personal information from other parties, such as through referrals, from your employer, our
        providers of recruiting services, or from our affiliates, vendors, and other third parties with whom we work. We
        also may obtain other personal information about you in ways that we describe at the time of collection or
        otherwise with your consent
      </p>
      <p className="privacy-section-header" id="automated-data-collection">
        Automated Data Collection
      </p>
      <p>
        When you use our website or open our emails, we may obtain certain information by automated means, such as
        through cookies, web server logs, web beacons (including pixels and tags), device fingerprinting, and other
        technologies. These technologies help us (1) remember your information so you do not have to re-enter it; (2)
        track, understand and analyze how you use and interact with our website; (3) personalize your experience with
        our website; (4) measure the usability of our website and the effectiveness of our communications; and (5)
        otherwise manage and enhance our websites and help ensure they are working properly.
      </p>
      <p>
        We may use these automated technologies through our website to collect information about your device, browsing
        actions, usage patterns and location. Through these automated means, we obtain:
      </p>
      <ol className="privacy-list">
        <li>
          <b>Device and Browser Information:</b> Such as your device IP address, general location information, unique
          device identifiers, device type and model, device characteristics and settings, browser and other app
          information (e.g., browser type, settings and characteristics), operating system information (e.g., type and
          version), hardware-based identifiers (e.g., MAC address), network connection type, error information, language
          and country preferences, referring or exit URLs, and other device and application details.
        </li>
        <li>
          <b>Information about your interactions with our Services:</b> Such as pages visited, links clicked, features
          used, dates and times of access, session information, and other information about your use of our Services.
        </li>
      </ol>
      <p>
        Through these automated technologies, we may track information about your online activities over time and across
        third-party websites and apps, such as our Customers’ Platforms. Your browser may tell you how to be notified
        about certain types of automated collection technologies and how to restrict or disable them. Please note,
        however, that without these technologies, you may not be able to use all of the features of our products or
        services. For mobile devices, you may be able to manage how your device and browser share certain device data by
        adjusting the privacy and security settings on your mobile device.{' '}
      </p>
      <p className="rules-section-header">How We Use the Information We Obtain</p>
      <p>
        We use the information we obtain for our legitimate interests in providing our products and services,
        maintaining our business, and for security and safety purposes, including to:
      </p>
      <ol className="privacy-list">
        <li>Provide products and services.</li>
        <li>Manage our relationships with you and our Customers.</li>
        <li>Provide, onboard and manage your account.</li>
        <li>Verify your identity.</li>
        <li>Communicate with you and provide technical and customer support.</li>
        <li>Personalize your experience on our services.</li>
        <li>
          Advertise and market our products and services and provide you with offers and other communications about
          products and services of NoFraud and others.
        </li>
        <li>Administer participation in surveys, sweepstakes, promotions or other programs.</li>
        <li>
          Manage career opportunities with us, including for recruitment purposes, candidate screening and evaluation,
          and employee onboarding.
        </li>
        <li>Compile, anonymize or aggregate personal information for our business purposes.</li>
        <li>
          Perform analytics and market, trend or statistical research and analysis (including developing, deriving and
          compiling market research, data sets, insights, trends, benchmarks, algorithms, models and other analyses or
          information).
        </li>
        <li>
          Operate, evaluate and improve our business (including developing new products and services; enhancing,
          improving and analyzing our website, products and services; managing our communications; and performing
          accounting, auditing and other internal functions).
        </li>
        <li>
          Maintain and enhance the safety and security of our website, products and services, prevent misuse, and
          troubleshoot technical issues.
        </li>
        <li>Prevent or detect fraud and other criminal activities, claims, and other liabilities.</li>
        <li>Exercise our rights and remedies and defend against legal claims.</li>
        <li>
          Comply with and enforce applicable legal requirements, relevant industry standards and NoFraud policies and
          terms and conditions, including our{' '}
          <a href="#fake" onClick={() => setActive('Terms of Use')}>
            Terms of Use
          </a>
          .
        </li>
      </ol>
      <p>
        We also may use the information in other ways for which we provide specific notice at the time of collection or
        with your consent, if required under applicable law.
      </p>
      <p className="privacy-section-header">Third-Party Analytics Services</p>
      <p>
        We may use third-party analytics services on our website, such as Google Analytics, HubSpot and LinkedIn
        Analytics. The providers of these analytics services use technologies such as cookies and web beacons to help us
        analyze your use of our website. The information collected through these means may be disclosed to or collected
        directly by these analytics services. To learn more about Google Analytics, please visit{'  '}
        <a href="https://www.google.com/policies/privacy/partners/" target="_blank" rel="noreferrer">
          https://www.google.com/policies/privacy/partners/
        </a>
        .
      </p>
      <p className="privacy-section-header">Interest-Based Advertising</p>
      <p>
        You may see our ads on other websites because we use third-party ad services on our site. Through these ad
        services, we can tailor our messaging to individuals considering demographic data, inferred interests and
        browsing context. These ad services track information about your online activities over time and across
        third-party websites and apps by collecting information through automated means, including through the use of
        cookies, web server logs, web beacons and other similar technologies. These ad services may collect data about
        your visits to websites and apps that participate in these services, such as the pages or ads you view and the
        actions you take on the websites or apps. This data collection takes place both on our website and on
        third-party websites and apps that participate in these ad services. These ad services use this information to
        show you ads that may be tailored to your individual interests. This process also helps us track the
        effectiveness of our marketing efforts.
      </p>
      <p>
        To learn how to opt out of interest-based advertising, please visit{' '}
        <a href="https://www.aboutads.info/choices" target="_blank" rel="noreferrer">
          www.aboutads.info/choices
        </a>
        ,{' '}
        <a href="https://www.networkadvertising.org/choices/" target="_blank" rel="noreferrer">
          www.networkadvertising.org/choices/
        </a>
        , or
        <a href="http://preferences-mgr.truste.com/" target="_blank" rel="noreferrer">
          http://preferences-mgr.truste.com/
        </a>
        . In the EU, please visit
        <a href="https://www.youronlinechoices.eu/" target="_blank" rel="noreferrer">
          www.youronlinechoices.eu/
        </a>{' '}
        (or{' '}
        <a href="https://www.youronlinechoices.com/uk/" target="_blank" rel="noreferrer">
          www.youronlinechoices.com/uk/
        </a>{' '}
        in the UK). In addition, your Facebook account settings may allow you to adjust the ads you see while on
        Facebook and the information Facebook can use to show you ads on Facebook.
      </p>
      <p className="rules-section-header">Information We Share</p>
      <p>We may share personal information, as set forth below:</p>
      <ol className="privacy-list">
        <li>
          <b>Affiliated Entities:</b> We share personal information with our affiliates for the purposes described in
          this Privacy Policy.
        </li>
        <li>
          <b>Our Customers:</b> If your employer is our Customer,we may share your personal information with your
          colleagues and employer in connection with establishing, maintaining and managing our relationship with your
          employer and for verification purposes.
        </li>
        <li>
          <b>Service Providers:</b> We share personal information with vendors and other entities to perform services
          for us on our behalf, such as consultants, auditors, attorneys and providers of information and communication
          technology, advertising and marketing, data enrichment, information verification, analytics, security, payment
          processing and billing, customer service, customer relationship management services, and others.
        </li>
        <li>
          <b>Social Networks:</b> We may share your personal information with social media platforms if you use those
          services to connect with us through the features on our website. Where required under applicable law, we will
          ask for you consent to do so.
        </li>
        <li>
          <b>Business Transfers:</b> We reserve the right to transfer any personal information we have about you in the
          event we sell or transfer all or a portion of our business or assets (including in the event of a merger,
          acquisition, joint venture, reorganization, divestiture, dissolution or liquidation).
        </li>
        <li>
          <b>Others:</b> We maydisclose personal information (1) if we are required to do so by law or legal process,
          such as a court order or subpoena; (2) in response to requests by government agencies, such as law enforcement
          authorities; (3) to establish, exercise or defend our legal rights; (4) when we believe disclosure is
          necessary or appropriate to prevent physical or other harm or financial loss, or to protect the safety,
          property or rights of NoFraud or any third party; (5) in connection with an investigation of suspected or
          actual illegal activity or security issues, or (6) otherwise with your consent or as directed by your
          representative.
        </li>
      </ol>
      <p className="rules-section-header">Data Transfers</p>
      <p>
        In connection with our Services, your personal information may be transferred to NoFraud or its service
        providers in countries other than the country in which you reside. Those countries may not have the same data
        protection laws as the country in which you initially provided the personal information. When your personal
        information is transferred to these other countries (such as the U.S.), we will protect that information as
        described in this Privacy Policy, including, to the extent required by applicable law, by entering into Standard
        Contractual Clauses or other data transfer safeguards. You may request a copy of these safeguards by contacting
        us as described in the How to Contact Us section of this Privacy Policy.
      </p>
      <p className="rules-section-header">How We Protect Personal Information</p>
      <p>
        We maintain administrative, technical and physical safeguards designed to protect personal information against
        accidental, unlawful or unauthorized access, destruction, loss, alteration, disclosure or use.
      </p>
      <p className="rules-section-header">Retention of Personal Information</p>
      <p>
        To the extent required by applicable law, we keep the personal information we obtain about you for the period
        necessary to achieve the purposes described in this Privacy Policy, taking into account applicable statute of
        limitations periods and any legal, regulatory, tax, accounting or other records retention requirements. We may
        retain your personal information for a longer period in the event of a complaint or in reasonable anticipation
        of litigation.
      </p>
      <p className="rules-section-header">Your Rights and Choices</p>
      <p>
        To the extent provided by applicable law, you may (1) request access to certain personal information we maintain
        about you; (2) request that we update, correct, amend or erase your personal information; (3) restrict or object
        to the processing of your personal information; or (4) exercise your right to data portability. To exercise
        these rights, update your preferences, limit the communications you receive from us, or submit a request, please
        contact us as specified in the How to Contact Us section below. Where provided by law, you may withdraw any
        consent you previously provided to us, and we will apply your preferences going forward as appropriate. This
        will not affect the lawfulness of our processing based on your consent prior to such withdrawal. You may have
        the right to file a complaint with a government regulator if you are not satisfied with our response.
      </p>
      <p>
        We send you promotional emails regarding our products and services. You can unsubscribe from our marketing
        mailing lists by following the “Unsubscribe” link in our relevant emails.
      </p>
      <p>
        If we are required by law or the contract we have with you to collect personal information, and you choose not
        to provide that information when requested, we may not be able to provide you with our services.
      </p>
      <p className="rules-section-header">Children’s Privacy</p>
      <p>
        Our website is designed for a general audience and are not directed to children. In connection with the website,
        we do not knowingly solicit or collect personal information from children under the age of 16 without parental
        consent. If you believe that a child under age 16 may have provided us with personal information without
        parental consent, please contact us as specified in the How to Contact Us section of this Privacy Policy.
      </p>
      <p className="rules-section-header">Links to Third-Party Services and Features</p>
      <p>
        For your convenience and information, our website may provide links to other online services, and may include
        third-party features such as apps, tools, widgets and plug-ins. These online services and third-party features
        may operate independently from us. The privacy practices of the relevant third parties, including details on the
        information they may collect about you, are subject to the privacy statements of these parties, which we
        strongly suggest you review. To the extent any linked online services or third-party features are not owned or
        controlled by NoFraud, we are not responsible for these third parties’ information practices.
      </p>
      <p className="rules-section-header">Updates to Our Privacy Policy</p>
      <p>
        We may change this Privacy Policy from time to time. We will indicate at the top of the Privacy Policy when it
        was most recently updated. You should periodically check this Privacy Policy for updates. For significant
        changes, we will notify you by posting a notice on our website.
      </p>
      <p className="rules-section-header">How to Contact Us</p>
      <p>
        You can update your preferences, ask us to remove your information from our mailing lists, submit a request or
        ask us questions about this Privacy Policy by contacting us at{' '}
        <a href="mailto:info@nofraud.com">info@nofraud.com</a>.{' '}
      </p>
      <p>
        For individuals located in the EU/UK, No Fraud LLC is the controller of the personal information we process
        about you pursuant to this Privacy Policy.
      </p>
    </div>
  </div>
);

Privacy.propTypes = {
  setActive: PropTypes.func.isRequired,
};

export default Privacy;
