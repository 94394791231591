import { createSlice } from '@reduxjs/toolkit';

import { retrieveData } from '../../../commons/utils/local-storage-manager';
import { platformCartIdValue } from '../../../helpers/prelaunchData';
import { deliveryMethod } from '../../../commons/constants';

export const sliceKey = 'shipping';

// #region initial state + local storage
const retrieved = retrieveData(sliceKey, platformCartIdValue, {});

const initialState = {
  deliveryMethod: retrieved.deliveryMethod || deliveryMethod.shipping,
  bopis: {
    isEnabled: false,
    pickupMethods: [],
    selectedPickupMethod: retrieved.selectedPickupMethod || null,
  },
  shippingMethodKey: undefined,
};

// Empty state is needed so we can always get a fresh, clear state when we reset the data
const emptyState = {
  deliveryMethod: deliveryMethod.shipping,
  bopis: {
    isEnabled: false,
    pickupMethods: [],
    selectedPickupMethod: null,
  },
  shippingMethodKey: undefined,
};
// #endregion

// #region slice
export const shippingSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    setDeliveryMethod: (state, action) => {
      state.deliveryMethod = action.payload;
    },
    setPickupMethods: (state, action) => {
      state.bopis.pickupMethods = action.payload;
      state.bopis.isEnabled = action.payload?.length > 0;
    },
    setSelectedPickupMethod: (state, action) => {
      state.bopis.selectedPickupMethod = action.payload;
    },
    setShippingMethodKey: (state, action) => {
      state.shippingMethodKey = action.payload;
    },
    resetKeys: state => {
      delete state.bopis.selectedPickupMethod;
      delete state.shippingMethodKey;
    },
    resetShipping: state => {
      state.deliveryMethod = emptyState.deliveryMethod;
      state.bopis = emptyState.bopis;
      state.shippingMethodKey = emptyState.shippingMethodKey;
    },
  },
});
// #endregion

export const {
  setDeliveryMethod,
  setPickupMethods,
  setSelectedPickupMethod,
  resetKeys,
  resetShipping,
  setShippingMethodKey,
} = shippingSlice.actions;

export const selectShipping = state => state.shipping;

export default shippingSlice.reducer;
