import { useContext } from 'react';
import DataContext from '../store/dataContext';

export const useBroadcastChannel = () => {
  const { setBroadcastMessage, setBroadcastErrorMessage } = useContext(DataContext);

  const postMessage = data => {
    window.opener.receiveMessage(data);
  };

  const postToPopup = data => {
    window.opener.postMessage(data);
  };

  const receiveMessage = popupRef => {
    if (popupRef && popupRef.opener) {
      // eslint-disable-next-line no-param-reassign
      popupRef.opener.receiveMessage = data => {
        if (data?.error) {
          setBroadcastErrorMessage(data.error);
        } else {
          setBroadcastMessage(data);
        }
      };
    }
  };

  return { postMessage, receiveMessage, postToPopup };
};
