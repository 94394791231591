import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DataContext from '../../../store/dataContext';

import { useModal } from '../hooks/use-modal';
import { ProductsOutOfStockTemplate, DefaultTemplate } from '../components';

import style from './modal.module.scss';

import { ReactComponent as CloseIcon } from '../../../commons/assets/close-no-fill.svg';
import { selectCheckout, deleteItemFromCart, setCartItems, selectApiStatus } from '../../../store/slice';

const ModalComponents = {
  PRODUCTS_OUT_OF_STOCK: ProductsOutOfStockTemplate,
};

export const Modal = () => {
  const { modalState, resetModal } = useModal();
  const dispatch = useDispatch();
  const checkoutState = useSelector(selectCheckout);
  const statusState = useSelector(selectApiStatus);
  const { merchantId } = useContext(DataContext);

  const onDeleteOutOfStockItems = async () => {
    const results = [];
    for (const p of modalState.data.products) {
      results.push(
        dispatch(
          deleteItemFromCart({
            merchantId: `merchant:${merchantId}`,
            checkoutId: checkoutState.id,
            productAndVariantId: encodeURIComponent(p.lookupKey),
          }),
        ),
      );
    }
    const allResults = await Promise.all(results);
    dispatch(setCartItems(allResults[0].payload.cart.items));
    resetModal();
  };

  const TemplateSpecificView = ModalComponents[modalState.template] ?? DefaultTemplate;
  const templateProps = {
    PRODUCTS_OUT_OF_STOCK: {
      data: modalState.data,
      onDeleteOutOfStockItems,
      isLoadingDeleteItemFromCart: statusState.isLoadingDeleteItemFromCart,
    },
  };

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <CloseIcon width={15} height={15} className={style['btn-close']} onClick={resetModal} />
        <TemplateSpecificView {...(templateProps[modalState.template] ?? {})} />
      </div>
    </div>
  );
};
