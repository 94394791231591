export const status = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  READY: 'READY',
  SUBMITTED: 'SUBMITTED',
  ERROR: 'ERROR',
};

export const inputs = {
  creditCard: 'credit-card-disable',
  creditCardContainer: 'credit-card-container',
  disableName: 'nf-disabled-but-clickable',
  tabList: 'tablistcontent',
  shippingMethod: 'container-shipping-method',
  name: 'shipping-name',
  googleAddress: 'shipping-address',
  downshiftAddress: 'downshift-1-input',
};

export const analyticsMethods = {
  autofill_accepted: 'autofill_accepted',
  card_manual: 'card_manual',
  card_selected: 'card_selected',
};

export const analyticsStage1 = {
  ADDRESS: 'ADDRESS',
  CREDIT_CARD: 'CREDIT_CARD',
  EMAIL: 'EMAIL',
  FAST_TRACK: 'FAST_TRACK',
  NOT_STARTED: 'NOT_STARTED',
};

export const analyticsStage2 = {
  ADDRESS: 'ADDRESS',
  NOT_STARTED: 'NOT_STARTED',
  PHONE: 'PHONE',
};

export const defaultLanguage = 'en';

export const defaultShopperCurrency = {
  baseCode: 'USD',
  displayCode: 'USD',
  precision: '2',
  separator: {
    radix: '.',
    thousand: ',',
  },
  symbol: {
    position: 'left',
    token: '$',
  },
  exchangeRate: 1,
  isTransactional: true,
};

export const defaultPortalSettings = {
  checkoutMode: {
    mode: 'ACTIVE',
    avsBehaviour: {
      billingDefaultValue: false,
    },
  },
  contactPhone: {
    isVisible: false,
    isRequired: false,
  },
  newsletter: {
    isVisible: false,
    checked: false,
  },
  analytics: {
    isEnabled: false,
  },
  orderNotes: {
    isEnabled: false,
    customName: '',
    color: '#aaaaaa',
    mobileView: 'In Dropdown',
  },
  shippingProtection: {
    isEnabled: false,
    quote: 0,
  },
  customerSignature: {
    isEnabled: false,
    quote: 0,
  },
  termsAndConditions: {
    isEnabled: false,
    value: '',
  },
  theme: {
    colour: {
      main: '#2d9cdb',
      text: '#ffffff',
    },
  },
  coupon: {
    isEnabled: false,
    name: '',
    color: '#aaaaaa',
    fontSize: 'medium',
    isBold: false,
    uppercase: false,
  },
  giftCard: {
    isEnabled: false,
  },
  preOrder: {},
  scripts: [],
  isCodesAvailable: false,
};

export const paymentIdList = {
  UNDEFINED: 'undefined',
  CREDIT_CARD: 'credit-card',
  ACH: 'ach',
  ACIMA: 'acima',
  AFFIRM: 'affirm',
  AFTERPAY: 'afterpay',
  APPLEPAY: 'applepay',
  GOOGLEPAY: 'googlepay',
  AMAZONPAY: 'amazonpay',
  PAYPAL: 'paypal',
  PAYLATER: 'paylater',
  KLARNA: 'klarna',
  CREDITKEY: 'creditkey',
  CREDOVA: 'credova',
  PAYTOMORROW: 'paytomorrow',
  PURCHASE: 'purchase',
  SEZZLE: 'sezzle',
  SNAPFINANCE: 'snapfinance',
  VENMO: 'venmo',
};

export const googlePayError = t => ({
  transactionState: 'ERROR',
  error: {
    intent: 'PAYMENT_AUTHORIZATION',
    message: t('payment_method.googlepay.error'),
    reason: 'PAYMENT_DATA_INVALID',
  },
});

export const productType = {
  DIGITAL: 'DIGITAL',
  PHYSICAL: 'PHYSICAL',
};

export const fontSize = {
  XXSMALL: 'xx-small',
  XSMALL: 'x-small',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'x-large',
  XXLARGE: 'xx-large',
  XXXLARGE: 'xxx-large',
};

export const fontWeight = {
  THIN: 100,
  XLIGHT: 200,
  LIGHT: 300,
  NORMAL: 400,
  MEDIUM: 500,
  SEMIBOLD: 600,
  BOLD: 700,
  XBOLD: 800,
  HEAVY: 900,
};

export const analyticsLocalStorageKey = 'analytics-cart-id';
