import { createSlice, current } from '@reduxjs/toolkit';

import { initialErrorState } from '../../../commons/utils/initial-error-state';
import { retrieveData } from '../../../commons/utils/local-storage-manager';
import { platformCartIdValue } from '../../../helpers/prelaunchData';
import { emptyManualData } from '../../../commons/containers/manual-address/container/utils';
import { emptyAdditionalData } from '../../../commons/utils/empty-additional-data';

export const sliceKey = 'billing';

// #region initial state + local storage
const retrieved = retrieveData(sliceKey, platformCartIdValue, {});

const initialState = {
  additionalData: retrieved.additionalData || emptyAdditionalData,
  address: retrieved.address || {},
  addressGoogle: {
    error: initialErrorState,
    value: retrieved.addressGoogle || null,
  },
  addressDownshift: {
    error: initialErrorState,
    value: retrieved.addressDownshift || null,
  },
  addressManual: emptyManualData,
  fullName: {
    error: initialErrorState,
    value: retrieved.fullName || '',
  },
  status: {
    isReady: false,
  },
};

// Empty state is needed so we can always get a fresh, clear state when we reset the data
const emptyState = {
  additionalData: emptyAdditionalData,
  address: {},
  addressGoogle: {
    error: initialErrorState,
    value: null,
  },
  addressDownshift: {
    error: initialErrorState,
    value: null,
  },
  addressManual: emptyManualData,
  fullName: {
    error: initialErrorState,
    value: '',
  },
  status: {
    isReady: false,
  },
};
// #endregion

// #region slice
export const billingSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    setFullName: (state, action) => {
      state.fullName.error = action.payload.error;
      state.fullName.value = action.payload.value;
    },
    setAddress: (state, action) => {
      state.address = action.payload.value || {};
    },
    setAdditionalData: (state, action) => {
      state.additionalData = action.payload;
    },
    setAddressGoogle: (state, action) => {
      state.addressGoogle.error = action.payload.addressGoogle.error;
      state.addressGoogle.value = action.payload.addressGoogle.value;
    },
    setAddressDownshift: (state, action) => {
      state.addressDownshift.error = action.payload.addressDownshift.error;
      state.addressDownshift.value = action.payload.addressDownshift.value;
    },
    setAddressManual: (state, action) => {
      state.addressManual = action.payload;
    },
    setStatus: (state, action) => {
      const currentIsReady = current(state.status).isReady;
      state.status.isReady = typeof action.payload.isReady === 'boolean' ? action.payload.isReady : currentIsReady;
    },
    resetBilling: state => {
      state.fullName = emptyState.fullName;
      state.address = emptyState.address;
      state.addressGoogle = emptyState.addressGoogle;
      state.addressDownshift = emptyState.addressDownshift;
      state.addressManual = emptyState.addressManual;
      state.additionalData = emptyState.additionalData;
      state.status = emptyState.status;
    },
  },
});
// #endregion

export const {
  setFullName,
  setAddress,
  setAddressGoogle,
  setAddressDownshift,
  setAddressManual,
  setStatus,
  resetBilling,
  setAdditionalData,
} = billingSlice.actions;

export const selectBilling = state => state.billing;
export const selectAddressDownshift = state => state.billing.addressDownshift;
export const selectAddressGoogle = state => state.billing.addressGoogle;

export default billingSlice.reducer;
