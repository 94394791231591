/* eslint-disable max-len */

export const mock_with_shipping_address_success = {
  cards: [],
  cartTotals: {
    total: 61.6,
    handling: 0,
    shipping: 0,
    tax: 0,
    discount: 0,
    subtotal: 61.6,
    coupons: [],
    insurance: 0,
    grandTotal: 61.6,
  },
  paymentMethodData: {},
  shipMethods: [
    {
      key: '4dcbf24f457dd67d5f89bcf374e0bc9b',
      label: 'Free Shipping',
      price: 0,
      isSelected: true,
      transitTime: '',
      type: 'free',
    },
    {
      key: 'cbdc131e6ca6e7d1e2977fe5e14ef07d',
      label: 'Flat Rate',
      price: 5,
      isSelected: false,
      transitTime: '',
      type: 'fixed-rate',
    },
    {
      key: '2ac413e4d2f34e434b8247624f33eee1',
      label: 'Ship by Weight',
      price: 8,
      isSelected: false,
      transitTime: '',
      type: 'by-weight',
    },
    {
      key: '0a52611632b740ab4bdbbb699e127348',
      label: 'UPS 3 to 7 Days Transit, No Weekends (UPS® Ground)',
      price: 22,
      isSelected: false,
      transitTime: '',
      type: 'by-weight',
    },
  ],
  success: true,
};

export const mock_smarty_autocomplete_01 = {
  suggestions: [
    {
      street_line: '520 Minnesota Ave',
      secondary: '',
      city: 'Albert Lea',
      state: 'MN',
      zipcode: '56007',
      entries: 0,
    },
    {
      street_line: '520 Minnesota Ave',
      secondary: '',
      city: 'Buffalo',
      state: 'NY',
      zipcode: '14215',
      entries: 0,
    },
    {
      street_line: '520 Minnesota Ave',
      secondary: '',
      city: 'Kansas City',
      state: 'KS',
      zipcode: '66101',
      entries: 0,
    },
    {
      street_line: '520 Minnesota Ave',
      secondary: '',
      city: 'Lorimor',
      state: 'IA',
      zipcode: '50149',
      entries: 0,
    },
    {
      street_line: '520 Minnesota Ave',
      secondary: '',
      city: 'Panama City',
      state: 'FL',
      zipcode: '32403',
      entries: 0,
    },
  ],
};

export const mock_smarty_autocomplete_02 = {
  suggestions: [],
};

export const mock_smarty_usstreet_01 = [
  {
    input_index: 0,
    candidate_index: 0,
    delivery_line_1: '520 Minnesota Ave',
    last_line: 'Kansas City KS 66101-2930',
    delivery_point_barcode: '661012930200',
    components: {
      primary_number: '520',
      street_name: 'Minnesota',
      street_suffix: 'Ave',
      city_name: 'Kansas City',
      default_city_name: 'Kansas City',
      state_abbreviation: 'KS',
      zipcode: '66101',
      plus4_code: '2930',
      delivery_point: '20',
      delivery_point_check_digit: '0',
    },
    metadata: {
      record_type: 'S',
      zip_type: 'Standard',
      county_fips: '20209',
      county_name: 'Wyandotte',
      carrier_route: 'C027',
      congressional_district: '02',
      rdi: 'Commercial',
      elot_sequence: '0044',
      elot_sort: 'A',
      latitude: 39.11564,
      longitude: -94.62274,
      precision: 'Zip9',
      time_zone: 'Central',
      utc_offset: -6,
      dst: true,
    },
    analysis: {
      dpv_match_code: 'Y',
      dpv_footnotes: 'AABB',
      dpv_cmra: 'N',
      dpv_vacant: 'N',
      dpv_no_stat: 'N',
      active: 'Y',
    },
  },
];

export const mock_smarty_autocomplete_03 = {
  suggestions: [
    {
      street_line: '1042 W Center St',
      secondary: '',
      city: 'Alma',
      state: 'MI',
      zipcode: '48801',
      entries: 0,
    },
    {
      street_line: '1042 W Center St',
      secondary: 'Apt',
      city: 'Orem',
      state: 'UT',
      zipcode: '84057',
      entries: 108,
    },
    {
      street_line: '1042 W Center St',
      secondary: '',
      city: 'Madisonville',
      state: 'KY',
      zipcode: '42431',
      entries: 0,
    },
    {
      street_line: '1042 W Center St',
      secondary: '',
      city: 'Orem',
      state: 'UT',
      zipcode: '84057',
      entries: 0,
    },
    {
      street_line: '1042 W Center St',
      secondary: 'Unit',
      city: 'Orem',
      state: 'UT',
      zipcode: '84057',
      entries: 66,
    },
  ],
};

export const mock_smarty_usstreet_02 = [
  {
    input_index: 0,
    candidate_index: 0,
    delivery_line_1: '1042 W Center St',
    delivery_line_2: 'Apt',
    last_line: 'Orem UT 84057-5208',
    delivery_point_barcode: '840575208993',
    components: {
      primary_number: '1042',
      street_predirection: 'W',
      street_name: 'Center',
      street_suffix: 'St',
      city_name: 'Orem',
      default_city_name: 'Orem',
      state_abbreviation: 'UT',
      zipcode: '84057',
      plus4_code: '5208',
      delivery_point: '99',
      delivery_point_check_digit: '3',
    },
    metadata: {
      record_type: 'H',
      zip_type: 'Standard',
      county_fips: '49049',
      county_name: 'Utah',
      carrier_route: 'C039',
      congressional_district: '03',
      building_default_indicator: 'Y',
      rdi: 'Residential',
      elot_sequence: '0009',
      elot_sort: 'A',
      latitude: 40.29725,
      longitude: -111.72093,
      precision: 'Zip9',
      time_zone: 'Mountain',
      utc_offset: -7,
      dst: true,
    },
    analysis: {
      dpv_match_code: 'D',
      dpv_footnotes: 'AAN1',
      dpv_cmra: 'N',
      dpv_vacant: 'N',
      dpv_no_stat: 'Y',
      active: 'Y',
      footnotes: 'H#',
    },
  },
];

export const mock_smarty_autocomplete_04 = {
  suggestions: [
    {
      street_line: '1042 W Center St',
      secondary: 'Apt A101',
      city: 'Orem',
      state: 'UT',
      zipcode: '84057',
      entries: 1,
    },
    {
      street_line: '1042 W Center St',
      secondary: 'Apt B101',
      city: 'Orem',
      state: 'UT',
      zipcode: '84057',
      entries: 1,
    },
    {
      street_line: '1042 W Center St',
      secondary: 'Apt C101',
      city: 'Orem',
      state: 'UT',
      zipcode: '84057',
      entries: 1,
    },
    {
      street_line: '1042 W Center St',
      secondary: 'Apt D101',
      city: 'Orem',
      state: 'UT',
      zipcode: '84057',
      entries: 1,
    },
    {
      street_line: '1042 W Center St',
      secondary: 'Apt E101',
      city: 'Orem',
      state: 'UT',
      zipcode: '84057',
      entries: 1,
    },
    {
      street_line: '1042 W Center St',
      secondary: 'Apt A102',
      city: 'Orem',
      state: 'UT',
      zipcode: '84057',
      entries: 1,
    },
    {
      street_line: '1042 W Center St',
      secondary: 'Apt B102',
      city: 'Orem',
      state: 'UT',
      zipcode: '84057',
      entries: 1,
    },
    {
      street_line: '1042 W Center St',
      secondary: 'Apt C102',
      city: 'Orem',
      state: 'UT',
      zipcode: '84057',
      entries: 1,
    },
    {
      street_line: '1042 W Center St',
      secondary: 'Apt D102',
      city: 'Orem',
      state: 'UT',
      zipcode: '84057',
      entries: 1,
    },
    {
      street_line: '1042 W Center St',
      secondary: 'Apt E102',
      city: 'Orem',
      state: 'UT',
      zipcode: '84057',
      entries: 1,
    },
  ],
};

export const mock_whoapi_01 = {
  status: 24,
  status_desc: 'Missing valid TLD in domain name.',
};

export const mock_whoapi_02 = {
  status: '0',
  taken: 1,
  requests_available: 9948,
  status_desc: 'Successfully processed',
};

export const mock_create_cart_with_bopis = {
  cartId: 'cart_af0a855c-d8d4-4ed2-8189-ab25c7ece22c',
  paymentMethods: {
    amazonPay: false,
    gpay: false,
    paypal: false,
  },
  pickupMethods: [
    {
      address: {
        city: 'Kansas City',
        countryIso2: 'US',
        line1: '520 Minnesota Ave',
        line2: '',
        postal: '66101',
        region: 'KS',
        userInputSingleLine: '520 Minnesota Ave, Kansas City, KS, 66101, US',
      },
      lookupKey: '1',
      name: 'Kansas Store',
    },
    {
      address: {
        city: 'Brooklyn',
        countryIso2: 'US',
        line1: '123 Melrose St',
        line2: '',
        postal: '11206',
        region: 'NY',
        userInputSingleLine: '123 Melrose St, Brooklyn, NY, 11206, US',
      },
      lookupKey: '2',
      name: 'Brooklyn Store',
    },
  ],
  platformPaymentMethods: {
    ach: false,
    acima: false,
    affirm: false,
    afterpay: false,
    amazonpay: false,
    applepay: false,
    credova: false,
    googlepay: false,
    klarna: false,
    creditkey: false,
    paytomorrow: false,
    paylater: false,
    paypal: false,
    settings: {
      isVisibleAtAddToCartModal: false,
      isVisibleAtCartPage: false,
      isVisibleAtPreviewDropdown: false,
    },
    sezzle: false,
    snapfinance: false,
    venmo: false,
  },
  skipCheckout: false,
  links: {
    returnPolicy: 'https://nofraud-qe-2-functional.mybigcommerce.com/shipping-returns/',
    terms: '',
    privacyPolicy: '',
  },
  settings: {
    abTesting: {
      isEnabled: false,
      partition: 100,
    },
    checkoutMode: {
      mode: 'ACTIVE',
      shouldCreateOrderOnFail: false,
    },
    contactPhone: {
      isEnabled: false,
    },
    coupon: {
      customName: '',
      isEnabled: true,
    },
    delayedShipMethodAcquisition: {
      isEnabled: false,
    },
    fullBilling: {
      isEnabled: false,
    },
    giftCard: {
      isEnabled: true,
    },
    helpfulLinks: {
      returnPolicy: 'https://nofraud-qe-2-functional.mybigcommerce.com/shipping-returns/',
      terms: '',
      privacyPolicy: '',
    },
    language: 'eng',
    manualCapture: {
      isEnabled: false,
    },
    orderNotes: {
      customName: '',
      isEnabled: true,
    },
    requiredCvv: {
      isEnabled: true,
    },
    staffNotes: {
      isEnabled: true,
    },
    termsAndConditions: {
      isEnabled: false,
    },
    theme: {
      coupon: {
        color: '#aaaaaa',
        fontSize: 'medium',
        isBold: false,
        uppercase: false,
      },
      mainColor: '#00A1E0',
      orderNotes: {
        color: '#AAAAAA',
        mobileView: 'In Dropdown',
      },
      outlineColor: '#2D9CDB',
      plugins: {
        isBold: false,
      },
      textColor: '#FFFFFF',
    },
    zipPlus4: {
      isEnabled: false,
    },
    plugins: {},
    scripts: [
      {
        content: '',
        parent: false,
        url: 'www.google.com',
        lastModified: 1669841036,
        id: 'b8ddb96d',
        name: 'Engineering',
        scriptLocation: 'Order Confirmation',
        merchantId: '1k8o6ugqcw',
        type: 'url',
      },
      {
        content:
          "const div = document.createElement('div'); div.innerHTML = \"**Please be advised Azar Displays' warehouse operations will be closed on Monday Sept 25th.  We will resume warehouse operations on Tuesday Sept 26th.  All orders will be processed in the order they are received.  Thank you and have a great day!** \"; const pluginDiv = document.getElementById('custom-plugin'); pluginDiv.appendChild(div);",
        parent: false,
        url: '',
        autoInstall: false,
        lastModified: 1695395150,
        id: 'a9ecc',
        name: 'Beam',
        scriptLocation: 'Order confirmation',
        merchantId: '1k8o6ugqcw',
        type: 'content',
      },
      {
        content:
          'if(window.location.href.indexOf(\'/checkout/order-confirmation\') === -1){\nwindow.parent.postMessage(JSON.stringify({\n    event: "begin_checkout",\n    data: {\n        currency: "USD",\n        value: "10.00",\n        items: [{\n      item_id: "SKU_12345"}],\n        event_id: Date.now()\n    }\n}), \'*\');\n}',
        parent: false,
        url: '',
        autoInstall: false,
        lastModified: 1700691974,
        id: 'f0a74',
        name: 'Begin Checkout Tracking',
        scriptLocation: 'Checkout',
        merchantId: '1k8o6ugqcw',
        type: 'content',
      },
      {
        content:
          'window.parent.postMessage(JSON.stringify({\n    event: "purchase",\n    data: {\n        currency: "USD",\n        transaction_id: {{ORDER_ID}},\n        value: {{cart_total}},\n        items: [{\n      item_id: "SKU_12345"}],\n        event_id: Date.now()\n    }\n}), \'*\');',
        parent: false,
        url: '',
        autoInstall: false,
        lastModified: 1700692427,
        id: 'be7fc',
        name: 'Purchase Tracking Ad',
        scriptLocation: 'Order confirmation',
        merchantId: '1k8o6ugqcw',
        type: 'content',
      },
      {
        content:
          "if(window.location.href.indexOf('/checkout/order-confirmation') === -1){\nwindow.parent.postMessage(JSON.stringify({\n    event: \"page_view\",\n    data: {\n        page_location: document.location.href,\n        page_title: 'NoFraud Checkout',\n        event_id: Date.now()\n    }\n}), '*');\n}",
        parent: false,
        url: '',
        autoInstall: false,
        lastModified: 1700691939,
        id: '8fdf9',
        name: 'Checkout Page View Tracking',
        scriptLocation: 'Checkout',
        merchantId: '1k8o6ugqcw',
        type: 'content',
      },
    ],
  },
  success: true,
};
