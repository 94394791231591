import { useRef, useEffect } from 'react';

const useDidUpdateEffect = (fn, inputs) => {
  const didMountRef = useRef(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (didMountRef.current) {
      return fn();
    }
    didMountRef.current = true;
  }, [inputs]);
};

export default useDidUpdateEffect;
