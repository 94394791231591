import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { fontSize, fontWeight } from '../../helpers/constants';
import { selectCart } from '../../store/slice';
import { showCodeTitle } from './CouponUtils';

const CouponOpenButton = ({ toggleFormVisibility }) => {
  const cartData = useSelector(selectCart);
  const portalSettings = cartData?.merchant?.settings;

  const couponStyles = portalSettings?.coupon?.isEnabled
    ? {
        fontWeight: fontWeight[portalSettings?.theme?.font?.coupon?.weight],
        textTransform: portalSettings?.theme?.uppercase?.coupon ? 'uppercase' : 'none',
        color: portalSettings?.theme?.colour?.coupon,
        fontSize: fontSize[portalSettings?.theme?.font?.coupon?.size],
      }
    : {};

  return (
    <button className="coupon-button" type="button" onClick={toggleFormVisibility}>
      {!portalSettings.coupon.name && <span style={couponStyles}>+ </span>}
      <span className={!portalSettings.coupon.name ? 'coupon-button' : ''} style={couponStyles}>
        {showCodeTitle()}
      </span>
    </button>
  );
};

CouponOpenButton.propTypes = {
  toggleFormVisibility: PropTypes.func,
};

export default CouponOpenButton;
