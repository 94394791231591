import { datadogRum } from '@datadog/browser-rum';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { initialErrorState } from '../../commons/utils/initial-error-state';
import { emptyManualData, emptyAddress } from '../../commons/containers/manual-address/container/utils';
import { retrieveData } from '../../commons/utils/local-storage-manager';
import { httpClient } from '../../commons/adapter/http-request';
import { defaultUrl } from '../../commons/hooks/use-platform';
import { CHECKOUT_API_URL } from '../../helpers/urlConst';
import { analyticsLocalStorageKey, defaultPortalSettings } from '../../helpers/constants';
import { platformCartIdValue } from '../../helpers/prelaunchData';

export const PLUGIN_CONSENT_DEFAULTS = {
  OFF: 'OFF_BY_DEFAULT',
  ON: 'ON_BY_DEFAULT',
};

export const sliceKey = 'cart';

const additionalDataInitial = {
  fullName: '',
  additionalData: {
    company: undefined,
    line2: undefined,
  },
};

const shippingRetrieved = retrieveData('shipping', platformCartIdValue, additionalDataInitial);
const billingRetrieved = retrieveData('billing', platformCartIdValue, additionalDataInitial);

// #region initial state + local storage
export const initialState = {
  refToFocus: undefined,
  error: {
    hasError: false,
    message: '',
    error: undefined,
  },
  status: {
    isLoading: false,
    event: 'default',
    isReady: true,
    isLoadingWithEmail: false,
    isLoadingWithShippingAddress: false,
    isLoadingWithShippingMethod: false,
    isLoadingDeleteItemFromCart: false,
    isPaymentReady: false,
  },
  addressManual: emptyManualData,
  addressGoogle: {
    error: initialErrorState,
    value: shippingRetrieved.addressGoogle || null,
  },
  addressDownshift: {},
  bigcomGiftCard: undefined,
  shipping: {
    ...shippingRetrieved.additionalData,
    name: shippingRetrieved.fullName,
  },
  billing: {
    ...billingRetrieved.additionalData,
    name: billingRetrieved.fullName,
  },
  checkout: {
    status: 'IDLE',
    coupons: [],
    giftCards: [],
    id: '',
    lookupKey: '',
    notes: [],
    plugin: {},
    shouldApplyStoreCredit: false,
    shouldSave: true,
    address: emptyAddress,
    input: {
      payment: {},
    },
  },
  cart: {
    platformCartId: platformCartIdValue,
    error: false,
    status: 'IDLE',
    additionalCharges: [],
    coupons: [],
    currency: {
      baseCurrency: 'USD',
      displayCurrency: 'USD',
      exchangeRate: 1,
      precision: 2,
      separator: {
        radix: '.',
        thousand: ',',
      },
      symbol: {
        position: 'left',
        token: '$',
      },
    },
    discounts: [],
    giftCards: [],
    items: [],
    plugin: {},
    shippingMethods: [],
    totals: {},
    merchant: {
      settings: defaultPortalSettings,
    },
  },
  portalSettings: defaultPortalSettings,
};

// #region api requests
export const postTrackCrash = createAsyncThunk(
  `${sliceKey}/track_crash`,
  async (payload, { signal, rejectWithValue }) => {
    try {
      const source = httpClient.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await httpClient.request({
        method: 'POST',
        baseURL: CHECKOUT_API_URL,
        url: `/analytics/merchant:${payload.merchantId}/checkout/${payload.lookupKey}/crash`,
        cancelToken: source.token,
      });

      datadogRum.setUserProperty('hasCrashed', true);

      return {
        ...response.data,
        event: payload.event,
      };
    } catch (err) {
      return rejectWithValue({
        error: err.response.error,
        message: err.response.message,
      });
    } finally {
      window.top.location.href = `${payload.storeUrl}/${defaultUrl}?preventIframeLoad=true`;
    }
  },
);
// #endregion

// #region api requests
export const postCreateCart = createAsyncThunk(
  `${sliceKey}/create_cart`,
  async (payload, { dispatch, signal, rejectWithValue }) => {
    try {
      const source = httpClient.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const response = await httpClient.request({
        method: 'POST',
        baseURL: CHECKOUT_API_URL,
        url: `/checkout/merchant:${payload.merchantId}`,
        data: {
          lookupKey: payload.lookupKey,
          deviceID: payload?.device?.id,
          input: payload?.hasInput ? initialState.checkout.input : undefined,
        },
        headers: {
          'nf-api-bot-detect': payload.isBot,
        },
        cancelToken: source.token,
      });
      datadogRum.setUserProperty('merchantID', `merchant:${payload?.merchantId}`);
      datadogRum.setUserProperty('checkoutID', response?.data?.checkout?.id);
      return {
        ...response.data,
        event: payload.event,
      };
    } catch (err) {
      if (err?.response?.data?.message === 'A_B_TESTING') {
        // Redirect to native without tracking if AB Testing
        window.top.location.href = `${payload.storeUrl}/${defaultUrl}?preventIframeLoad=true`;
      } else {
        // Otherwise, track the crash and redirect
        await dispatch(
          postTrackCrash({
            merchantId: payload.merchantId,
            lookupKey: payload.lookupKey,
            storeUrl: payload.storeUrl,
          }),
        );
      }

      return rejectWithValue({
        error: err.response.error,
        message: err.response.message,
      });
    }
  },
);
// #endregion

// #region api requests
export const patchEnrich = createAsyncThunk(`${sliceKey}/enrich`, async (payload, { signal, rejectWithValue }) => {
  try {
    const source = httpClient.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    // This will be properly handled in the future
    const body = payload;
    if (typeof body?.checkout === 'object' && body.checkout?.shippingMethodKey === '') {
      delete body.checkout.shippingMethodKey;
    }

    return await httpClient
      .request({
        method: 'PATCH',
        baseURL: CHECKOUT_API_URL,
        url: `/checkout/merchant:${payload.merchantId}/${payload.cartId}`,
        data: {
          ...body.checkout,
        },
        cancelToken: source.token,
      })
      .then(response => ({
        ...response.data,
        event: payload.event,
      }))
      .catch(error =>
        rejectWithValue({
          error: error?.response?.data?.message?.code,
          message: error?.response?.data?.message,
        }),
      );
  } catch (err) {
    return rejectWithValue({
      error: err.response.error,
      message: err.response.message,
    });
  }
});
// #endregion

// #region api requests
export const postAnalyticsStage1 = createAsyncThunk(
  `${sliceKey}/analyticsStage1`,
  async (payload, { signal, rejectWithValue }) => {
    try {
      const source = httpClient.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      return httpClient
        .request({
          method: 'POST',
          baseURL: CHECKOUT_API_URL,
          url: `/analytics/merchant:${payload.merchantId}/checkout/${payload.cartId}/stage-1`,
          data: { step: payload.step },
          headers: {
            'nf-api-has-loaded': payload?.hasLoaded,
          },
          cancelToken: source.token,
        })
        .catch(error =>
          rejectWithValue({
            error: error?.response?.status,
            message: error?.response?.data?.message,
          }),
        );
    } catch (err) {
      return rejectWithValue({
        error: err.response.error,
        message: err.response.message,
      });
    }
  },
);

export const postAnalyticsStage2 = createAsyncThunk(
  `${sliceKey}/analyticsStage2`,
  async (payload, { signal, rejectWithValue }) => {
    try {
      const source = httpClient.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      return httpClient
        .request({
          method: 'POST',
          baseURL: CHECKOUT_API_URL,
          url: `/analytics/merchant:${payload.merchantId}/checkout/${payload.cartId}/stage-2`,
          data: { step: payload.step },
          headers: {
            'nf-api-has-loaded': payload?.hasLoaded,
          },
          cancelToken: source.token,
        })
        .catch(error =>
          rejectWithValue({
            error: error?.response?.status,
            message: error?.response?.data?.message,
          }),
        );
    } catch (err) {
      return rejectWithValue({
        error: err.response.error,
        message: err.response.message,
      });
    }
  },
);
// #endregion

// #region api requests
export const postSubmit = createAsyncThunk(
  `${sliceKey}/submit`,
  async (payload, { signal, rejectWithValue, dispatch }) => {
    try {
      const source = httpClient.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });

      const response = await httpClient.request({
        method: 'POST',
        baseURL: CHECKOUT_API_URL,
        url: `/checkout/merchant:${payload.merchantId}/${payload.cartId}`,
        data: payload.checkout,
        cancelToken: source.token,
      });

      localStorage.removeItem(analyticsLocalStorageKey);

      return {
        ...response.data,
        event: payload.event,
      };
    } catch (err) {
      const errorCode = err?.response?.status;
      if (errorCode === 500 || errorCode === 404) {
        await dispatch(
          postTrackCrash({
            merchantId: payload.merchantId,
            lookupKey: payload.lookupKey,
            storeUrl: payload.storeUrl,
          }),
        );
      }

      return rejectWithValue({
        error: errorCode ?? err.response.error,
        message: err?.response?.data?.message ?? err.response.message,
      });
    }
  },
);
// #endregion

// #region api requests
export const postSpecialOffer = createAsyncThunk(`${sliceKey}/plugin`, async (payload, { signal, rejectWithValue }) => {
  try {
    const source = httpClient.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    const response = await httpClient.request({
      method: 'POST',
      baseURL: CHECKOUT_API_URL,
      url: `/checkout/merchant:${payload.merchantId}/${payload.checkoutId}/plugin`,
      data: {
        ppo: {
          itemID: payload.itemId,
        },
      },
      cancelToken: source.token,
    });
    return {
      ...response.data,
      event: payload.event,
    };
  } catch (err) {
    return rejectWithValue({
      error: err.response.error,
      message: err.response.message,
    });
  }
});
// #endregion

// #region api requests
export const deleteData = createAsyncThunk(`${sliceKey}/delete`, async (payload, { signal, rejectWithValue }) => {
  try {
    const source = httpClient.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    const response = await httpClient.request({
      method: 'DELETE',
      baseURL: CHECKOUT_API_URL,
      url: `/checkout/merchant:${payload.merchantId}/${payload.cartId}`,
      cancelToken: source.token,
    });
    return {
      ...response.data,
      event: payload.event,
    };
  } catch (err) {
    return rejectWithValue({
      error: err.response.error,
      message: err.response.message,
    });
  }
});
// #endregion

// #region "Delete Item From Cart"
export const deleteItemFromCart = createAsyncThunk(
  `${sliceKey}/delete-item-from-cart`,
  async (payload, { signal, rejectWithValue }) => {
    try {
      const source = httpClient.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const response = await httpClient.request({
        method: 'DELETE',
        baseURL: CHECKOUT_API_URL,
        url: `/checkout/${payload.merchantId}/${payload.checkoutId}/cart/${payload.productAndVariantId}`,
        cancelToken: source.token,
      });
      return {
        ...response.data,
      };
    } catch (err) {
      return rejectWithValue({
        error: err.response.error,
        message: err.response.message,
      });
    }
  },
);
// #endregion

// #region slice
export const cartSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    clearStore: state => {
      state.cart = {
        ...state.cart,
        suggestions: [],
        customer: {
          email: '',
        },
        status: 'reset',
      };
      state.checkout = {
        id: state.checkout.id,
        status: state.checkout.status,
        address: initialState.checkout.address,
        coupons: initialState.checkout.coupons,
        notes: initialState.checkout.notes,
        giftCards: initialState.checkout.giftCards,
        customer: {
          email: '',
        },
      };
      state.error = initialState.error;
      state.status = {
        ...state.status,
        event: 'reset',
      };
      state.addressManual = initialState.addressManual;
      state.addressGoogle = initialState.addressGoogle;
      state.portalSettings = initialState.portalSettings;
    },
    removeAddress: state => {
      state.cart = {
        ...state.cart,
        shippingMethods: initialState.cart.shippingMethods,
        totals: {
          ...state.cart.totals,
          handling: 0,
          shipping: 0,
        },
      };
      state.checkout = {
        ...state.checkout,
        address: {
          ...initialState.checkout.address,
          name: state.checkout.address.name,
        },
        shippingMethodKey: undefined,
      };
    },
    setStatus: (state, action) => {
      state.status = {
        ...state.status,
        ...action.payload,
      };
    },
    setAddressManual: (state, action) => {
      state.addressManual = action.payload;
    },
    setAddressDownshift: (state, action) => {
      state.addressDownshift = action.payload;
    },
    setEmail: (state, action) => {
      state.checkout.customer.email = action.payload.value;
      state.status.event = 'emailChange';
      datadogRum.setUserProperty('email', action.payload.email);
    },
    setPhone: (state, action) => {
      state.checkout.customer = state.checkout.customer || {};
      state.checkout.customer.phone = action.payload;
      state.status.event = 'phoneChange';
    },
    setBigcomGiftCard: (state, action) => {
      state.bigcomGiftCard = action.payload;
    },
    setError: (state, action) => {
      state.error = {
        ...state.error,
        ...action.payload.value,
      };
    },
    setFullName: (state, action) => {
      state.checkout.address = {
        ...state.checkout.address,
        name: action.payload || '',
      };
      state.status.event = 'nameChange';
      datadogRum.setUserProperty('name', action.payload);
    },
    setStatusEvent: (state, action) => {
      state.status.event = action.payload.event;
    },
    setIsLoading: (state, action) => {
      state.status.isLoading = true;
      state.status.event = action.payload.event;
    },
    setPortalSettings: (state, action) => {
      if (action.payload) {
        state.portalSettings = {
          ...defaultPortalSettings,
          ...action.payload,
        };
      }
    },
    setShippingName: (state, action) => {
      state.shipping.name = action.payload;
      datadogRum.setUserProperty('name', action.payload);
    },
    setShippingCompany: (state, action) => {
      state.shipping.company = action.payload;
    },
    setShippingLine2: (state, action) => {
      state.shipping.line2 = action.payload;
    },
    setBillingName: (state, action) => {
      state.billing.name = action.payload;
      datadogRum.setUserProperty('billingName', action.payload.email);
    },
    setBillingCompany: (state, action) => {
      state.billing.company = action.payload;
    },
    setBillingLine2: (state, action) => {
      state.billing.line2 = action.payload;
    },
    setAddressGoogle: (state, action) => {
      state.addressGoogle.error = action.payload.addressGoogle.error;
      state.addressGoogle.value = action.payload.addressGoogle.value;
    },
    setLoginData: (state, action) => {
      state.checkout.email = action.payload.email;
      state.checkout.address = action.payload.address;
      state.status.event = 'login';
    },
    resetShipping: state => {
      state.checkout.address = emptyAddress;
      state.addressManual = emptyManualData;
      state.addressGoogle = {
        error: initialErrorState,
        value: null,
      };
    },
    setCartItems: (state, action) => {
      state.cart.items = action.payload;
    },
    setIsPaymentReady: (state, action) => {
      state.status.isPaymentReady = action.payload;
    },
    setShouldSave: (state, action) => {
      state.checkout.shouldSave = action.payload;
    },
    saveRef: (state, action) => {
      state.refToFocus = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(postCreateCart.pending, state => {
      state.status.isLoading = true;
      state.status.event = 'createCart';
    });
    builder.addCase(patchEnrich.pending, state => {
      state.status.isLoading = true;
      state.status.event = 'enrich';
    });
    builder.addCase(postSubmit.pending, state => {
      state.status.isLoading = true;
      state.status.event = 'submit';
    });
    builder.addCase(patchEnrich.fulfilled, (state, action) => {
      const newEmail = action.payload.checkout?.email;
      const oldEmail = state.checkout?.customer?.email || state.cart?.customer?.email;
      if (newEmail && newEmail !== oldEmail) {
        datadogRum.setUserProperty('email', newEmail);
      }
      state.cart = {
        platformCartId: state.cart.platformCartId,
        ...action.payload.cart,
        merchant: {
          ...action.payload.cart.merchant,
          settings: {
            ...action.payload.cart.merchant.settings,
          },
        },
        status: 'fulfilled',
      };
      state.checkout = {
        ...action.payload.checkout,
        address: {
          ...state.checkout.address,
          ...action.payload.checkout.address,
        },
        status: 'fulfilled',
      };
      state.status.event = action.payload.event || 'enrich';
      state.status.isLoading = false;
      state.status.loadingType = 'default';
    });
    builder.addCase(postCreateCart.fulfilled, (state, action) => {
      state.cart = {
        platformCartId: state.cart.platformCartId,
        ...action.payload.cart,
        status: 'fulfilled',
        merchant: {
          ...action.payload.cart.merchant,
          settings: {
            ...action.payload.cart.merchant.settings,
          },
        },
        plugin: {
          ...action.payload.cart.plugin,
        },
      };
      state.checkout = {
        ...state.checkout,
        status: 'fulfilled',
        ...action.payload.checkout,
        address: {
          ...state.checkout.address,
          ...action.payload.checkout.address,
        },
      };
      state.status.event = 'createCart';
      state.status.isLoading = false;
      state.status.loadingType = 'default';
    });
    builder.addCase(postSubmit.fulfilled, (state, action) => {
      state.cart = {
        platformCartId: state.cart.platformCartId,
        ...action.payload.cart,
        status: 'fulfilled',
      };
      state.checkout = {
        ...state.checkout,
        payment: action.payload.checkout.payment,
        address: action.payload.checkout.address,
        billing: action.payload.checkout.billing,
        notes: action.payload.checkout.notes,
        status: 'fulfilled',
      };
      state.status.event = 'submit';
      state.status.isLoading = false;
      state.status.loadingType = 'default';
    });
    builder.addCase(postCreateCart.rejected, (state, action) => {
      state.error = {
        hasError: true,
        status: action.status,
        data: action.data,
        request: 'postCreateCart',
      };
    });
    builder.addCase(patchEnrich.rejected, (state, action) => {
      state.error = {
        hasError: true,
        status: action.status,
        data: action.data,
        request: 'patchEnrich',
      };
    });
    builder.addCase(postSubmit.rejected, (state, action) => {
      state.error = {
        hasError: true,
        status: action.payload.error,
        message: action.payload.message,
        data: action.payload.message?.error || action.payload.message,
        request: 'postSubmit',
      };
    });
    builder.addCase(deleteData.rejected, (state, action) => {
      state.error = {
        hasError: true,
        status: action.status,
        data: action.data,
        request: 'deleteData',
      };
    });
    builder.addCase(deleteData.pending, state => {
      state.status.isLoading = true;
      state.status.event = 'deleteData';
    });
    builder.addCase(deleteData.fulfilled, state => {
      state.status.event = 'delete';
      state.status.isLoading = false;
      state.status.loadingType = 'default';
    });
    builder.addCase(deleteItemFromCart.pending, state => {
      state.status.isLoading = true;
      state.status.isLoadingDeleteItemFromCart = true;
    });
    builder.addCase(deleteItemFromCart.fulfilled, state => {
      state.status.isLoading = false;
      state.status.isLoadingDeleteItemFromCart = false;
    });
  },
});
// #endregion

export const {
  clearStore,
  setEmail,
  setPortalSettings,
  setIsLoading,
  setStatusEvent,
  resetShipping,
  setFullName,
  setAddressGoogle,
  setStatus,
  setAddressManual,
  setError,
  setBigcomGiftCard,
  setAddressDownshift,
  removeAddress,
  setLoginData,
  setCartItems,
  setShippingCompany,
  setBillingCompany,
  setShippingLine2,
  setBillingLine2,
  setShippingName,
  setBillingName,
  setIsPaymentReady,
  setShouldSave,
  saveRef,
} = cartSlice.actions;

export const selectAdditionalData = state => ({
  shipping: state.cart.shipping,
  billing: state.cart.billing,
});

export const selectData = state => state.cart;
export const selectCart = state => state.cart.cart;
export const selectPlugin = state => state.cart.cart.plugin;
export const selectCheckout = state => state.cart.checkout;
export const selectPortalSettings = state => state.cart.portalSettings;
export const selectOrder = state => state.cart.order;
export const selectApiStatus = state => state.cart.status;
export const selectAddressGoogle = state => state.cart.addressGoogle;
export const selectAddressDownshift = state => state.cart.addressDownshift;
export const selectError = state => state.cart.error;
export const selectBigcomGiftCard = state => state.cart.bigcomGiftCard;
export const selectRefToFocus = state => state.cart.refToFocus;
export const selectCardAddress = state => ({
  fullName: state.cart?.address?.name,
  address: state.cart?.address?.singleLine || '',
  line2: state.cart?.address?.apartment || '',
  company: state.cart?.address?.company || '',
});
export const selectHasPluginNewsletter = state => {
  const plugin = selectPlugin(state);

  return (
    plugin?.attentive?.newsletter?.isEnabled ||
    plugin?.bigcommerceNewsletter?.newsletter?.isEnabled ||
    plugin?.klaviyo?.newsletter?.isEnabled
  );
};
export const selectPluginNewsletterDefault = state => {
  const plugin = selectPlugin(state);

  if (selectHasPluginNewsletter(state)) {
    if (plugin?.attentive?.newsletter?.behaviour === PLUGIN_CONSENT_DEFAULTS.ON) return true;

    if (plugin?.bigcommerceNewsletter?.newsletter?.behaviour === PLUGIN_CONSENT_DEFAULTS.ON) return true;

    if (plugin?.klaviyo?.newsletter?.behaviour === PLUGIN_CONSENT_DEFAULTS.ON) return true;

    return false;
  }

  return undefined;
};
export const selectHasPluginSMS = state => {
  const plugin = selectPlugin(state);

  return plugin?.attentive?.sms?.isEnabled || plugin?.klaviyo?.sms?.isEnabled;
};
export const selectPluginSMSDefault = state => {
  const plugin = selectPlugin(state);

  if (selectHasPluginSMS(state)) {
    if (plugin?.attentive?.sms?.behaviour === PLUGIN_CONSENT_DEFAULTS.OFF) return false;

    if (plugin?.klaviyo?.sms?.behaviour === PLUGIN_CONSENT_DEFAULTS.OFF) return false;

    return true;
  }

  return undefined;
};

export default cartSlice.reducer;
