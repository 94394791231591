import { createSlice } from '@reduxjs/toolkit';

export const sliceKey = 'modal';

const initialState = {
  isVisible: false,
  template: '',
  data: null,
};

export const modalSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.isVisible = action.payload.isVisible;
      state.template = action.payload.template;
      state.data = action.payload.data;
    },
  },
});

export const { setModal } = modalSlice.actions;

export const selectModal = state => state.modal;

export default modalSlice.reducer;
