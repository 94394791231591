export const emptyAdditionalData = {
  line2: '',
  company: '',
};

export const convertAddressToBackend = (address, fullName) => {
  if (!address) {
    return {
      name: fullName,
    };
  }
  return {
    name: fullName,
    line1: address.line1,
    line2: address.line2,
    city: address.city,
    region: address.region,
    regionCode: address.regionCode,
    postal: address.postal,
    country: address.country,
    singleLine: address.singleLine,
  };
};

export const parseTypeAsText = (type, label) => {
  if (!label.includes('.' || '_')) {
    return label;
  }
  if (!type.includes('.' || '_')) {
    return type;
  }
  return '';
};

export const areRequiredFieldsReady = (fullName, address) => [fullName, address].every(Boolean);
