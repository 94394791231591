import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { currentBaseUrl } from '../../helpers/urlConst';
import { fetchScript } from '../../hooks/useScripts';
import { useProduct } from '../../hooks/useProduct';
import usePortalSettings from '../../hooks/usePortalSettings';
import ShippingMethodImage from '../../commons/components/shipping-method-image';
import DataContext from '../../store/dataContext';
import UiContext from '../../store/uiContext';
import { paymentIdList } from '../../helpers/constants';
import useCartTotalsUtils from '../../hooks/useCartTotalsUtils';
import { selectCheckout, selectCart } from '../../store/slice';
import { handleResetPassword } from '../../commons/utils/cross-origin-communication';

import Cart from '../../modules/cart/components/cart';
import ContinueShoppingBtn from '../Button/ContinueShoppingBtn/index';

import { ReactComponent as SuccessIcon } from './icon/thank-you.svg';

import './styles.scss';
import { parsePhoneFormatNational } from '../../commons/utils/phone-parser';

const OrderDisplay = () => {
  const { t } = useTranslation();
  const dataContext = useContext(DataContext);
  const { setShowExtraInfoBackground, setHasExtraInfo } = useContext(UiContext);
  const { portalSettings } = usePortalSettings();
  const { allDigital } = useProduct();
  const { getOrderTotal } = useCartTotalsUtils();
  const checkoutData = useSelector(selectCheckout);
  const cartData = useSelector(selectCart);
  const showShipping = !allDigital() && checkoutData.shippingMethodKey;

  const apiRedirect = cartData.order?.redirectURL;
  const cardType = checkoutData.payment?.context?.cardType;
  const shippingMethod = cartData?.shippingMethods?.find(item => item.lookupKey === checkoutData?.shippingMethodKey);
  const [updatedOrder, setUpdatedOrder] = useState(false);
  const [orderNumber, setOrderNumber] = useState(cartData.order.lookupKey);
  const phone = checkoutData?.billing?.phone || '';
  const parsedPhone = parsePhoneFormatNational(phone);
  const showPhone = !!parsedPhone;

  useEffect(() => {
    if (cartData.order.lookupKey !== orderNumber) {
      setUpdatedOrder(true);
      setOrderNumber(cartData.order.lookupKey);
    }
    if (cartData.platform?.hasCreatedAccount) {
      handleResetPassword(checkoutData?.customer?.email);
    }
  }, [cartData]);

  const renderFirstBoldWord = text => {
    const arrayFromString = text.split(' ');
    const firstWord = arrayFromString.shift();
    const restOfTheText = arrayFromString.join(' ');
    return (
      <>
        <span className="order-details-strong">{firstWord} </span>
        {restOfTheText}
      </>
    );
  };

  const renderBillingHeader = () => {
    const c1 = checkoutData?.billing?.name;
    const c2 = checkoutData?.payment?.type;
    const shouldRender = c1 || c2;
    if (shouldRender) {
      return <p className="address-title billing-info-title">{t('order_display.billing_information')}</p>;
    }
    return null;
  };

  const renderPayedWith = payment => {
    if (payment?.type === 'platform') {
      return (
        <span>
          <span className="payment-method">
            {dataContext.applyStoreCredit ? t('cart_total.store_credit') : t('gift_title')}
          </span>
        </span>
      );
    }
    if (payment?.type && payment?.type !== 'direct') {
      switch (payment?.type) {
        case paymentIdList.PAYPAL:
          return (
            <div className="payment-container">
              <img className="paypal-logo" alt="PayPal logo" src={`${currentBaseUrl()}assets/paypal-logo.png`} />
              <span>{cartData?.payer?.email}</span>
            </div>
          );
        case paymentIdList.AMAZONPAY:
          return (
            <div className="payment-container">
              <img className="amazon-logo" alt="Amazon logo" src={`${currentBaseUrl()}assets/amazonpay-text.svg`} />
              <span>{cartData?.payer?.email}</span>
            </div>
          );
        default:
          return (
            <span>
              <span className="payment-method"></span>
            </span>
          );
      }
    }
    if (getOrderTotal() > 0) {
      return (
        <span>
          <span className="protected">••••-</span>
          {payment?.context?.last4}
        </span>
      );
    }
    return null;
  };

  useEffect(() => {
    const { href, search, protocol, host, pathname } = window.location;
    if (
      href.indexOf('/checkout/order-confirmation') === -1 &&
      portalSettings?.scripts?.find(
        item => item?.scriptLocation === 'All pages' || item?.scriptLocation === 'Order confirmation',
      )
    ) {
      if (portalSettings?.scripts?.find(item => item?.parent)) {
        const parentUrl = `${dataContext.storeUrl}/checkout/order-confirmation?preventRefresh=true`;
        window.parent.postMessage(
          JSON.stringify({
            task: 'changeUrl',
            url: parentUrl,
          }),
          '*',
        );
        window.parent.postMessage(
          JSON.stringify({
            task: 'createElement',
            id: 'order_id',
            value: `order_id: ${cartData?.order?.lookupKey}`,
            type: 'hidden',
          }),
          '*',
        );
        window.parent.postMessage(
          JSON.stringify({
            task: 'removeAllScripts',
          }),
          '*',
        );
      }
      const newUrl = `${protocol}//${host}/checkout/order-confirmation${pathname}${search}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
    window.parent.postMessage(
      JSON.stringify({
        task: 'removeAllScripts',
      }),
      '*',
    );
    for (let x = 0; x < portalSettings?.scripts?.length; x++) {
      const script = document.getElementById(`script-${x}`);
      if (script) {
        try {
          document.head.removeChild(script);
        } catch (err) {
          // err
        }
      }
      const setting = portalSettings?.scripts[x];
      if (setting.scriptLocation === 'All pages' || setting.scriptLocation === 'Order confirmation') {
        if (setting?.parent) {
          window.parent.postMessage(
            JSON.stringify({
              task: 'appendScript',
              url: setting?.url,
              content: setting?.content,
              type: setting?.type?.toLowerCase(),
            }),
            '*',
          );
        } else {
          let value = cartData?.order?.lookupKey;
          if (setting?.param?.toLowerCase() === 'order_id' || setting?.param?.toLowerCase() === 'orderId') {
            value = cartData?.order?.lookupKey;
          }
          if (
            setting.name === 'Beam' ||
            setting.name?.indexOf('Bing') > -1 ||
            setting.name?.indexOf('Ad') > -1 ||
            setting.name?.indexOf('dynamic script') > -1
          ) {
            if (setting.name === 'Beam') {
              setShowExtraInfoBackground(true);
              setHasExtraInfo(true);
            }
            fetchScript(setting, x, {
              platformOrderId: cartData?.order?.lookupKey,
              email: checkoutData?.customer?.email,
              total: cartData?.totals?.total,
            });
          } else {
            fetchScript(setting, x, value);
          }
        }
      }
    }
    return () => {
      for (let x = 0; x < portalSettings?.scripts?.length; x++) {
        const script = document.getElementById(`script-${x}`);
        if (script) {
          try {
            document.head.removeChild(script);
          } catch (err) {
            // err
          }
        }
      }
    };
  }, [portalSettings, cartData?.order?.lookupKey]);

  useEffect(() => {
    if (apiRedirect && !cartData?.order) {
      window.top.location = dataContext.storeUrl + apiRedirect;
    }
    return () => dataContext.updateBillingPhone('');
  }, [apiRedirect]);

  const clearEstimated = text => text?.replace('Est. ', '');

  return (
    <div id="order-display" className="section">
      {cartData?.order ? (
        <div className="order-display-wrapper">
          <div className="order-display-header">
            <SuccessIcon />
            <div>
              <h1 className="order-title">{t('order_display.thank_you')}</h1>
              <input type="hidden" value={`order_id: ${cartData?.order?.lookupKey}`} />
              <p className="order-message">
                {updatedOrder
                  ? t('order_display.your_order_has_been_updated')
                  : t('order_display.your_order_has_been_placed')}
              </p>
              <div id="order_id" className="order-details">
                {updatedOrder
                  ? renderFirstBoldWord(t('order_display.new_order_number'))
                  : t('order_display.order_number')}
                <span>{cartData?.order?.lookupKey}</span>
              </div>
            </div>
          </div>

          <div className="user-details">
            {showShipping && <p className="address-title">{t('order_display.shipping_information')}</p>}
            {showShipping && (
              <p className="name">
                {checkoutData?.address?.name}
                <span>{checkoutData?.address?.company ? ` - ${checkoutData?.address?.company}` : null}</span>
              </p>
            )}
            {showShipping && (
              <>
                <p className="address">{checkoutData?.address?.singleLine}</p>
                <p className="address">{checkoutData?.address?.line2}</p>
              </>
            )}
            {showShipping && <p className="email">{checkoutData?.customer?.email}</p>}
          </div>
          <br />
          {showShipping && checkoutData?.address && checkoutData.shippingMethodKey && (
            <div className="shipping-details">
              <p className="address-title">{t('order_display.shipping_method')}</p>
              <div className="shipping-company-and-method">
                <ShippingMethodImage type={shippingMethod?.type} label={shippingMethod?.label} />
                <h2>{shippingMethod?.label}</h2>
              </div>
              {shippingMethod?.transitTime && (
                <div className="shipping-time">
                  <p className="transit-text">{t('order_display.estimated_arrival')}</p>
                  <p className="transit-time">{clearEstimated(shippingMethod.transitTime)}</p>
                </div>
              )}
            </div>
          )}
          {renderBillingHeader()}
          <div className="card-info">
            {checkoutData?.payment?.type === 'direct' && (
              <img alt={cardType} src={`${currentBaseUrl()}card-logos/${cardType}.svg`} className="card-type" />
            )}
            {renderPayedWith(checkoutData?.payment)}
          </div>
          {!checkoutData?.billing?.name && !showShipping && (
            <>
              <p className="name">
                {checkoutData?.address?.name}
                <span>{checkoutData?.address?.company ? ` - ${checkoutData?.address?.company}` : null}</span>
              </p>
              <p className="address">{checkoutData?.address?.singleLine}</p>
              <p className="address">{checkoutData?.address?.line2}</p>
            </>
          )}
          {checkoutData?.billing?.name && (
            <p className="name">
              {checkoutData?.billing?.name}
              <span>{checkoutData?.billing?.company ? ` - ${checkoutData?.billing?.company}` : null}</span>
            </p>
          )}
          {showShipping && checkoutData?.billing?.address?.singleLine && (
            <>
              <p className="address">{checkoutData?.billing?.address?.singleLine}</p>
              <p className="address">{checkoutData?.billing?.address?.line2}</p>
            </>
          )}
          {!showShipping && cartData?.payer?.email && checkoutData?.payment?.type === 'direct' && (
            <p className="email">{cartData?.payer?.email}</p>
          )}
          {showPhone && <p className="phone">{parsedPhone}</p>}
          <Cart type="order" />
          {checkoutData?.notes?.length > 0 && checkoutData.notes[0] !== '' && (
            <>
              <br />
              <p className="address-title">Notes</p>
              <p className="transit-text">{checkoutData.notes[0]}</p>
            </>
          )}
          <ContinueShoppingBtn />
        </div>
      ) : (
        <>
          <div className="section-header">
            <h1>{`${t('order_display.redirecting')} ${t('order_display.thank_you')}`}</h1>
          </div>
          <div className="order-message">{t('order_display.your_order_has_been_placed')}</div>
          <ContinueShoppingBtn />
        </>
      )}
      <div className="app-credit">
        <div className="app-credit--powered-by">{t('checkout.powered_by')}</div>
        <div className="app-credit--nofraud">NOFRAUD</div>
      </div>
    </div>
  );
};

export default OrderDisplay;
