import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useCurrency from '../../../currency/hooks/use-currency';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import UiContext from '../../../../store/uiContext';
import DataContext from '../../../../store/dataContext';
import { currentBaseUrl } from '../../../../helpers/urlConst';
import { amountFormatter, priceWithZeros, reformatedAmount } from '../../../../helpers/format';
import AdditionalServiceWrapper from '../../../additional-service/containers/additional-service-wrapper';

import { pluginList } from '../../../../commons/constants';
import { selectData, selectApiStatus } from '../../../../store/slice';
import CartAttributes from '../attributes';
import CartTotals from '../totals';
import { CouponContainer } from '../../../../components/Coupon';
import { OrderNotesInput } from '../notes';

import { ReactComponent as Arrow } from '../../icon/Arrow.svg';

import './styles.scss';
import usePortalSettings from '../../../../hooks/usePortalSettings';

const Cart = ({ type }) => {
  const { t } = useTranslation();
  const {
    done,
    formStatus,
    isLoadingCoupons,
    isLoadingSignature,
    isLoadingInsurance,
    isLoadingRoute,
    isCheckoutItemsCollapsed,
    setIsCheckoutItemsCollapsed,
    showExtraInfoBackground,
  } = useContext(UiContext);
  const { portalSettings } = usePortalSettings();
  const { showLoading } = useContext(DataContext);
  const { isMobileDevice } = useWindowDimensions();
  const defaultType = type === 'default';
  const orderType = type === 'order';
  const lineItemListRef = useRef(null);
  const [fullScrolled, setFullScrolled] = useState(false);
  const cartData = useSelector(selectData);
  const status = useSelector(selectApiStatus);
  const { getShoppercurrency } = useCurrency();

  useEffect(() => {
    if (lineItemListRef && cartData?.cart?.items?.length > 2) {
      const element = lineItemListRef?.current;
      const handleListAnimation = () => {
        const isFullScrolled = element.scrollHeight - Math.abs(element.scrollTop) < element.clientHeight + 10;
        if (isFullScrolled) {
          setFullScrolled(true);
        } else {
          setFullScrolled(false);
        }
      };
      lineItemListRef?.current?.addEventListener('scroll', handleListAnimation);

      /* eslint consistent-return: off */
      return () => lineItemListRef?.current?.removeEventListener('scroll', handleListAnimation);
    }
  }, [lineItemListRef]);

  const getLineItemsQuantity = () => {
    const lineItemQuantityReducer = (currentQuantity, lineItem) => currentQuantity + lineItem.quantity;
    return cartData?.cart?.items?.reduce(lineItemQuantityReducer, 0);
  };

  const toggleCheckoutItemsCollapse = () => {
    setIsCheckoutItemsCollapsed(!isCheckoutItemsCollapsed);
  };

  const defaultImage = `${currentBaseUrl()}assets/dummy.png`;

  const cartInfo = () => (
    <>
      <div className="line-item-total">{`${getLineItemsQuantity()} ${t('cart.items')}`}</div>
      <div
        className={`line-item-list ${
          (!fullScrolled && cartData?.cart?.items?.length > 2 ? 'decorated' : '') ||
          (fullScrolled && cartData?.cart?.items?.length > 2 ? 'scrolled' : '')
        }`}
        ref={lineItemListRef}
      >
        {cartData?.cart?.items?.map(lineItem => (
          <div key={lineItem.lookupKey} className="line-item">
            <div className="line-item-image">
              <div className="img-container" style={{ backgroundImage: `url(${lineItem.imageURL ?? defaultImage})` }}>
                {lineItem?.preOrder?.expectedAvailability && (
                  <div className="pre-order-tag">{portalSettings?.preOrder?.name ?? t('cart.pre_order')}</div>
                )}
              </div>
            </div>
            <div className="line-item-details">
              <div className="line-item-name">{lineItem.name}</div>
              <CartAttributes quantity={lineItem?.quantity} attributes={lineItem?.attribute} />
              <div className="line-item-price">
                <span className={`${lineItem.discount > 0 ? 'discount' : ''}`}>{`${amountFormatter({
                  shopperCurrency: getShoppercurrency(),
                  value: priceWithZeros(lineItem.price),
                })}`}</span>
              </div>
              {lineItem.discount > 0 && (
                <div className="line-item-price">
                  <span>{`${amountFormatter({
                    shopperCurrency: getShoppercurrency(),
                    value: priceWithZeros(lineItem.price - lineItem.discount),
                  })}`}</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <CartTotals cart={cartData?.cart} checkout={cartData?.checkout} />
    </>
  );

  const formatedAmount = cartValues => {
    if (cartValues?.currency?.baseCurrency) {
      return reformatedAmount(cartValues?.currency?.baseCurrency, () => cartValues?.totals?.total);
    }
    return '---';
  };

  return (
    <div
      className={classNames({
        section: true,
        cart: defaultType,
        'order-cart': orderType,
        'has-extra-info': showExtraInfoBackground,
        'p25-20': true,
      })}
    >
      <div className="section-header">
        <h2>{t('cart.order_details')}</h2>
        <button type="button" onClick={() => toggleCheckoutItemsCollapse()}>
          <span className="cart-icon">
            <img
              className="shopping-icon"
              src={`${currentBaseUrl()}assets/shopping_cart.svg`}
              alt="Shopping Cart Icon"
            />
            <span className="shopping-icon">{getLineItemsQuantity()}</span>
          </span>
          <p id="cart-subtotal" className="cart-subtotal">
            <span className="mobile-sign">{formatedAmount(cartData?.cart).sign}</span>
            <span className="mobile-amount">{formatedAmount(cartData?.cart).amount}</span>
            <span className="mobile-rest">{formatedAmount(cartData?.cart).rest}</span>
          </p>
          <Arrow className="cart-arrow-up" />
        </button>
      </div>
      {cartData?.cart?.items?.length > 0 && cartInfo()}
      {!done && <CouponContainer cart={cartData?.cart} />}
      {!done && cartData?.cart?.merchant?.settings?.orderNotes?.isEnabled && <OrderNotesInput />}
      {!done && cartData?.cart?.plugin?.route && !isMobileDevice && (
        <AdditionalServiceWrapper
          type={pluginList.route}
          shopperCurrency={getShoppercurrency()}
          isAppMainLoading={showLoading}
          isLoadingInsurance={isLoadingInsurance}
          isLoadingRoute={isLoadingRoute}
          isLoadingSignature={isLoadingSignature}
          shippingStatus={status?.isLoading}
          formStatus={formStatus}
          isLoadingCoupons={isLoadingCoupons}
          cart={cartData?.cart}
          checkout={cartData?.checkout}
        />
      )}
      {!done && cartData?.cart?.plugin?.packageInsurance && !isMobileDevice && (
        <AdditionalServiceWrapper
          type={pluginList.packageInsurance}
          shopperCurrency={getShoppercurrency()}
          isAppMainLoading={showLoading}
          isLoadingInsurance={isLoadingInsurance}
          isLoadingRoute={isLoadingRoute}
          isLoadingSignature={isLoadingSignature}
          shippingStatus={status?.isLoading}
          formStatus={formStatus}
          isLoadingCoupons={isLoadingCoupons}
          cart={cartData?.cart}
          checkout={cartData?.checkout}
        />
      )}
      {!done && cartData?.cart?.plugin?.customerSignature && !isMobileDevice && (
        <AdditionalServiceWrapper
          type={pluginList.customerSignature}
          shopperCurrency={getShoppercurrency()}
          isAppMainLoading={showLoading}
          isLoadingInsurance={isLoadingInsurance}
          isLoadingRoute={isLoadingRoute}
          isLoadingSignature={isLoadingSignature}
          shippingStatus={status?.isLoading}
          formStatus={formStatus}
          isLoadingCoupons={isLoadingCoupons}
          cart={cartData?.cart}
          checkout={cartData?.checkout}
        />
      )}
    </div>
  );
};

Cart.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Cart;
