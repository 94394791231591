/* global amazon */
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setPaymentMethod } from '../../modules/payment/slice';
import { paymentIdList } from '../../helpers/constants';
import DataContext from '../../store/dataContext';

import { useBroadcastChannel } from '../useBroadcastChannel';

export const useAmazon = () => {
  const { t } = useTranslation();
  const { postMessage } = useBroadcastChannel();
  const dispatch = useDispatch();

  const { setBroadcastMessage, amazonButtonData } = useContext(DataContext);

  const { paymentMethodError, setPaymentMethodError, broadcastErrorMessage } = useContext(DataContext);

  useEffect(() => {
    if (broadcastErrorMessage?.amazon) {
      dispatch(setPaymentMethod(paymentIdList.AMAZONPAY));
      setPaymentMethodError({ ...paymentMethodError, amazon: broadcastErrorMessage?.amazon });
    }
  }, [broadcastErrorMessage]);

  const createAmazonButton = () => {
    if (amazonButtonData?.button) {
      const { button, createCheckoutSessionConfig } = amazonButtonData;
      if (window.onAmazonPaymentsReady && !document.getElementById('AmazonPayButton')) {
        const amazonPayButton = document.createElement('div');
        amazonPayButton.id = 'AmazonPayButton';
        document.body.appendChild(amazonPayButton);
      }

      window.onAmazonPaymentsReady = () => {
        const payload = {
          merchantId: button.merchantId,
          publicKeyId: button.publicKeyId,
          ledgerCurrency: button.ledgerCurrency,
          checkoutLanguage: button.checkoutLanguage,
          productType: button.productType,
          placement: button.placement,
          buttonColor: button.buttonColor,
          popup: false,
        };

        const amazonPayButton = amazon
          ? amazon.Pay.renderButton('#AmazonPayButton', payload)
          : window.amazon.Pay.renderButton('#AmazonPayButton', payload);

        amazonPayButton.onClick(() => {
          // define your custom actions here
          amazonPayButton.initCheckout({
            createCheckoutSessionConfig,
          });
        });
      };
    }

    if (window.onAmazonPaymentsReady) {
      window.onAmazonPaymentsReady();
      const div = document.getElementById('AmazonPayButton');
      div.dispatchEvent(new Event('click'));
    }
  };

  const handleAmazonUrl = () => {
    if (window.location.href.indexOf('amazon') > -1) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const amazonParam = urlParams.get('amazon');
      if (amazonParam === 'openPopup') {
        const amazonButton = document.createElement('div');
        amazonButton.id = 'AmazonPayButton';
        document.body.appendChild(amazonButton);
        createAmazonButton();
      } else {
        const amazonCheckoutSessionId = urlParams.get('amazonCheckoutSessionId');
        setBroadcastMessage({ amazonCheckoutSessionId });
        if (amazonParam === 'checkoutReturn') {
          postMessage({
            amazonCheckoutSessionId,
            execute: 'amazon',
          });
          window.close();
        } else if (amazonParam === 'cancel') {
          postMessage({
            error: {
              amazon: t('payment_method.amazon.error'),
            },
          });
          window.close();
        } else if (amazonParam === 'checkoutPay') {
          dispatch(setPaymentMethod(paymentIdList.AMAZONPAY));
        }
      }
    }
  };

  const isAmazonPay = () => window.location.href.indexOf('amazon=openPopup') > -1;

  return { handleAmazonUrl, isAmazonPay };
};
