import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withLDConsumer as featureFlagClient } from 'launchdarkly-react-client-sdk';

import { datadogRum } from '@datadog/browser-rum';
import { useSkeleton } from '../../../../hooks/useSkeleton';
import {
  selectData,
  selectCart,
  selectCheckout,
  selectAdditionalData,
  setBillingLine2,
  setBillingCompany,
  setStatusEvent,
  selectApiStatus,
  setFullName as setShippingFullName,
} from '../../../../store/slice';
import { useProduct } from '../../../../hooks/useProduct';
import FullName from '../../../../commons/containers/full-name';
import GoogleAddressAutocomplete from '../../../../commons/containers/google-address-autocomplete';
import AddressAutocomplete from '../../../../commons/containers/address-autocomplete';
import ManualAddress from '../../../../commons/containers/manual-address/container';
import { retrieveData, saveData } from '../../../../commons/utils/local-storage-manager';
import { platformCartIdValue } from '../../../../helpers/prelaunchData';
import { initialErrorState } from '../../../../commons/utils/initial-error-state';
import { autofillSource } from '../../../../commons/constants';
import { convertToGoogle } from '../../../../commons/containers/google-address-autocomplete/utils';
import { EVENT, on, off } from '../../../../commons/utils/custom-event';
import { usePickup } from '../../../shipping/containers/pickup-in-store/use-pickup';
import UiContext from '../../../../store/uiContext';
import useCartTotals from '../../../../store/cartTotals/useCartTotals';
import { formatAsAutocompleteSuggestion } from '../../../../commons/containers/address-autocomplete/utils';

import { selectShipping } from '../../../shipping/slice';

import { convertAddressToBackend } from '../../utils';
import { analyticsStage1, analyticsStage2 } from '../../../../helpers/constants';
import { useEndpoint } from '../../../../hooks/useEndpoint';
import {
  sliceKey,
  setFullName,
  setAddress,
  setAddressGoogle,
  setAddressDownshift,
  setAddressManual,
  setStatus,
  resetBilling,
  selectBilling,
} from '../../slice';

import AdditionalData from '../additional-data';

import style from './index.module.scss';

const Billing = ({ flags, isFieldsetDisabled, theme, setContextBillingAddress, children }) => {
  const billingSelector = useSelector(selectBilling);
  const additionalData = useSelector(selectAdditionalData);
  const { setData: setCartTotalsData } = useCartTotals();
  const isMounted = useRef(false);
  const { bopis, shippingMethodKey } = useSelector(selectShipping);
  const status = useSelector(selectApiStatus);
  const { isPickupActive } = usePickup();
  const { setShowLoading } = useSkeleton();
  const { patchEnrich, postAnalyticsStage1, postAnalyticsStage2 } = useEndpoint();

  const dispatch = useDispatch();

  const { updateCheckoutState, TO_CARD_ADD, setShowLoadingPage } = useContext(UiContext);

  const { t } = useTranslation();
  const [autofillFullName, setAutofillFullName] = useState(undefined);
  const [previousFullName, setPreviousFullName] = useState(undefined);
  const [resetTimestamp, setResetTimestamp] = useState(0);
  const [validationTimestamp, setValidationTimestamp] = useState(0);
  const [addressAsTyped, setAddressAsTyped] = useState('');
  const [autofillAddress, setAutofillAddress] = useState(undefined);
  const [allowEnterManually, setAllowEnterManually] = useState(false);
  const [shouldFocusUnit, setShouldFocusUnit] = useState(false);
  const [hasSuggestions, setHasSuggestions] = useState(false);
  const [autofilled, setAutofilled] = useState(false);
  const [openTimestamp, setOpenTimestamp] = useState(0);
  const { allDigital } = useProduct();
  const cartData = useSelector(selectCart);
  const cart = useSelector(selectData);
  const checkoutData = useSelector(selectCheckout);
  const addressSuggested = [];

  if (cartData?.suggestions?.length > 0) {
    addressSuggested.concat(cartData?.suggestions);
  }

  if (cartData?.customer?.addresses?.length > 0) {
    addressSuggested.concat(cartData?.customer?.addresses);
  }

  if (checkoutData?.address?.length > 0) {
    addressSuggested.concat(checkoutData?.address);
  }

  const saveFieldToLs = (fieldKey, fieldValue) => {
    // the local storage key is the platformCartIdValue
    // it must match the key used inside the slice
    // platformCartIdValue is different than cartId received as a prop
    const retrieved = retrieveData(sliceKey, platformCartIdValue, {});
    const updated = { ...retrieved };
    updated[fieldKey] = fieldValue;
    saveData(sliceKey, updated, platformCartIdValue);
  };

  const updateBillingReady = ({ fnValue, fnError, ssValue }) => {
    const isReady = [fnValue, !fnError.hasError, ssValue].every(Boolean);
    dispatch(
      setStatus({
        isReady,
      }),
    );
  };

  const handleAddressStore = async (address, fullName) => {
    const valueAddressForContext = convertAddressToBackend(address, fullName);
    const isPickUp = isPickupActive();
    const isDigital = allDigital();

    if (isPickUp) {
      await patchEnrich({
        address: {
          ...valueAddressForContext,
          name: checkoutData?.address?.name,
        },
        pickUpMethodKey: bopis.selectedPickupMethod.lookupKey,
      });
    } else {
      setContextBillingAddress(valueAddressForContext);
    }

    if (isPickUp || isDigital) {
      postAnalyticsStage1({ step: analyticsStage1.ADDRESS });
    } else {
      postAnalyticsStage2({ step: analyticsStage2.ADDRESS });
    }
  };

  // #region full name (fn)
  const fnBlur = async (currentFullName, fnError) => {
    if (currentFullName && allDigital() && currentFullName !== previousFullName) {
      dispatch(setShippingFullName(currentFullName));
    }
    if (!fnError.hasError && currentFullName && currentFullName !== previousFullName) {
      await handleAddressStore(billingSelector.address, currentFullName);
    }
  };

  const fnAfterValidationCb = (fnValue, fnError) => {
    dispatch(
      setFullName({
        error: fnError,
        value: fnError.hasError ? '' : fnValue,
      }),
    );
    saveFieldToLs('fullName', fnValue);
    updateBillingReady({ fnValue, fnError, ssValue: billingSelector.address });
  };

  const fnChange = value => {
    saveFieldToLs('fullName', value);
  };
  // #endregion

  // #region address (ss)
  const ssUpdateAddress = valueAddress => {
    dispatch(
      setAddress({
        value: valueAddress,
      }),
    );
    saveFieldToLs('address', valueAddress);
    updateBillingReady({
      fnValue: billingSelector.fullName.value,
      fnError: billingSelector.fullName.error,
      ssValue: valueAddress,
    });
  };

  const ssUpdateAddressGoogle = ({ valueGoogle, errorGoogle }) => {
    dispatch(
      setAddressGoogle({
        addressGoogle: {
          error: errorGoogle,
          value: valueGoogle,
        },
      }),
    );
    saveFieldToLs('addressGoogle', valueGoogle);
  };

  const ssUpdateAddressDownshift = ({ error, value }) => {
    dispatch(
      setAddressDownshift({
        addressDownshift: {
          error,
          value,
        },
      }),
    );
    saveFieldToLs('addressDownshift', value);
  };

  const sharedValidationSteps = validationResult => {
    if (validationResult.hasError) {
      ssUpdateAddress(null);
      datadogRum.addAction('entered-invalid-address');
    } else {
      datadogRum.addAction('entered-valid-address');
    }
  };

  const ssAfterValidationCb = validationResult => {
    sharedValidationSteps();
    if (validationResult.hasError) {
      ssUpdateAddressGoogle({
        valueGoogle: null,
        errorGoogle: validationResult,
      });
    }
  };

  const ssAfterValidationDownshiftCb = validationResult => {
    sharedValidationSteps(validationResult);
    if (validationResult.hasError) {
      ssUpdateAddressDownshift({
        error: validationResult,
        value: null,
      });
    }
  };

  const clearAddressRelatedState = () => {
    ssUpdateAddress(null);
    setContextBillingAddress({});
  };

  const clearGoogleAddress = () => {
    clearAddressRelatedState();
    ssUpdateAddressGoogle({
      valueGoogle: null,
      errorGoogle: initialErrorState,
    });
  };

  const clearDownshiftAddress = () => {
    clearAddressRelatedState();
    ssUpdateAddressDownshift({
      error: initialErrorState,
      value: null,
    });
  };

  const ssHandleSelectCb = async ({ valueGoogle, valueAddress, action }) => {
    setAddressAsTyped('');
    if (action === 'clear') {
      clearGoogleAddress();
    } else if (action === 'select-option' || action === 'nf-autofill') {
      ssUpdateAddress(valueAddress);
      ssUpdateAddressGoogle({ valueGoogle, errorGoogle: initialErrorState });
      await handleAddressStore(valueAddress, billingSelector.fullName.value);
    }
    if (allDigital()) {
      setCartTotalsData(cartData?.totals);
    }
  };

  const ssHandleSelectDownshiftCb = async selection => {
    ssUpdateAddress(selection.legacy);
    ssUpdateAddressDownshift({ error: initialErrorState, value: selection });
    await handleAddressStore(selection.legacy, billingSelector.fullName.value);
    if (allDigital()) {
      setCartTotalsData(cartData?.totals);
    }
  };
  // #endregion

  // #region manual address (ma)
  const maCancelCb = () => {
    setAllowEnterManually(false);
  };

  const maSaveData = value => {
    dispatch(setAddressManual(value));
  };

  const maTriggerAddressAutofill = value => {
    const label = value.singleLine;
    setAutofillAddress({
      source: autofillSource.MANUAL_ADDRESS,
      addressGoogle: convertToGoogle(label),
      addressDownshift: formatAsAutocompleteSuggestion(value, autofillSource.MANUAL_ADDRESS),
      addressSuggestions: [],
      addressManual: value,
    });
  };

  const maSubmitCb = async value => {
    maSaveData(value);
    maTriggerAddressAutofill(value);
    setAllowEnterManually(false);
  };
  // #endregion

  // #region reset checkout
  const resetBillingData = () => {
    dispatch(resetBilling());
    saveData(sliceKey, {}, platformCartIdValue);
    setAutofillFullName(undefined);
    setAutofillAddress(undefined);
    setAllowEnterManually(false);
    setShouldFocusUnit(false);
    setAddressAsTyped('');
    setResetTimestamp(new Date().getTime());
    setValidationTimestamp(0);
    setOpenTimestamp(0);
  };

  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    const subscribeToResetCheckout = () => {
      on(EVENT.RESET_CHECKOUT, () => {
        if (isMounted.current) {
          resetBillingData();
        }
      });

      return () => {
        off(EVENT.RESET_CHECKOUT);
      };
    };
    subscribeToResetCheckout();
    return () => {
      isMounted.current = false;
    };
  }, []);
  // #endregion

  // #region validate checkout
  useEffect(() => {
    const subscribeToSubmitCheckout = () => {
      on(EVENT.SUBMIT_CHECKOUT, () => {
        if (isMounted.current) {
          setValidationTimestamp(new Date().getTime());
        }
      });

      return () => {
        off(EVENT.SUBMIT_CHECKOUT);
      };
    };
    subscribeToSubmitCheckout();
    return () => {
      isMounted.current = false;
    };
  }, []);
  // #endregion

  const adUpdate = value => {
    dispatch(setBillingLine2(value.line2));
    dispatch(setBillingCompany(value.company));
    saveFieldToLs('additionalData', value);
  };

  const adChange = value => {
    adUpdate(value);
    const updatedAddress = {
      ...billingSelector.address,
      ...value,
    };
    ssUpdateAddress(updatedAddress);
  };

  const hasWarning = value => {
    const whenValueIsNull = !value;
    const whenProductIsNotDigital = !allDigital();
    const whenBopisIsNotEnabled = !bopis.isEnabled;
    return whenValueIsNull && whenProductIsNotDigital && whenBopisIsNotEnabled;
  };

  useEffect(() => {
    if (billingSelector.addressGoogle.value && billingSelector.fullName.value) {
      updateCheckoutState(TO_CARD_ADD);
    }
  }, [billingSelector.addressGoogle.value, billingSelector.fullName.value]);

  const getInputValue = () => {
    if (bopis.isEnabled && !billingSelector.addressDownshift.value?.description) {
      const downshiftAddress = cart.addressDownshift?.addressDownshift?.value;
      if (downshiftAddress) return downshiftAddress;

      const customerSuggestedAddress = cart?.checkout.customer?.suggestions?.[0]?.address;
      if (customerSuggestedAddress)
        return formatAsAutocompleteSuggestion(customerSuggestedAddress, autofillSource.MANUAL_ADDRESS);

      const suggestedAddress = cart?.cart?.suggestions?.[0]?.address;
      if (suggestedAddress) return formatAsAutocompleteSuggestion(suggestedAddress, autofillSource.MANUAL_ADDRESS);

      return null;
    }
    return billingSelector.addressDownshift.value;
  };

  const inputValue = getInputValue();

  const renderAutocomplete = () => {
    if (flags['disable-downshift-autocomplete']) {
      return (
        <GoogleAddressAutocomplete
          addressAsTyped={addressAsTyped}
          afterValidationCb={ssAfterValidationCb}
          autofillAddress={autofillAddress}
          disabled={isFieldsetDisabled}
          hasSuggestions={hasSuggestions}
          inputError={initialErrorState}
          inputId={`${sliceKey}-address`}
          inputValue={billingSelector.addressGoogle.value}
          inputWarning={hasWarning(billingSelector.addressGoogle.value)}
          onSelectCb={ssHandleSelectCb}
          placeholder={`* ${t('google_address_autocomplete.billing')}`}
          placeholderTop={t('google_address_autocomplete.billing')}
          setAddressAsTyped={setAddressAsTyped}
          setAllowEnterManually={setAllowEnterManually}
          setShouldOpenAdditionalData={setOpenTimestamp}
          theme={theme}
          triggerReset={resetTimestamp}
          triggerValidation={validationTimestamp}
        />
      );
    }
    return (
      <AddressAutocomplete
        additionalData={additionalData}
        afterValidationCb={ssAfterValidationDownshiftCb}
        autofillAddress={autofillAddress}
        inputId={`${sliceKey}-address`}
        inputValue={inputValue}
        inputWarning={hasWarning(inputValue)}
        isDisabled={isFieldsetDisabled}
        onClearCb={clearDownshiftAddress}
        onSelectCb={ssHandleSelectDownshiftCb}
        placeholder={`* ${t('google_address_autocomplete.billing')}`}
        placeholderTop={t('google_address_autocomplete.billing')}
        setAddressAsTyped={setAddressAsTyped}
        setAllowEnterManually={setAllowEnterManually}
        setShouldOpenAdditionalData={setOpenTimestamp}
        theme={theme}
        triggerReset={resetTimestamp}
        triggerValidation={validationTimestamp}
        updateAdditionalData={adUpdate}
        setShowLoadingPage={setShowLoadingPage}
      />
    );
  };

  // #region autofill (ll)
  const fnSetFullName = (fnValue, fnError) => {
    if (fnError || fnValue !== previousFullName) {
      dispatch(
        setFullName({
          value: fnValue,
          error: fnError,
        }),
      );
      saveFieldToLs('fullName', fnValue);
      datadogRum.addAction('entered-valid-name');
    }
  };

  const handleReturningUser = () => {
    if (checkoutData?.customer?.email) {
      setHasSuggestions(true);
      // handle full name
      const billingName = checkoutData?.address?.name || checkoutData?.billing?.name;
      if (billingName) {
        setAutofillFullName(billingName);
        setPreviousFullName(billingName);
        fnSetFullName(billingName);
      }
      // handle address
      const billingAddress = checkoutData?.address;
      const addressLabel = billingAddress?.singleLine;
      if (addressLabel) {
        setAutofillAddress({
          source: autofillSource.EMAIL_RESPONSE,
          addressGoogle: convertToGoogle(addressLabel),
          addressDownshift: formatAsAutocompleteSuggestion(billingAddress, autofillSource.EMAIL_RESPONSE),
          addressSuggestions: addressSuggested,
          addressManual: null,
        });
        // handle additional data
        const updatedAdditionalData = {
          line2: billingAddress?.apartment || billingAddress?.line2 || '',
          company: checkoutData?.address?.company || '',
        };
        if (updatedAdditionalData.line2 || updatedAdditionalData.company) {
          adUpdate(updatedAdditionalData);
        }
      }
    }
    if (status?.event === 'createCart') {
      dispatch(setStatusEvent({ event: 'autofill' }));
      setShowLoading(false);
    }
  };

  const llParseAutofill = response => {
    if (checkoutData?.address?.line1) {
      handleReturningUser();
    } else {
      const { suggestions } = response;
      const emailHasSuggestions = suggestions && Object.values(suggestions).length > 0;
      if (emailHasSuggestions) {
        setHasSuggestions(true);
        // handle full name
        const billingName = checkoutData?.address?.name || suggestions?.autofill?.shipName;
        if (billingName) {
          setAutofillFullName(billingName);
          fnSetFullName(billingName, initialErrorState);
        }
        // handle address
        const billingAddress = suggestions?.addresses?.find(
          item => item.hash === suggestions?.autofill?.billAddressHash,
        );
        const addressLabel = billingAddress?.singleLine;
        if (addressLabel) {
          setAutofillAddress({
            source: autofillSource.EMAIL_RESPONSE,
            addressGoogle: convertToGoogle(addressLabel),
            addressDownshift: formatAsAutocompleteSuggestion(billingAddress, autofillSource.EMAIL_RESPONSE),
            addressSuggestions: suggestions.addresses,
            addressManual: null,
          });
          // handle additional data
          const company = additionalData?.billing?.company || suggestions?.autofill?.shipCompany;
          const line2 = additionalData?.billing?.line2 || billingAddress?.line2;
          adUpdate({
            line2,
            company,
          });
        }
      }
    }
  };

  useEffect(() => {
    const waitForApiResponse = () => {
      if (!autofilled) {
        if (checkoutData?.status === 'fulfilled' && allDigital()) {
          llParseAutofill(checkoutData);
        }
        if (status?.event === 'createCart' && allDigital()) {
          llParseAutofill(checkoutData);
        }
        if (checkoutData?.status === 'fulfilled' && bopis.isEnabled) {
          llParseAutofill(checkoutData);
          setAutofilled(true);
        }
      }
    };
    waitForApiResponse();
  }, [checkoutData, status]);
  // #endregion

  return (
    <div className={style.section}>
      {(allDigital() || shippingMethodKey) && (
        <FullName
          autoComplete={`${sliceKey} name`}
          autofillFullName={autofillFullName}
          afterValidationCb={fnAfterValidationCb}
          disabled={isFieldsetDisabled}
          inputError={billingSelector.fullName.error}
          inputId="billing-name"
          inputValue={billingSelector.fullName.value}
          inputWarning={hasWarning(billingSelector.fullName.value)}
          onBlurCb={fnBlur}
          onChangeCb={fnChange}
          theme={theme}
          triggerReset={resetTimestamp}
          triggerValidation={validationTimestamp}
        />
      )}
      {renderAutocomplete()}
      <ManualAddress
        checkoutData={checkoutData}
        addressAsTyped={addressAsTyped}
        addressLabel={t('google_address_autocomplete.billing')}
        handleCancelCb={maCancelCb}
        handleSubmitCb={maSubmitCb}
        allowEnterManually={allowEnterManually}
        textFillYourAddress={t('manual_address.please_fill_in_your_billing_address_manually')}
        theme={theme}
        triggerReset={resetTimestamp}
      />
      {allDigital() && (
        <AdditionalData
          disabled={isFieldsetDisabled}
          inputValue={additionalData.billing}
          onBlurCb={() => {}}
          onChangeCb={adChange}
          onFocusCb={() => {}}
          shouldFocusUnit={shouldFocusUnit}
          theme={theme}
          triggerOpenEffect={openTimestamp}
          triggerResetEffect={resetTimestamp}
        />
      )}
      {children}
    </div>
  );
};

Billing.propTypes = {
  children: PropTypes.node,
  flags: PropTypes.shape({
    'disable-downshift-autocomplete': PropTypes.bool,
  }),
  isFieldsetDisabled: PropTypes.bool.isRequired,
  theme: PropTypes.oneOfType([PropTypes.object]),
  setContextBillingAddress: PropTypes.func.isRequired,
};

export default featureFlagClient()(Billing);
