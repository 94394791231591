import { configureStore } from '@reduxjs/toolkit';
import { setupEventMiddleware } from '../../sdk/events';

import counterReducer from '../../modules/counter/slice';
import currencyReducer from '../../modules/currency/slice';
import paymentReducer from '../../modules/payment/slice';
import cartReducer from '../../store/slice';
import billingReducer from '../../modules/billing/slice';
import shippingReducer from '../../modules/shipping/slice';
import modalReducer from '../../modules/modal/slice';

export const setupStore = preloadedState =>
  configureStore({
    reducer: {
      billing: billingReducer,
      counter: counterReducer,
      currency: currencyReducer,
      payment: paymentReducer,
      cart: cartReducer,
      shipping: shippingReducer,
      modal: modalReducer,
      ...preloadedState,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(setupEventMiddleware()),
  });
