import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const SpecialOfferButton = ({ isAppliedOffer, handleApplieOffer, isAvailableTime, minutes, seconds }) => {
  const { t } = useTranslation();

  return (
    (isAvailableTime || isAppliedOffer) && (
      <button
        type="button"
        className={`${styles['offer-button']}  ${isAppliedOffer ? styles.active : styles.default}`}
        onClick={handleApplieOffer}
        disabled={isAppliedOffer}
      >
        <span className={styles['offer-button-title']}>{t('order_display.special_offer')}</span>
        {!isAppliedOffer && isAvailableTime && seconds && (
          <span className={styles['offer-button-timing']}>
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </span>
        )}
      </button>
    )
  );
};

export default SpecialOfferButton;

SpecialOfferButton.propTypes = {
  isAppliedOffer: PropTypes.bool.isRequired,
  handleApplieOffer: PropTypes.func.isRequired,
  isAvailableTime: PropTypes.bool.isRequired,
  minutes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  seconds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
