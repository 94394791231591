/* eslint-disable no-param-reassign */
import { useEffect } from 'react';

const useScrollMethods = (listContainerRef, list) => {
  useEffect(() => {
    if (listContainerRef.current && list?.length >= 3) {
      let pos = { top: 0, left: 0, x: 0, y: 0 };

      const mouseMoveHandler = e => {
        // How far the mouse has been moved
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        // Scroll the element
        listContainerRef.current.scrollTop = pos.top - dy;
        listContainerRef.current.scrollLeft = pos.left - dx;
      };

      const mouseUpHandler = () => {
        listContainerRef.current.removeEventListener('mousemove', mouseMoveHandler);
        listContainerRef.current.removeEventListener('mouseup', mouseUpHandler);

        listContainerRef.current.style.cursor = 'grab';
        listContainerRef.current.style.removeProperty('user-select');
      };

      const mouseDownHandler = e => {
        listContainerRef.current.style.cursor = 'grabbing';
        listContainerRef.current.style.userSelect = 'none';

        pos = {
          // The current scroll
          left: listContainerRef.current.scrollLeft,
          top: listContainerRef.current.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY,
        };

        listContainerRef.current.addEventListener('mousemove', mouseMoveHandler);
        listContainerRef.current.addEventListener('mouseup', mouseUpHandler);
      };

      listContainerRef.current.addEventListener('mousedown', mouseDownHandler);
    }
  }, [listContainerRef, list]);
};

export default useScrollMethods;
