import { useContext } from 'react';

import { status } from '../../helpers/constants';
import DataContext from '../dataContext';
import * as actionTypes from './actionTypes';

const useApiStatus = () => {
  const { apiStatus, apiStatusDispatch } = useContext(DataContext);

  const set = payload =>
    apiStatusDispatch({
      type: actionTypes.ApiStatus_Set,
      payload,
    });
  const reset = () => apiStatusDispatch({ type: actionTypes.ApiStatus_Reset });
  const isLoading =
    apiStatus.isSessionReady === status.LOADING ||
    apiStatus.isShippingAddressReady === status.LOADING ||
    apiStatus.isShippingMethodReady === status.LOADING ||
    apiStatus.isCardInProgress;

  return { apiStatus, set, reset, isLoading };
};

export default useApiStatus;
