import { useContext } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector, useDispatch } from 'react-redux';

import { defaultPortalSettings } from '../helpers/constants';
import usePlatform from '../commons/hooks/use-platform';
import { setPortalSettings, selectPortalSettings } from '../store/slice';
import DataContext from '../store/dataContext';

const transformContactPhone = contactPhone => {
  let transformed = { ...defaultPortalSettings.contactPhone };
  if (contactPhone) {
    if (contactPhone.isRequired) {
      transformed = {
        isVisible: true,
        isRequired: true,
      };
    } else if (contactPhone.isEnabled) {
      transformed = {
        isVisible: true,
        isRequired: false,
      };
    }
  }
  return transformed;
};

const transformNewsletter = newsletter => {
  let transformed = { ...defaultPortalSettings.newsletter };
  if (newsletter) {
    transformed = newsletter;
  }
  return transformed;
};

const transformSocialImpact = socialImpact => {
  let transformed = { ...defaultPortalSettings.socialImpact };
  if (socialImpact) {
    transformed = socialImpact;
  }
  return transformed;
};

const initGtag = gtagId => {
  const gtagScript = document.createElement('script');
  const gtagScriptContent = document.createElement('script');

  gtagScript.type = 'text/javascript';
  gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;

  gtagScriptContent.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${gtagId}', {
        'cookie_flags': 'SameSite=None;Secure'
      });
    `;

  document.getElementsByTagName('head')[0].prepend(gtagScriptContent);
  document.getElementsByTagName('head')[0].prepend(gtagScript);
};

const initGtm = gtmId => {
  const gtmHeadScript = document.createElement('script');
  gtmHeadScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmId}');`;

  const gtmBodyScript = document.createElement('noscript');
  gtmBodyScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

  document.getElementsByTagName('head')[0].append(gtmHeadScript);
  document.getElementsByTagName('body')[0].append(gtmBodyScript);
};

const transformAnalytics = (settingsPluginsAnalytics, flags) => {
  const transformed = {
    ...defaultPortalSettings.analytics,
    ...(settingsPluginsAnalytics ?? {}),
  };

  if (!flags['disable-google-analytics'] && transformed.isEnabled) {
    if (transformed.type === 'gtag') {
      initGtag(transformed.measurementID);
    } else {
      initGtm(transformed.measurementID);
    }
  }

  return transformed;
};

const transformOrderNotes = settings => {
  let transformed = { ...defaultPortalSettings.orderNotes };
  if (settings?.orderNotes?.isEnabled) {
    transformed = {
      isEnabled: true,
      customName: settings?.orderNotes?.customName || defaultPortalSettings.orderNotes.customName,
      color: settings?.theme?.orderNotes?.color || defaultPortalSettings.orderNotes.color,
      mobileView: settings?.theme?.orderNotes?.mobileView || defaultPortalSettings.orderNotes.mobileView,
    };
  }
  return transformed;
};

const transformCoupon = settings => {
  let transformed = { ...defaultPortalSettings.coupon };
  if (settings.coupon && settings.coupon.isEnabled) {
    transformed = {
      isEnabled: true,
      name: settings?.coupon?.name || defaultPortalSettings.coupon.name,
      color: settings?.theme?.coupon?.color || defaultPortalSettings.coupon.color,
      fontSize: settings?.theme?.coupon?.fontSize || defaultPortalSettings.coupon.fontSize,
      isBold: settings?.theme?.coupon?.isBold || defaultPortalSettings.coupon.isBold,
      uppercase: settings?.theme?.coupon?.uppercase || defaultPortalSettings.coupon.uppercase,
    };
  }
  return transformed;
};

const transformGiftCard = giftCard => {
  let transformed = { ...defaultPortalSettings.giftCard };
  if (giftCard && giftCard.isEnabled) {
    transformed = {
      isEnabled: true,
    };
  }
  return transformed;
};

const transformPreOrder = preOrder => {
  const transformed = {
    ...defaultPortalSettings.preOrder,
  };
  if (preOrder && preOrder.name) {
    transformed.name = preOrder.name.slice(0, 20);
  }
  return transformed;
};

const transformCodes = settings => {
  let transformed = defaultPortalSettings.isCodesAvailable;
  if (settings && (settings.coupon?.isEnabled || settings.giftCard?.isEnabled)) {
    transformed = true;
  }
  return transformed;
};

const transformShippingProtection = (shippingProtection, params, themePlugins) => {
  let transformed = { ...defaultPortalSettings.shippingProtection };
  if (shippingProtection?.isEnabled && params) {
    transformed = {
      isEnabled: true,
      quote: params.quote,
      type: params.type,
      isBold: Boolean(themePlugins?.isBold),
    };
  }
  return transformed;
};

const transformCustomerSignature = (customerSignature, params, themePlugins) => {
  let transformed = { ...defaultPortalSettings.customerSignature };
  if (customerSignature?.isEnabled && params?.quote) {
    transformed = {
      isEnabled: true,
      quote: params.quote,
      isBold: Boolean(themePlugins?.isBold),
    };
  }
  return transformed;
};

const transformTermsAndConditions = termsAndConditions => {
  let transformed = { ...defaultPortalSettings.termsAndConditions };
  if (termsAndConditions?.isEnabled && termsAndConditions?.value) {
    transformed = {
      isEnabled: true,
      value: termsAndConditions.value,
    };
  }
  return transformed;
};

const transformCheckoutMode = checkoutMode => {
  let transformed = { ...defaultPortalSettings.checkoutMode };
  if (checkoutMode) {
    transformed = {
      ...checkoutMode,
    };
  }
  return transformed;
};

const transformScripts = scripts => {
  if (scripts && scripts.length > 0) {
    return scripts;
  }
  return [];
};

const transformRequiredCvv = cvv => {
  if (cvv?.isEnabled) {
    return cvv?.isEnabled;
  }
  return false;
};

const parse = (settings, plugins, flags) => {
  const contactPhone = transformContactPhone(settings?.contactPhone);
  const newsletter = transformNewsletter(settings?.plugins?.newsletter);
  const socialImpact = transformSocialImpact(settings?.plugins?.socialImpact);
  const consent =
    settings?.plugins?.klaviyo?.sms?.isEnabled && settings?.plugins?.klaviyo?.sms?.behaviour === 'ON_BY_DEFAULT';
  const analytics = transformAnalytics(settings?.plugins?.analytics, flags);
  const orderNotes = transformOrderNotes(settings);
  const shippingProtection = transformShippingProtection(
    settings?.plugins?.shippingProtection,
    plugins?.shippingProtection,
    settings?.theme?.plugins,
  );
  const customerSignature = transformCustomerSignature(
    settings?.plugins?.customerSignature,
    plugins?.customerSignature,
    settings?.theme?.plugins,
  );
  const termsAndConditions = transformTermsAndConditions(settings?.termsAndConditions);
  const checkoutMode = transformCheckoutMode(settings?.checkoutMode);
  const theme = settings?.theme;
  const scripts = transformScripts(settings?.scripts);
  const coupon = transformCoupon(settings);
  const giftCard = transformGiftCard(settings?.giftCard);
  const preOrder = transformPreOrder(settings?.preOrder);
  const isCodesAvailable = transformCodes(settings);
  const requiredCvv = transformRequiredCvv(settings?.requiredCvv);

  return {
    contactPhone,
    newsletter,
    socialImpact,
    analytics,
    orderNotes,
    shippingProtection,
    customerSignature,
    termsAndConditions,
    checkoutMode,
    theme,
    scripts,
    consent,
    storeLanguage: settings?.language,
    coupon,
    giftCard,
    preOrder,
    isCodesAvailable,
    requiredCvv,
  };
};

const getSameBilling = ({ checkoutMode }) => {
  if (!checkoutMode?.billingBehaviour?.isEnabled) return false;

  return checkoutMode.billingBehaviour.behaviour === 'ON_BY_DEFAULT';
};

const usePortalSettings = () => {
  const portalSettings = useSelector(selectPortalSettings);
  const { updateSameBilling } = useContext(DataContext);
  const dispatch = useDispatch();
  const { setStoreLanguage } = usePlatform();
  const flags = useFlags();

  const updatePortalSettings = (settings, plugins) => {
    setStoreLanguage(settings?.language);
    const transformed = parse(settings, plugins, flags);
    updateSameBilling(getSameBilling(transformed));
    delete transformed.consent;
    dispatch(setPortalSettings(transformed));
  };

  const updateSubscribeToEmail = value => {
    dispatch(
      setPortalSettings(settings => {
        const { newsletter } = settings;
        return { ...settings, newsletter: { ...newsletter, checked: value } };
      }),
    );
  };

  return { portalSettings, updatePortalSettings, updateSubscribeToEmail };
};

export default usePortalSettings;
