import React from 'react';
import PropTypes from 'prop-types';

import { storeUrlValue } from '../../../helpers/prelaunchData';

import { getPaymentProviders, sections } from './utils';

import './payment-buttons.scss';

const PaymentButtons = ({ buttonsConfiguration, section }) => {
  const doesUseNoFraud =
    buttonsConfiguration?.useNoFraudCheckout && buttonsConfiguration?.useNoFraudCheckout.length > 0;
  const doesUseDefault =
    buttonsConfiguration?.useDefaultCheckout && buttonsConfiguration?.useDefaultCheckout.length > 0;
  const shouldIterate = doesUseNoFraud || doesUseDefault;
  let paymentProviders;

  if (section === sections.cartPage) {
    paymentProviders = getPaymentProviders('horizontal-buttons');
    import('./cart-page.scss');
  } else {
    paymentProviders = getPaymentProviders('small-squares');
    import('./preview-dropdown.scss');
  }

  const renderNoFraudCheckout = (provider, alt, src) => {
    const handleNoFraudCheckout = () => {
      window.parent.postMessage(
        {
          type: 'USE_NOFRAUD_CHECKOUT',
          payload: {
            provider,
          },
        },
        storeUrlValue,
      );
    };
    if (section === sections.cartPage) {
      return <input type="image" alt={alt} src={src} key={provider} onClick={handleNoFraudCheckout} />;
    }
    return (
      <button type="button" key={provider} onClick={handleNoFraudCheckout}>
        <img alt={alt} src={src} />
        {alt}
      </button>
    );
  };

  const renderDefaultCheckout = (provider, alt, src) => {
    const handleDefaultCheckout = () => {
      window.parent.postMessage(
        {
          type: 'USE_DEFAULT_CHECKOUT',
          payload: {
            provider,
          },
        },
        storeUrlValue,
      );
    };
    if (section === sections.cartPage) {
      return <input type="image" alt={alt} src={src} key={provider} onClick={handleDefaultCheckout} />;
    }
    return (
      <button type="button" key={provider} onClick={handleDefaultCheckout}>
        <img alt={alt} src={src} />
        {alt}
      </button>
    );
  };

  const renderButtons = () => {
    const buttonsMarkup = [];
    Object.keys(paymentProviders).forEach(provider => {
      const { alt, src } = paymentProviders[provider];
      if (provider === 'applepay' && typeof ApplePaySession !== 'function') {
        return;
      }
      if (buttonsConfiguration.useNoFraudCheckout.includes(provider)) {
        buttonsMarkup.push(renderNoFraudCheckout(provider, alt, src));
      }
      if (buttonsConfiguration.useDefaultCheckout.includes(provider)) {
        buttonsMarkup.push(renderDefaultCheckout(provider, alt, src));
      }
    });
    return buttonsMarkup && buttonsMarkup.length > 0 ? buttonsMarkup : null;
  };

  if (shouldIterate) {
    return (
      <div className="wrapper">
        <div className="nf-payment-options">{renderButtons()}</div>
      </div>
    );
  }

  return null;
};

PaymentButtons.propTypes = {
  buttonsConfiguration: PropTypes.shape({
    useDefaultCheckout: PropTypes.arrayOf(PropTypes.string),
    useNoFraudCheckout: PropTypes.arrayOf(PropTypes.string),
  }),
  section: PropTypes.string,
};

export default PaymentButtons;
