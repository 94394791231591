import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UiContext from '../../store/uiContext';
import DataContext from '../../store/dataContext';
import { selectCheckout } from '../../store/slice';

import './style.scss';

const BillingCheckbox = () => {
  const { t } = useTranslation();
  const checkoutData = useSelector(selectCheckout);
  const { sameBilling, updateSameBilling } = useContext(DataContext);

  const {
    checkoutStage,
    TO_USER_ADD,
    TO_PAYMENT_ADD,
    updateCheckoutState,
    TO_BILLING_ADDRESS_ADD,
    setIsShippingSectionCollapsed,
    setAllowBillingAddressAdd,
    setShowLoadingPage,
  } = useContext(UiContext);
  const isDisabled = checkoutStage === TO_USER_ADD;

  useEffect(() => {
    if (!sameBilling && checkoutData?.shippingMethodKey) {
      updateCheckoutState(TO_BILLING_ADDRESS_ADD);
      setIsShippingSectionCollapsed(true);
      setShowLoadingPage(true);
    } else if (checkoutData?.shippingMethodKey) {
      updateCheckoutState(TO_PAYMENT_ADD);
      setIsShippingSectionCollapsed(false);
      setAllowBillingAddressAdd(false);
    }
    setTimeout(() => {
      setShowLoadingPage(false);
    }, [500]);
  }, [sameBilling, checkoutData.shippingMethodKey]);

  const handleClick = () => {
    if (!isDisabled) {
      updateSameBilling(!sameBilling);
    }
  };

  return (
    <div className="billing-checkbox" tabIndex={0} role="button" onClick={handleClick} onKeyDown={null}>
      <span className="warn">{t('address_collapsed.billing_is_same')}</span>
      <input type="checkbox" checked={sameBilling} onChange={handleClick} />
      <span className="billing-checkmark" />
    </div>
  );
};

export default BillingCheckbox;
