import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DataContext from '../../store/dataContext';
import UiContext from '../../store/uiContext';

import { ReactComponent as LinkIcon } from './icon/icon.svg';
import './style.scss';

const Info = ({ isEnterAddressManuallyOpened, openTerms }) => {
  const dataContext = useContext(DataContext);
  const { showExtraInfoBackground } = useContext(UiContext);
  const { t } = useTranslation();

  const capitalize = link => {
    const firstLetter = link.charAt(0);
    const firstLetterCap = firstLetter.toUpperCase();
    const remainingLetters = link.slice(1);
    return `${firstLetterCap}${remainingLetters}`;
  };

  const renderCustomLinks = () => {
    const c1 = dataContext?.helpfulLinks?.customLinks;
    const c2 = c1 && Object.values(dataContext.helpfulLinks.customLinks).length > 0;
    const shouldMap = c1 && c2;
    let markup;
    if (shouldMap) {
      const customLinksEntries = Object.entries(dataContext.helpfulLinks.customLinks);
      markup = customLinksEntries.reduce((result, item) => {
        const { label, link } = item[1];
        const shouldRenderLinkIcon = label.length <= 17;
        const transformedLabel = t(`info.${label}`).indexOf('info.') > -1 ? capitalize(label) : t(`info.${label}`);
        if (label && link) {
          return (
            <div className="checkout-terms" key={item[0]}>
              <a className="helpful-links" href={link} target="_blank" rel="noreferrer">
                {transformedLabel}
              </a>
              {shouldRenderLinkIcon && <LinkIcon style={{ marginLeft: '3px' }} />}
            </div>
          );
        }
        return result;
      }, []);
    }
    return markup;
  };

  return (
    <div
      className={`info section ${classNames({
        'd-none': isEnterAddressManuallyOpened,
        hide: showExtraInfoBackground,
        'p25-20': true,
      })}`}
    >
      <h1>{t('info.helpful_Links')}</h1>
      <div className="links">
        <div className="checkout-terms">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="helpful-links"
            role="button"
            tabIndex={0}
            onClick={() => openTerms('Terms of Use')}
            onKeyDown={null}
          >
            {t('info.terms_of_use')}
          </a>
        </div>
        {dataContext?.helpfulLinks &&
          Object.entries(dataContext?.helpfulLinks)?.map(
            item =>
              item[1] &&
              item[0] !== 'customLinks' && (
                <div className="checkout-terms" key={item}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="helpful-links" href={item[1]} target="_blank" rel="noreferrer">
                    {t(`info.${item[0]}`).indexOf('info.') > -1 ? capitalize(item[0]) : t(`info.${item[0]}`)}
                  </a>
                  {item[0].length <= 17 && <LinkIcon style={{ marginLeft: '3px' }} />}
                </div>
              ),
          )}
        {renderCustomLinks()}
      </div>
    </div>
  );
};

Info.propTypes = {
  isEnterAddressManuallyOpened: PropTypes.bool.isRequired,
  openTerms: PropTypes.func,
};

export default Info;
