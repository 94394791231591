import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import InputWrapper from '../../../../commons/components/input-wrapper';
import { emptyAdditionalData } from '../../../../commons/utils/empty-additional-data';

import style from './index.module.scss';

const AdditionalData = ({
  disabled,
  inputValue,
  onChangeCb,
  onFocusCb,
  onBlurCb,
  shouldFocusUnit,
  theme,
  triggerOpenEffect,
  triggerResetEffect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [additionalData, setAdditionalData] = useState(inputValue);

  const line2Ref = useRef(null);

  const { t } = useTranslation();

  const handleReset = () => {
    setIsOpen(false);
    setAdditionalData(emptyAdditionalData);
  };

  const handleChange = e => {
    const changedData = {
      ...additionalData,
      [e.target.id]: e.target.value,
    };
    setAdditionalData(changedData);
    if (typeof onChangeCb === 'function') {
      onChangeCb(changedData);
    }
  };

  const handleFocus = () => {
    if (typeof onFocusCb === 'function') {
      onFocusCb(additionalData);
    }
  };

  const handleBlur = e => {
    if (typeof onBlurCb === 'function') {
      onBlurCb(e);
    }
  };

  const triggerOpen = triggerOpenCb => {
    setIsOpen(true);
    if (typeof triggerOpenCb === 'function') {
      triggerOpenCb();
    }
  };

  useEffect(() => {
    if (inputValue) {
      setAdditionalData(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (triggerOpenEffect) {
      triggerOpen();
    }
  }, [triggerOpenEffect]);

  useEffect(() => {
    const focusOnUnit = () => {
      if (line2Ref && line2Ref.current) {
        line2Ref.current.focus();
      }
    };
    if (shouldFocusUnit) {
      triggerOpen(focusOnUnit);
    }
  }, [shouldFocusUnit]);

  useEffect(() => {
    const resetEffect = () => {
      if (triggerResetEffect) {
        handleReset();
      }
    };
    resetEffect();
  }, [triggerResetEffect]);

  return (
    <div className={`${style.container} ${!isOpen ? style['gap-bottom'] : ''}`}>
      <div className={`${style.row} ${!isOpen ? style.invisible : ''}`}>
        <div className={style['custom-grid']}>
          <InputWrapper
            classNameInput={classNames({
              'is-value': additionalData.line2,
            })}
            disabled={disabled}
            inputId="line2"
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            placeholder={`${t('address_add.apt_or_unit')} #`}
            placeholderTop={`${t('address_add.apt_or_unit')} #`}
            ref={line2Ref}
            theme={theme}
            type="text"
            value={additionalData.line2}
          />
          <InputWrapper
            classNameInput={classNames({
              'is-value': additionalData.company,
            })}
            disabled={disabled}
            inputId="company"
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            placeholder={`${t('address_add.company_name')}`}
            placeholderTop={`${t('address_add.company_name')}`}
            theme={theme}
            type="text"
            value={additionalData.company}
          />
        </div>
      </div>
      <div className={`${style.row} ${isOpen ? style.invisible : ''}`}>
        <button type="button" onClick={triggerOpen} disabled={disabled} className={style['add-button']}>
          <span>+ {t('address_add.apartment_company_name')}</span>
        </button>
      </div>
    </div>
  );
};

AdditionalData.propTypes = {
  disabled: PropTypes.bool.isRequired,
  inputValue: PropTypes.oneOfType([PropTypes.object]),
  onChangeCb: PropTypes.func.isRequired,
  onFocusCb: PropTypes.func.isRequired,
  onBlurCb: PropTypes.func.isRequired,
  shouldFocusUnit: PropTypes.bool.isRequired,
  theme: PropTypes.oneOfType([PropTypes.object]).isRequired,
  triggerOpenEffect: PropTypes.number,
  triggerResetEffect: PropTypes.number,
};

export default AdditionalData;
