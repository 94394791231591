import { isEqual } from 'lodash';

import { productType } from '../../helpers/constants';
import { deliveryMethod } from '../../commons/constants';

const { eventEmitter } = require('./emitter');
const { types } = require('./types');

const onCheckoutStarted = state => ({
  platformCartId: state.cart?.cart?.platformCartId,
  nofraudCartId: state.cart?.checkout?.id,
  items: state.cart?.cart?.items.map(item => ({
    id: item?.lookupKey,
    name: item?.name,
    quantity: item?.quantity,
    price: item?.price,
  })),
  currency: state.cart?.cart?.currency,
  totals: {
    billed: state.cart?.cart?.totals?.billed,
    discount: state.cart?.cart?.totals?.discount,
    handling: state.cart?.cart?.totals?.handling,
    shipping: state.cart?.cart?.totals?.shipping,
    subtotal: state.cart?.cart?.totals?.subtotal,
    tax: state.cart?.cart?.totals?.tax,
    total: state.cart?.cart?.totals?.total,
  },
  timestamp: Date.now(),
});

const onCheckoutCompleted = state => ({
  ...onCheckoutStarted(state),
  email: state.cart?.checkout?.customer?.email,
  name: state.cart?.checkout?.address?.name,
  order: {
    lookupKey: state.cart?.cart?.order?.lookupKey,
  },
  timestamp: Date.now(),
});

const onCustomerUpdated = state => ({
  email: state.cart?.checkout?.customer?.email,
  phone: state.cart?.checkout?.customer?.phone,
  name: state.cart?.checkout?.address?.name,
});

const onPaymentReady = state => ({
  currency: state.cart?.cart?.currency,
  items: state.cart?.cart?.items.map(item => ({
    id: item?.lookupKey,
    name: item?.name,
    quantity: item?.quantity,
    price: item?.price,
  })),
  paymentMethod: state.payment?.selectedMethod,
  totals: {
    billed: state.cart?.cart?.totals?.billed,
    discount: state.cart?.cart?.totals?.discount,
    handling: state.cart?.cart?.totals?.handling,
    shipping: state.cart?.cart?.totals?.shipping,
    subtotal: state.cart?.cart?.totals?.subtotal,
    tax: state.cart?.cart?.totals?.tax,
    total: state.cart?.cart?.totals?.total,
  },
});

const onPaymentSuccessful = state => ({
  currency: state.cart?.cart?.currency,
  amount: state.cart?.cart?.totals?.total,
  paymentMethod: state.payment?.selectedMethod,
  timestamp: Date.now(),
});

const onAddressUpdated = state => {
  const allDigital =
    state.cart?.cart?.items?.length > 0 && state.cart?.cart?.items.every(item => item?.type === productType.DIGITAL);
  const isPickupActive = state.shipping?.bopis?.isEnabled && state.shipping?.deliveryMethod === deliveryMethod.pickup;

  const shipping =
    !allDigital && !isPickupActive
      ? {
          name: state.cart?.checkout?.address?.name ?? state.fullName,
          city: state.cart?.checkout?.address?.city,
          country: state.cart?.checkout?.address?.country,
          line1: state.cart?.checkout?.address?.line1,
          postal: state.cart?.checkout?.address?.postal,
          region: state.cart?.checkout?.address?.region,
          regionCode: state.cart?.checkout?.address?.regionCode,
          company: state.cart?.checkout?.address?.company,
          line2: state.cart?.checkout?.address?.line2,
          singleLine: state.cart?.checkout?.address?.singleLine,
        }
      : null;

  const isBillingDefined = state.billing?.address?.line1 != null;
  if (!isBillingDefined) {
    const isCheckoutBillingDefined = state.cart?.checkout?.billing?.line1 != null;
    if (isCheckoutBillingDefined)
      return {
        billing: state.cart?.checkout?.billing,
        shipping,
      };

    return {
      billing: shipping,
      shipping,
    };
  }

  const billing = {
    name: state.cart?.checkout?.address?.name ?? state.fullName,
    line1: state.billing?.address?.line1,
    line2: state.billing?.address?.line2,
    postal: state.billing?.address?.postal,
    region: state.billing?.address?.region,
    regionCode: state.billing?.address?.regionCode,
    singleLine: state.billing?.address?.singleLine,
    city: state.billing?.address?.city,
    country: state.billing?.address?.country,
  };

  return {
    billing,
    shipping,
  };
};

const conditionalOnAddressUpdated = (state, _, oldState) => {
  if (!isEqual(state.billing?.address, oldState.billing?.address)) return onAddressUpdated(state);
  if (!isEqual(state.cart?.checkout?.address, oldState.cart?.checkout?.address)) return onAddressUpdated(state);

  return null;
};

const getSubEvent = (state, oldState) => {
  const cart = state.cart?.cart;
  const oldCart = oldState.cart?.cart;

  const subEvents = [
    {
      name: 'shipping_protection',
      changed: !isEqual(cart?.plugin?.route, oldCart?.plugin?.route),
    },
    {
      name: 'gift_card',
      changed: !isEqual(cart?.giftCards, oldCart?.giftCards),
    },
    {
      name: 'coupon',
      changed: !isEqual(cart?.coupons, oldCart?.coupons),
    },
    {
      name: 'signature_protection',
      changed: !isEqual(cart?.plugin?.customerSignature, oldCart?.plugin?.customerSignature),
    },
    {
      name: 'shipping',
      changed: !isEqual(cart?.totals?.shipping, oldCart?.totals?.shipping),
    },
    {
      name: 'tax',
      changed: !isEqual(cart?.totals?.tax, oldCart?.totals?.tax),
    },
  ];

  return subEvents.find(subEvent => subEvent.changed)?.name ?? 'other';
};

const onCartUpdated = (state, _, oldState) => ({
  ...onCheckoutStarted(state),
  subEvent: getSubEvent(state, oldState),
});

const onPaymentFailed = (state, action) => ({
  currency: state.cart?.cart?.currency,
  amount: state.cart?.cart?.totals?.total,
  paymentMethod: state.payment?.selectedMethod,
  timestamp: Date.now(),
  errorCode: action.error?.code,
  errorMessage: action.error?.message,
});

const onPaymentSelected = state => ({
  paymentMethod: state.payment?.selectedMethod,
  timestamp: Date.now(),
});

const onPageInitiated = (state, action, page) => ({ page });

const createPayloads = {
  [types.AddressUpdated]: onAddressUpdated,
  [types.CartUpdated]: onCartUpdated,
  [types.CheckoutCompleted]: onCheckoutCompleted,
  [types.CheckoutStarted]: onCheckoutStarted,
  [types.CustomerUpdated]: onCustomerUpdated,
  [types.PageInitiated]: onPageInitiated,
  [types.PaymentFailed]: onPaymentFailed,
  [types.PaymentSelected]: onPaymentSelected,
  [types.PaymentSuccessful]: onPaymentSuccessful,
  [types.PaymentReady]: onPaymentReady,
};

const conditionalAddressUpdatedRoute = () => ({
  eventType: types.AddressUpdated,
  createPayload: conditionalOnAddressUpdated,
});

const fromType = eventType => ({
  eventType,
  createPayload: createPayloads[eventType],
});

const fromPage = page => ({
  eventType: types.PageInitiated,
  createPayload: (state, action) => createPayloads[types.PageInitiated](state, action, page),
});

const routes = {
  'cart/create_cart/fulfilled': [fromPage('checkout'), fromType(types.CheckoutStarted)],
  'cart/resetShipping': fromType(types.AddressUpdated),
  'cart/setEmail': fromType(types.CustomerUpdated),
  'cart/setPhone': fromType(types.CustomerUpdated),
  'cart/setFullName': fromType(types.CustomerUpdated),
  'cart/setCartItems': fromType(types.CartUpdated),
  'cart/setIsPaymentReady': fromType(types.PaymentReady),
  'cart/enrich/fulfilled': [fromType(types.CartUpdated), conditionalAddressUpdatedRoute()],
  'cart/submit/fulfilled': [
    fromPage('order_confirmation'),
    fromType(types.PaymentSuccessful),
    fromType(types.CheckoutCompleted),
  ],
  'cart/submit/rejected': fromType(types.PaymentFailed),
  'billing/resetBilling': fromType(types.AddressUpdated),
  'payment/setPaymentMethod': fromType(types.PaymentSelected),
};

const emitEvents = async (state, action, oldState, subscriptions) => {
  for (const subscription of subscriptions) {
    const { createPayload, eventType } = subscription;
    if (createPayload && eventType) {
      const payload = createPayload(state, action, oldState);
      if (payload) {
        eventEmitter.emit(eventType, payload);
      }
    }
  }
};

export const EventMiddleware = store => next => action => {
  const oldState = store.getState();
  if (!next || !action) {
    return [];
  }
  const result = next(action);
  const route = routes[action.type];
  if (!route) return result;

  const state = store.getState();
  const subscriptions = route instanceof Array ? route : [route];

  emitEvents(state, action, oldState, subscriptions).catch(err => console.error(err)); // eslint-disable-line no-console

  return result;
};
