import React, { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import InputWrapper from '../../commons/components/input-wrapper';
import { currentBaseUrl } from '../../helpers/urlConst';
import { selectCart } from '../../store/slice';
import UiContext from '../../store/uiContext';
import { status } from '../../helpers/constants';

import { showCodeTitle } from './CouponUtils';

const CouponForm = ({ formValues, formStatus, formErrors, handleChange, handleSubmit, isApiStatusLoading }) => {
  const cartData = useSelector(selectCart);
  const portalSettings = cartData?.merchant?.settings;
  const { isLoadingInsurance, isLoadingRoute, isLoadingSignature } = useContext(UiContext);
  const loading = formStatus === status.LOADING || isApiStatusLoading;
  const codeRef = useRef(null);

  const renderError = text =>
    // eslint-disable-next-line react/no-array-index-key
    Array.isArray(text) ? text?.map((message, index) => <p key={index}>{message}</p>) : <p>{text}</p>;

  useEffect(() => {
    if (codeRef?.current) {
      codeRef.current.focus();
    }
  }, []);

  const InputButton = () => (
    <button
      className={`edit-btn ${loading && 'loading'}`}
      type="submit"
      disabled={loading || !formValues.code || isLoadingInsurance || isLoadingRoute || isLoadingSignature}
      style={{
        backgroundColor: cartData?.merchant?.settings?.theme?.colour?.main,
      }}
    >
      {!loading ? (
        <img alt="check-icon" src={`${currentBaseUrl()}assets/check.svg`} />
      ) : (
        <img alt="Loading..." src={`${currentBaseUrl()}loaders/spinner-white.png`} width={30} />
      )}
    </button>
  );

  return (
    <form className="coupon-form" onSubmit={handleSubmit}>
      <div className="edit-wrapper">
        <InputWrapper
          classNameInput={classNames({
            'has-error': !formErrors.isValid,
            'is-value': formValues.code,
          })}
          isUppercase={portalSettings.coupon.uppercase}
          classNameWrapper="coupon"
          inputId="code"
          onChange={handleChange}
          placeholder={showCodeTitle()}
          placeholderTop={showCodeTitle()}
          theme={portalSettings.theme}
          type="text"
          value={formValues.code}
          ref={codeRef}
        >
          <InputButton />
        </InputWrapper>
        {!formErrors.isValid && (
          <div className="error">
            {renderError(formErrors.fields.code)}
            {renderError(formErrors.fields.api)}
          </div>
        )}
      </div>
    </form>
  );
};

CouponForm.propTypes = {
  formValues: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
  formStatus: PropTypes.string.isRequired,
  formErrors: PropTypes.shape({
    isValid: PropTypes.bool,
    fields: PropTypes.oneOfType([PropTypes.object]),
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isApiStatusLoading: PropTypes.bool.isRequired,
};

export default CouponForm;
