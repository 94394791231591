import _ from 'lodash';

export function memoizeDebounce(func, wait = 0, options = {}, resolver) {
  const debounceMemo = _.memoize(() => _.debounce(func, wait, options), resolver);

  function wrappedFunction(...args) {
    return debounceMemo(...args)(...args);
  }

  const flush = (...args) => debounceMemo(...args).flush();

  const cancel = (...args) => debounceMemo(...args).cancel();

  wrappedFunction.flush = flush;
  wrappedFunction.cancel = cancel;

  return wrappedFunction;
}
