import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import UiContext from '../../store/uiContext';
import { currentBaseUrl } from '../../helpers/urlConst';

import './style.scss';

const LoadingPage = () => {
  const { t } = useTranslation();
  const { showLoadingPage, setShowLoadingPage } = useContext(UiContext);

  useEffect(() => {
    if (showLoadingPage) {
      setTimeout(() => {
        if (showLoadingPage) {
          setShowLoadingPage(false);
        }
      }, [2000]);
    }
  }, [showLoadingPage]);

  return (
    <div className="loading-page">
      <div className="loading-container">
        <div className="loading-name">{t('checkout.loading')}</div>
        <div className="animation-wrapper">
          <div className="animation-wrapper-block">
            <div className="loading-wrapper">
              <img
                className="loading"
                alt="Loading..."
                src={`${currentBaseUrl()}loaders/spinner-blue.png`}
                width={50}
                height={50}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
