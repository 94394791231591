import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withLDConsumer as featureFlagClient } from 'launchdarkly-react-client-sdk';
import { defaultUrl } from '../../commons/hooks/use-platform';
import { selectCart, selectCheckout } from '../../store/slice';
import { currentBaseUrl } from '../../helpers/urlConst';
import { useProduct } from '../../hooks/useProduct';
import { waitForElm } from '../../helpers/dom';

import { currentPaymentMethod, setPaymentMethod } from '../../modules/payment/slice';
import { paymentIdList } from '../../helpers/constants';
import CreditCardApp from '../../modules/credit-card-app/containers';
import DataContext from '../../store/dataContext';
import useAddress from '../../hooks/useAddress';
import { useEndpoint } from '../../hooks/useEndpoint';

import AppleCheckout from '../PaymentMethods/AppleCheckout';
import PaypalCheckout from '../PaymentMethods/PaypalCheckout';
import AmazonCheckout from '../PaymentMethods/AmazonCheckout';
import GoogleCheckout from '../PaymentMethods/GoogleCheckout';
import VenmoCheckout from '../PaymentMethods/VenmoCheckout';
import DefaultView from '../PaymentMethods/DefaultView';

import './styles.scss';

const PaymentMethodSelected = ({ setOrderSubmitting, flags }) => {
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(false);
  const [reloadIframe, setReloadIframe] = useState(false);
  const { cartId, storeUrl, billingPhone } = useContext(DataContext);
  const selectedMethod = useSelector(currentPaymentMethod);
  const [localMethod, setLocalMethod] = useState(selectedMethod);
  const cart = useSelector(selectCart);
  const checkoutData = useSelector(selectCheckout);
  const { getBilling } = useAddress();
  const { patchEnrich } = useEndpoint();

  const { allDigital } = useProduct();
  const resultingMarkup = [];

  useEffect(() => {
    if (!cart?.payment?.[selectedMethod] && selectedMethod !== paymentIdList.CREDIT_CARD) {
      dispatch(setPaymentMethod(paymentIdList.CREDIT_CARD));
    }
  }, [cart]);

  const updateData = async shouldRedirect => {
    const billing = getBilling();
    try {
      await patchEnrich({
        input: !shouldRedirect,
        inputPayment: true,
        payment: {
          cvv: undefined,
          onBehalfOf: undefined,
          cardType: undefined,
          id: cartId,
          type: 'platform',
          context: {},
        },
        address: allDigital() ? billing : checkoutData?.address,
        billing: allDigital()
          ? {
              phone: billingPhone,
            }
          : billing,
      });
    } catch (err) {
      // err
    }
    if (shouldRedirect) {
      window.top.location.href = `${storeUrl}/${defaultUrl}?preventIframeLoad=true`;
      waitForElm('.nf-buttons-container').then(() => {
        document.querySelector('.nf-buttons-container').style.display = 'none';
      });
    } else {
      setLocalMethod(selectedMethod);
      setShowLoading(false);
    }
  };

  useEffect(() => {
    if (localMethod !== selectedMethod) {
      if (selectedMethod === paymentIdList.CREDIT_CARD) {
        setReloadIframe(true);
      } else {
        setReloadIframe(false);
      }
      setShowLoading(true);
      let shouldRedirect =
        !cart?.payment?.[selectedMethod] && cart?.merchant?.settings?.paymentMethod?.[selectedMethod];
      if (selectedMethod === 'paylater' || selectedMethod === 'venmo') {
        shouldRedirect =
          !cart?.payment?.paypal?.[selectedMethod]?.isEnabled &&
          cart?.merchant?.settings?.paymentMethod?.[selectedMethod];
      }
      updateData(shouldRedirect);
    }
  }, [selectedMethod]);

  let redirectLoading = !cart?.payment?.[selectedMethod] && cart?.merchant?.settings?.paymentMethod?.[selectedMethod];
  if (selectedMethod === 'paylater' || selectedMethod === 'venmo') {
    redirectLoading =
      !cart?.payment?.paypal?.[selectedMethod]?.isEnabled && cart?.merchant?.settings?.paymentMethod?.[selectedMethod];
  }
  if (localMethod !== selectedMethod || showLoading || redirectLoading) {
    return (
      <div className="loading-payment">
        <img alt="Loading..." src={`${currentBaseUrl()}loaders/spiner-plugin.png`} width="25" height="25" />
      </div>
    );
  }

  if (
    !flags['disable-paypal'] &&
    (selectedMethod === paymentIdList.PAYPAL || selectedMethod === paymentIdList.PAYLATER)
  ) {
    resultingMarkup.push(
      <PaypalCheckout flags={flags} setOrderSubmitting={setOrderSubmitting} key={paymentIdList.PAYPAL} />,
    );
  } else if (!flags['disable-venmo'] && selectedMethod === paymentIdList.VENMO) {
    resultingMarkup.push(
      <VenmoCheckout flags={flags} setOrderSubmitting={setOrderSubmitting} key={paymentIdList.VENMO} />,
    );
  } else if (!flags['disable-amazon'] && selectedMethod === paymentIdList.AMAZONPAY) {
    resultingMarkup.push(
      <AmazonCheckout flags={flags} setOrderSubmitting={setOrderSubmitting} key={paymentIdList.AMAZONPAY} />,
    );
  } else if (!flags['disable-google'] && selectedMethod === paymentIdList.GOOGLEPAY) {
    resultingMarkup.push(
      <GoogleCheckout flags={flags} setOrderSubmitting={setOrderSubmitting} key={paymentIdList.GOOGLEPAY} />,
    );
  } else if (!flags['disable-applepay'] && selectedMethod === paymentIdList.APPLEPAY) {
    resultingMarkup.push(
      <AppleCheckout flags={flags} setOrderSubmitting={setOrderSubmitting} key={paymentIdList.APPLEPAY} />,
    );
  } else {
    resultingMarkup.push(
      <div style={{ display: selectedMethod === paymentIdList.CREDIT_CARD ? 'none' : 'block' }} key="default-view">
        <DefaultView flags={flags} />
      </div>,
    );
  }

  resultingMarkup.push(
    <div
      id="credit-card"
      style={{ display: selectedMethod === paymentIdList.CREDIT_CARD ? 'block' : 'none' }}
      key={paymentIdList.CREDIT_CARD}
    >
      <CreditCardApp flags={flags} reload={reloadIframe} />
    </div>,
  );

  return resultingMarkup.map(item => item);
};

PaymentMethodSelected.propTypes = {
  setOrderSubmitting: PropTypes.func.isRequired,
  flags: PropTypes.shape({
    'disable-google': PropTypes.bool,
    'disable-applepay': PropTypes.bool,
    'disable-paypal': PropTypes.bool,
    'disable-amazon': PropTypes.bool,
    'disable-venmo': PropTypes.bool,
  }),
};

export default featureFlagClient()(PaymentMethodSelected);
