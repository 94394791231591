import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import translationEN from '../translations/en.json';
import translationIT from '../translations/it.json';
import translationDE from '../translations/de.json';
import translationES from '../translations/es.json';
import translationFR from '../translations/fr.json';

const resources = {
  eng: {
    translation: translationEN,
  },
  ita: {
    translation: translationIT,
  },
  deu: {
    translation: translationDE,
  },
  spa: {
    translation: translationES,
  },
  fra: {
    translation: translationFR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: ['eng', 'ita', 'deu', 'spa', 'fra'],
  debug: false,
  lng: 'eng',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;

export const formatedLang = lang => {
  switch (lang) {
    case 'en':
      return 'eng';
    case 'it':
      return 'ita';
    case 'de':
      return 'deu';
    case 'es':
      return 'spa';
    case 'fr':
      return 'fra';
    default:
      return 'eng';
  }
};
