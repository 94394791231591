import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { currentBaseUrl } from '../../../../helpers/urlConst';
import { amountFormatter } from '../../../../helpers/format';
import { pluginList } from '../../../../commons/constants';

import { useAdditionalService } from '../../hooks/use-additional-service';
import useProtection from '../../hooks/use-shipping-protection';
import useCustomerSignature from '../../hooks/use-customer-signature';

import { selectCart } from '../../../../store/slice';

import './index.scss';

const AdditionalServiceTotals = ({
  type,
  shopperCurrency,
  isLoadingInsurance,
  isLoadingRoute,
  isLoadingSignature,
  done,
  formStatus,
  isLoadingCoupons,
}) => {
  const { isRoute, isPackageInsurance, isCustomerSignature, isDisabled } = useAdditionalService({
    type,
    isLoadingInsurance,
    isLoadingRoute,
    isLoadingSignature,
    formStatus,
    isLoadingCoupons,
  });
  const cartData = useSelector(selectCart);

  const { handleChange: handleProtectionRemove } = useProtection();
  const { handleChange: handleSignatureRemove } = useCustomerSignature();
  const handleRouteRemove = handleProtectionRemove(pluginList.route);
  const handlePackageInsuranceRemove = handleProtectionRemove(pluginList.packageInsurance);

  const { t } = useTranslation();

  const showCorrectTitle = () => {
    if (isRoute) {
      return t('protection_total_container.route');
    }

    if (isPackageInsurance) {
      return t('protection_total_container.title');
    }

    if (isCustomerSignature) {
      return t('signature_total_container.title');
    }
    return '';
  };

  const showCorrectPrice = () => {
    if (isRoute) {
      return cartData?.additionalCharges.find(item => item.name === cartData?.plugin?.route?.charge?.name)?.price;
    }

    if (isPackageInsurance) {
      return cartData?.additionalCharges.find(item => item.name === cartData?.plugin?.packageInsurance?.charge?.name)
        ?.price;
    }

    if (isCustomerSignature) {
      return cartData?.additionalCharges.find(item => item.name === cartData?.plugin?.customerSignature?.charge?.name)
        ?.price;
    }
    return 0;
  };

  const handleRemove = event => {
    if (isRoute) {
      handleRouteRemove(event);
    }

    if (isPackageInsurance) {
      handlePackageInsuranceRemove(event);
    }

    if (isCustomerSignature) {
      handleSignatureRemove(event);
    }
  };

  const isLoadingState = () => {
    if (isRoute) {
      return isLoadingRoute;
    }

    if (isPackageInsurance) {
      return isLoadingInsurance;
    }

    if (isCustomerSignature) {
      return isLoadingSignature;
    }

    return true;
  };

  const renderTotal = () => {
    if (isLoadingState()) {
      return (
        <img
          className="button-position loading"
          alt="Loading..."
          src={`${currentBaseUrl()}loaders/spinner-blue.png`}
          width={15}
          height={15}
        />
      );
    }
    return (
      <>
        <input
          className="button-position"
          type="image"
          alt="Remove Plugin"
          src={`${currentBaseUrl()}assets/close.svg`}
          onClick={handleRemove}
          disabled={isDisabled}
        />
      </>
    );
  };

  return (
    <div className="protect-total">
      <div>
        <span>{showCorrectTitle()} </span>
        {!done && renderTotal()}
      </div>
      <div>
        <span>
          {amountFormatter({
            shopperCurrency,
            value: showCorrectPrice(),
            isNegative: false,
          })}
        </span>
      </div>
    </div>
  );
};

export default AdditionalServiceTotals;

AdditionalServiceTotals.propTypes = {
  type: PropTypes.oneOf([pluginList.route, pluginList.packageInsurance, pluginList.customerSignature]).isRequired,
  shopperCurrency: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isLoadingInsurance: PropTypes.bool.isRequired,
  isLoadingRoute: PropTypes.bool.isRequired,
  isLoadingSignature: PropTypes.bool.isRequired,
  done: PropTypes.bool.isRequired,
  formStatus: PropTypes.string,
  isLoadingCoupons: PropTypes.bool,
};
