import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useEndpoint } from '../../hooks/useEndpoint';
import useCurrency from '../../modules/currency/hooks/use-currency';
import useCartTotals from '../../store/cartTotals/useCartTotals';
import { currentBaseUrl } from '../../helpers/urlConst';
import { amountFormatter } from '../../helpers/format';
import DataContext from '../../store/dataContext';
import UiContext from '../../store/uiContext';
import { selectCart, selectCheckout } from '../../store/slice';

import './CouponTotalContainer.scss';

const CouponTotalContainer = ({ gift }) => {
  const { t } = useTranslation();
  const {
    updateCoupon,
    coupon,
    updateShippingMethods,
    updateShippingMethodId,
    updateGiftInfo,
    setPaymentMethodData,
    additionalCharges,
  } = useContext(DataContext);
  const { getShoppercurrency } = useCurrency();
  const { isLoadingInsurance, isLoadingRoute, isLoadingSignature, isLoadingCoupons, setIsLoadingCoupons } =
    useContext(UiContext);
  const uiContext = useContext(UiContext);
  const { setData: setCartTotalsData, cartTotals } = useCartTotals();
  const cartData = useSelector(selectCart);
  const checkoutData = useSelector(selectCheckout);
  const { patchEnrich } = useEndpoint();
  const isCoupon = cartData?.coupons?.length > 0;

  const isAddressFilledOut = checkoutData?.address && checkoutData?.address?.singleLine;

  const handleRemoveCoupon = async discountType => {
    try {
      const checkout = {};
      if (discountType === 'coupon') {
        checkout.coupons = [];
      } else {
        checkout.giftCards = [];
      }
      const result = await patchEnrich({
        ...checkout,
        additionalCharges,
      });
      return {
        cart: result.cart,
        checkout: result.checkout,
        shippingMethods: result.cart.shippingMethods,
        shippingMethodKey: result.checkout.shippingMethodKey,
        totals: result.cart.totals,
        paymentMethodData: result.cart.payment,
        success: true,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };

  const handleRemove = async () => {
    setIsLoadingCoupons(true);
    let removeCouponResult;

    if (isCoupon) {
      removeCouponResult = await handleRemoveCoupon('coupon');
    } else {
      removeCouponResult = await handleRemoveCoupon('certificate');
    }
    if (removeCouponResult && removeCouponResult.cartTotals) {
      const newCartTotals = { ...cartTotals.data, ...removeCouponResult.cartTotals };
      setCartTotalsData(newCartTotals);
    }
    if (isAddressFilledOut) {
      updateShippingMethods(removeCouponResult.shippingMethods);
      updateShippingMethodId(removeCouponResult.shippingMethodKey);
    }
    if (isCoupon) {
      updateCoupon({});
    }
    if (removeCouponResult.giftCertificates) {
      updateGiftInfo(removeCouponResult?.giftCertificates);
    }
    setPaymentMethodData(removeCouponResult.payment);
    setIsLoadingCoupons(false);
  };

  const renderTotal = () => {
    if (isLoadingCoupons) {
      return (
        <img
          className="button-position loading"
          alt="Loading..."
          src={`${currentBaseUrl()}loaders/spinner-blue.png`}
          width={15}
          height={15}
        />
      );
    }
    return (
      <input
        className="button-position"
        type="image"
        alt="Remove Coupon"
        src={`${currentBaseUrl()}assets/close.svg`}
        onClick={handleRemove}
        disabled={isLoadingInsurance || isLoadingRoute || isLoadingSignature}
      />
    );
  };

  const figureOutExpectedValue = () => {
    if (isCoupon) {
      return coupon.amount;
    }
    if (gift) {
      const floatGiftBalance = parseFloat(gift.balance);
      const floatGrandTotal = parseFloat(cartTotals.data?.grandTotal);
      const isGiftGreaterThanTotal = floatGiftBalance > floatGrandTotal;
      if (isGiftGreaterThanTotal) {
        return gift.used;
      }
      if (gift.applied) {
        // This condition was created for Magento
        return gift.applied;
      }
      return gift.balance;
    }
    return 0;
  };

  return (
    <div className="coupon-total">
      <div className="coupon-info">
        <span className="coupon-title">{isCoupon ? t('coupon_title') : t('gift_total_title')}</span>
        <span className="coupon-code-wrapper">
          (
          <span className="coupon-code">
            {isCoupon ? cartData?.coupons?.[0]?.code : cartData?.giftCards?.[0]?.code}
          </span>
          )
        </span>
      </div>
      {!uiContext.done && renderTotal()}
      <div>
        <span>
          {amountFormatter({
            shopperCurrency: getShoppercurrency(),
            value: figureOutExpectedValue(),
            isNegative: true,
          })}
        </span>
      </div>
    </div>
  );
};

CouponTotalContainer.propTypes = {
  gift: PropTypes.oneOfType([PropTypes.object]),
};

export default CouponTotalContainer;
