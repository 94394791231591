import { load } from '@fingerprintjs/botd';
import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { withLDConsumer as featureFlagClient } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { useSkeleton } from './hooks/useSkeleton';
import useAutofill from './hooks/useAutofill';
import CloseWrapperBtn from './components/Button/CloseWrapperBtn';
import Popup from './components/Popup';
import LoadingPage from './components/LoadingPage';
import usePlatform, { defaultUrl } from './commons/hooks/use-platform';
import TermsPrivacyAbout from './components/TermsPrivacyAbout';
import { usePaypal } from './hooks/paymentMethods/usePaypal';
import { useAmazon } from './hooks/paymentMethods/useAmazon';
import { useApplepay } from './hooks/paymentMethods/useApplepay';
import SkeletonLoading from './components/SkeletonLoading';
import usePortalSettings from './hooks/usePortalSettings';
import useReset from './hooks/useReset';
import useCartTotals from './store/cartTotals/useCartTotals';
import useApiStatus from './store/apiStatus/useApiStatus';
import PreOrderModal from './components/PreOrderModal';
import OrderDisplay from './components/OrderDisplay';
import OrderSubmit from './components/OrderSubmit';
import EditEmail from './components/EditEmail';
import DataContext from './store/dataContext';
import Checkout from './components/Checkout';
import Overlay from './components/Overlay';
import { analyticsStage1, status } from './helpers/constants';
import { isPopup } from './helpers/urlConst';
import UiContext from './store/uiContext';
import { checkMethodsAvailable } from './hooks/paymentMethods/useCheckEligibility';
import Cart from './modules/cart/components/cart';
import Info from './components/Info';
import ExtraInfo from './components/ExtraInfo';
import { setStatus, resetBilling } from './modules/billing/slice';
import { handlePaymentScripts } from './hooks/useScripts';
import { forceNf, forcePlat } from './helpers/prelaunchData';
import useCurrency from './modules/currency/hooks/use-currency';
import { applyDevelopmentUI } from './helpers/development';
import SpecialOffer from './components/SpecialOffer';
import { setPickupMethods, setShippingMethodKey, setSelectedPickupMethod } from './modules/shipping/slice';
import {
  postCreateCart,
  postTrackCrash,
  selectCart,
  selectCheckout,
  resetShipping,
  selectApiStatus,
  setLoginData,
  setStatusEvent,
} from './store/slice';
import { useEndpoint } from './hooks/useEndpoint';
import { selectModal } from './modules/modal/slice';
import { Modal } from './modules/modal/container/modal';
import useTagManager from './hooks/useTagManager';
import { useStoreApi } from './hooks/useStoreApi';

import './App.scss';

const App = ({ flags }) => {
  const {
    setIsCheckoutItemsCollapsed,
    setIsCheckoutTermsModalOpen,
    isCheckoutItemsCollapsed,
    allowManualAddress,
    showLoadingPage,
    openPreOrder,
    showOverlay,
    editEmail,
    openPopup,
    done,
  } = useContext(UiContext);
  const { updatePortalSettings } = usePortalSettings();
  const { handlePaypalUrl, isPaypal } = usePaypal();
  const { handleAmazonUrl, isAmazonPay } = useAmazon();
  const { applePaySumary, handleApplepayUrl } = useApplepay();
  const { setShowLoading } = useSkeleton();
  const { handleReset } = useReset();
  const {
    getLocalStorage,
    setLocalStorage,
    removeLocalStorage,
    getSessionStorage,
    setSessionStorage,
    removeSessionStorage,
  } = useStoreApi();
  const { logOut } = usePlatform();
  const { setData: setCartTotalsData } = useCartTotals();
  const { set: setApiStatus } = useApiStatus();
  const { autofillForm } = useAutofill();
  const { patchEnrich, postAnalyticsStage1 } = useEndpoint();
  const {
    setApiShippingAddressResponse,
    setContextBillingAddress,
    setRegisteredPaymentMethods,
    setUserWasAuthorized,
    updateHelpfulLinks,
    setMoveToPlatform,
    updateWasLoggedIn,
    orderSubmitting,
    platformCartId,
    updateGiftInfo,
    setIsLoggedIn,
    updateCartId,
    updateCoupon,
    wasLoggedIn,
    updateEmail,
    merchantId,
    storeUrl,
    device,
    email,
  } = useContext(DataContext);
  const hideBody = isPaypal() || isAmazonPay();
  const { postGAMessage } = useTagManager();

  const cartData = useSelector(selectCart);
  const checkoutData = useSelector(selectCheckout);
  const statusApi = useSelector(selectApiStatus);
  const modalState = useSelector(selectModal);

  const { parseCurrency } = useCurrency();

  const dispatch = useDispatch();

  const doLogOut = async () => {
    await handleReset();
    updateWasLoggedIn(false);
    setIsLoggedIn(false);
    setUserWasAuthorized(true);
    await logOut();
  };

  const executeCreateCart = async payload => {
    try {
      const result = await dispatch(postCreateCart(payload)).unwrap();
      postAnalyticsStage1({ cartId: result.checkout.id, step: analyticsStage1.NOT_STARTED, hasLoaded: true });
      return result;
    } catch (error) {
      if (error.message === 'nf_error_race_condition') {
        throw error;
      }
      return null;
    }
  };

  useEffect(() => {
    const createCart = async () => {
      handlePaypalUrl();
      if (device?.isReady) {
        postGAMessage('page_view', {});

        // Create Cart
        await executeCreateCart({
          merchantId,
          lookupKey: platformCartId,
          device,
          hasInput: !!email,
          storeUrl,
          isBot: String((await load()).detect().bot),
        });

        setUserWasAuthorized(false);
        handleAmazonUrl();
        handleApplepayUrl();
        window.getVersion = () => {
          try {
            // eslint-disable-next-line global-require
            return require('../package.json').version;
          } catch (err) {
            return err;
          }
        };
      }
    };
    createCart();
  }, [device]);

  useEffect(() => {
    if (flags['disable-bigcom-checkout-form'] === true) {
      window.top.location.href = `${storeUrl}/${defaultUrl}?preventIframeLoad=true`;
    }
  }, [flags]);

  const handleLoginUser = async () => {
    const address = cartData?.customer?.addresses?.[0] || cartData?.suggestions[0]?.address;
    const loginEmail = cartData?.customer?.email;
    const resultEnrich = await patchEnrich({
      address,
      email: loginEmail,
    });

    let result = {};
    if (!address && resultEnrich?.cart?.suggestions[0]?.address?.hash) {
      result = await patchEnrich({
        address: resultEnrich?.cart?.suggestions[0]?.address,
        email: loginEmail,
      });
    } else {
      result = resultEnrich;
    }

    if (!result?.cart?.suggestions[0]?.payment?.id) {
      if (result?.checkout?.address?.hash) {
        // Update analytics when the logged in user has an address but no payment suggestion
        postAnalyticsStage1({ step: analyticsStage1.EMAIL });
        postAnalyticsStage1({ step: analyticsStage1.ADDRESS });
      } else if (result?.checkout?.customer?.email) {
        // Update analytics when the logged in user has no address and no payment suggestion
        postAnalyticsStage1({ step: analyticsStage1.EMAIL });
      }
    }

    setApiShippingAddressResponse(result);
    autofillForm(result.checkout, result.cart);
    updateEmail(loginEmail);
    dispatch(
      setLoginData({
        email: loginEmail,
        address: address || resultEnrich?.cart?.suggestions[0]?.address,
      }),
    );
    dispatch(setStatusEvent({ event: 'suggestion-login' }));
    setShowLoading(false);
  };

  useEffect(() => {
    if (cartData?.status === 'fulfilled' && device?.isReady) {
      if (
        cartData.merchant?.settings?.abTesting?.isEnabled &&
        cartData.merchant?.settings?.abTesting?.partition === 0 &&
        !forceNf
      ) {
        localStorage.removeItem('ab-testing-hash');
        window.top.location.href = `${storeUrl}/${defaultUrl}?preventIframeLoad=true`;
      }
      if (forcePlat) {
        window.top.location.href = `${storeUrl}/${defaultUrl}?preventIframeLoad=true`;
      }
      updateCartId(checkoutData?.id);
      const customLinks = {};
      for (let x = 0; x < cartData.merchant?.settings?.helpfulLinks?.customLinks?.length; x++) {
        const item = cartData.merchant?.settings?.helpfulLinks?.customLinks[x];
        customLinks[item.label] = item.link;
      }
      updateHelpfulLinks({
        ...cartData.merchant?.settings?.helpfulLinks,
        ...customLinks,
      });
      updatePortalSettings(cartData.merchant?.settings, cartData.merchant?.plugin);
      if (!cartData.merchant?.settings?.contactPhone?.isRequired) {
        dispatch(setStatus({ isReady: true }));
      }
      if (cartData?.payment) {
        const apiPaymentMethods = checkMethodsAvailable(cartData);
        setRegisteredPaymentMethods(apiPaymentMethods);
        setMoveToPlatform(cartData?.merchant?.settings?.paymentMethod || {});
        if (apiPaymentMethods) {
          handlePaymentScripts(apiPaymentMethods);
        }
      }
      if (checkoutData.suggest_email && email && email !== checkoutData.suggest_email && cartData?.customer) {
        dispatch(resetShipping());
        dispatch(resetBilling());
        if (!cartData.merchant?.settings?.contactPhone?.isRequired) {
          dispatch(setStatus({ isReady: true }));
        }
      }
      if (!email && isPopup && checkoutData.suggest_email) {
        updateEmail(checkoutData.suggest_email);
      }
      const activeCoupon = cartData.coupons[0] || {};
      const activeGiftCertificates = cartData?.giftCertificates?.length > 0;
      if (Object.keys(cartData.totals || {})?.length > 0) {
        setCartTotalsData(cartData.totals);
      }
      updateCoupon(activeCoupon);
      if (activeGiftCertificates) {
        updateGiftInfo(cartData?.giftCertificates);
      }
      parseCurrency(cartData);

      if (cartData?.customer?.email && statusApi?.event === 'createCart') {
        setShowLoading(false);
        handleLoginUser();
      }

      if (!checkoutData?.customer?.email) {
        setShowLoading(false);
      }

      if (statusApi?.event === 'createCart') {
        datadogRum.addAction('checkout-page-loaded');
      }

      if (checkoutData?.address) {
        setApiShippingAddressResponse({
          cart: cartData,
          checkout: checkoutData,
        });

        if (checkoutData?.billing?.address) {
          setContextBillingAddress(checkoutData.billing.address);
        } else {
          setContextBillingAddress(checkoutData.address);
        }
      }
      if (cartData.pickUpMethods) {
        dispatch(setPickupMethods(cartData.pickUpMethods));
      }
      if (checkoutData.shippingMethodKey) {
        dispatch(setShippingMethodKey(checkoutData.shippingMethodKey));
      }
      if (cartData.pickUpMethods && checkoutData.pickUpMethodKey) {
        const pickupMethod = cartData.pickUpMethods.find(method => method.lookupKey === checkoutData.pickUpMethodKey);

        if (pickupMethod) dispatch(setSelectedPickupMethod(pickupMethod));
      }
    } else if (!cartData && device?.isReady) {
      dispatch(
        postTrackCrash({
          merchantId,
          lookupKey: platformCartId,
          storeUrl,
        }),
      ).unwrap();
    }
  }, [cartData, device?.isReady]);

  useEffect(() => {
    const checkLogin = async () => {
      if (cartData?.status === 'fulfilled' && statusApi?.event === 'createCart') {
        if (cartData?.customer?.email) {
          setShowLoading(true);
          updateWasLoggedIn(true);
          setIsLoggedIn(true);
          setApiStatus({ isEmailReady: status.READY });
        } else {
          if (wasLoggedIn && Object.keys(wasLoggedIn).length !== 0) {
            doLogOut();
          }
          setIsLoggedIn(false);
          updateWasLoggedIn(false);
        }
      }
    };
    checkLogin();
  }, [cartData]);

  const handleLocalStorage = () => {
    window.nofraud = {
      cart: Object.fromEntries(
        Object.entries(cartData || {}).filter(
          ([key]) =>
            key !== 'merchant' &&
            key !== 'payer' &&
            key !== 'payment' &&
            key !== 'platform' &&
            key !== 'plugin' &&
            key !== 'scripts' &&
            key !== 'status' &&
            key !== 'suggestions',
        ),
      ),
      checkout: Object.fromEntries(
        Object.entries(checkoutData || {})
          .filter(
            ([key]) =>
              key === 'address' ||
              key === 'billing' ||
              key === 'customer' ||
              key === 'shippingMethodKey' ||
              key === 'pickUpMethodKey',
          )
          .map(([key, value]) => {
            switch (key) {
              case 'customer':
                return [key, { ...value, deviceID: undefined }];
              case 'address':
                return [key, { ...value, hash: undefined }];
              case 'billing':
                if (value && 'address' in value) {
                  return [key, { ...value, address: { ...value.address, hash: undefined } }];
                }
                return [key, value];
              default:
                return [key, value];
            }
          }),
      ),
      parent: {
        localStorage: {
          getItem: getLocalStorage,
          setItem: setLocalStorage,
          removeItem: removeLocalStorage,
        },
        sessionStorage: {
          getItem: getSessionStorage,
          setItem: setSessionStorage,
          removeItem: removeSessionStorage,
        },
      },
    };
  };

  useEffect(() => {
    if (cartData) {
      handleLocalStorage();
    }
  }, [cartData]);

  useEffect(() => {
    if (cartData.scripts) {
      cartData.scripts.forEach(script => {
        const { type, url, content, isParent } = script;
        if (!isParent) {
          if (type === 'url') {
            const scriptElement = document.createElement('script');
            scriptElement.src = url;
            document.body.appendChild(scriptElement);
          } else if (type === 'content') {
            const scriptElement = document.createElement('script');
            scriptElement.textContent = content;
            document.body.appendChild(scriptElement);
          }
        } else {
          // Load script in iFrame parent
          window.parent.postMessage(
            JSON.stringify({
              task: 'appendScript',
              url: script?.url,
              content: script?.content,
              type: script?.type?.toLowerCase(),
            }),
            '*',
          );
        }
      });
    }
  }, [cartData]);

  useEffect(() => {
    setTimeout(() => {
      document.body.classList.remove('preload');
    }, 7500);
  });

  useEffect(() => {
    if (navigator.appVersion.includes('iPhone')) {
      document.body.classList.add('iphone-styles');
    }
    if (navigator.appVersion.includes('Android')) {
      document.body.classList.add('android-styles');
      window.addEventListener('click', () => {
        if (document.activeElement.tagName === 'INPUT') {
          document.activeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }
      });
    }
    applyDevelopmentUI();
  }, []);

  const mobileItemsCollapsed = ({ id }) => {
    if (id === 'app-sidebar') {
      setIsCheckoutItemsCollapsed(!isCheckoutItemsCollapsed);
    }
  };

  const body = () => (
    <>
      <div
        style={{
          display: cartData?.totals?.total === undefined ? 'block' : 'none',
        }}
      >
        <SkeletonLoading />
      </div>
      <div
        style={{
          display: cartData?.totals?.total === undefined ? 'none' : 'block',
          maxHeight: '100vh',
        }}
      >
        <div className={`app-container ${done ? 'thank-you' : null}`}>
          <div className="app-wrapper">
            {isPopup && applePaySumary()}
            <div className={`app-main ${isCheckoutItemsCollapsed ? 'mb' : ''}`}>
              {!done ? <Checkout setOrderSubmitting={orderSubmitting} /> : <OrderDisplay />}
            </div>
            <div
              className={classNames({
                active: isCheckoutItemsCollapsed,
                'app-sidebar': true,
              })}
              id="app-sidebar"
              role="button"
              tabIndex="0"
              onKeyDown={null}
              onClick={event => mobileItemsCollapsed(event.target)}
            >
              <Cart type="default" />
              <Info isEnterAddressManuallyOpened={allowManualAddress} openTerms={setIsCheckoutTermsModalOpen} />
              <CloseWrapperBtn />
            </div>
            <OrderSubmit />
            {showLoadingPage && <LoadingPage />}
            {editEmail && <EditEmail />}
            {showOverlay && <Overlay />}
            {openPopup && <Popup />}
            {openPreOrder && <PreOrderModal />}
            <TermsPrivacyAbout />
          </div>
          {done && <ExtraInfo />}
          <SpecialOffer />
          {modalState.isVisible ? <Modal /> : null}
        </div>
      </div>
    </>
  );

  if (cartData?.status === 'ERROR' || checkoutData?.status === 'ERROR') {
    dispatch(
      postTrackCrash({
        merchantId,
        lookupKey: platformCartId,
        storeUrl,
      }),
    ).unwrap();
  }

  if (hideBody) {
    return null;
  }

  return body();
};

App.propTypes = {
  flags: PropTypes.shape({
    'disable-bigcom-checkout-form': PropTypes.bool,
  }),
};

export default featureFlagClient()(App);
