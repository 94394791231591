/* global paypal */
import { useContext } from 'react';

import DataContext from '../../store/dataContext';

const removeEmpty = obj => {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};

export const checkMethodsAvailable = cartData => {
  const apiPaymentMethods = removeEmpty({
    ...cartData?.merchant?.settings?.paymentMethod,
    amazonpay: cartData?.payment?.amazonpay || cartData?.payment?.amazonPay,
    paypal: {
      ...cartData?.payment?.paypal,
      isEnabled: !!cartData?.payment?.paypal?.clientId,
    },
    paylater: {
      isEnabled: cartData?.payment?.paypal?.paylater?.isEnabled,
    },
    venmo: {
      isEnabled: cartData?.payment?.paypal?.venmo?.isEnabled,
    },
    googlepay: {
      ...cartData?.payment?.gpay,
      isEnabled: !!cartData?.payment?.gpay?.gpayKeys,
    },
    applepay: {
      ...cartData?.payment?.applepay,
      isEnabled: !!cartData?.payment?.applepay?.applepayKeys,
    },
  });
  delete apiPaymentMethods.gpay;
  return apiPaymentMethods;
};

export const useCheckEligibility = () => {
  const { paymentMethodError, registeredPaymentMethods } = useContext(DataContext);

  const paypalButton = fundingSource =>
    paypal.Buttons({
      fundingSource,
      createOrder: (_data, actions) =>
        actions.order.create({
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: 10,
              },
            },
          ],
        }),
      onApprove: () => {},
      onError: () => {},
      onCancel: () => {},
    });

  const methods = {
    amazonpay: () => {
      if (
        !paymentMethodError?.amazonpay &&
        (registeredPaymentMethods?.amazonpay || registeredPaymentMethods?.amazonPay)
      ) {
        return true;
      }
      return false;
    },
    applepay: () => {
      if (!window.ApplePaySession) {
        return false;
      }
      if (!window.ApplePaySession.canMakePayments()) {
        return false;
      }
      return registeredPaymentMethods?.applepay?.isEnabled;
    },
    googlepay: () =>
      !paymentMethodError?.googlepay &&
      (registeredPaymentMethods?.googlepay?.isEnabled ||
        registeredPaymentMethods?.googlePay?.isEnabled ||
        registeredPaymentMethods?.gpay?.isEnabled),
    paylater: () => {
      let eligible = false;
      try {
        if (paypal?.getFundingSources()) {
          paypal.getFundingSources().forEach(fundingSource => {
            if (fundingSource === 'paylater') {
              eligible = paypalButton(fundingSource).isEligible();
            }
          });
        }
      } catch (err) {
        eligible = false;
      }
      return eligible;
    },
    paypal: () => {
      if (!paymentMethodError?.paypal && registeredPaymentMethods?.paypal?.isEnabled) {
        return true;
      }
      return false;
    },
    venmo: () => {
      let eligible = false;
      try {
        if (paypal?.getFundingSources()) {
          paypal.getFundingSources().forEach(fundingSource => {
            if (fundingSource === 'venmo') {
              eligible = paypalButton(fundingSource).isEligible();
            }
          });
        }
      } catch (err) {
        eligible = false;
      }
      return eligible;
    },
  };

  const checkMethod = method => {
    if (methods[method]) {
      return methods[method]();
    }

    return false;
  };

  return { checkMethod };
};
