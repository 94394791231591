import React from 'react';
import PropTypes from 'prop-types';

import { currentBaseUrl } from '../../helpers/urlConst';

const CouponCloseButton = ({ toggleFormVisibility }) => (
  <div className="close-form">
    <input
      className="coupon-button"
      type="image"
      alt="Close Coupon Form"
      src={`${currentBaseUrl()}assets/close.svg`}
      onClick={toggleFormVisibility}
    />
  </div>
);

CouponCloseButton.propTypes = {
  toggleFormVisibility: PropTypes.func,
};

export default CouponCloseButton;
