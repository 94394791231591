import { useContext } from 'react';

import DataContext from '../dataContext';
import * as actionTypes from './actionTypes';

const useCartTotals = () => {
  const { cartTotals, cartTotalsDispatch } = useContext(DataContext);

  const setLoading = () =>
    cartTotalsDispatch({
      type: actionTypes.CartTotals_SetLoading,
    });

  const setData = payload =>
    cartTotalsDispatch({
      type: actionTypes.CartTotals_SetData,
      payload,
    });

  const setError = payload =>
    cartTotalsDispatch({
      type: actionTypes.CartTotals_SetError,
      payload,
    });

  const reset = () => cartTotalsDispatch({ type: actionTypes.CartTotals_Reset });

  return {
    cartTotals,
    setLoading,
    setData,
    setError,
    reset,
  };
};

export default useCartTotals;
